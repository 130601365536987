import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  addProvider,
  retrieveProvider,
  updateCurrentProvider,
} from '../../../../../../../data/actions'
import validate from '../../../../../../../util/helpers/validate'
import { ProviderFormComponent } from '../component'

const ProviderFormContainer = ({
  header,
  onCancel,
  mode,
  provider,
  providerSlug,
  addNewProvider,
  updateProvider,
  getProvider,
}) => {
  const baseProvider = {
    name: providerSlug && provider ? provider.name : '',
    description: providerSlug && provider ? provider.description : '',
    cloudProvider: providerSlug && provider ? provider.cloudProvider : false,
  }
  useEffect(() => {
    if (providerSlug) {
      getProvider(providerSlug)
    }
  }, [])

  const controls = {
    name: {
      value: baseProvider.name,
      valid: validate(baseProvider.name, { minLength: 1 }),
      touched: Boolean(provider),
      validationRules: {
        minLength: 1,
      },
    },
    description: {
      value: baseProvider.description,
      valid: true,
      touched: Boolean(provider),
      validationRules: {},
    },
    cloudProvider: {
      value: baseProvider.cloudProvider,
      valid: true,
      touched: Boolean(provider),
      validationRules: {},
    },
  }
  const [state, setState] = useState(controls)

  const updateInputState = (control, value) => {
    setState({
      ...state,
      [control]: {
        ...state[control],
        value: value,
        touched: true,
        valid: validate(value, controls[control].validationRules),
      },
    })
  }

  useEffect(() => {
    if (providerSlug && provider) {
      setState(controls)
    }
  }, [provider])

  const onSubmit = () => {
    const newProvider = {
      name: state.name.value,
      description: state.description.value,
      cloudProvider: state.cloudProvider.value,
    }
    mode === 'edit'
      ? updateProvider({
          ...provider,
          ...newProvider,
        })
      : addNewProvider(newProvider)
  }

  return (
    <ProviderFormComponent
      header={header}
      mode={mode}
      controls={state}
      updateInputState={updateInputState}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  )
}

ProviderFormContainer.propTypes = {
  header: PropTypes.string,
  onCancel: PropTypes.func,
  mode: PropTypes.string,
  provider: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.string,
    cloudProvider: PropTypes.bool,
    id: PropTypes.number,
  }),
  providerSlug: PropTypes.string,
  addNewProvider: PropTypes.func,
  updateProvider: PropTypes.func,
  getProvider: PropTypes.func,
}

export const ProviderForm = connect(
  ({ backOffice: { provider } }) => ({
    provider,
  }),
  {
    addNewProvider: addProvider,
    updateProvider: updateCurrentProvider,
    getProvider: retrieveProvider,
  },
)(ProviderFormContainer)
