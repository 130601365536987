import React from 'react'
import PropTypes from 'prop-types'
import { TaggedLabelsList } from './TaggedLabelsList'
import { ReactSelectCreatable } from '../../../../../../../common'

export const LabelSelector = ({
  editMode,
  onLabelInputChange,
  value,
  options,
}) => {
  return (
    <div className="tenant-members__labels-form">
      {editMode ? (
        <ReactSelectCreatable
          value={value}
          options={options}
          onLabelInputChange={onLabelInputChange}
          onBlur={() => onLabelInputChange(value)}
        />
      ) : (
        <TaggedLabelsList tags={value} labelsToDisplay={2} />
      )}
    </div>
  )
}

LabelSelector.propTypes = {
  editMode: PropTypes.bool,
  value: PropTypes.array,
  options: PropTypes.array,
  onLabelInputChange: PropTypes.func,
}
