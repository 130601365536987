import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getTenantMembersWithPermissions } from '../../../../../../../data/actions'
import { TenantMembersDialogComponent } from '../component'

const TenantMembersDialogContainer = ({ onClose, getTenantInfo }) => {
  useEffect(() => {
    getTenantInfo()
  }, [])

  return <TenantMembersDialogComponent onClose={onClose} />
}

TenantMembersDialogContainer.propTypes = {
  onClose: PropTypes.func,
  getTenantInfo: PropTypes.func,
}

export const TenantMembersDialog = connect(null, {
  getTenantInfo: getTenantMembersWithPermissions,
})(TenantMembersDialogContainer)
