import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { PackageServicesComponent } from '../component'
import { fetchPlatformPackageServices } from '../../../../../../../http'

export const PackageServices = ({ packageId }) => {
  const [services, setServices] = useState([])
  useEffect(() => {
    const updateServices = async (pkgId) => {
      const newServices = await fetchPlatformPackageServices(pkgId)
      setServices(newServices)
    }
    if (packageId) {
      updateServices(packageId)
    }
  }, [packageId])
  return (
    <PackageServicesComponent
      services={services}
      packageIsActive={Boolean(packageId)}
    />
  )
}

PackageServices.propTypes = {
  packageId: PropTypes.number,
}
