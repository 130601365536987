import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  ModalContent,
  ModalFooter,
  Button,
  CheckBox,
  Tag,
} from '../../../../../common'

export const AcceptBulkActionModal = ({
  data: {
    tenantUserRoleExpiryDate,
    users,
    addedRoles = [],
    deletedRoles = [],
    dateChanged,
    addedLabels = [],
    deletedLabels = [],
  },
  action,
  onApprove,
  onCancel,
}) => {
  const [approved, setApproved] = useState(false)

  const headline = {
    extend: `Extend users Until ${tenantUserRoleExpiryDate}`,
    remove: 'Are you sure you want to remove the following user(s) :',
    changeRoles: `You are editing user ${users[0].firstName} ${users[0].lastName} (${users[0].daimlerId}):`,
    addLabel: `You are adding labels to users:`,
  }

  return (
    <div className="bulk-add-users">
      <ModalContent headline={headline[action]}>
        <div>
          {action === 'extend' ? (
            <h3 className="wb-margin-bottom-xs wb-type-heading-s">User (s)</h3>
          ) : null}
          {action !== 'changeRoles' ? (
            <div className="wb-card wb-type-copy wb-padding-vertical-xxs wb-margin-bottom-xs bulk-add-users__section">
              {users.map(({ firstName, lastName, daimlerId }) => (
                <Tag
                  variant="tertiary"
                  className="wb-margin-start-xxs wb-margin-vertical-xxs"
                  label={`${firstName} ${lastName}`}
                  key={daimlerId}
                />
              ))}
            </div>
          ) : null}
          {addedRoles.length ? (
            <>
              <h3 className="wb-margin-bottom-xs wb-type-heading-s">
                Roles being added:
              </h3>
              <div className="wb-card wb-card--smoke wb-type-copy wb-padding-vertical-xxs wb-margin-bottom-xs bulk-add-users__section">
                {addedRoles.map(({ label, value }) => (
                  <Tag
                    variant="tertiary"
                    className="wb-margin-start-xxs wb-margin-vertical-xxs"
                    label={label}
                    key={value}
                  />
                ))}
              </div>
            </>
          ) : null}
          {deletedRoles.length ? (
            <>
              <h3 className="wb-margin-bottom-xs wb-type-heading-s">
                Roles being removed:
              </h3>
              <div className="wb-card wb-card--smoke wb-type-copy wb-padding-vertical-xxs wb-margin-bottom-xs bulk-add-users__section">
                {deletedRoles.map(({ label, value }) => (
                  <Tag
                    variant="tertiary"
                    className="wb-margin-start-xxs wb-margin-vertical-xxs"
                    label={label}
                    key={value}
                  />
                ))}
              </div>
            </>
          ) : null}
          {dateChanged ? (
            <>
              <h3 className="wb-margin-bottom-xs wb-type-heading-s">
                Member until date changed:
              </h3>
              <div className="wb-card wb-card--smoke wb-type-copy wb-padding-vertical-xxs wb-margin-bottom-xs bulk-add-users__section wb-padding-start-xs">
                {users[0].tenantMemberUntil}
              </div>
            </>
          ) : null}
          {addedLabels.length ? (
            <>
              <h3 className="wb-margin-bottom-xs wb-type-heading-s">
                Labels being added:
              </h3>
              <div className="wb-card wb-card--smoke wb-type-copy wb-padding-vertical-xxs wb-margin-bottom-xs bulk-add-users__section">
                {addedLabels.map(({ label, value }) => (
                  <Tag
                    variant="tertiary"
                    className="wb-margin-start-xxs wb-margin-vertical-xxs"
                    label={label}
                    key={value}
                  />
                ))}
              </div>
            </>
          ) : null}
          {deletedLabels.length ? (
            <>
              <h3 className="wb-margin-bottom-xs wb-type-heading-s">
                Labels being removed:
              </h3>
              <div className="wb-card wb-card--smoke wb-type-copy wb-padding-vertical-xxs wb-margin-bottom-xs bulk-add-users__section">
                {deletedLabels.map(({ label, value }) => {
                  return (
                    <Tag
                      variant="tertiary"
                      className="wb-margin-start-xxs wb-margin-vertical-xxs"
                      label={label}
                      key={value}
                    />
                  )
                })}
              </div>
            </>
          ) : null}
        </div>

        <CheckBox
          className="wb-margin-vertical-xs"
          checked={approved}
          label="I confirm that I have reviewed all users access and privileges
                needed to the product"
          onChange={() => setApproved(!approved)}
        />

        <ModalFooter>
          <Button variant="secondary" size="small" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="primary"
            size="small"
            disabled={!approved}
            onClick={onApprove}
          >
            Approve
          </Button>
        </ModalFooter>
      </ModalContent>
    </div>
  )
}

AcceptBulkActionModal.propTypes = {
  data: PropTypes.shape({
    tenantUserRoleExpiryDate: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        daimlerId: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        tenantMemberUntil: PropTypes.string,
      }),
    ),
    addedRoles: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    deletedRoles: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    addedLabels: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    deletedLabels: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    dateChanged: PropTypes.bool,
  }),
  action: PropTypes.string,
  onApprove: PropTypes.func,
  onCancel: PropTypes.func,
}
