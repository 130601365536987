import React from 'react'
import PropTypes from 'prop-types'

import { CheckBox } from '../../../../../../../common'

export const ItemList = ({
  value,
  disabled,
  event,
  onCheckBoxClick,
  chooseForAll,
}) => {
  return (
    <div key={event.slug} style={{ display: 'flex' }}>
      <CheckBox
        disabled={disabled}
        key={event.slug}
        className="wb-margin-bottom-xs"
        checked={value.includes(event.slug) || chooseForAll}
        onChange={() => onCheckBoxClick(event.slug)}
      />
      <p>{event.name}</p>
    </div>
  )
}

ItemList.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  event: PropTypes.shape({
    slug: PropTypes.string,
    name: PropTypes.string,
  }),
  onCheckBoxClick: PropTypes.func,
  chooseForAll: PropTypes.bool,
}
