export const isInYear = (booking, year) => {
  const { startDate, endDate } = booking
  const startYear = new Date(startDate).getFullYear()
  const endYear = endDate ? new Date(endDate).getFullYear() : 3000
  return year >= startYear && year <= endYear
}

export const monthlyPrice = (booking, year) => {
  const nextYear = new Date().getFullYear() + 1
  const { prices } = booking
  const providedPrice = prices?.find(
    (price) => parseInt(price.year) === year,
  )?.monthlyPrice
  // assume price in next year will be the same as in the current year, if not provided
  if ([null, undefined, NaN, ''].includes(providedPrice) && year === nextYear) {
    return monthlyPrice(booking, nextYear - 1)
  }
  return providedPrice ?? NaN
}

export const bookingMonthsForYear = (booking, year) => {
  const startDateSplit = booking.startDate.split('-')
  const endDateSplit = booking.endDate
    ? booking.endDate.split('-')
    : ['3000', '12', '31']
  let startDate = new Date(
    Date.UTC(
      parseInt(startDateSplit[0]),
      parseInt(startDateSplit[1]) - 1,
      parseInt(startDateSplit[2]),
    ),
  )
  let endDate = new Date(
    Date.UTC(
      parseInt(endDateSplit[0]),
      parseInt(parseInt(endDateSplit[1])) - 1,
      parseInt(endDateSplit[2]),
    ),
  )

  if (startDate.getUTCFullYear() < year) {
    startDate = new Date(Date.UTC(year, 0, 1))
  }
  if (endDate.getUTCFullYear() > year) {
    endDate = new Date(Date.UTC(year, 11, 31))
  }

  let months = 1
  while (startDate.getUTCMonth() !== endDate.getUTCMonth()) {
    months++
    startDate.setUTCMonth(startDate.getUTCMonth() + 1)
  }
  return months
}

export const bookingTotalForYear = (booking, year) =>
  bookingMonthsForYear(booking, year) * monthlyPrice(booking, year)

export const bookingsDataToArray = (bookings) => {
  const startYear = 2019
  const nextYear = new Date().getFullYear() + 1
  const bookingsByYear = []
  for (let year = startYear; year <= nextYear; year++) {
    const bookedPackages = {}
    for (const booking of bookings) {
      const { provider } = booking
      if (isInYear(booking, year)) {
        const bookedPackage = {
          ...booking,
          total: bookingTotalForYear(booking, year),
          monthlyPrice: monthlyPrice(booking, year),
        }
        if (!Array.isArray(bookedPackages[provider])) {
          bookedPackages[provider] = []
        }
        bookedPackages[provider].push(bookedPackage)
      }
    }
    bookingsByYear.push({
      year,
      bookedPackages,
    })
  }
  return bookingsByYear
}

export const totalsByYear = (bookingsDataArray) => {
  const totals = new Map()
  bookingsDataArray.forEach((entry) => {
    const { year, bookedPackages } = entry
    const total = Object.values(bookedPackages)
      .flat(2)
      .map((provider) => provider.total)
      .reduce((sum, current) => sum + current, 0)
    totals.set(year, total)
  })
  return totals
}

export const numberToPrice =
  (alt = 'Error') =>
  (euros) => {
    if (isNaN(euros)) {
      return alt
    }
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(euros)
  }

export const costOverviewColumns = [
  { label: 'Package', property: 'packageDisplayName', width: '17.5%' },
  { label: 'Price Variant', property: 'priceVariantName', width: '17.5%' },
  { label: 'Comment', property: 'comment', width: '15%' },
  {
    label: 'Price per month',
    property: 'monthlyPrice',
    width: '10%',
    format: numberToPrice('missing'),
    right: true,
  },
  { label: 'Start date', property: 'startDate', width: '17.5%', right: true },
  { label: 'End date', property: 'endDate', width: '17.5%', right: true },
  {
    label: 'Total',
    property: 'yearlyPrice',
    width: '7.5%',
    format: numberToPrice('cannot be calculated'),
    right: true,
  },
]
