import React from 'react'
import PropTypes from 'prop-types'
import { ServiceItem } from './ServiceItem'

export const showHint = (packageIsActive) =>
  packageIsActive
    ? 'No services found for the selected package'
    : 'Choose package'

export const PackageServicesComponent = ({ services, packageIsActive }) => (
  <div className="wb-margin-vertical-xs">
    <h3>Package Services</h3>
    {services.length ? (
      <ul className="packages-editor__list package-services__list">
        {services.map((service) => (
          <ServiceItem key={service.id} service={service} />
        ))}
      </ul>
    ) : (
      showHint(packageIsActive)
    )}
  </div>
)

PackageServicesComponent.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  packageIsActive: PropTypes.bool,
}
