import React from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  TextArea,
  Select,
  RadioButtonGroup,
} from '../../../../../common'
import { ControllingForm } from '../components/ControllingForm'
import { FormActions } from '../components/FormActions'
import {
  tenantStatuses,
  generateInputError,
  controllingProviders,
} from '../controls'

export const TenantFormComponent = ({
  editMode,
  controls,
  arts,
  updateInputState,
  onCancel,
  onSave,
}) => (
  <div className="wb-padding-bottom-xs">
    <FormActions onCancel={onCancel} onSave={onSave} />
    <div className="tenant-form">
      {!editMode ? (
        <div className="wb-padding-bottom-xs input-wrapper">
          <Input
            label="* Acronym"
            value={controls.acronym.value}
            error={generateInputError(controls, 'acronym')}
            hint="The acronym used to identify the tenant"
            onChange={(e) => updateInputState('acronym', e.target.value)}
          />
        </div>
      ) : null}
      <div className="wb-padding-bottom-xs input-wrapper">
        <Input
          label="* Name"
          value={controls.name.value}
          error={generateInputError(controls, 'name')}
          hint="The full name of the experience, service, platform component where the acronym was derived from"
          onChange={(e) => updateInputState('name', e.target.value)}
        />
      </div>
      <div className="wb-padding-bottom-xs input-wrapper">
        <TextArea
          label="* Description"
          value={controls.description.value}
          error={generateInputError(controls, 'description')}
          hint="A brief summary or essential comments about the tenant goal to reach"
          onChange={(e) => updateInputState('description', e.target.value)}
        />
      </div>
      <div className="wb-padding-bottom-xs input-wrapper">
        <Input
          label="* Program Name"
          value={controls.programName.value}
          error={generateInputError(controls, 'programName')}
          hint="The program the tenant is associated with"
          onChange={(e) => updateInputState('programName', e.target.value)}
        />
      </div>
      <div className="wb-padding-bottom-xs input-wrapper">
        <RadioButtonGroup
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
          name="partOfDigitalHouse"
          error={generateInputError(controls, 'partOfDigitalHouse')}
          checkedValue={controls.partOfDigitalHouse.value}
          onChange={(e) =>
            updateInputState('partOfDigitalHouse', e.target.value === 'true')
          }
          label="Part of the Digital House (DH)"
          hint="Whether the tenant is part of the digital house or not"
        />
      </div>
      <div className="wb-padding-bottom-xs input-wrapper">
        <Select
          label="Agile Release Train (ART)"
          error={generateInputError(controls, 'agileReleaseTrain')}
          value={controls.agileReleaseTrain.value}
          onChange={(e) =>
            updateInputState('agileReleaseTrain', e.target.value)
          }
          placeholder="Please select"
          hint="Agile Release Train (ART)"
          options={arts.map(({ key, value }) => ({ name: value, id: key }))}
        />
      </div>
      <div className="wb-padding-bottom-xs input-wrapper">
        {controllingProviders.map((controllingProvider) => {
          const providerControl = `${controllingProvider}ControllingInformation`
          return (
            controls[providerControl] && (
              <ControllingForm
                key={controllingProvider}
                provider={controllingProvider}
                controls={controls[providerControl]}
                onChange={(newControllingState) =>
                  updateInputState(providerControl, newControllingState)
                }
              />
            )
          )
        })}
      </div>
      <div className="wb-padding-bottom-xs input-wrapper">
        <Input
          label="AWS account id"
          type="string"
          hint="Only visible and editable for admins"
          value={controls.awsAccountId.value}
          error={generateInputError(controls, 'awsAccountId')}
          onChange={(e) => updateInputState('awsAccountId', e.target.value)}
        />
      </div>
      <div className="wb-padding-bottom-xs input-wrapper">
        <RadioButtonGroup
          items={tenantStatuses}
          label="* Status"
          checkedValue={controls.status.value}
          name="tenantStatus"
          error={generateInputError(controls, 'status')}
          onChange={(e) => updateInputState('status', e.target.value)}
          hint="The current status of project. Available options: - active: the project is currently active - idle: the project is currently stale but not archived - archived: the project is not going to be worked on anymore"
        />
      </div>
      <div className="wb-padding-bottom-xs input-wrapper">
        <Input
          label="* APP-ID (LeanIX)"
          type="number"
          hint="An internal ID representing a specific version of a tenant application in Daimler Databases"
          value={controls.planningItId.value}
          error={generateInputError(controls, 'planningItId')}
          onChange={(e) => updateInputState('planningItId', e.target.value)}
        />
      </div>
      <div className="wb-padding-bottom-xs input-wrapper">
        <Input
          label="Documentation link"
          hint="Add an external link for additional documentation."
          value={controls.docLink.value}
          error={generateInputError(controls, 'docLink')}
          placeholder="http://"
          onChange={(e) => updateInputState('docLink', e.target.value)}
        />
      </div>
      <div className="wb-padding-bottom-xs input-wrapper">
        <Input
          label="* Cost Center / CO Number"
          hint="The cost operational number that the tenant is associated with. Examples: [000-1990, co123456789, 5674846]"
          value={controls.coNumber.value}
          error={generateInputError(controls, 'coNumber')}
          onChange={(e) => updateInputState('coNumber', e.target.value)}
        />
      </div>
    </div>
    <FormActions onCancel={onCancel} onSave={onSave} />
  </div>
)

TenantFormComponent.propTypes = {
  editMode: PropTypes.bool,
  controls: PropTypes.shape({
    acronym: PropTypes.shape({ value: PropTypes.string }),
    name: PropTypes.shape({ value: PropTypes.string }),
    description: PropTypes.shape({ value: PropTypes.string }),
    programName: PropTypes.shape({ value: PropTypes.string }),
    partOfDigitalHouse: PropTypes.shape({ value: PropTypes.bool }),
    agileReleaseTrain: PropTypes.shape({ value: PropTypes.string }),
    awsAccountId: PropTypes.shape({ value: PropTypes.string }),
    status: PropTypes.shape({ value: PropTypes.string }),
    planningItId: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    docLink: PropTypes.shape({ value: PropTypes.string }),
    coNumber: PropTypes.shape({ value: PropTypes.string }),
  }),
  arts: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  updateInputState: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}
