import React from 'react'
import { Button, Tag } from '../../../../../../common'
import { TDMS_ROLES_LEGACY } from '../../../constants'

export const generateColumns = (onEditUser) => [
  {
    header: 'Name',
    accessorKey: 'name',
    accessorFn: (row) => `${row.firstName} ${row.lastName}`,
    size: 200,
  },
  {
    header: 'Email',
    accessorKey: 'email',
    accessorFn: (row) => row.email,
    size: 200,
  },
  {
    header: 'DaimlerId',
    accessorKey: 'daimlerId',
    accessorFn: (row) => row.daimlerId,
    size: 200,
  },
  {
    header: 'Roles',
    accessorFn: (row) =>
      row.userInfo.roles.map((role) => TDMS_ROLES_LEGACY[role].label).join(''),
    size: 200,
    cell: ({ row }) =>
      row.original.userInfo.roles.map((role) => (
        <Tag
          key={role}
          label={TDMS_ROLES_LEGACY[role].label}
          variant="tertiary"
          className="wb-margin-end-xxs"
        />
      )),
  },
  {
    header: 'Actions',
    maxSize: 200,
    cell: ({ row }) => (
      <Button
        size="small"
        iconOnly
        variant="secondary"
        withIcon="bds/pencil/24"
        onClick={() => onEditUser(row.original)}
      >
        Delete
      </Button>
    ),
  },
]
