import {
  SET_PACKAGES,
  ADD_PACKAGE,
  UPDATE_PACKAGE,
  SET_SERVICES,
  ADD_PLATFORM_SERVICE,
  SET_PACKAGE_PRICE_VARIANTS,
  ADD_PACKAGE_PRICE_VARIANT,
  UPDATE_PACKAGE_PRICE_VARIANT,
  REMOVE_PACKAGE_PRICE_VARIANT,
  UPDATE_PLATFORM_SERVICE,
  SET_PACKAGE_SERVICES,
  SET_SERVICE_PERMISSIONS,
  SET_SERVICE_PROPERTIES,
  ADD_SERVICE_PROPERTY,
  UPDATE_SERVICE_PROPERTY,
  SET_PROPERTY,
  REMOVE_SERVICE_PROPERTY,
  SET_SERVICE_PERMISSION,
  ADD_PERMISSION_TO_SERVICE,
  UPDATE_SERVICE_PERMISSION,
  REMOVE_PERMISSION_FROM_SERVICE,
  ATTACH_SERVICE_TO_PACKAGE,
  DETACH_SERVICE_FROM_PACKAGE,
  SET_PACKAGE,
  SET_SERVICE,
  SET_MODAL,
  SET_PACKAGE_PRICES,
  ADD_PACKAGE_PRICE,
  UPDATE_PACKAGE_PRICE,
  SET_SERVICE_PROVIDERS,
  SET_ACTIVE_PACKAGE_PRICE,
  SET_PROVIDER,
  SET_PROVIDERS,
  SET_PROVIDER_USERS,
  SET_TDMS_USERS_WITH_ROLES,
  SET_BACKOFFICE_BOOKING,
  SET_BACKOFFICE_BOOKINGS,
} from '../../../../actionsTypes'

export const setPackages = (packages) => ({
  type: SET_PACKAGES,
  payload: packages,
})
export const addPackage = (newPackage) => ({
  type: ADD_PACKAGE,
  payload: newPackage,
})
export const updatePackage = (currentPackage) => ({
  type: UPDATE_PACKAGE,
  payload: currentPackage,
})
export const setServices = (services) => ({
  type: SET_SERVICES,
  payload: services,
})
export const setPackageServices = (packageServices) => ({
  type: SET_PACKAGE_SERVICES,
  payload: packageServices,
})
export const addService = (service) => ({
  type: ADD_PLATFORM_SERVICE,
  payload: service,
})
export const editService = (service) => ({
  type: UPDATE_PLATFORM_SERVICE,
  payload: service,
})
export const setServicePermissions = (permissions) => ({
  type: SET_SERVICE_PERMISSIONS,
  payload: permissions,
})
export const setPkgPriceVariants = (packageSlug, priceVariants) => ({
  type: SET_PACKAGE_PRICE_VARIANTS,
  payload: { packageSlug, priceVariants },
})
export const addPkgPriceVariant = (packageSlug, priceVariant) => ({
  type: ADD_PACKAGE_PRICE_VARIANT,
  payload: { packageSlug, priceVariant },
})
export const updatePkgPriceVariant = (packageSlug, priceVariant) => ({
  type: UPDATE_PACKAGE_PRICE_VARIANT,
  payload: { packageSlug, priceVariant },
})
export const removePkgPriceVariant = (packageSlug, priceVariantSlug) => ({
  type: REMOVE_PACKAGE_PRICE_VARIANT,
  payload: { packageSlug, priceVariantSlug },
})
export const setServiceProperties = (properties) => ({
  type: SET_SERVICE_PROPERTIES,
  payload: properties,
})
export const addServiceProperty = (property) => ({
  type: ADD_SERVICE_PROPERTY,
  payload: property,
})
export const editServiceProperty = (property) => ({
  type: UPDATE_SERVICE_PROPERTY,
  payload: property,
})
export const setProperty = (property) => ({
  type: SET_PROPERTY,
  payload: property,
})
export const removeProperty = (propertyId) => ({
  type: REMOVE_SERVICE_PROPERTY,
  payload: propertyId,
})
export const setServicePermission = (permission) => ({
  type: SET_SERVICE_PERMISSION,
  payload: permission,
})
export const addPermissionToCurrentService = (permission) => ({
  type: ADD_PERMISSION_TO_SERVICE,
  payload: permission,
})
export const updateCurrentServicePermission = (permission) => ({
  type: UPDATE_SERVICE_PERMISSION,
  payload: permission,
})
export const detachPermissionFromService = (permissionId) => ({
  type: REMOVE_PERMISSION_FROM_SERVICE,
  payload: permissionId,
})
export const addServiceToPackage = (service) => ({
  type: ATTACH_SERVICE_TO_PACKAGE,
  payload: service,
})
export const removeServiceFromPackage = (service) => ({
  type: DETACH_SERVICE_FROM_PACKAGE,
  payload: service,
})
export const setPackage = (currentPackage) => ({
  type: SET_PACKAGE,
  payload: currentPackage,
})
export const setService = (service) => ({
  type: SET_SERVICE,
  payload: service,
})
export const setModal = (modalObj) => ({
  type: SET_MODAL,
  payload: modalObj,
})
export const setPackagePrices = (prices) => ({
  type: SET_PACKAGE_PRICES,
  payload: prices,
})
export const addPackagePrice = (newPrice) => ({
  type: ADD_PACKAGE_PRICE,
  payload: newPrice,
})
export const setPackagePrice = (price) => ({
  type: SET_ACTIVE_PACKAGE_PRICE,
  payload: price,
})
export const updatePackagePrice = (currentPrice) => ({
  type: UPDATE_PACKAGE_PRICE,
  payload: currentPrice,
})
export const setServiceProviders = (providers) => ({
  type: SET_SERVICE_PROVIDERS,
  payload: providers,
})
export const setProviders = (providers) => ({
  type: SET_PROVIDERS,
  payload: providers,
})
export const setProvider = (provider) => ({
  type: SET_PROVIDER,
  payload: provider,
})
export const setProviderUsers = (providerUsers) => ({
  type: SET_PROVIDER_USERS,
  payload: providerUsers,
})
export const setTdmsUsersWithRoles = (users) => ({
  type: SET_TDMS_USERS_WITH_ROLES,
  payload: users,
})
export const setBookings = (bookings) => ({
  type: SET_BACKOFFICE_BOOKINGS,
  payload: bookings,
})
export const setBooking = (booking) => ({
  type: SET_BACKOFFICE_BOOKING,
  payload: booking,
})
