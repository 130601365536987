import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import validate from '../../../../../util/helpers/validate'
import {
  tenantFormControls,
  populateStateWithTenantData,
  populateTenantPayloadFromFormState,
} from './controls'
import { TenantFormComponent } from './component'

export const TenantForm = ({ tenant, arts, onCancel, onSave }) => {
  const [state, setState] = useState(tenantFormControls)

  const updateInputState = (control, value) => {
    if (typeof value === 'object') {
      setState({
        ...state,
        [control]: value,
      })
    } else {
      setState({
        ...state,
        [control]: {
          ...state[control],
          value: value,
          touched: true,
          valid: validate(value, tenantFormControls[control].validationRules),
        },
      })
    }
  }

  useEffect(() => {
    if (tenant.acronym) {
      setState(populateStateWithTenantData(tenant))
    }
  }, [tenant])

  const onSaveData = () =>
    onSave(populateTenantPayloadFromFormState(tenant, state))
  return (
    <TenantFormComponent
      editMode={!!tenant.acronym}
      tenant={tenant}
      controls={state}
      arts={arts}
      onCancel={onCancel}
      onSave={onSaveData}
      updateInputState={updateInputState}
    />
  )
}

TenantForm.propTypes = {
  tenant: PropTypes.shape({
    acronym: PropTypes.string,
    providers: PropTypes.arrayOf(PropTypes.string),
  }),
  arts: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}
