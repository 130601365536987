import validate from '../../../../../util/helpers/validate'

export const tenantStatuses = [
  { label: 'Active', value: 'active' },
  { label: 'Idle', value: 'idle' },
  { label: 'Archived', value: 'archived' },
]

export const controllingProviders = ['nebula', 'b2xcore']

export const baseControllingInfo = {
  costCenter: '',
  costNumber: '',
  pspNumber: '',
  isaNumber: '',
  purchaseOrder: '',
}

export const controllingFormControls = {
  costCenter: {
    value: '',
    valid: false,
    touched: false,
    error: 'CostCenter is required',
    validationRules: {
      minLength: 1,
    },
  },
  costNumber: {
    value: '',
    valid: false,
    error: 'Internal Order Number is required',
    touched: false,
    validationRules: {
      minLength: 1,
    },
  },
  pspNumber: {
    value: '',
    valid: false,
    error: 'PSP Number should be a numeric',
    touched: false,
    validationRules: {},
  },
  isaNumber: {
    value: '',
    valid: false,
    touched: false,
    error: 'ISA Number should be a numeric',
    validationRules: {},
  },
  purchaseOrder: {
    value: '',
    valid: false,
    touched: false,
    error: 'Purchase Order should be a numeric',
    validationRules: {},
  },
}

export const tenantFormControls = {
  acronym: {
    value: '',
    valid: false,
    touched: false,
    error: 'Acronym is required and it should be at most 10 symbols',
    validationRules: {
      minLength: 1,
      maxLength: 10,
    },
  },
  name: {
    value: '',
    valid: false,
    touched: false,
    error: 'Name is required field',
    validationRules: {
      minLength: 1,
    },
  },
  description: {
    value: '',
    error: 'Description is required',
    valid: false,
    touched: false,
    validationRules: {
      minLength: 1,
    },
  },
  programName: {
    value: '',
    valid: false,
    touched: false,
    error: 'Program name is required field',
    validationRules: {
      minLength: 1,
    },
  },
  partOfDigitalHouse: {
    value: false,
    valid: false,
    touched: false,
    validationRules: {
      oneOf: [true, false],
    },
  },
  agileReleaseTrain: {
    value: '',
    valid: true,
    touched: false,
    validationRules: {},
  },
  awsAccountId: {
    value: '',
    valid: false,
    touched: false,
    error: 'AWS Account ID should be a numeric',
    validationRules: {},
  },
  status: {
    value: '',
    valid: false,
    error: 'Status is required',
    touched: false,
    validationRules: {
      oneOf: tenantStatuses.map(({ value }) => value),
    },
  },
  planningItId: {
    value: 0,
    valid: false,
    touched: false,
    error: 'APP-ID (LeanIX) is required field',
    validationRules: {
      isNumeric: true,
    },
  },
  docLink: {
    value: '',
    valid: false,
    touched: false,
    error: 'Doc link should be a valid URL',
    validationRules: {
      isUrlOrEmpty: true,
    },
  },
  coNumber: {
    value: '',
    valid: false,
    error: 'Cost Center / CO Number is required as numeric value',
    touched: false,
    validationRules: {
      minLength: 1,
    },
  },
  nebulaControllingInformation: controllingFormControls,
}

export const generateInputError = (state, control) =>
  state[control].touched && !state[control].valid && state[control].error

export const populateStateWithTenantData = (tenant) => ({
  ...populateStateWithData(tenantFormControls, tenant),
  ...generateControllingInfoState(tenant),
})

export const populateStateWithData = (state, data) => {
  const newState = JSON.parse(JSON.stringify(state))
  Object.keys(newState).forEach((control) =>
    updateStateWithValue(control, data[control], newState),
  )
  return newState
}

const updateStateWithValue = (control, value, state) => {
  state[control].value = value
  state[control].touched = true
  state[control].valid = validate(value, state[control].validationRules)
}

export const generateControllingInfoState = (tenant) => {
  const tenantControllingInfoState = {}
  const tenantControllingInfo = generateControllingInfo(tenant)
  Object.keys(tenantControllingInfo).forEach((infoControl) => {
    tenantControllingInfoState[infoControl] = populateStateWithData(
      controllingFormControls,
      tenantControllingInfo[infoControl],
    )
  })
  return tenantControllingInfoState
}

export const generateControllingInfo = (tenant) => {
  const tenantControlling = {
    nebulaControllingInformation:
      tenant.nebulaControllingInformation || baseControllingInfo,
  }
  tenant.providers?.forEach((provider) => {
    if (controllingProviders.includes(provider)) {
      const controllingInfoControl = `${provider}ControllingInformation`
      tenantControlling[controllingInfoControl] =
        tenant[controllingInfoControl] || baseControllingInfo
    }
  })
  return tenantControlling
}

export const populateTenantPayloadFromFormState = (tenant, state) => ({
  ...tenant,
  ...generatePayloadFromControlsState(state),
})

export const generatePayloadFromControlsState = (controlsState) => {
  const payloadObject = {}
  const infoControls = controllingProviders.map(
    (provider) => `${provider}ControllingInformation`,
  )
  Object.keys(controlsState).forEach((control) => {
    payloadObject[control] = infoControls.includes(control)
      ? generatePayloadFromControlsState(controlsState[control])
      : controlsState[control].value
  })
  return payloadObject
}
