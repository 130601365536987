import React from 'react'
import PropTypes from 'prop-types'
import {
  UsersAsyncMultiSelect,
  Button,
  ModalFooter,
  ModalContent,
  ReactSelect,
} from '../../../../../../common'
import { TDMS_ROLES_ACTUAL } from '../../../constants'

const checkButtonDisabled = (editMode, user, roles) =>
  !user || (!editMode && !roles.length)

export const TdmsUserFormComponent = ({
  header,
  editMode,
  user,
  roles,
  actions: { onUserSelect, filterOutputFn, setRoles, onCancel, onSubmit },
}) => (
  <ModalContent headline={header}>
    <div className="tdms-user-form">
      <div>
        <div className="wb-margin-bottom-xs">
          <UsersAsyncMultiSelect
            selectedUsers={user}
            onUserSelect={onUserSelect}
            disabled={editMode}
            isMulti={false}
            placeholder="Select user"
            filterOutputFn={filterOutputFn}
            loadOptionsOnMenuOpen={false}
            closeMenuOnSelect={true}
          />
        </div>
        <div className="wb-margin-bottom-xs">
          <ReactSelect
            value={roles}
            isMulti={true}
            name={'Select Roles'}
            options={Object.values(TDMS_ROLES_ACTUAL)}
            placeholder="Select role"
            onChange={setRoles}
          />
        </div>
      </div>
      <ModalFooter>
        <Button variant="secondary" size="medium" onClick={onCancel}>
          Close
        </Button>
        <Button
          variant="primary"
          size="medium"
          onClick={onSubmit}
          disabled={checkButtonDisabled(editMode, user, roles)}
        >
          Save
        </Button>
      </ModalFooter>
    </div>
  </ModalContent>
)

TdmsUserFormComponent.propTypes = {
  header: PropTypes.string,
  editMode: PropTypes.bool,
  user: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  roles: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ),
  actions: PropTypes.shape({
    onUserSelect: PropTypes.func,
    filterOutputFn: PropTypes.func,
    setRoles: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
  }),
}
