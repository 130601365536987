import { CHANGE_MODAL } from '../../actionsTypes'

const initialState = {
  activeModalId: null,
}

export const modalReducer = (state = initialState, action) => {
  if (action.type === CHANGE_MODAL) {
    return {
      ...state,
      activeModalId: action.payload.activeModalId,
    }
  }
  return state
}
