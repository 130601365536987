import { combineMembersAndPermissions } from '../../util/data'

export const selectCurrentBookingServicePermissions = (
  bookingSlug,
  serviceSlug,
  bookingServicePermissions,
) =>
  bookingServicePermissions?.[bookingSlug]?.[serviceSlug]?.servicePermissions ||
  []

export const selectAvailablePermissionsForTenantMember = (
  permissions,
  member,
) =>
  !member.isNebulaMember
    ? permissions.filter((perm) => !perm.providerOnly)
    : permissions

export const selectAvailablePermissionsForTenantMembers = (
  permissions,
  members,
) => {
  const currentMembers = Array.isArray(members) ? members : [members]
  let membersIncludeNonNebula = false
  let membersIncludeProviderOnly = false
  currentMembers.forEach((member) => {
    if (member.isNebulaMember) {
      membersIncludeProviderOnly = true
    } else {
      membersIncludeNonNebula = true
    }
  })
  if (membersIncludeNonNebula && membersIncludeProviderOnly) {
    return permissions.filter((perm) => !perm.providerOnly)
  } else if (membersIncludeNonNebula && !membersIncludeProviderOnly) {
    return permissions.filter((perm) => !perm.providerOnly)
  } else {
    return permissions
  }
}

export const selectWaitingPermissions = (permissions, bookingId, serviceSlug) =>
  permissions?.[bookingId]?.[serviceSlug] || []

export const selectBookingMembers = (
  members,
  bookingSlug,
  serviceSlug,
  bookingServicePermissions,
) => {
  const bookingPerms =
    bookingServicePermissions?.[bookingSlug]?.[serviceSlug]
      ?.bookingServicePermissions
  const servicePerms =
    bookingServicePermissions?.[bookingSlug]?.[serviceSlug]?.servicePermissions
  return !bookingPerms || !servicePerms
    ? []
    : combineMembersAndPermissions(
        JSON.parse(JSON.stringify(members)),
        bookingPerms,
        servicePerms,
      )
}

export const selectCurrentBookingServices = (bookingSlug, services) =>
  services[bookingSlug] || []

export const selectCurrentBookingServiceProperties = (
  bookingSlug,
  serviceSlug,
  properties,
) => properties?.[bookingSlug]?.[serviceSlug] || []
