import React from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  Select,
  TextArea,
  Button,
  ModalContent,
  ModalFooter,
} from '../../../../../../../common'

export const ServicesFormComponent = ({
  header,
  disableProviderInput,
  providerOptions,
  controls,
  actions: { updateInputState, onCancel, onSubmit },
}) => (
  <ModalContent headline={header}>
    <div className="wb-margin-bottom-xs wb-margin-top-xs">
      <Input
        label="Name"
        value={controls.name.value}
        error={
          controls.name.touched &&
          !controls.name.valid &&
          'Please provide valid name'
        }
        onChange={(e) => updateInputState('name', e.target.value)}
      />
    </div>
    <div className="wb-margin-bottom-xs">
      <TextArea
        label="Description"
        value={controls.description.value}
        onChange={(e) => updateInputState('description', e.target.value)}
      />
    </div>
    <div className="wb-margin-bottom-xs">
      <Select
        label="Provider"
        placeHolder="Choose Provider"
        value={controls.providerSlug.value}
        disabled={disableProviderInput}
        error={
          controls.providerSlug.touched &&
          !controls.providerSlug.valid &&
          'Please choose provider'
        }
        options={providerOptions}
        onChange={(e) => updateInputState('providerSlug', e.target.value)}
      />
    </div>
    <ModalFooter>
      <Button
        type="button"
        variant="secondary"
        size="medium"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        type="button"
        onClick={onSubmit}
        variant="primary"
        size="medium"
        disabled={!(controls.name.valid && controls.providerSlug.valid)}
      >
        Save
      </Button>
    </ModalFooter>
  </ModalContent>
)

ServicesFormComponent.propTypes = {
  header: PropTypes.string,
  disableProviderInput: PropTypes.bool,
  providerOptions: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string }),
  ),
  controls: PropTypes.shape({
    name: PropTypes.shape({
      value: PropTypes.string,
      valid: PropTypes.bool,
      touched: PropTypes.bool,
    }),
    description: PropTypes.shape({
      value: PropTypes.string,
      valid: PropTypes.bool,
      touched: PropTypes.bool,
    }),
    providerSlug: PropTypes.shape({
      value: PropTypes.string,
      valid: PropTypes.bool,
      touched: PropTypes.bool,
    }),
  }),
  actions: PropTypes.shape({
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    updateInputState: PropTypes.func,
  }),
}
