import React from 'react'
import { CSSTransition } from 'react-transition-group'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Button, Input, Select, MenuList } from '../../../common'
import { animationSettings } from '../../../../config'
import { FilterPopover } from './components/FilterPopover'

export const AppsNavComponent = (props) => {
  const {
    state: { inputState, filterCategories },
    narrowViewport,
    isEditing,
    selectedTenant,
    filters,
    actions: {
      onInputChange,
      onItemClick,
      onNewClick,
      handleSelectionChange,
      reduceTenants,
      setFilters,
      resetFilters,
    },
    isFetching,
    apiAccess,
    filteredTenants,
    isSaving,
    isWhitelisted,
  } = props

  const isNavigable = !isFetching && apiAccess
  const selectableItems = reduceTenants(filteredTenants)
  const _timeout = animationSettings.timeout
  const navClassNames = classnames('nav', {
    'nav--disabled': isEditing,
    'nav--admin': isWhitelisted,
  })
  return (
    <nav className={navClassNames}>
      <CSSTransition
        in={isNavigable}
        timeout={_timeout}
        classNames="nav-input"
        unmountOnExit
      >
        <div className="nav-input__container">
          <div className="nav-input__top">
            <div className="nav-input__wrapper">
              <Input
                placeholder="by Acronym, Name, Email, Id, etc."
                label="Filter Tenant"
                type="text"
                value={inputState}
                onChange={(e) => onInputChange(e.target.value)}
                disabled={isEditing}
              />
            </div>

            <div className="nav-actions">
              {isWhitelisted && (
                <Button
                  variant="primary"
                  size="small"
                  iconOnly
                  withIcon="plus"
                  disabled={isSaving || isEditing}
                  onClick={onNewClick}
                ></Button>
              )}
              <FilterPopover
                filters={filters}
                filterCategories={filterCategories}
                setFilter={setFilters}
              />
            </div>
          </div>

          <div className="wb-margin-top-xxs wb-margin-bottom-xs nav-filter">
            {filterCategories.length || inputState ? (
              <Button
                variant="tertiary"
                size="small"
                onClick={resetFilters}
                withIcon="close"
              >
                Clear all
              </Button>
            ) : null}
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={isNavigable && narrowViewport}
        timeout={_timeout}
        classNames="nav-dropdown"
        unmountOnExit
      >
        <div className="nav-dropdown">
          <Select
            label="Choose tenant"
            options={selectableItems}
            value={selectedTenant}
            onChange={(e) => onItemClick(e.target.value)}
            disabled={isEditing}
          />
        </div>
      </CSSTransition>

      <CSSTransition
        in={isNavigable && !narrowViewport}
        timeout={_timeout}
        classNames="nav-list"
        unmountOnExit
      >
        <MenuList
          items={selectableItems}
          selectedItem={selectedTenant}
          onItemClick={onItemClick}
          onSelectionChange={handleSelectionChange}
        />
      </CSSTransition>
    </nav>
  )
}

AppsNavComponent.propTypes = {
  state: PropTypes.shape({
    inputState: PropTypes.string,
    filterCategories: PropTypes.array,
  }),
  narrowViewport: PropTypes.bool,
  isEditing: PropTypes.bool,
  selectedTenant: PropTypes.string,
  filters: PropTypes.object,
  actions: PropTypes.shape({
    onInputChange: PropTypes.func,
    onItemClick: PropTypes.func,
    onNewClick: PropTypes.func,
    handleSelectionChange: PropTypes.func,
    reduceTenants: PropTypes.func,
    setFilters: PropTypes.func,
    resetFilters: PropTypes.func,
  }),
  isFetching: PropTypes.bool,
  apiAccess: PropTypes.bool,
  filteredTenants: PropTypes.object,
  isSaving: PropTypes.bool,
  isWhitelisted: PropTypes.bool,
}
