import React from 'react'
import PropTypes from 'prop-types'
import { ModalWrapper } from '../components/ModalWrapper'
import { MembersFormComponent } from '../components/MembersFormComponent'

export const MembersDialogComponent = ({
  waitingMode,
  members,
  selectedMembers,
  editMode,
  loggedInUserDaimlerId,
  service,
  booking,
  disabled,
  actions: {
    onClose,
    onSelectPermission,
    onSelectMember,
    onSubmit,
    membersHaveSamePermission,
  },
}) => (
  <ModalWrapper
    waitingMode={waitingMode}
    editMode={editMode}
    disabled={disabled}
    actions={{ onClose, onSubmit }}
  >
    <MembersFormComponent
      members={members}
      editMode={editMode}
      service={service}
      booking={booking}
      loggedInUserDaimlerId={loggedInUserDaimlerId}
      selectedMembers={selectedMembers}
      actions={{
        onSelectMember,
        onSelectPermission,
        membersHaveSamePermission,
      }}
    />
  </ModalWrapper>
)

MembersDialogComponent.propTypes = {
  waitingMode: PropTypes.bool,
  members: PropTypes.array,
  selectedMembers: PropTypes.array,
  editMode: PropTypes.bool,
  loggedInUserDaimlerId: PropTypes.string,
  service: PropTypes.object,
  booking: PropTypes.object,
  disabled: PropTypes.bool,
  actions: PropTypes.shape({
    onClose: PropTypes.func,
    onSelectPermission: PropTypes.func,
    onSelectMember: PropTypes.func,
    membersHaveSamePermission: PropTypes.func,
    onSubmit: PropTypes.func,
  }),
}
