import React from 'react'
import PropTypes from 'prop-types'

export const InfoItem = ({ title, value, subItem }) => (
  <div className="tenant-body__block">
    <div
      className={`tenant-body__block-label ${subItem ? 'warning__label' : ''}`}
    >
      {title}
    </div>
    <p className="tenant-body__block-value">{value}</p>
  </div>
)

InfoItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subItem: PropTypes.bool,
}
