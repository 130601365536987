import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { addNewTenantMembers } from '../../../../../../../data/actions'
import { UserFormComponent } from '../component'

const UserFormContainer = ({
  tenantRoles,
  header,
  currentTenantMembers,
  selectedTenant,
  addNewMembers,
  onCancel,
}) => {
  const [selectedRoles, setSelectedRoles] = useState([])
  const [members, setMembers] = useState([])
  const rolesObject = {}

  const roles = tenantRoles.map(({ name, description }) => {
    rolesObject[name] = description
    return {
      label: description,
      value: name,
      color: '#00B8D9',
      isFixed: true,
    }
  })

  const memberDaimlerIds = currentTenantMembers.map(
    ({ daimlerId }) => daimlerId,
  )

  const onSubmit = () =>
    addNewMembers(
      {
        tenantAcr: selectedTenant,
        daimlerIds: members.map(({ value }) => value),
        roles: selectedRoles.map(({ value }) => value),
        tenantUserRoleExpiryDate: moment().add(90, 'days').format('YYYY-MM-DD'),
      },
      onCancel,
    )

  const filterOutputFn = ({ daimlerId }) =>
    !memberDaimlerIds.includes(daimlerId)

  return (
    <UserFormComponent
      header={header}
      selectedRoles={selectedRoles}
      members={members}
      roles={roles}
      disabled={!(members.length && selectedRoles.length)}
      actions={{
        onCancel,
        setMembers,
        filterOutputFn,
        setSelectedRoles,
        onSubmit,
      }}
    />
  )
}

UserFormContainer.propTypes = {
  tenantRoles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  currentTenantMembers: PropTypes.arrayOf(
    PropTypes.shape({
      daimlerId: PropTypes.string,
    }),
  ),
  selectedTenant: PropTypes.string,
  header: PropTypes.string,
  onCancel: PropTypes.func,
  addNewMembers: PropTypes.func,
}

export const AddUserForm = connect(
  ({
    tenants: { tenantRoles, currentTenantMembers, selectedTenant },
    roles: { users },
  }) => ({
    tenantRoles,
    users,
    selectedTenant,
    currentTenantMembers,
  }),
  { addNewMembers: addNewTenantMembers },
)(UserFormContainer)
