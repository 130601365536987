import React from 'react'
import PropTypes from 'prop-types'
import { Input, Button } from '../..'

export const EditableInputComponent = ({
  mode,
  value,
  actions: { onChangeInput, onClickSave, onClickRemove },
}) => {
  const icons = {
    save: mode === 'edit' ? 'checkmark' : 'bds/pencil/24',
    remove: mode === 'edit' ? 'close' : 'bds/trash/24',
  }
  return (
    <div className="editable-input">
      {mode === 'edit' ? (
        <Input value={value} onChange={onChangeInput} />
      ) : (
        <div className="editable-input__value">{value}</div>
      )}
      <div className="editable-input__controls">
        <Button
          className="wb-round-button"
          size="small"
          onClick={onClickSave}
          withIcon={icons.save}
          iconOnly
        />
        <Button
          size="small"
          className="wb-round-button"
          onClick={onClickRemove}
          iconOnly
          withIcon={icons.remove}
        />
      </div>
    </div>
  )
}

EditableInputComponent.propTypes = {
  mode: PropTypes.string,
  value: PropTypes.string,
  actions: PropTypes.shape({
    onChangeInput: PropTypes.func,
    onClickSave: PropTypes.func,
    onClickRemove: PropTypes.func,
  }),
}
