import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader } from '../../../../../common'
import { ApiKeyForm } from '../ApiKeyForm'
import { KeyPreview } from '../KeyPreview'
import { RemoveKeyPrompt } from '../RemoveKeyPrompt'

export const ApiModal = ({ modal, onCloseModal }) => {
  const modalMapper = {
    create: ApiKeyForm,
    preview: KeyPreview,
    remove: RemoveKeyPrompt,
  }
  const Component = modalMapper[modal.variant]
  return Component ? (
    <Modal mode="edit" onCloseModal={onCloseModal}>
      <ModalHeader />
      <Component {...modal} onClose={onCloseModal} />
    </Modal>
  ) : null
}

ApiModal.propTypes = {
  modal: PropTypes.shape({ variant: PropTypes.string }),
  onCloseModal: PropTypes.func,
}
