import React from 'react'
import PropTypes from 'prop-types'
import { MultiSelectWithSelectAll } from '../../MultiSelectWithSelectAll'

export const ProvidersSelectorComponent = ({
  providers,
  value,
  onClickProvider,
  invalid,
}) => (
  <div className="tenant-selection wb-margin-bottom-xs ">
    <MultiSelectWithSelectAll
      items={providers}
      selectedItems={value}
      onChange={onClickProvider}
      invalid={invalid}
      modelName="Providers"
    />
  </div>
)

ProvidersSelectorComponent.propTypes = {
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      producerId: PropTypes.number,
      slug: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    PropTypes.string,
  ]),
  onClickProvider: PropTypes.func,
  invalid: PropTypes.bool,
}
