import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  addMembersToBookingServicePermission,
  updateMembersToBookingServicePermission,
  postNotification,
} from '../../../../../../../../../../../data/actions'
import { selectWaitingPermissions } from '../../../../../../../../../../../data/selectors/tenantBookings'
import { MembersDialogComponent } from '../component'
import {
  selectedMembersIncludesNonNebulaMembers,
  generateNewSelectedMemberState,
  generateDaimlerIdsList,
  generateBookingPermissionsList,
  membersHaveSamePermission,
  validateMembersAgainstNebulaPermissions,
  validateSelectedPermissionAndMembers,
} from './utils'

const MembersDialogContainer = ({
  members,
  nebulaMembers,
  editMode,
  loggedInPoPmDaimlerId,
  service,
  booking,
  waitingPermissions,
  addMemberPermissions,
  updateMemberPermissions,
  onClose,
  notify,
}) => {
  const [permission, setPermission] = useState(null)
  const [selectedMembers, setSelectedMembers] = useState([])

  const membersWithWaitingMode = members.map((member) => ({
    ...member,
    waitingMode: waitingPermissions.includes(member.daimlerId),
  }))

  const waitingMode = membersWithWaitingMode.some(
    (member) => member.waitingMode,
  )

  const onSelectPermission = (selectedPermission) => {
    selectedPermission?.providerOnly &&
    selectedMembersIncludesNonNebulaMembers(selectedMembers, nebulaMembers)
      ? notify({
          message:
            'You cannot assign NEBULA_ONLY permission to non NEBULA tenant member',
          mode: 'error',
        })
      : setPermission(selectedPermission)
  }

  const onSelectMember = (currentMember) =>
    setSelectedMembers(
      generateNewSelectedMemberState(selectedMembers, currentMember),
    )

  const validateForm = (users) => {
    if (!(users.length && permission)) {
      notify({
        message: 'Member(s) and/or permission is not selected!',
        mode: 'error',
      })
      return false
    }
    return true
  }

  const updateMembers = () => {
    validateForm(members)
    const permissionIds = generateBookingPermissionsList(
      membersWithWaitingMode,
      permission,
    )
    permissionIds.length &&
      updateMemberPermissions(
        booking,
        permissionIds,
        permission.slug,
        service,
        onClose,
      )
  }

  const createMembers = () => {
    validateForm(selectedMembers)
    const daimlerIds = generateDaimlerIdsList(selectedMembers, permission)
    daimlerIds.length &&
      addMemberPermissions(daimlerIds, booking, permission, service, () => {
        setSelectedMembers([])
        onClose()
      })
  }

  const onSubmit = () => (editMode ? updateMembers() : createMembers())

  const saveButtonDisabled = () =>
    waitingMode ||
    !validateSelectedPermissionAndMembers(
      editMode,
      permission,
      membersWithWaitingMode,
      selectedMembers,
    ) ||
    validateMembersAgainstNebulaPermissions(
      permission,
      selectedMembers,
      nebulaMembers,
    )

  return (
    <MembersDialogComponent
      members={membersWithWaitingMode}
      editMode={editMode}
      selectedMembers={selectedMembers}
      loggedInPoPmDaimlerId={loggedInPoPmDaimlerId}
      service={service}
      waitingMode={waitingMode}
      booking={booking}
      disabled={saveButtonDisabled()}
      actions={{
        onSelectPermission,
        onSelectMember,
        membersHaveSamePermission,
        onSubmit,
        onClose,
      }}
    />
  )
}

MembersDialogContainer.propTypes = {
  members: PropTypes.array,
  nebulaMembers: PropTypes.object,
  editMode: PropTypes.bool,
  loggedInPoPmDaimlerId: PropTypes.string,
  service: PropTypes.object,
  booking: PropTypes.object,
  waitingPermissions: PropTypes.arrayOf(PropTypes.string),
  addMemberPermissions: PropTypes.func,
  updateMemberPermissions: PropTypes.func,
  onClose: PropTypes.func,
  notify: PropTypes.func,
}

export const MembersDialog = connect(
  (
    { tenantBookings: { waitingPermissions }, tenants: { nebulaMembers } },
    ownProps,
  ) => ({
    waitingPermissions: selectWaitingPermissions(
      waitingPermissions,
      ownProps.booking.id,
      ownProps.service.slug,
    ),
    nebulaMembers,
  }),
  {
    addMemberPermissions: addMembersToBookingServicePermission,
    updateMemberPermissions: updateMembersToBookingServicePermission,
    notify: postNotification,
  },
)(MembersDialogContainer)
