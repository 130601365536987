import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../../../../../common'

export const PriceVariantsListComponent = ({
  readOnly,
  packagePriceVariants,
  actions: {
    handleRemovePriceVariant,
    onEditPrices,
    onEditPriceVariant,
    onAddPriceVariant,
  },
}) => (
  <div className="price-variants">
    <div className="wb-margin-vertical-xxs price-variants__header">
      <div></div>
      <Button
        disabled={readOnly}
        onClick={onAddPriceVariant}
        variant="secondary"
        size="medium"
      >
        Add Price Variant
      </Button>
    </div>
    <table className="wb-table wb-table--border-horizontal">
      <tbody>
        {packagePriceVariants.map((variant) => (
          <tr key={variant.slug}>
            <td>{variant.name}</td>
            <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
              {variant?.prices?.map((price) => (
                <div key={price.id}>
                  <span style={{ fontWeight: 'bold' }}>{price.year}</span> | $
                  {price.monthlyPrice} €
                </div>
              ))}
            </td>
            <th scope="row" style={{ verticalAlign: 'middle' }}>
              <div className="price-variants__table-actions">
                <Button
                  disabled={readOnly}
                  onClick={() => onEditPrices(variant)}
                  variant="primary"
                  size="small"
                  withIcon="edit"
                >
                  Edit Prices
                </Button>
                <Button
                  disabled={readOnly}
                  onClick={() => onEditPriceVariant(variant)}
                  variant="primary"
                  size="small"
                  withIcon="edit"
                  className="wb-margin-horizontal-xxs"
                  iconOnly
                >
                  Edit Price Variant
                </Button>
                <Button
                  onClick={() => handleRemovePriceVariant(variant)}
                  disabled={readOnly}
                  variant="secondary"
                  size="small"
                  withIcon="bds/trash/16"
                  iconOnly
                >
                  Remove Price Variant
                </Button>
              </div>
            </th>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

PriceVariantsListComponent.propTypes = {
  actions: PropTypes.shape({
    handleRemovePriceVariant: PropTypes.func,
    onEditPrices: PropTypes.func,
    onEditPriceVariant: PropTypes.func,
    onAddPriceVariant: PropTypes.func,
  }),
  readOnly: PropTypes.bool,
  packagePriceVariants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      prices: PropTypes.array,
    }),
  ),
}
