import React from 'react'
import PropTypes from 'prop-types'
import { CheckBox } from '../../../../../common'
import { PermissionsOptions } from '../PermissionsOptions'

export const PackageOptions = ({ items, checkedPackages, onItemCheck }) => {
  const onClick = (packageSlug) => {
    const newPackages = { ...checkedPackages }
    if (checkedPackages[packageSlug]) {
      delete newPackages[packageSlug]
    } else {
      newPackages[packageSlug] = []
    }
    onItemCheck(newPackages)
  }
  const onCheckPermission = (packageSlug, newPermissions) => {
    const newPackages = { ...checkedPackages }
    newPackages[packageSlug] = newPermissions
    onItemCheck(newPackages)
  }
  return (
    <div className="wb-margin-bottom-xs">
      <legend>Filter by Packages/Permissions</legend>
      {items.map(({ name, slug }) => (
        <div key={`types_${slug}`}>
          <CheckBox
            name={`${slug}_tenant_type`}
            id={`package_${slug}`}
            label={name}
            value={slug.toLowerCase()}
            checked={!!checkedPackages[slug]}
            onChange={() => onClick(slug)}
          />
          {checkedPackages[slug] ? (
            <PermissionsOptions
              packageSlug={slug}
              checkedPermissions={checkedPackages[slug]}
              onItemCheck={(newPermissionSlugs) =>
                onCheckPermission(slug, newPermissionSlugs)
              }
            />
          ) : null}
        </div>
      ))}
    </div>
  )
}
PackageOptions.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, slug: PropTypes.string }),
  ),
  checkedPackages: PropTypes.objectOf(PropTypes.array),
  onItemCheck: PropTypes.func,
}
