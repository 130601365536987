import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindServiceToPackage } from '../../../../../../../../data/actions'
import { generateCurrentPackageServices } from '../../../../utils'
import { PackageServicesFormComponent } from '../component'

const PackageServicesFormContainer = ({
  services,
  packageServices,
  selectedPackage,
  readOnly,
  handleSubmitService,
}) => {
  const currentServices = generateCurrentPackageServices(
    services,
    packageServices,
  )
  return (
    <PackageServicesFormComponent
      services={currentServices}
      selectedPackage={selectedPackage}
      handleSubmitService={handleSubmitService}
      readOnly={readOnly}
    />
  )
}

const ServiceTypeProp = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  provider: PropTypes.string,
  serviceKey: PropTypes.string,
  slug: PropTypes.string,
})
PackageServicesFormContainer.propTypes = {
  services: PropTypes.arrayOf(ServiceTypeProp),
  packageServices: PropTypes.arrayOf(ServiceTypeProp),
  selectedPackage: PropTypes.object,
  handleSubmitService: PropTypes.func,
  readOnly: PropTypes.bool,
}

export const PackageServicesForm = connect(
  ({ backOffice: { services, packageServices } }) => ({
    services,
    packageServices,
  }),
  (dispatch) => ({
    handleSubmitService: (service, attached) =>
      dispatch(bindServiceToPackage(service, attached)),
  }),
)(PackageServicesFormContainer)
