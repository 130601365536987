import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../../../common'

export const FormActions = ({ onCancel, onSave }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <div className="tenants-edit__required-info">
      Fields marked with an asterisk (*) are required
    </div>
    <div>
      <Button
        variant="secondary"
        size="small"
        className="wb-button--theme-dark close"
        iconOnly
        withIcon="close"
        onClick={onCancel}
      />
      <Button
        variant="primary"
        size="small"
        className="wb-margin-start-xxs wb-button--theme-dark save"
        iconOnly
        withIcon="save"
        onClick={onSave}
      />
    </div>
  </div>
)

FormActions.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}
