import React from 'react'
import PropTypes from 'prop-types'
import { Input, Button } from '../../../../../common'

export const RequestHeaderBuilder = ({ value: headers, touched, onChange }) => {
  const onAddNewHeader = () => {
    const newHeaders = [...headers]
    newHeaders.push({
      name: '',
      value: '',
    })
    onChange(newHeaders)
  }

  const onClickDelete = (itemIndex) => {
    const newHeaders = [...headers]
    newHeaders.splice(itemIndex, 1)
    onChange(newHeaders)
  }

  const updateInputState = (index, variant, value) => {
    const newHeaders = [...headers]
    newHeaders[index] = { ...newHeaders[index] }
    newHeaders[index][variant] = value
    onChange(newHeaders)
  }

  return (
    <wb-grid
      className="wb-margin-bottom-xs tokenAuth"
      style={{ width: '100%' }}
    >
      {headers.map((header, index) => (
        <wb-grid-row className="wb-margin-bottom-xs" key={header}>
          <wb-grid-col mq1="5">
            <Input
              label="Name"
              value={header.name}
              onChange={(e) => updateInputState(index, 'name', e.target.value)}
              error={touched && !header.name && 'Please provide header name'}
            />
          </wb-grid-col>
          <wb-grid-col mq1="5">
            <Input
              label="Value"
              value={header.value}
              onChange={(e) => updateInputState(index, 'value', e.target.value)}
              error={touched && !header.value && 'Please provide header value'}
            />
          </wb-grid-col>
          <wb-grid-col mq1="2">
            <Button
              variant="tertiary"
              size="medium"
              iconOnly
              withIcon="bds/trash/24"
              onClick={() => onClickDelete(index)}
            >
              Delete
            </Button>
          </wb-grid-col>
        </wb-grid-row>
      ))}

      <div className="wb-margin-vertical-xs">
        <Button
          variant="primary"
          size="small"
          withIcon="bds/plus/24"
          onClick={onAddNewHeader}
        >
          Add new
        </Button>
      </div>
    </wb-grid>
  )
}

RequestHeaderBuilder.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  touched: PropTypes.bool,
  onChange: PropTypes.func,
}
