import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { MenuListItem } from './components/MenuListItem'

const MenuListContainer = ({ selectedItem, items, onItemClick }) => {
  const itemsFactory = () =>
    items.map((item) => {
      const hasChilds = !!item.folder
      return (
        <MenuListItem
          isSelected={selectedItem === item.id}
          key={item.id}
          text={item.text}
          provider={item.provider}
          type={hasChilds ? 'parent' : 'child'}
          status={item.status}
          onItemClick={() => !hasChilds && onItemClick(item.id)}
        />
      )
    })

  return <ul className="wb-list nav-list">{itemsFactory()}</ul>
}

MenuListContainer.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItem: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  onItemClick: PropTypes.func.isRequired,
}

const mapStateToProps = function (state) {
  const { navMenuSelected } = state.global

  return {
    navMenuSelected,
  }
}
export const MenuList = connect(mapStateToProps)(MenuListContainer)
