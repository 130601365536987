import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button } from '../../../../../../common'

export const PropertiesListItem = ({
  property,
  readOnly,
  currentProperty,
  onEditProperty,
  onRemoveProperty,
}) => (
  <li
    className={classnames('packages-editor__list-item', {
      'pacages-editor__list-item--active': property.id === currentProperty?.id,
    })}
  >
    <div className="permission-item">
      <div className="permission-item__info">
        <div>{property.name}</div>
        <div className="packages-editor__list-item-description">
          {property.type}
        </div>
        <div className="packages-editor__list-item-description">
          {property.description}
        </div>
        <div className="packages-editor__list-item-description">
          {property.required ? 'Required' : ''}
        </div>
      </div>
      <div className="permission-item__buttons">
        <Button
          onClick={() => onEditProperty(property)}
          disabled={readOnly}
          variant="secondary"
          size="small"
          iconOnly
          withIcon="bds/pencil/24"
        />
        <Button
          onClick={() => onRemoveProperty(property.id)}
          disabled={readOnly}
          variant="secondary"
          size="small"
          iconOnly
          withIcon="bds/trash/24"
        />
      </div>
    </div>
  </li>
)

PropertiesListItem.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
  }),
  readOnly: PropTypes.bool,
  currentProperty: PropTypes.shape({
    id: PropTypes.number,
  }),
  onEditProperty: PropTypes.func,
  onRemoveProperty: PropTypes.func,
}
