import React from 'react'
import PropTypes from 'prop-types'
import { ProcessingIndicator } from '../../../../ProcessingIndicator'
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalLevel,
} from '../../../../../../../../../../../common'

export const ModalWrapper = ({
  waitingMode,
  editMode,
  disabled,
  actions: { onClose, onSubmit },
  children,
}) => (
  <Modal onCloseModal={onClose}>
    <ModalLevel>
      <ModalHeader />
      <ModalContent headline={(editMode ? 'Update' : 'Add') + ' members'}>
        {children}
      </ModalContent>
      <ModalFooter>
        <Button variant="secondary" size="large" onClick={onClose}>
          {waitingMode ? 'Close' : 'Cancel'}
        </Button>
        <Button
          variant="primary"
          size="large"
          onClick={onSubmit}
          disabled={disabled}
        >
          {waitingMode ? <ProcessingIndicator withText /> : null}
          <div>{!waitingMode ? 'Save' : ''}</div>
        </Button>
      </ModalFooter>
    </ModalLevel>
  </Modal>
)

ModalWrapper.propTypes = {
  waitingMode: PropTypes.bool,
  editMode: PropTypes.bool,
  disabled: PropTypes.bool,
  actions: PropTypes.shape({
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
