import {
  SET_CROSS_CHARGING_OVERVIEW,
  SET_CURRENT_TENANT_CROSS_CHARGING_OVERVIEW,
  SET_PACKAGE_PERMISSIONS,
} from '../../actionsTypes'
import {
  fetchCrossChargingOverview,
  fetchTenantsCrossChargingOverview,
  fetchPackagePermissions,
} from '../../../http'
import { postNotification } from '../'

export const getCrossChargingOverview = () => async (dispatch) => {
  try {
    const response = await fetchCrossChargingOverview()
    if (response.errorMessages) {
      throw new Error(response.message)
    }
    dispatch(setCrossChargingOverview(response))
  } catch (err) {
    dispatch(postNotification({ message: err.message, mode: 'error' }))
  }
}

export const getTenantsCrossChargingOverview =
  () => async (dispatch, getState) => {
    try {
      const currentTenantAcronym = getState().tenants.selectedTenant
      if (!currentTenantAcronym) {
        throw new Error('Tenant is not selected!')
      }

      const response = await fetchTenantsCrossChargingOverview(
        currentTenantAcronym,
      )
      if (response.errorMessages) {
        throw new Error(response.message)
      }
      dispatch(setCurrentTenantCrossChargingOverview(response))
    } catch (err) {
      err.message !== 'Tenant is not selected!' &&
        dispatch(postNotification({ message: err.message, mode: 'error' }))
    }
  }

export const getPackagePermissions = (packageSlug) => async (dispatch) => {
  try {
    const response = await fetchPackagePermissions(packageSlug)
    if (response.errorMessages) {
      throw new Error(response.message)
    }
    dispatch(setCurrentTenantCrossChargingOverview(response))
  } catch (err) {
    dispatch(postNotification({ message: err.message, mode: 'error' }))
  }
}

export const setCurrentTenantCrossChargingOverview = (overviewData) => ({
  type: SET_CURRENT_TENANT_CROSS_CHARGING_OVERVIEW,
  payload: overviewData,
})
export const setCrossChargingOverview = (overviewData) => ({
  type: SET_CROSS_CHARGING_OVERVIEW,
  payload: overviewData,
})
export const setPackagePermissions = (packageSlug, permissions) => ({
  type: SET_PACKAGE_PERMISSIONS,
  payload: { packageSlug, permissions },
})
