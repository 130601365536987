import {
  SET_AKAMAI_CP_CODES,
  TOGGLE_CP_CODE,
  TOGGLE_AKAMAI_LOADING_STATE,
  CLEAN_AKAMAI_SELECTED_CP_CODES,
  SET_AKAMAI_SERVER_MESSAGES,
  CLEAN_AKAMAI_SERVER_MESSAGES,
  CLEAN_AKAMAI_STATE,
  TOGGLE_AKAMAI_INVALIDATING_STATE,
} from '../../actionsTypes'

const initialState = {
  cpCodes: [],
  selectedCpCodes: {},
  loading: false,
  invalidating: false,
  serverMessages: [],
}

export const akamaiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AKAMAI_CP_CODES:
      return {
        ...state,
        cpCodes: [...action.payload.cpCodes],
        selectedCpCodes: {},
      }
    case TOGGLE_CP_CODE: {
      const selectedCpCodes = { ...state.selectedCpCodes }
      if (selectedCpCodes[action.payload.cpCodeId]) {
        delete selectedCpCodes[action.payload.cpCodeId]
      } else {
        selectedCpCodes[action.payload.cpCodeId] = true
      }
      return {
        ...state,
        selectedCpCodes,
      }
    }
    case TOGGLE_AKAMAI_LOADING_STATE:
      return {
        ...state,
        loading: action.payload.loading,
      }
    case TOGGLE_AKAMAI_INVALIDATING_STATE:
      return {
        ...state,
        invalidating: action.payload.invalidating,
      }
    case CLEAN_AKAMAI_SELECTED_CP_CODES:
      return {
        ...state,
        selectedCpCodes: {},
      }
    case CLEAN_AKAMAI_STATE:
      return {
        ...state,
        cpCodes: [],
        serverMessages: [],
        selectedCpCodes: {},
      }
    case CLEAN_AKAMAI_SERVER_MESSAGES:
      return {
        ...state,
        serverMessages: [],
      }
    case SET_AKAMAI_SERVER_MESSAGES:
      return {
        ...state,
        serverMessages: action.payload.serverMessages,
      }
    default:
      return state
  }
}
