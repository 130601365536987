import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from '../../../../../../common'

const LABELS_TO_DISPLAY = 5

export const TaggedMemberLabels = ({ labels }) =>
  labels.length > 0 && (
    <p className="members-content__item__row">
      <span className="members-content__item-label">Labels</span>
      {labels.slice(0, LABELS_TO_DISPLAY).map((label) => (
        <span key={label} className="wb-tag wb-tag--tertiary wb-margin-3xs">
          <Tag
            key={label}
            label={label}
            variant="tertiary"
            className="wb-margin-3xs"
          >
            {label}
          </Tag>
        </span>
      ))}

      {labels.length > LABELS_TO_DISPLAY ? (
        <span className="members-content__item-label">
          + {labels.length - LABELS_TO_DISPLAY} more
        </span>
      ) : null}
    </p>
  )

TaggedMemberLabels.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
}
