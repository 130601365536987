import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { PackageFormComponent } from '../component'
import { createPackage } from '../../../../../../../../data/actions'

const serviceProvidersSelector = (serviceProviders, tdmsRoles, providerRoles) =>
  tdmsRoles.includes('admin')
    ? serviceProviders
    : serviceProviders.filter(({ slug }) => providerRoles[slug])

const PackageFormContainer = ({
  mode,
  selectedPackage,
  serviceProviders,
  onSubmit,
  onCancel,
}) => {
  const header = mode === 'edit' ? 'Update package' : 'Add a package'
  const basePackage = {
    name: '',
    description: '',
    providerSlug: '',
    allowBookingPermissions: true,
  }
  const [platformPackage, setPlatformPackage] = useState(
    (mode === 'edit' && selectedPackage) || basePackage,
  )

  const [errors, setErrors] = useState({})
  const updateInputState = (variant, value) => {
    setPlatformPackage({
      ...platformPackage,
      [variant]: value,
    })
  }
  const handleSubmit = () => {
    if (!platformPackage.name) {
      setErrors({
        name: 'Package Name is required!',
      })
      return
    }
    if (!platformPackage.providerSlug) {
      setErrors({
        provider: 'Provider is required!',
      })
      return
    }

    onSubmit(platformPackage)
  }

  return platformPackage ? (
    <PackageFormComponent
      header={header}
      handleSubmit={handleSubmit}
      platformPackage={platformPackage}
      handleClose={onCancel}
      errors={errors}
      serviceProviders={serviceProviders.map(({ slug, name }) => ({
        name,
        id: slug,
      }))}
      updateInputState={updateInputState}
    />
  ) : null
}

PackageFormContainer.propTypes = {
  mode: PropTypes.string,
  onSubmit: PropTypes.func,
  selectedPackage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    packageKey: PropTypes.string,
    description: PropTypes.string,
    provider: PropTypes.string,
  }),
  serviceProviders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  onCancel: PropTypes.func,
}

export const PackageForm = connect(
  ({ backOffice, sso: { roles, providerRoles } }) => ({
    selectedPackage: backOffice.package,
    serviceProviders: serviceProvidersSelector(
      backOffice.providers.filter(({ deleted_at }) => !deleted_at),
      roles,
      providerRoles,
    ),
  }),
  (dispatch) => ({
    onSubmit: (pkg) => dispatch(createPackage(pkg)),
  }),
)(PackageFormContainer)
