import React from 'react'
import PropTypes from 'prop-types'
import { copyToClipBoard } from '../../../utils/helpers'
import { Button } from '../../../../../common'

export const ActionButtons = ({ emailList }) => (
  <div>
    {emailList && (
      <>
        <Button
          type="submit"
          variant="secondary"
          size="small"
          className=" wb-margin-bottom-xs"
          withIcon="contact"
          onClick={() => window.open(`mailto: ${emailList}`)}
        >
          Open Email
        </Button>
        <Button
          type="submit"
          variant="secondary"
          size="small"
          onClick={() => copyToClipBoard(emailList)}
        >
          Copy Emails
        </Button>
      </>
    )}
  </div>
)

ActionButtons.propTypes = {
  emailList: PropTypes.string,
}
