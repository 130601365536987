import React from 'react'
import PropTypes from 'prop-types'

export const NavigationItem = ({ onClick, children }) => (
  <wb-navigation-item onClick={onClick}>{children}</wb-navigation-item>
)

NavigationItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
