import {
  fetchServicePermissions,
  createServicePermission,
  updateServicePermission,
  removeServicePermission,
} from '../../../../../http'
import {
  postNotification,
  removeWaitingPermissions,
  addWaitingPermissions,
} from '../../..'
import {
  setServicePermissions,
  detachPermissionFromService,
  updateCurrentServicePermission,
  addPermissionToCurrentService,
} from '../shared'

export const getServicePermissions = (serviceId) => async (dispatch) => {
  try {
    const permissions = await fetchServicePermissions(serviceId)
    dispatch(setServicePermissions(permissions))
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while retrieving service permissions - ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const addServicePermission =
  (permissionObj, cb) => async (dispatch, getState) => {
    const currentServiceId = getState().backOffice.service.id
    try {
      const response = await createServicePermission(
        currentServiceId,
        JSON.stringify(permissionObj),
      )
      if (response.errorMessages || response.error) {
        throw new Error(response.message || response.error)
      }
      dispatch(addPermissionToCurrentService(response))
      dispatch(
        postNotification({
          message: `Service permission was added successfully!`,
        }),
      )
      cb?.()
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while adding servicePermission - ${err.message}`,
          mode: 'error',
        }),
      )
    }
  }

export const editServicePermission =
  (permissionObj, cb) => async (dispatch) => {
    try {
      const response = await updateServicePermission(
        permissionObj.id,
        JSON.stringify(permissionObj),
      )
      if (response.errorMessages || response.error) {
        throw new Error(response.message || response.error)
      }
      dispatch(updateCurrentServicePermission(response))
      cb?.()
      dispatch(
        postNotification({
          message: `Service permission was updated successfully!`,
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while updating service permission - ${err.message}`,
          mode: 'error',
        }),
      )
    }
  }

export const deleteServicePermission =
  (permissionId, cb) => async (dispatch) => {
    try {
      await removeServicePermission(permissionId)
      dispatch(detachPermissionFromService(permissionId))
      cb?.()
      dispatch(
        postNotification({
          message: `Service permission was deleted successfully!`,
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while removing servicePermission - ${err.message}`,
          mode: 'error',
        }),
      )
    }
  }

export const addPermissionToWaitingList =
  (bookingId, serviceSlug, daimlerIds) => (dispatch) => {
    const removePerm = () => {
      dispatch(removeWaitingPermissions(bookingId, serviceSlug, daimlerIds))
    }
    setTimeout(() => {
      removePerm()
    }, 10000)
    dispatch(addWaitingPermissions(bookingId, serviceSlug, daimlerIds))
  }
