import React from 'react'
import PropTypes from 'prop-types'
import { Tag, TooltipHost } from '../../../../../../../../common'

export const TaggedRolesList = ({ rolesToDisplay, tags }) => {
  const visibleRoles = tags
    .slice(0, rolesToDisplay)
    .map(({ label }) => <Tag key={label} label={label} variant="tertiary" />)

  const toolTipRoles = tags
    .slice(rolesToDisplay, tags.length)
    .map(({ label }) => (
      <Tag
        key={label}
        label={label}
        variant="tertiary"
        className="wb-margin-bottom-xxs"
      />
    ))

  return (
    <div className="tenant-members__tagged_roles">
      {visibleRoles}
      {toolTipRoles.length ? (
        <TooltipHost position="bottom" message={toolTipRoles}>
          <span className="wb-button--tertiary wb-margin-start-xxs">{`+${
            tags.length - rolesToDisplay
          } Roles`}</span>
        </TooltipHost>
      ) : null}
    </div>
  )
}

TaggedRolesList.propTypes = {
  rolesToDisplay: PropTypes.number,
  tags: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
}
