import {
  fetchPlatformServices,
  postPlatFormService,
  putPlatFormService,
  archivePlatformServices,
} from '../../../../../http'
import { postNotification } from '../../..'
import { editService, setService, setServices, setModal } from '../shared'
import { servicePermissionsMapper, applyServicePermissions } from '../utils'

export const archiveService =
  (activeService, cb) => async (dispatch, getState) => {
    try {
      const response = await archivePlatformServices(activeService.id)
      const { sso } = getState()
      if (response.errorMessages) {
        throw new Error(response.message)
      }
      const newService = applyServicePermissions(
        {
          ...activeService,
          archived: true,
        },
        sso.roles,
        sso.providerRoles,
      )
      dispatch(editService(newService))
      dispatch(setService(newService))
      dispatch(
        postNotification({
          message: `The service (${activeService.name}) was archived successfully!`,
        }),
      )
      cb?.()
    } catch (err) {
      dispatch(
        postNotification({
          message: err.message,
          mode: 'error',
        }),
      )
    }
  }

export const getServices = () => async (dispatch, getState) => {
  try {
    const { sso } = getState()
    const services = await fetchPlatformServices()
    dispatch(
      setServices(
        servicePermissionsMapper(services, sso.roles, sso.providerRoles),
      ),
    )
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while retrieving services - ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const createService = (serviceObj) => async (dispatch) => {
  try {
    await postPlatFormService({
      body: JSON.stringify(serviceObj),
    })
    dispatch(getServices())
    dispatch(
      postNotification({
        message: 'Service was created successfully!',
      }),
    )
    dispatch(setModal({}))
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while creating service - ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const updateService =
  (serviceObj, serviceId) => async (dispatch, getState) => {
    try {
      const { sso } = getState()
      const service = await putPlatFormService({
        id: serviceId,
        body: JSON.stringify(serviceObj),
      })
      dispatch(
        setService(
          applyServicePermissions(service, sso.roles, sso.providerRoles),
        ),
      )
      dispatch(getServices())
      dispatch(
        postNotification({
          message: 'Service was updated successfully!',
        }),
      )
      dispatch(setModal({}))
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while updating service - ${err.message}`,
          mode: 'error',
        }),
      )
    }
  }
