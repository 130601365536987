import React from 'react'
import PropTypes from 'prop-types'
import { CheckBox } from '../../../../../common'

export const CheckboxesGroup = ({ title, items, onClickItem }) => (
  <div>
    {title}
    {items.map(({ name, id, label, checked, value }) => (
      <CheckBox
        key={value}
        name={name}
        id={id}
        label={label}
        checked={checked}
        value={value}
        onChange={() => onClickItem(value)}
      />
    ))}
  </div>
)

CheckboxesGroup.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      label: PropTypes.string,
      checked: PropTypes.bool,
      value: PropTypes.string,
    }),
  ),
  onClickItem: PropTypes.func,
}
