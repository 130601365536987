import { getContent, getNavigation } from './requests'

export const generateStateFromNavItems = async (sectionFolder, prevState) => {
  const newState = { ...prevState }
  try {
    const response = await getNavigation(sectionFolder)
    if (response.navigation) {
      newState.navItems = response.navigation
    }
  } catch (err) {
    newState.error = true
  }
  newState.isloading = false
  return newState
}

export const generateStateFromDocContent = async (pageData, prevState) => {
  const newState = { ...prevState, isloading: false, error: false }
  try {
    const text = await getContent(pageData)
    newState.pages = {
      ...prevState.pages,
      [pageData.id]: {
        ...prevState.pages[pageData.id],
        html: text,
      },
    }
  } catch (err) {
    newState.error = true
  }
  return newState
}
