import React from 'react'
import PropTypes from 'prop-types'

export const AccordionMobileHeader = ({ children }) => (
  <wb-accordion-mobile-header>{children}</wb-accordion-mobile-header>
)

AccordionMobileHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
