import React from 'react'
import PropTypes from 'prop-types'

export const WbIcon = ({ icon, fill, className }) => (
  <wb-icon name={icon} aria-hidden="true" class={className} fill={fill} />
)

WbIcon.propTypes = {
  icon: PropTypes.string,
  fill: PropTypes.string,
  className: PropTypes.string,
}
