import React from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  Button,
  Select,
  CheckBox,
  ModalContent,
  ModalFooter,
} from '../../../../../../../common'

export const PackageFormComponent = ({
  header,
  errors,
  handleSubmit,
  platformPackage,
  serviceProviders,
  handleClose,
  updateInputState,
}) => (
  <div>
    <ModalContent headline={header}>
      <div className="bookings-editor__form-field wb-margin-top-xs">
        <Input
          label="Name"
          value={platformPackage.name}
          error={errors.name}
          onChange={(e) => updateInputState('name', e.target.value)}
        />
      </div>
      <div className="bookings-editor__form-field">
        <Input
          label="Description"
          value={platformPackage.description}
          onChange={(e) => updateInputState('description', e.target.value)}
        />
      </div>
      <div className="bookings-editor__form-field">
        <Select
          className="wb-margin-bottom-xxs"
          label="Provider"
          value={platformPackage.providerSlug}
          disabled={Boolean(platformPackage.id)}
          onChange={(event) =>
            updateInputState('providerSlug', event.target.value)
          }
          placeHolder="Select Provider"
          options={serviceProviders}
          error={errors.providerSlug}
        />
      </div>
      <div className="bookings-editor__form-field wb-margin-vertical-m">
        <CheckBox
          className="wb-margin-bottom-xs"
          checked={platformPackage.allowBookingPermissions}
          onChange={() =>
            updateInputState(
              'allowBookingPermissions',
              !platformPackage.allowBookingPermissions,
            )
          }
          label="Allow booking permissions"
        />
      </div>
      <ModalFooter>
        <Button variant="secondary" size="medium" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          size="medium"
          disabled={!platformPackage.name || !platformPackage.providerSlug}
          className="wb-margin-start-s"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </ModalFooter>
    </ModalContent>
  </div>
)

PackageFormComponent.propTypes = {
  header: PropTypes.string,
  handleSubmit: PropTypes.func,
  platformPackage: PropTypes.shape({
    id: PropTypes.number,
    allowBookingPermissions: PropTypes.bool,
    name: PropTypes.string,
    packageKey: PropTypes.string,
    description: PropTypes.string,
    providerSlug: PropTypes.string,
  }),
  errors: PropTypes.shape({
    name: PropTypes.string,
    providerSlug: PropTypes.string,
  }),
  serviceProviders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  handleClose: PropTypes.func,
  updateInputState: PropTypes.func,
}
