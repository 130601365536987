import {
  fetchBookingServiceProperty,
  fetchServiceProperties,
  fetchServiceProperty,
  putBookingServiceProperty,
  postServiceProperties,
  removeServiceProperty,
  putServiceProperties,
} from '../../../../../http'
import {
  postNotification,
  setBookingServiceProperty,
  editBookingServiceProperty,
  removeBookingServiceProperty,
} from '../../../index'
import {
  setProperty,
  setServiceProperties,
  addServiceProperty,
  editServiceProperty,
  removeProperty,
} from '../shared'

export const getServiceProperty = (propertyId, cb) => async (dispatch) => {
  try {
    const response = await fetchServiceProperty(propertyId)

    if (response.errorMessages) {
      throw new Error(response.message)
    }
    dispatch(setProperty(response))
    cb?.()
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while retrieving serviceProperty - ${err.message}!`,
        mode: 'error',
      }),
    )
  }
}

export const getBookingServiceProperty =
  (platformBookingId, serviceSlug, property, cb) => async (dispatch) => {
    try {
      const response = await fetchBookingServiceProperty(
        platformBookingId,
        serviceSlug,
        property.slug,
      )
      if (response.errorMessages) {
        throw new Error(response.message)
      }
      if (property.type === 'SELECT') {
        dispatch(getServiceProperty(property.platformServicePropertyId))
      }
      dispatch(setBookingServiceProperty(response))
      cb?.()
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while retrieving serviceProperties - ${err.message}!`,
          mode: 'error',
        }),
      )
    }
  }

export const updateBookingServiceProperty =
  (propertyId, formObj, cb) => async (dispatch, getState) => {
    const modalObj = getState().backOffice.modalObj
    try {
      const response = await putBookingServiceProperty(
        modalObj.bookingId,
        propertyId,
        JSON.stringify(formObj),
      )
      if (response.errorMessages) {
        throw new Error(response.errorMessages[0].message)
      }
      dispatch(
        editBookingServiceProperty({
          bookingSlug: modalObj.bookingId,
          serviceSlug: modalObj.serviceSlug,
          property: response,
        }),
      )
      dispatch(
        postNotification({
          message: 'Service property was updated successfully',
        }),
      )
      cb?.()
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while updating serviceProperties - ${err.message}!`,
          mode: 'error',
        }),
      )
    }
  }

export const deleteBookingServiceProperty =
  (payload, cb) => async (dispatch) => {
    try {
      await removeServiceProperty(payload.propertyId)
      dispatch(removeBookingServiceProperty(payload))
      cb?.()
      dispatch(
        postNotification({
          message: 'Service property was removed successfully',
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while removing serviceProperty - ${err.message}!`,
          mode: 'error',
        }),
      )
    }
  }

export const getServiceProperties =
  (serviceId) => async (dispatch, getState) => {
    const service = getState().backOffice.service
    try {
      const response = await fetchServiceProperties(serviceId || service.id)
      if (response.errorMessages) {
        throw new Error(response.message)
      }
      dispatch(setServiceProperties(response))
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while retrieving serviceProperties - ${err.message}!`,
          mode: 'error',
        }),
      )
    }
  }

export const addServiceProperties =
  (formObj, cb) => async (dispatch, getState) => {
    const service = getState().backOffice.service
    try {
      const response = await postServiceProperties(
        service.id,
        JSON.stringify(formObj),
      )
      if (response.errorMessages) {
        throw new Error(response.errorMessages[0].message)
      }
      dispatch(addServiceProperty(response))
      cb?.()
      dispatch(
        postNotification({
          message: 'Service property was added successfully',
        }),
      )
    } catch (err) {
      dispatch(postNotification({ message: err.message, mode: 'error' }))
    }
  }

export const deleteServiceProperty =
  (servicePropertyId, cb) => async (dispatch) => {
    try {
      await removeServiceProperty(servicePropertyId)
      dispatch(removeProperty(servicePropertyId))
      cb?.()
      dispatch(
        postNotification({
          message: 'Service property was removed successfully',
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while removing serviceProperty - ${err.message}!`,
          mode: 'error',
        }),
      )
    }
  }

export const updateServiceProperty = (formObj, cb) => async (dispatch) => {
  try {
    const response = await putServiceProperties(
      formObj.id,
      JSON.stringify(formObj),
    )
    if (response.errorMessages) {
      throw new Error(response.errorMessages[0].message)
    }
    dispatch(editServiceProperty(response))
    dispatch(
      postNotification({
        message: 'Service property was updated successfully',
      }),
    )
    cb?.()
  } catch (err) {
    dispatch(postNotification({ message: err.message, mode: 'error' }))
  }
}
