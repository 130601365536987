import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../../../../../../common'

export const TableRow = ({
  provider,
  actions: { onClickProvider, onClickEditProvider, onClcikRemoveProvider },
}) => (
  <tr>
    <td>
      <div>
        <Button
          variant="tertiary"
          size="small"
          disabled={!provider.isManagable}
          onClick={() => onClickProvider(provider.slug)}
          withIcon={provider.cloudProvider ? 'bds/globe/24' : ''}
        >
          {provider.name}
        </Button>
      </div>
    </td>
    <td>
      <div>{provider.slug}</div>
    </td>
    <td>
      <div>{provider.description}</div>
    </td>
    <th scope="row">
      <div className="providers-table__actions">
        <Button
          size="small"
          onClick={() => onClickEditProvider(provider.slug)}
          iconOnly
          variant="secondary"
          disabled={!provider.isEditable}
          withIcon="edit"
          className="wb-margin-end-xxs"
        >
          {provider.name}
        </Button>

        <Button
          size="small"
          variant="secondary"
          onClick={() => onClcikRemoveProvider(provider)}
          iconOnly
          disabled={!provider.isRemovable}
          withIcon="bds/trash/24"
        >
          {provider.name}
        </Button>
      </div>
    </th>
  </tr>
)

TableRow.propTypes = {
  provider: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    cloudProvider: PropTypes.bool,
    isEditable: PropTypes.bool,
    isRemovable: PropTypes.bool,
    isManagable: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    onClickProvider: PropTypes.func,
    onClickEditProvider: PropTypes.func,
    onClcikRemoveProvider: PropTypes.func,
  }),
}
