import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  changeNavMenu,
  fetchSchemaIfNeeded,
  fetchTenantsIfNeeded,
  fetchWhitelistIfNeeded,
  updateUserProfile,
  updateUser,
  fetchCurrentUser,
} from '../../data/actions'
import { MemberForm } from './partials/MemberForm'

const Member = ({
  userData,
  changeMenu,
  fetchWhitelist,
  fetchTenants,
  fetchSchema,
  updateProfile,
  updateUserData,
  getUser,
}) => {
  useEffect(() => {
    changeMenu('member')
    fetchWhitelist()

    fetchSchema()
    fetchTenants()
    getUser()
  }, [])

  return userData ? (
    <MemberForm
      newMember={false}
      userData={userData}
      onUpdateUser={updateProfile}
      updateUser={updateUserData}
      onFormDataChange={() => {}}
    />
  ) : null
}

Member.propTypes = {
  userData: PropTypes.object,
  updateProfile: PropTypes.func,
  updateUserData: PropTypes.func,
  changeMenu: PropTypes.func,
  getUser: PropTypes.func,
  fetchWhitelist: PropTypes.func,
  fetchTenants: PropTypes.func,
  fetchSchema: PropTypes.func,
}

export default connect(({ sso: { userData } }) => ({ userData }), {
  fetchSchema: fetchSchemaIfNeeded,
  fetchTenants: fetchTenantsIfNeeded,
  fetchWhitelist: fetchWhitelistIfNeeded,
  changeMenu: changeNavMenu,
  updateProfile: updateUserProfile,
  updateUserData: updateUser,
  getUser: fetchCurrentUser,
})(Member)
