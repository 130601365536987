import { store } from '../../App'
import { receiveTenants } from '../../data/actions'
import moment from 'moment'
import { tdmsClient } from '../apiClient'

export const fetchTenantLogs = async (options) =>
  tdmsClient.client.tenantMethods.logs(options)

export const fetchAllTenants = async () => {
  const tenants = await tdmsClient.client.tenantMethods.list()
  const tenantsObj = {}
  tenants.forEach((tenant) => (tenantsObj[tenant.acronym] = tenant))
  return tenantsObj
}

export const fetchOperationTypes = async () => {
  const opTypes = await tdmsClient.client.tenantMethods.operationTypes()
  return {
    content: opTypes,
  }
}

export const createTenant = async (options) =>
  tdmsClient.client.tenantMethods.create(options)

export const removeTenant = async (acronym) => {
  await tdmsClient.client.tenantMethods.delete(acronym)
  return { success: true }
}
export const updateTenantData = async (acronym, tenantData) =>
  tdmsClient.client.tenantMethods.update(acronym, tenantData)

export const updateUserProfileFromAlice = async (daimlerId) =>
  tdmsClient.client.userMethods.updateProfileFromAlice(daimlerId)

export const fetchTenantRoles = async () =>
  tdmsClient.client.userMethods.listRoles()

export const fetchTenant = async (tenantAcronym) => {
  const response = await tdmsClient.client.tenantMethods.getByAcronym(
    tenantAcronym,
  )
  return { [response.acronym]: response }
}

export const fetchExpiringTenantMembers = async (tenantAcronym) =>
  tdmsClient.client.tenantMethods.expiringMembers(tenantAcronym)

export const fetchArts = async () => tdmsClient.client.tenantMethods.arts()

export const fetchTenantComplianceData = async (tenantAcr) =>
  tdmsClient.client.tenantMethods.getComplianceInfo(tenantAcr)

export const saveTenantComplianceData = async (tenantAcr, complianceData) =>
  tdmsClient.client.tenantMethods.updateComplianceInfo(
    tenantAcr,
    complianceData,
  )

export const fetchAkamaiCpCodes = async (acronym) =>
  tdmsClient.client.tenantMethods.getAkamaiCpCodes(acronym)

export const invalidateAkamaiCpCodes = async (acronym, cpCodes) =>
  tdmsClient.client.tenantMethods.invalidateAkamaiCpCodes(acronym, cpCodes)

export const postMembership = async ({ acronym, user }) => {
  const expiryDate = moment().add(3, 'months').format('YYYY-MM-DD')
  const memberData = {
    daimlerId: user.daimlerId,
    roles: ['OTHER'],
    tenantUserRoleExpiryDate: expiryDate,
  }
  try {
    await tdmsClient.client.tenantMethods.assignMember(acronym, memberData)
    const allTenants = { ...store.getState().tenants.allTenants }
    allTenants[acronym].members = [
      ...allTenants[acronym].members,
      {
        ...user,
        roles: ['OTHER'],
        tenantMemberUntil: expiryDate,
      },
    ]
    store.dispatch(receiveTenants(allTenants))
    return user
  } catch {
    return false
  }
}

export const deleteMembership = async ({ acronym, daimlerId }) => {
  try {
    await tdmsClient.client.tenantMethods.removeMember(acronym, daimlerId)
    const allTenants = { ...store.getState().tenants.allTenants }
    allTenants[acronym].members = allTenants[acronym].members.filter(
      (user) => user.daimlerId !== daimlerId,
    )
    store.dispatch(receiveTenants(allTenants))
    return true
  } catch {
    return false
  }
}

export const deleteTenantMember = async ({ tenantAcr, daimlerId }) =>
  tdmsClient.client.tenantMethods.removeMember(tenantAcr, daimlerId)

export const batchDeleteTenantMembers = async ({ tenantAcr, daimlerIds }) =>
  tdmsClient.client.tenantMethods.batchRemoveMembers(tenantAcr, daimlerIds)

export const batchAddTenantMembers = async ({
  tenantAcr,
  daimlerIds,
  roles,
  labels,
  tenantUserRoleExpiryDate,
}) =>
  tdmsClient.client.tenantMethods.batchAssignMembers(tenantAcr, {
    daimlerIds,
    roles,
    labels,
    tenantUserRoleExpiryDate,
  })

export const batchAddLabelsMembers = async ({
  tenantAcr,
  daimlerIds,
  labels,
}) => {
  tdmsClient.client.tenantMethods.batchUpdateMembers(tenantAcr, {
    daimlerIds,
    labels,
  })
}

export const setTenantMembersExpiryDate = async ({
  tenantAcr,
  daimlerIds,
  tenantUserRoleExpiryDate,
}) =>
  tdmsClient.client.tenantMethods.setMembersExpiryDate(tenantAcr, {
    daimlerIds,
    tenantUserRoleExpiryDate,
    confirmedReviewedMembersSelectedForExtension: true,
  })

export const updateTenantMember = async ({
  tenantAcr,
  daimlerId,
  roles,
  labels,
  tenantUserRoleExpiryDate,
}) => {
  tdmsClient.client.tenantMethods.updateMember(tenantAcr, {
    daimlerId,
    roles,
    labels,
    tenantUserRoleExpiryDate,
  })
}

export const putMembership = async ({ acronym, user }) => {
  try {
    await tdmsClient.client.tenantMethods.updateMember(acronym, user)
    const allTenants = { ...store.getState().tenants.allTenants }
    const updatedMembers = [...allTenants[acronym].members]
    const indexOfUpdatedMember = updatedMembers.findIndex(
      (u) => u.daimlerId === user.daimlerId,
    )
    const updatedMember = updatedMembers[indexOfUpdatedMember]
    updatedMembers[indexOfUpdatedMember] = {
      ...updatedMember,
      roles: user.roles,
      tenantMemberUntil: user.tenantUserRoleExpiryDate,
    }
    allTenants[acronym].members = updatedMembers
    store.dispatch(receiveTenants(allTenants))
    return true
  } catch {
    return false
  }
}

export const changeTenantProvider = async ({ acronym, provider }) =>
  tdmsClient.client.tenantMethods.changeProvider(acronym, provider)
