import {
  fetchBookings,
  deleteBooking,
  putBooking,
  postBooking,
} from '../../../../../http'
import { postNotification } from '../../..'
import { setBookings, setBooking, setModal } from '../shared'
import { bookingPermissionsMapper } from '../utils'

export const getTenantBookings = (acronym) => async (dispatch, getState) => {
  try {
    if (!acronym) {
      return
    }
    const bookings = await fetchBookings({ acronym, details: true })
    const {
      sso: { providerRoles, roles },
    } = getState()
    dispatch(
      setBookings(bookingPermissionsMapper(bookings, roles, providerRoles)),
    )
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while retrieving bookings - ${err.message}!`,
        mode: 'error',
      }),
    )
  }
}

export const removeTenantBooking = (acronym, bookingId) => async (dispatch) => {
  try {
    await deleteBooking(bookingId)
    dispatch(getTenantBookings(acronym))
    dispatch(setModal({}))
    dispatch(
      postNotification({
        message: 'Booking was removed successfully!',
      }),
    )
  } catch (err) {
    dispatch(
      postNotification({
        message: err.message,
        mode: 'error',
      }),
    )
  }
}

export const updateTenantBooking =
  (tenantAcronym, bookingId, data) => async (dispatch) => {
    try {
      await putBooking({ bookingId, data })
      dispatch(getTenantBookings(tenantAcronym))
      dispatch(setModal({}))
      dispatch(
        postNotification({
          message: 'Booking was updated successfully!',
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: err.message,
          mode: 'error',
        }),
      )
    }
  }

export const addTenantBooking =
  (tenantAcr, priceVariantId, bookingData) => async (dispatch) => {
    try {
      await postBooking({ tenantAcr, priceVariantId, bookingData })
      dispatch(getTenantBookings(tenantAcr))
      dispatch(setModal({}))
      dispatch(
        postNotification({
          message: 'Booking was created successfully!',
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: err.message,
          mode: 'error',
        }),
      )
    }
  }

export const selectTenantBooking = (booking) => (dispatch) =>
  dispatch(setBooking(booking))
