import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { rankItem } from '@tanstack/match-sorter-utils'
import { Button, Input } from '../../../../../../common'

export const ActionItems = ({ onClickEdit, onClickRemove }) => (
  <div className="wh-table__action-items">
    <Button
      variant="secondary"
      size="small"
      iconOnly
      withIcon="bds/pencil/24"
      onClick={onClickEdit}
    />
    <Button
      variant="primary"
      size="small"
      iconOnly
      withIcon="bds/trash/24"
      onClick={onClickRemove}
      className="wb-margin-start-xs"
    />
  </div>
)

ActionItems.propTypes = {
  onClickEdit: PropTypes.func,
  onClickRemove: PropTypes.func,
}

export const generateGithubUrl = ({
  ref,
  repoName,
  repoOwner,
  workflowFilePath,
}) =>
  `https://git.i.mercedes-benz.com/${[
    repoOwner,
    repoName,
    workflowFilePath,
    ref,
  ]
    .filter((a) => a)
    .join('/')}`

export const generateTargetUrl = (protocol) =>
  protocol.type === 'HTTP'
    ? protocol.properties.url
    : generateGithubUrl(protocol.properties)

export const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <Input
      label="Search for webhooks"
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  )
}

DebouncedInput.propTypes = {
  value: PropTypes.string,
  debounce: PropTypes.number,
  onChange: PropTypes.func,
}

export const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value)
  addMeta({
    itemRank,
  })
  return itemRank.passed
}

export const generateColumns = (generateActionItemProps) => [
  {
    header: 'Protocol',
    id: 'protocol',
    accessorKey: 'protocol.type',
  },
  {
    header: 'Producers',
    id: 'producers',
    accessorKey: 'producerSlug',
  },
  {
    header: 'Providers',
    id: 'providers',
    accessorKey: 'providerSlugs',
    accessorFn: (row) => row.providerSlugs.join(' '),
  },
  {
    header: 'Tenants',
    id: 'tenants',
    accessorKey: 'scopes',
    accessorFn: (row) => row.scopes.join(' '),
  },
  {
    header: 'Target',
    id: 'target',
    cell: ({
      row: {
        original: { protocol },
      },
    }) => <div>{generateTargetUrl(protocol)}</div>,
    accessorFn: (row) => generateTargetUrl(row.protocol),
  },
  {
    header: 'Events',
    id: 'events',
    accessorKey: 'eventSlugs',
    cell: ({ row }) => (
      <div style={{ wordBreak: 'break-word' }}>
        {row.original.eventSlugs.map((event) => (
          <p className="wb-tag wb-tag--tertiary wb-margin-top-xxs" key={event}>
            {event}
          </p>
        ))}
      </div>
    ),
    accessorFn: (row) => row.eventSlugs.join(' '),
  },
  {
    header: 'Actions',
    id: 'actions',
    cell: ({ row }) => <ActionItems {...generateActionItemProps(row)} />,
  },
]
