import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
} from '../../../../common'

export const DeleteConfirmationModalContainer = ({
  onDeleteConfirmClick,
  onDeleteCloseClick,
  onDeleteCancelClick,
}) => (
  <Modal mode="edit" onCloseModal={onDeleteCloseClick}>
    <ModalHeader />
    <ModalContent headline="Confirmation">
      Are you sure you want to <strong>delete</strong> tenant?
    </ModalContent>
    <ModalFooter class="tenant-members-dialog__footer">
      <Button variant="secondary" size="medium" onClick={onDeleteCancelClick}>
        Cancel
      </Button>
      <Button variant="primary" size="medium" onClick={onDeleteConfirmClick}>
        Confirm
      </Button>
    </ModalFooter>
  </Modal>
)

DeleteConfirmationModalContainer.propTypes = {
  onDeleteConfirmClick: PropTypes.func,
  onDeleteCloseClick: PropTypes.func,
  onDeleteCancelClick: PropTypes.func,
}
