export function deepCopy(value) {
  if (Array.isArray(value)) {
    const arr = []
    for (let i = 0; i < value.length; i++) {
      arr[i] = deepCopy(value[i])
    }
    return arr
  } else if (typeof value === 'object' && value !== null) {
    const obj = {}
    for (const prop in value) {
      obj[prop] = deepCopy(value[prop])
    }
    return obj
  } else {
    return value
  }
}

if (!window.structuredClone) {
  window.structuredClone = deepCopy
}

export const RISK_ASSESSMENTS = {
  RACIAL_ETHNIC_ORIGIN: 'Racial or ethnic origin',
  POLITICAL_OPINION: 'Political opinion',
  RELIGIOUS_PHILOSOPHICAL_BELIEFS: 'Religious or philosophical beliefs',
  TRADE_UNION_MEMBERSHIP: 'Trade union membership',
  GENETIC_BIOMETRIC_DATA: 'Genetic and biometrical data',
  HEALTH_DATA: 'Health data',
  SEX_LIFE_SEXUAL_ORIENTATION: 'Sex life and sexual orientation',
  CRIMINAL_OFFENCES:
    'Data on criminal convictions and offences or related security measures',
  SOCIAL_PROFESSIONAL_SECRECY:
    'Data is subject to social or perofessional secrecy (doctor or lawyer)',
  OVER_5K_INDIVIDUALS_AFFECTED:
    'More than 5.000 individuals are affected by the processing',
  OVER_50K_INDIVIDUALS_AFFECTED:
    'More than 50.000 data records with information of individuals are affected',
  CRITICAL_SCOPE:
    'The scope is critical for other reasons (e.g. total number of customers of a unit)',
}

export const MBAG_AGREEMENT_ENTITY = {
  MBIO_PT: 'MB.io Portugal',
  MBIO_DE: 'MB.io Germany',
  NOT_MBAG: 'no MBAG order',
}

export const formOptions = {
  mbagOwned: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
  personalData: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
  mbagAgreementEntity: [
    {
      label: MBAG_AGREEMENT_ENTITY.MBIO_PT,
      value: 'MBIO_PT',
    },
    {
      label: MBAG_AGREEMENT_ENTITY.MBIO_DE,
      value: 'MBIO_DE',
    },
    {
      label: MBAG_AGREEMENT_ENTITY.NOT_MBAG,
      value: 'NOT_MBAG',
    },
  ],
  thirdPartyAccess: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
  riskAssessment: [
    {
      label: RISK_ASSESSMENTS.RACIAL_ETHNIC_ORIGIN,
      value: 'RACIAL_ETHNIC_ORIGIN',
    },
    {
      label: RISK_ASSESSMENTS.POLITICAL_OPINION,
      value: 'POLITICAL_OPINION',
    },
    {
      label: RISK_ASSESSMENTS.RELIGIOUS_PHILOSOPHICAL_BELIEFS,
      value: 'RELIGIOUS_PHILOSOPHICAL_BELIEFS',
    },
    {
      label: RISK_ASSESSMENTS.TRADE_UNION_MEMBERSHIP,
      value: 'TRADE_UNION_MEMBERSHIP',
    },
    {
      label: RISK_ASSESSMENTS.GENETIC_BIOMETRIC_DATA,
      value: 'GENETIC_BIOMETRIC_DATA',
    },
    {
      label: RISK_ASSESSMENTS.HEALTH_DATA,
      value: 'HEALTH_DATA',
    },
    {
      label: RISK_ASSESSMENTS.SEX_LIFE_SEXUAL_ORIENTATION,
      value: 'SEX_LIFE_SEXUAL_ORIENTATION',
    },
    {
      label: RISK_ASSESSMENTS.CRIMINAL_OFFENCES,
      value: 'CRIMINAL_OFFENCES',
    },
    {
      label: RISK_ASSESSMENTS.SOCIAL_PROFESSIONAL_SECRECY,
      value: 'SOCIAL_PROFESSIONAL_SECRECY',
    },
    {
      label: RISK_ASSESSMENTS.OVER_5K_INDIVIDUALS_AFFECTED,
      value: 'OVER_5K_INDIVIDUALS_AFFECTED',
    },
    {
      label: RISK_ASSESSMENTS.OVER_50K_INDIVIDUALS_AFFECTED,
      value: 'OVER_50K_INDIVIDUALS_AFFECTED',
    },
    {
      label: RISK_ASSESSMENTS.CRITICAL_SCOPE,
      value: 'CRITICAL_SCOPE',
    },
  ],
  dataAnalytics: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
  automatedDecisionMaking: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
}

export const controls = {
  processOnBehalf: {
    agreementSubject: {
      value: '',
      valid: false,
      touched: false,
      validationRules: {
        minLength: 3,
      },
    },
    dataProcessingLocation: {
      value: '',
      valid: false,
      touched: false,
      validationRules: {
        minLength: 3,
      },
    },
    mbagAgreementEntity: {
      value: '',
      valid: false,
      touched: false,
      validationRules: {
        minLength: 3,
      },
    },
    mbagOwned: {
      value: false,
      valid: false,
      touched: false,
      validationRules: {},
    },
    personalData: {
      value: null,
      valid: false,
      touched: false,
      validationRules: {},
    },
    personalDataScope: {
      value: '',
      valid: false,
      touched: false,
      validationRules: {
        minLength: 3,
      },
    },
    personalDataTargetGroups: {
      value: '',
      valid: false,
      touched: false,
      validationRules: {
        minLength: 3,
      },
    },
    personalDataTypes: {
      value: '',
      valid: false,
      touched: false,
      validationRules: {
        minLength: 3,
      },
    },
    riskAssessment: {
      value: [],
      valid: false,
      touched: false,
      validationRules: {},
    },
    thirdPartyAccess: {
      value: null,
      valid: false,
      touched: false,
      validationRules: {},
    },
  },
  dataProtectionContactPerson: {
    value: '',
    valid: false,
    touched: false,
    validationRules: {
      minLength: 3,
    },
  },
  dataAnalytics: {
    value: false,
    valid: false,
    touched: false,
    validationRules: {},
  },
  automatedDecisionMaking: {
    value: false,
    valid: false,
    touched: false,
    validationRules: {},
  },
}

export const generateControls = (confluenceInfo) => {
  const newControls = structuredClone(controls)
  newControls.automatedDecisionMaking.value =
    confluenceInfo.automatedDecisionMaking
  newControls.dataAnalytics.value = confluenceInfo.dataAnalytics
  newControls.dataProtectionContactPerson.value =
    confluenceInfo.dataProtectionContactPerson
  newControls.processOnBehalf.mbagOwned.value =
    confluenceInfo.processOnBehalf.mbagOwned
  newControls.processOnBehalf.mbagAgreementEntity.value =
    confluenceInfo.processOnBehalf.mbagAgreementEntity
  newControls.processOnBehalf.thirdPartyAccess.value =
    confluenceInfo.processOnBehalf.thirdPartyAccess
  newControls.processOnBehalf.personalData.value =
    confluenceInfo.processOnBehalf.personalData
  newControls.processOnBehalf.agreementSubject.value =
    confluenceInfo.processOnBehalf.agreementSubject
  newControls.processOnBehalf.riskAssessment.value =
    confluenceInfo.processOnBehalf.riskAssessment
  newControls.processOnBehalf.dataProcessingLocation.value =
    confluenceInfo.processOnBehalf.dataProcessingLocation
  newControls.processOnBehalf.personalDataScope.value =
    confluenceInfo.processOnBehalf.personalDataScope
  newControls.processOnBehalf.personalDataTypes.value =
    confluenceInfo.processOnBehalf.personalDataTypes
  newControls.processOnBehalf.personalDataTargetGroups.value =
    confluenceInfo.processOnBehalf.personalDataTargetGroups
  return newControls
}
