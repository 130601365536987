import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getTenantComplianceData } from '../../../../../data/actions'
import { TenantDashboardSection } from '../../../../common'
import {
  RISK_ASSESSMENTS,
  MBAG_AGREEMENT_ENTITY,
} from '../tenantComplianceDialog/components/ComplianceForm/container/formOptions'

export const getStateOrUnknown = (val) =>
  typeof val === 'boolean' ? getYesOrNo(val) : 'unknown'

export const getYesOrNo = (val) => (val ? 'yes' : 'no')

const TenantComplianceInfoContainer = ({
  getTenantComplianceInfo,
  selectedTenant,
  complianceInfo,
}) => {
  useEffect(() => {
    getTenantComplianceInfo()
  }, [selectedTenant])

  if (!complianceInfo) {
    return null
  }

  const processingValues = [
    [
      [
        'Data protection contact',
        complianceInfo.dataProtectionContactPerson || 'unknown',
      ],
      [
        'Mercedes-Benz AG is owner of the data',
        getStateOrUnknown(complianceInfo.processOnBehalf.mbagOwned),
      ],
      [
        'Service Agreement between Mercedes-Benz AG and ...',
        complianceInfo.processOnBehalf.mbagAgreementEntity
          ? MBAG_AGREEMENT_ENTITY[
              complianceInfo.processOnBehalf.mbagAgreementEntity
            ]
          : 'unknown',
      ],
      [
        'External Parners involved',
        getStateOrUnknown(complianceInfo.processOnBehalf.thirdPartyAccess),
      ],
      [
        'Personal Data involved',
        getStateOrUnknown(complianceInfo.processOnBehalf.personalData),
      ],
      [
        'Subject matter of the agreement',
        complianceInfo.processOnBehalf.agreementSubject || 'unknown',
      ],
      [
        'Types of personal data processed',
        complianceInfo.processOnBehalf.personalDataTypes || 'unknown',
      ],
    ],
    [
      [
        'Risk Assessment',
        complianceInfo.processOnBehalf.riskAssessment.length
          ? complianceInfo.processOnBehalf.riskAssessment
              .map((item) => RISK_ASSESSMENTS[item])
              .join(', ')
          : 'unknown',
      ],
      [
        'Locations of data processing',
        complianceInfo.processOnBehalf.dataProcessingLocation || 'unknown',
      ],
      [
        'Scope, Nature and Purpose of Processing of Personal Data',
        complianceInfo.processOnBehalf.personalDataScope || 'unknown',
      ],
      [
        'The groups of people effected by the handling of their personal data in the context of the present agreement',
        complianceInfo.processOnBehalf.personalDataTargetGroups || 'unknown',
      ],
    ],
  ]

  const dataAnalyticsValues = [
    [
      [
        'Data analytics involved',
        getStateOrUnknown(complianceInfo.dataAnalytics),
      ],
    ],
  ]
  const decisionMakingValues = [
    [
      [
        'Automated individual decision-making, incl. profiling',
        getStateOrUnknown(complianceInfo.automatedDecisionMaking),
      ],
    ],
  ]
  return (
    <>
      <TenantDashboardSection
        values={processingValues}
        title="Processing on Behalf"
      />
      <TenantDashboardSection
        values={dataAnalyticsValues}
        title="Data Analytics"
      />
      <TenantDashboardSection
        values={decisionMakingValues}
        title="Automated individual decision-making"
      />
    </>
  )
}

TenantComplianceInfoContainer.propTypes = {
  selectedTenant: PropTypes.string,
  complianceInfo: PropTypes.shape({
    dataProtectionContactPerson: PropTypes.string,
    dataAnalytics: PropTypes.bool,
    automatedDecisionMaking: PropTypes.bool,
    processOnBehalf: PropTypes.shape({
      mbagOwned: PropTypes.bool,
      mbagAgreementEntity: PropTypes.string,
      thirdPartyAccess: PropTypes.bool,
      personalData: PropTypes.bool,
      agreementSubject: PropTypes.string,
      personalDataTypes: PropTypes.string,
      riskAssessment: PropTypes.arrayOf(PropTypes.string),
      dataProcessingLocation: PropTypes.string,
      personalDataScope: PropTypes.string,
      personalDataTargetGroups: PropTypes.string,
    }),
  }),
  getTenantComplianceInfo: PropTypes.func,
}

export const TenantComplianceInfo = connect(
  ({ tenants: { complianceInfo, selectedTenant } }) => ({
    complianceInfo,
    selectedTenant,
  }),
  { getTenantComplianceInfo: getTenantComplianceData },
)(TenantComplianceInfoContainer)
