import React from 'react'
import PropTypes from 'prop-types'
import { TenantList } from '../TenantList'
import { ParseTenantInfo } from '../utils/ParseTenantInfo'
import { Accordion, Spinner } from '../../../common'

const getRequirements = (tenantsList) => {
  const requirements = {
    ONEWEB: {
      title: 'OneWeb',
      list: [],
    },
    ONEWEB_INTEGRATION: {
      title: 'OneWeb Integration',
      list: [],
    },
    ONEWEB_FRONTEND: {
      title: 'OneWeb Frontend',
      list: [],
    },
    COMMERCE: {
      title: 'Commerce',
      list: [],
    },
    COMMERCE_INTEGRATION: {
      title: 'Commerce Integration',
      list: [],
    },
    PLATFORM: {
      title: 'Platform',
      list: [],
    },
    DIGITAL_ASSISTANT: {
      title: 'Digital Assistant',
      list: [],
    },
    AFTER_SALES: {
      title: 'After Sales',
      list: [],
    },
    MERCEDES_ME_HOME: {
      title: 'Mercedes Me Home',
      list: [],
    },
    SERVICES: {
      title: 'Services',
      list: [],
    },
    OTHERS: {
      title: 'Others',
      list: [],
    },
  }

  const programNames = Object.keys(requirements)

  tenantsList.forEach((tenant) =>
    programNames.forEach((programName) => {
      if (tenant.programName === programName) {
        requirements[programName].list.push(ParseTenantInfo(tenant))
      }
    }),
  )
  return requirements
}

export const ProgramReport = ({ allTenants }) => {
  const tenantsList = Object.values(allTenants)
  const requirements = getRequirements(tenantsList)

  if (!tenantsList.length) {
    return (
      <div className="page-body__section">
        <div className="loader-container">
          <Spinner mode="dark" />
        </div>
      </div>
    )
  }

  return (
    <div className="page-body__section">
      <h3 className="wb-type-heading-m page-body__margin-bottom-s">Program</h3>

      <Accordion>
        {Object.values(requirements).map(({ title, list }) => (
          <TenantList title={title} list={list} key={title} />
        ))}
      </Accordion>
    </div>
  )
}

ProgramReport.propTypes = {
  allTenants: PropTypes.objectOf(
    PropTypes.shape({
      status: PropTypes.string,
      programName: PropTypes.string,
    }),
  ),
}
