import React from 'react'
import PropTypes from 'prop-types'
import { MultiSelectWithSelectAll } from '../../MultiSelectWithSelectAll'

export const TenantsSelectorComponent = ({
  tenantsArray,
  value,
  onClickTenant,
  invalid,
}) => (
  <div className="tenant-selection wb-margin-bottom-xs">
    <MultiSelectWithSelectAll
      items={tenantsArray}
      selectedItems={value}
      onChange={onClickTenant}
      invalid={invalid}
      modelName="Tenants"
    />
  </div>
)

TenantsSelectorComponent.propTypes = {
  tenantsArray: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  ]),
  onClickTenant: PropTypes.func,
  invalid: PropTypes.bool,
}
