import React from 'react'
import PropTypes from 'prop-types'
import { BookingServicePermissions } from '../components/BookingServicePermissions'
import { BookingServiceProperties } from '../components/BookingServiceProperties'
import { BookingServiceDetails } from '../components/BookingServiceDetails'
import { Tabs } from '../../Tabs'
import { WbAccordion, Accordion } from '../../../../../../../common'

export const BookingItemComponent = ({ booking, packageServices }) => {
  const generateTabsContent = () => {
    const tabs = {}
    if (booking.packageAllowBookingPermissions) {
      tabs.Permissions = BookingServicePermissions
    }
    tabs.Properties = BookingServiceProperties
    tabs.Details = BookingServiceDetails
    return tabs
  }

  return (
    <Accordion>
      {packageServices.map((service) => (
        <WbAccordion title={service.name} key={service.id}>
          <Tabs content={generateTabsContent()} props={{ service, booking }} />
        </WbAccordion>
      ))}
    </Accordion>
  )
}

export const serviceProp = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  provider: PropTypes.string,
  serviceKey: PropTypes.string,
  slug: PropTypes.string,
})

BookingItemComponent.propTypes = {
  booking: PropTypes.shape({
    comment: PropTypes.string,
    packageAllowBookingPermissions: PropTypes.bool,
    endDate: PropTypes.string,
    id: PropTypes.number,
    packageName: PropTypes.string,
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        year: PropTypes.string,
        monthlyPrice: PropTypes.number,
        platformServicePackageId: PropTypes.string,
      }),
    ),
    provider: PropTypes.string,
    slug: PropTypes.string,
    startDate: PropTypes.string,
    tenantAcronym: PropTypes.string,
    tenantName: PropTypes.string,
  }),
  packageServices: PropTypes.arrayOf(serviceProp),
}
