import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  ModalContent,
  ModalFooter,
  CheckBox,
} from '../../../../../common'

export const RemoveKeyPrompt = ({ row, title, onClose, onApprove }) => {
  const [agree, setAgree] = useState(false)
  return (
    <ModalContent headline={title}>
      <div className="wb-margin-bottom-xm">
        By deleting this key all integrations with TDMS using it will break.
        This process is not reversible.
      </div>
      <div className="wb-margin-vertical-xs">
        <CheckBox
          className="wb-margin-bottom-xs"
          checked={agree}
          onChange={() => setAgree(!agree)}
          label="I confirm that I understand the consequences of deleting this API key."
        />
      </div>
      <ModalFooter>
        <Button variant="secondary" size="medium" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          withIcon="bds/trash/16"
          size="medium"
          disabled={!agree}
          onClick={() => onApprove(row.id)}
        >
          Delete
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}

RemoveKeyPrompt.propTypes = {
  row: PropTypes.object,
  title: PropTypes.string,
  onApprove: PropTypes.func,
  onClose: PropTypes.func,
}
