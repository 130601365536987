import React from 'react'
import PropTypes from 'prop-types'
import { TableBody } from '../TableBody'
import { TableHeader } from '../TableHeader'
import { TablePagination } from '../../../../../../../common'

export const TableComponent = ({
  table,
  globalFilter,
  setGlobalFilter,
  onAddNew,
}) => (
  <div className="wh-table">
    <TableHeader
      onClickAddNew={onAddNew}
      globalFilter={globalFilter}
      setGlobalFilter={setGlobalFilter}
    />
    <TableBody table={table} />
    <TablePagination table={table} />
  </div>
)

TableComponent.propTypes = {
  table: PropTypes.object,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  onAddNew: PropTypes.func,
}
