import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { flexRender } from '@tanstack/react-table'
import { renderSubComponent } from '../utils'
import { TableHead } from '../TableHead'
import { TablePagination, UnstiledButton } from '../../../../../common'

export const TenantMemberActionsComponent = ({
  selectedOpTypes,
  logTypes,
  selectedTenants,
  allTenants,
  globalFilter,
  table,
  tenant,
  actions: {
    setSelectedOpTypes,
    setSelectedTenants,
    setQuery: setGlobalFilter,
  },
}) => (
  <div className="tenant-logs wb-type-copy wb-padding-top-xs">
    <h2>Tenant logs</h2>
    <hr />
    <TableHead
      selectedOpTypes={selectedOpTypes}
      operationTypes={logTypes}
      selectedTenants={selectedTenants}
      allTenants={allTenants}
      query={globalFilter}
      isTenantScoped={Boolean(tenant)}
      actions={{
        setSelectedOpTypes,
        setSelectedTenants,
        setQuery: setGlobalFilter,
      }}
    />
    <div className="tenant-logs__table">
      <table className="wb-table wb-table--border-horizontal">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} style={{ cursor: 'pointer' }}>
                  {header.isPlaceholder ? null : (
                    <UnstiledButton
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted()] ?? null}
                    </UnstiledButton>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getSortedRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              <tr>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    <div>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </div>
                  </td>
                ))}
              </tr>
              {row.getIsExpanded() && (
                <tr>
                  <td colSpan={row.getVisibleCells().length}>
                    {renderSubComponent({ row })}
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
    <div className="tenant-logs__pagination">
      <TablePagination table={table} />
    </div>
  </div>
)

TenantMemberActionsComponent.propTypes = {
  selectedOpTypes: PropTypes.array,
  logTypes: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      group: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  selectedTenants: PropTypes.array,
  allTenants: PropTypes.arrayOf(PropTypes.string),
  globalFilter: PropTypes.string,
  table: PropTypes.object,
  tenant: PropTypes.object,
  actions: PropTypes.shape({
    setSelectedOpTypes: PropTypes.func,
    setSelectedTenants: PropTypes.func,
    setQuery: PropTypes.func,
  }),
}
