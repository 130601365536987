import React from 'react'
import PropTypes from 'prop-types'
import { TenantList } from '../TenantList'
import { ParseTenantInfo } from '../utils/ParseTenantInfo'
import { Accordion, Spinner } from '../../../common'

const generateRequirements = (tenantsList) => {
  const requirements = {
    confidentialitySecret: {
      title: 'Confidentiality - Secret',
      list: [],
    },
    confidentialityConfidential: {
      title: 'Confidentiality - Confidential',
      list: [],
    },
    availabilityCritical: {
      title: 'Availability - Critical',
      list: [],
    },
    continuityCritical: {
      title: 'Continuity - Critical',
      list: [],
    },
  }

  const mapperValues = {
    confidentiality: {
      secret: 'confidentialitySecret',
      confidential: 'confidentialityConfidential',
    },
    availability: {
      critical: 'availabilityCritical',
    },
    continuity: {
      critical: 'continuityCritical',
    },
  }

  tenantsList.forEach((tenant) => {
    if (tenant.status === 'active') {
      Object.keys(mapperValues).forEach((prop) => {
        Object.keys(mapperValues[prop]).forEach((propertyValue) => {
          if (tenant[prop] === propertyValue) {
            requirements[mapperValues[prop][propertyValue]].list.push(
              ParseTenantInfo(tenant),
            )
          }
        })
      })
    }
  })
  return requirements
}

export const ComplianceRequirements = ({ allTenants = {} }) => {
  const tenantsList = Object.values(allTenants)
  const requirements = generateRequirements(tenantsList)

  if (tenantsList.length === 0) {
    return (
      <div className="page-body__section">
        <div className="loader-container">
          <Spinner mode="dark" />
        </div>
      </div>
    )
  }

  return (
    <div className="page-body__section">
      <h3 className="wb-type-heading-m page-body__margin-bottom-s">
        Tenants with critical compliance requirements
      </h3>
      <Accordion>
        {Object.values(requirements).map(({ title, list }) => (
          <TenantList key={title} title={title} list={list} />
        ))}
      </Accordion>
    </div>
  )
}

ComplianceRequirements.propTypes = {
  allTenants: PropTypes.objectOf(
    PropTypes.shape({
      status: PropTypes.string,
      confidentiality: PropTypes.string,
      availability: PropTypes.string,
      continuity: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }),
  ),
}
