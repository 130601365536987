import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from '../../../../../../common'

export const MemberRoles = ({ roles }) => {
  const rolesToHighlight = ['PO', 'PM', 'BUDGET_APPROVER', 'ISA']
  const rolesList = roles
    .filter((role) => rolesToHighlight.includes(role))
    .map((role) => (role === 'BUDGET_APPROVER' ? 'BA' : role))
  return (
    <div className="members-content__badge">
      {rolesList.map((role) => (
        <Tag
          key={role}
          label={role}
          variant="tertiary"
          className="wb-margin-3xs"
        />
      ))}
    </div>
  )
}

MemberRoles.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
}
