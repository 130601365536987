import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export const ButtonGroupItem = ({ selected, onClick, children }) => (
  <label
    className={classnames('wb-button wb-button--secondary wb-button--medium', {
      'wb-button-group__item--selected': selected,
    })}
  >
    <input type="radio" name="group" onChange={onClick} />
    {children}
  </label>
)

ButtonGroupItem.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
