import React from 'react'
import PropTypes from 'prop-types'

export const PopoverHost = ({ position, children }) => (
  <wb-popover-host position={position}>{children}</wb-popover-host>
)

PopoverHost.propTypes = {
  position: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
