import React from 'react'
import PropTypes from 'prop-types'
import { numberToPrice } from '../../../../../apps/tenant/components/tenantCostOverview/utils'

export const CCOTable = ({ packages }) => (
  <table className="wb-table wb-table--border-horizontal wb-margin-bottom-xs">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Package</th>
        <th scope="col">Price Variant</th>
        <th scope="col">Services</th>
        <th scope="col">Price</th>
      </tr>
    </thead>
    <tbody>
      {packages.map((pkg, index) => (
        <tr key={pkg.name + pkg.priceVariantName}>
          <th scope="row">{index + 1}</th>
          <td>{pkg.name}</td>
          <td>{pkg.priceVariantName}</td>
          <td>
            <ul className="wb-list wb-list--offset-m wb-list--unordered wb-margin-bottom-m">
              {pkg.services.map((srv) => (
                <li className="wb-list__item" key={srv.serviceName}>
                  {srv.serviceName} ( <span>{srv.providerSlug}</span> )
                </li>
              ))}
            </ul>
          </td>
          <td>{numberToPrice()(pkg.price)}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

CCOTable.propTypes = {
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      priceVariantName: PropTypes.string,
      price: PropTypes.number,
      services: PropTypes.arrayOf(
        PropTypes.shape({
          serviceName: PropTypes.string,
          provider: PropTypes.string,
        }),
      ),
    }),
  ),
}
