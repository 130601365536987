import React from 'react'
import PropTypes from 'prop-types'
import { ProviderDialogForm } from '../components/ProviderDialogForm'
import { Modal, ModalHeader, ModalLevel } from '../../../../../common'

export const ChangeProviderDialog = ({ onClose }) => (
  <Modal mode="edit" onCloseModal={onClose}>
    <ModalLevel level={1}>
      <ModalHeader />
      <ProviderDialogForm onCancel={onClose} />
    </ModalLevel>
  </Modal>
)

ChangeProviderDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
}
