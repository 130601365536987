import React from 'react'
import PropTypes from 'prop-types'
import { sortMembers } from './util'
import { MemberItem } from '../components/MemberItem'

export const TenantMembersComponent = ({ members, tenantRoles }) => {
  if (!members?.length) {
    return <p>-</p>
  }
  const sortedMembersList = sortMembers(members)
  return (
    <div className="tenant-body__block members">
      <div className="tenant-body__block-label">Members</div>
      <div className=" members-content">
        {sortedMembersList.map((member) => (
          <MemberItem
            member={member}
            key={member.daimlerId}
            tenantRoles={tenantRoles}
          />
        ))}
      </div>
    </div>
  )
}

TenantMembersComponent.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({ daimlerId: PropTypes.string })),
  tenantRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
}
