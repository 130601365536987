import React, { useState } from 'react'
import PropTypes from 'prop-types'
import validate from '../../../../../../../../../util/helpers/validate'
import { PricesFormComponent } from '../component'

export const PricesForm = ({ header, priceVariant, onCancel, onSave }) => {
  const priceConrols = {
    year: {
      value: '',
      valid: false,
      touched: false,
      validationRules: {
        isYear: true,
      },
    },
    monthlyPrice: {
      value: '',
      valid: false,
      touched: false,
      validationRules: {
        isNumeric: true,
      },
    },
  }

  const controls = {
    prices: {
      value: priceVariant.prices.map((price) => {
        const newPc = JSON.parse(JSON.stringify(priceConrols))
        newPc.year.value = price.year || ''
        newPc.year.touched = true
        newPc.year.valid = validate(
          newPc.year.value,
          newPc.year.validationRules,
        )
        newPc.monthlyPrice.value = price.monthlyPrice || 0
        newPc.monthlyPrice.valid = validate(
          newPc.monthlyPrice.value,
          newPc.monthlyPrice.validationRules,
        )
        newPc.monthlyPrice.touched = true
        return newPc
      }),
      touched: false,
      validationRules: {
        minLength: 1,
      },
      valid: false,
    },
  }

  const [state, setState] = useState(controls)

  const updateInputState = (value) => {
    const pricesAreValid = value.reduce(
      (acc, price) => acc && price.year.valid && price.monthlyPrice.valid,
      validate(value, controls.prices.validationRules),
    )
    setState({
      ...state,
      ['prices']: {
        ...state.prices,
        value,
        valid: pricesAreValid,
        touched: true,
      },
    })
  }

  const onAddPrice = () => {
    const newPrices = [...state.prices.value]
    newPrices.push(priceConrols)
    updateInputState(newPrices)
  }

  const onRemovePrice = (index) => {
    const newPrices = [...state.prices.value]
    newPrices.splice(index, 1)
    updateInputState(newPrices)
  }

  const updatePriceVariants = (index, control, value) => {
    const newPrices = [...state.prices.value]
    const targetPriceControls = newPrices[index]
    let validationRules = targetPriceControls[control].validationRules
    if (control === 'year') {
      validationRules = {
        ...validationRules,
        notIn: state.prices.value.map((p) => p.year.value),
      }
    }
    targetPriceControls[control] = {
      ...targetPriceControls[control],
      valid: validate(value, validationRules),
      touched: true,
      value,
    }
    updateInputState(newPrices)
  }

  const handleSubmit = () => {
    const prices = state.prices.value.map((price) => ({
      year: price.year.value,
      monthlyPrice: price.monthlyPrice.value,
    }))
    onSave(priceVariant.slug, prices)
  }

  return (
    <PricesFormComponent
      actions={{
        onCancel,
        handleSubmit,
        onAddPrice,
        onRemovePrice,
        updatePriceVariants,
      }}
      controls={state}
      header={header}
      priceVariant={priceVariant}
    />
  )
}

PricesForm.propTypes = {
  header: PropTypes.string,
  priceVariant: PropTypes.shape({
    name: PropTypes.string,
    prices: PropTypes.array,
    slug: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}
