export const member = {
  properties: [
    {
      title: 'First name*',
      key: 'firstName',
      type: 'string',
      description: "The member's first name",
    },
    {
      title: 'Last name*',
      key: 'lastName',
      type: 'string',
      description: "The member's last name",
    },
    {
      title: 'Daimler ID*',
      key: 'daimlerId',
      type: 'string',
      description: 'The Daimler username ID ( GEMS )',
    },
    {
      title: 'MB.io ID',
      key: 'mbioId',
      type: 'string',
      description: 'The MB.io IT username ID ( LDAP )',
    },
    {
      title: 'Email*',
      key: 'email',
      type: 'string',
      format: 'email',
      description: "The member's email",
    },
    {
      title: 'Team',
      key: 'team',
      type: 'string',
      description: "The member's team",
    },
  ],
}
