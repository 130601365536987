import React from 'react'
import PropTypes from 'prop-types'

export const ButtonGroup = ({ children }) => (
  <wb-button-group>{children}</wb-button-group>
)

ButtonGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
