import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import validate from '../../../../../../util/helpers/validate'
import { Input } from '../../../../../common'

export const GithubEndpointGenerator = ({ state, setState }) => {
  const updateInputState = (control, value) => {
    setState({
      ...state,
      [control]: {
        ...state[control],
        value,
        touched: true,
        valid:
          value.length > 0 ||
          validate(value, state[control].validationRules, state),
      },
    })
  }
  const mandatoryField = 'Mandatory Field'

  return (
    <div className="wh-github-form" style={{ width: '100%' }}>
      <Input
        type="text"
        label="Organization"
        value={state.organization.value}
        placeholder="Type here..."
        onChange={(e) => updateInputState('organization', e.target.value)}
        className={classnames({
          error: state.organization.touched && !state.organization.valid,
        })}
        error={
          state.organization.touched &&
          !state.organization.valid &&
          mandatoryField
        }
      />
      <Input
        type="text"
        label="Repository"
        value={state.repository.value}
        placeholder="Type here..."
        onChange={(e) => updateInputState('repository', e.target.value)}
        className={classnames({
          error: state.repository.touched && !state.repository.valid,
        })}
        error={
          state.repository.touched && !state.repository.valid && mandatoryField
        }
      />
      <Input
        type="text"
        label="Workflow file"
        value={state.workflowFileName.value}
        placeholder="Type here..."
        onChange={(e) => updateInputState('workflowFileName', e.target.value)}
        className={classnames({
          error:
            state.workflowFileName.touched && !state.workflowFileName.valid,
        })}
        error={
          state.workflowFileName.touched &&
          !state.workflowFileName.valid &&
          mandatoryField
        }
      />
      <Input
        type="text"
        label="Git Reference"
        value={state.gitReference.value}
        placeholder="Type here..."
        onChange={(e) => updateInputState('gitReference', e.target.value)}
        className={classnames({
          error: state.gitReference.touched && !state.gitReference.valid,
        })}
        error={
          state.gitReference.touched &&
          !state.gitReference.valid &&
          mandatoryField
        }
      />
      <div style={{ width: '100%', marginBottom: '30px' }}>
        <h4>
          URL Preview
          <wb-tooltip-host position="right">
            <wb-icon
              name="bds/info-circle/16"
              style={{
                width: '12px',
                height: '12px',
                marginLeft: '10px',
                color: '#0078D6',
              }}
            ></wb-icon>
            <wb-tooltip>
              URL Preview will be displayed when all fields above are filled.
            </wb-tooltip>
          </wb-tooltip-host>
        </h4>
        <Input
          type="text"
          value={
            state.organization.value &&
            state.repository.value &&
            state.workflowFileName.value &&
            state.gitReference.value &&
            `${state.organization.value}/${state.repository.value}/.github/workflows/${state.workflowFileName.value}@${state.gitReference.value}`
          }
          disabled={
            !state.gitReference ||
            !state.workflowFileName ||
            !state.repository ||
            !state.organization
          }
          style={{ width: '100%', color: '#696969' }}
          button={'bds/location/24'}
        />
      </div>
      <div style={{ width: '100%' }}>
        <Input
          label="Access Token (optional)"
          value={state.githubToken.value}
          placeholder="Github Token"
          onChange={(e) => updateInputState('githubToken', e.target.value)}
        />
      </div>
    </div>
  )
}

const InputProps = PropTypes.shape({
  value: PropTypes.string,
  valid: PropTypes.bool,
  touched: PropTypes.bool,
  validationRules: PropTypes.shape({
    isValidUrl: PropTypes.bool,
  }),
})

GithubEndpointGenerator.propTypes = {
  state: PropTypes.shape({
    organization: InputProps,
    repository: InputProps,
    workflowFileName: InputProps,
    gitReference: InputProps,
    githubToken: InputProps,
  }),
  setState: PropTypes.func,
}
