import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Select } from '../../../../../../common'
import { fetchPackagePriceVariants } from '../../../../../../../http'

export const PriceVariantsSelector = ({
  packageSlug,
  control,
  error,
  onChange,
}) => {
  const [options, setOptions] = useState([])
  useEffect(() => {
    const getPriceVariants = async (pkgSlug) => {
      const pvs = await fetchPackagePriceVariants(pkgSlug)
      setOptions(pvs)
    }
    if (packageSlug) {
      getPriceVariants(packageSlug)
    }
  }, [packageSlug])
  return (
    <Select
      label="Price variant"
      disabled={control.disabled}
      value={control.value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Select price variant"
      options={options}
      error={error}
    />
  )
}
PriceVariantsSelector.propTypes = {
  packageSlug: PropTypes.string,
  control: PropTypes.shape({
    disabled: PropTypes.bool,
    value: PropTypes.string,
  }),
  error: PropTypes.string,
  onChange: PropTypes.func,
}
