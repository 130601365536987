import {
  SET_PACKAGES,
  ADD_PACKAGE,
  UPDATE_PACKAGE,
  SET_SERVICES,
  ADD_PLATFORM_SERVICE,
  SET_PACKAGE_PRICE_VARIANTS,
  ADD_PACKAGE_PRICE_VARIANT,
  UPDATE_PACKAGE_PRICE_VARIANT,
  REMOVE_PACKAGE_PRICE_VARIANT,
  UPDATE_PLATFORM_SERVICE,
  SET_PACKAGE_SERVICES,
  SET_SERVICE_PERMISSIONS,
  SET_SERVICE_PROPERTIES,
  ADD_SERVICE_PROPERTY,
  UPDATE_SERVICE_PROPERTY,
  SET_PROPERTY,
  REMOVE_SERVICE_PROPERTY,
  SET_SERVICE_PERMISSION,
  ADD_PERMISSION_TO_SERVICE,
  UPDATE_SERVICE_PERMISSION,
  REMOVE_PERMISSION_FROM_SERVICE,
  ATTACH_SERVICE_TO_PACKAGE,
  DETACH_SERVICE_FROM_PACKAGE,
  SET_PACKAGE,
  SET_SERVICE,
  SET_MODAL,
  SET_PACKAGE_PRICES,
  SET_SERVICE_PROVIDERS,
  ADD_PACKAGE_PRICE,
  UPDATE_PACKAGE_PRICE,
  SET_ACTIVE_PACKAGE_PRICE,
  SET_PROVIDER,
  SET_PROVIDERS,
  SET_PROVIDER_USER,
  SET_PROVIDER_USERS,
  SET_TDMS_USERS_WITH_ROLES,
  SET_BACKOFFICE_BOOKING,
  SET_BACKOFFICE_BOOKINGS,
} from '../../actionsTypes'

const initialState = {
  packages: [],
  packageServices: [],
  services: [],
  package: null,
  service: null,
  serviceProperties: [],
  property: null,
  servicePermissions: [],
  permission: null,
  packagePrices: [],
  packagePrice: null,
  modalObj: {},
  priceVariants: {},
  serviceProviders: [],
  providers: [],
  provider: null,
  providerUsers: [],
  providerUser: null,
  tdmsUsers: [],
  bookings: [],
  booking: null,
}

const handlers = {
  [SET_PACKAGES]: (state, action) => ({
    ...state,
    packages: action.payload,
  }),
  [ADD_PACKAGE]: (state, action) => ({
    ...state,
    packages: [...state.packages, action.payload],
  }),
  [UPDATE_PACKAGE]: (state, action) => ({
    ...state,
    packages: [
      ...state.packages.map((pkg) =>
        pkg.id === action.payload.id ? { ...action.payload } : pkg,
      ),
    ],
  }),
  [SET_SERVICES]: (state, action) => ({
    ...state,
    services: action.payload,
  }),
  [SET_SERVICE_PROVIDERS]: (state, action) => ({
    ...state,
    serviceProviders: action.payload,
  }),
  [ADD_PLATFORM_SERVICE]: (state, action) => ({
    ...state,
    services: [...state.services, action.payload],
  }),
  [SET_PACKAGE_PRICE_VARIANTS]: (state, action) => {
    const { packageSlug, priceVariants } = action.payload
    return {
      ...state,
      priceVariants: {
        ...state.priceVariants,
        [packageSlug]: priceVariants,
      },
    }
  },
  [ADD_PACKAGE_PRICE_VARIANT]: (state, action) => {
    const { packageSlug: pkgSlug, priceVariant: newPv } = action.payload
    const newPackagePvs = [...state.priceVariants[pkgSlug]]

    newPackagePvs.push(newPv)

    return {
      ...state,
      priceVariants: {
        ...state.priceVariants,
        [pkgSlug]: newPackagePvs,
      },
    }
  },
  [UPDATE_PACKAGE_PRICE_VARIANT]: (state, action) => {
    const { packageSlug, priceVariant } = action.payload
    const targetPvs = state.priceVariants[packageSlug]
    const newPvs = targetPvs.map((pv) =>
      pv.slug === priceVariant.slug ? { ...priceVariant } : pv,
    )
    return {
      ...state,
      priceVariants: {
        ...state.priceVariants,
        [packageSlug]: newPvs,
      },
    }
  },
  [REMOVE_PACKAGE_PRICE_VARIANT]: (state, action) => {
    const { packageSlug: currentPackageSlug, priceVariantSlug } = action.payload
    const targetPriceVariants = state.priceVariants[currentPackageSlug]
    const newPriceVariants = targetPriceVariants.filter(
      (pv) => pv.slug !== priceVariantSlug,
    )
    return {
      ...state,
      priceVariants: {
        ...state.priceVariants,
        [currentPackageSlug]: newPriceVariants,
      },
    }
  },
  [UPDATE_PLATFORM_SERVICE]: (state, action) => {
    const newState = {
      services: [
        ...state.services.map((service) =>
          service.id === action.payload.id ? { ...action.payload } : service,
        ),
      ],
      service: state.service,
    }
    if (state.service.id === action.payload.id) {
      newState.service = { ...action.payload }
    }
    return {
      ...state,
      ...newState,
    }
  },
  [SET_PACKAGE_SERVICES]: (state, action) => ({
    ...state,
    packageServices: action.payload,
  }),
  [SET_SERVICE_PERMISSIONS]: (state, action) => ({
    ...state,
    servicePermissions: action.payload,
  }),
  [SET_SERVICE_PROPERTIES]: (state, action) => ({
    ...state,
    serviceProperties: action.payload,
  }),
  [ADD_SERVICE_PROPERTY]: (state, action) => ({
    ...state,
    serviceProperties: [...state.serviceProperties, action.payload],
  }),
  [UPDATE_SERVICE_PROPERTY]: (state, action) => ({
    ...state,
    serviceProperties: [
      ...state.serviceProperties.map((property) =>
        property.id === action.payload.id ? { ...action.payload } : property,
      ),
    ],
  }),
  [SET_PROPERTY]: (state, action) => ({
    ...state,
    property: action.payload,
  }),
  [REMOVE_SERVICE_PROPERTY]: (state, action) => ({
    ...state,
    serviceProperties: state.serviceProperties.filter(
      (prop) => prop.id !== action.payload,
    ),
  }),
  [SET_SERVICE_PERMISSION]: (state, action) => ({
    ...state,
    permission: action.payload,
  }),
  [ADD_PERMISSION_TO_SERVICE]: (state, action) => ({
    ...state,
    servicePermissions: [...state.servicePermissions, action.payload],
  }),
  [UPDATE_SERVICE_PERMISSION]: (state, action) => ({
    ...state,
    servicePermissions: [
      ...state.servicePermissions.map((permission) =>
        permission.id === action.payload.id
          ? { ...action.payload }
          : permission,
      ),
    ],
  }),
  [REMOVE_PERMISSION_FROM_SERVICE]: (state, action) => ({
    ...state,
    servicePermissions: [
      ...state.servicePermissions.filter(
        (permission) => permission.id !== action.payload,
      ),
    ],
  }),
  [ATTACH_SERVICE_TO_PACKAGE]: (state, action) => ({
    ...state,
    packageServices: [...state.packageServices, action.payload],
  }),
  [DETACH_SERVICE_FROM_PACKAGE]: (state, action) => ({
    ...state,
    packageServices: [
      ...state.packageServices.filter(
        (service) => service.id !== action.payload.id,
      ),
    ],
  }),
  [SET_PACKAGE]: (state, action) => ({
    ...state,
    package: action.payload,
  }),
  [SET_SERVICE]: (state, action) => ({
    ...state,
    service: action.payload,
  }),
  [SET_MODAL]: (state, action) => ({
    ...state,
    modalObj: action.payload,
  }),
  [SET_PACKAGE_PRICES]: (state, action) => ({
    ...state,
    packagePrices: action.payload,
  }),
  [SET_PACKAGE_PRICES]: (state, action) => ({
    ...state,
    packagePrices: action.payload,
  }),
  [ADD_PACKAGE_PRICE]: (state, action) => ({
    ...state,
    packagePrices: [...state.packagePrices, action.payload],
  }),
  [UPDATE_PACKAGE_PRICE]: (state, action) => ({
    ...state,
    packagePrices: [
      ...state.packagePrices.map((price) =>
        price.id === action.payload.id ? { ...action.payload } : price,
      ),
    ],
    packagePrice: null,
  }),
  [SET_ACTIVE_PACKAGE_PRICE]: (state, action) => ({
    ...state,
    packagePrice: action.payload,
  }),
  [SET_PROVIDERS]: (state, action) => ({
    ...state,
    providers: action.payload,
  }),
  [SET_PROVIDER]: (state, action) => ({
    ...state,
    provider: action.payload,
  }),
  [SET_PROVIDER_USER]: (state, action) => ({
    ...state,
    providerUser: action.payload,
  }),
  [SET_PROVIDER_USERS]: (state, action) => ({
    ...state,
    providerUsers: action.payload,
  }),
  [SET_TDMS_USERS_WITH_ROLES]: (state, action) => ({
    ...state,
    tdmsUsers: action.payload,
  }),
  [SET_BACKOFFICE_BOOKING]: (state, action) => ({
    ...state,
    booking: action.payload,
  }),
  [SET_BACKOFFICE_BOOKINGS]: (state, action) => ({
    ...state,
    bookings: action.payload,
  }),
}

export const backOfficeReducer = (state = initialState, action) => {
  return handlers[action.type] ? handlers[action.type](state, action) : state
}
