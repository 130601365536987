export const providersSorter = (a, b) => {
  const name1 = a.name.toLowerCase()
  const name2 = b.name.toLowerCase()
  return name1.localeCompare(name2)
}

export const usersSorter = (a, b) => {
  const name1 = `${a.firstName} ${a.lastName}`.toLowerCase()
  const name2 = `${b.firstName} ${b.lastName}`.toLowerCase()
  return name1.localeCompare(name2)
}
export const providerUsersSorter = (a, b) => {
  const name1 = `${a.user.firstName} ${a.user.lastName}`.toLowerCase()
  const name2 = `${b.user.firstName} ${b.user.lastName}`.toLowerCase()
  return name1.localeCompare(name2)
}

export const applyProviderPermissions = (
  provider,
  tdmsRoles,
  providerRoles,
) => {
  const userIsTdmsAdmin = tdmsRoles.includes('admin')
  return {
    ...provider,
    isEditable: userIsTdmsAdmin,
    isRemovable: userIsTdmsAdmin,
    isManagable: userIsTdmsAdmin || providerRoles[provider.slug] === 'ADMIN',
  }
}

export const applyBookingPermissions = (booking, tdmsRoles, providerRoles) => {
  const userIsTdmsAdmin = tdmsRoles.includes('admin')
  const userIsProviderAdmin = Boolean(providerRoles[booking.providerSlug])
  return {
    ...booking,
    isEditable: userIsTdmsAdmin || userIsProviderAdmin,
    isRemovable: userIsTdmsAdmin || userIsProviderAdmin,
  }
}

export const applyPackagePermissions = (
  currentPackage,
  tdmsRoles,
  providerRoles,
) => {
  const userIsTdmsAdmin = tdmsRoles.includes('admin')
  return {
    ...currentPackage,
    isEditable:
      !currentPackage.archived &&
      (userIsTdmsAdmin || Boolean(providerRoles[currentPackage.providerSlug])),
  }
}

export const applyServicePermissions = (service, tdmsRoles, providerRoles) => {
  const userIsTdmsAdmin = tdmsRoles.includes('admin')
  return {
    ...service,
    isEditable:
      !service.archived &&
      (userIsTdmsAdmin || Boolean(providerRoles[service.providerSlug])),
  }
}

export const bookingPermissionsMapper = (bookings, tdmsRoles, providerRoles) =>
  bookings.map((booking) => ({
    ...applyBookingPermissions(booking, tdmsRoles, providerRoles),
  }))

export const servicePermissionsMapper = (services, tdmsRoles, providerRoles) =>
  services.map((pkg) => ({
    ...applyPackagePermissions(pkg, tdmsRoles, providerRoles),
  }))

export const packagePermissionsMapper = (packages, tdmsRoles, providerRoles) =>
  packages.map((pkg) => ({
    ...applyPackagePermissions(pkg, tdmsRoles, providerRoles),
  }))

export const providersPermissinoMapper = (
  providers,
  tdmsRoles,
  providerRoles,
) =>
  providers.map((provider) => ({
    ...applyProviderPermissions(provider, tdmsRoles, providerRoles),
  }))
