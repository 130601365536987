export const getPremissionFreeMembers = (members) =>
  members.filter((member) => !member.permission)

export const getMembersWithPermissions = (members) =>
  members.filter((member) => member.permission)

export const toggleMembersState = (member, members) => {
  const newMembers = [...members]
  const currentMemberIndex = members.findIndex(
    (memberDaimlerId) => memberDaimlerId === member.daimlerId,
  )
  if (currentMemberIndex > -1) {
    newMembers.splice(currentMemberIndex, 1)
  } else {
    newMembers.push(member.daimlerId)
  }
  return newMembers
}

export const getMemberByDaimlerId = (memberDaimlerId, tenantMembers) =>
  tenantMembers.find(({ daimlerId }) => daimlerId === memberDaimlerId)

export const generateFullMembersListFromDaimlerIds = (
  tenantMembers,
  targetMemberDaimlerIds,
) =>
  tenantMembers.filter((member) =>
    targetMemberDaimlerIds.includes(member.daimlerId),
  )
