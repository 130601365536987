import React from 'react'
import PropTypes from 'prop-types'

export const BookingDetails = ({ booking }) => (
  <div className="wb-margin-bottom-xxs">
    <div className="wb-type-heading-xs wb-margin-bottom-3xs">
      {booking.packageName}
    </div>
    <div className="wb-type-hint between-dates">
      <span className="wb-type-hint-strong">Between: </span>
      {booking.startDate} - {booking.endDate ? booking.endDate : 'no end-date'}
    </div>
    <div className="wb-type-hint">{booking.serviceName}</div>
    <div className="wb-type-hint">
      <span className="wb-type-hint-strong">Price Variant: </span>
      {booking.variantName}
    </div>
    <div className="wb-type-hint wb-margin-top-xs">{booking.comment}</div>
  </div>
)

BookingDetails.propTypes = {
  booking: PropTypes.shape({
    packageName: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    serviceName: PropTypes.string,
    variantName: PropTypes.string,
    comment: PropTypes.string,
  }),
}
