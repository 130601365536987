import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  setModal,
  archiveService,
  updateService,
} from '../../../../../../../data/actions'
import { CurrentServiceComponent } from '../component'

const CurrentServiceContainer = ({
  service,
  dispatchModal,
  updateCurrentService,
  moveToArchive,
}) => {
  const onEditService = () =>
    dispatchModal({
      variant: 'service',
      mode: 'edit',
      onCancel: () => dispatchModal({}),
      onApprove: updateCurrentService,
    })
  const onMoveToArchive = (targetService) =>
    dispatchModal({
      variant: 'prompt',
      okText: 'Archive',
      onApprove: () => {
        moveToArchive(targetService, () => dispatchModal({}))
      },
      header: `This operration cannot be undone! Are you sure you want to archive the current service (${targetService.name}) ?`,
      onCancel: () => {
        dispatchModal({})
      },
    })
  return (
    <CurrentServiceComponent
      service={service}
      onEditService={onEditService}
      onMoveToArchive={onMoveToArchive}
    />
  )
}

CurrentServiceContainer.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    provider: PropTypes.string,
    slug: PropTypes.string,
    id: PropTypes.number,
  }),
  dispatchModal: PropTypes.func,
  updateCurrentService: PropTypes.func,
  moveToArchive: PropTypes.func,
}

export const CurrentService = connect(
  ({ backOffice: { service } }) => ({ service }),
  {
    dispatchModal: setModal,
    moveToArchive: archiveService,
    updateCurrentService: updateService,
  },
)(CurrentServiceContainer)
