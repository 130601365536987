import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { BookingItem } from './BookingItem'

const generateExpiredBookings = (bookings) => {
  const curDate = moment(new Date())
  return bookings.map((booking) => ({
    ...booking,
    expired: Boolean(
      booking.endDate &&
        moment(booking.endDate).clone().endOf('day').isBefore(curDate),
    ),
  }))
}

export const bookingsSorter = (bookingA, bookingB) =>
  bookingA.expired - bookingB.expired

export const BookingItems = ({ bookings, onDeleteBooking, onEditBooking }) => {
  const bookingsWithExpiredFlag = generateExpiredBookings(
    bookings.filter((booking) => booking.isEditable),
  ).sort(bookingsSorter)
  return (
    <ul className="bookings-list">
      {bookingsWithExpiredFlag.map((booking) => (
        <BookingItem
          key={booking.id}
          booking={booking}
          onDeleteBooking={() => onDeleteBooking(booking)}
          onEditBooking={() => onEditBooking(booking)}
        />
      ))}
    </ul>
  )
}

const bookingProp = PropTypes.shape({
  packageName: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  serviceName: PropTypes.string,
  variantName: PropTypes.string,
  comment: PropTypes.string,
})

BookingItems.propTypes = {
  bookings: PropTypes.arrayOf(bookingProp),
  onDeleteBooking: PropTypes.func,
  onEditBooking: PropTypes.func,
}
