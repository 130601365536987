import React from 'react'
import PropTypes from 'prop-types'
import { ProvidersTable } from '../components/ProvidersTable'
import { ListHeaderComponent } from '../components/ListHeader'

export const ProvidersListComponent = ({
  providers,
  userIsTdmsAdmin,
  onAddProvider,
  onClickProvider,
  onClickEditProvider,
  onClcikRemoveProvider,
}) => (
  <div className="wb-margin-bottom-xs providers-list">
    <ListHeaderComponent
      onAddProvider={onAddProvider}
      userIsTdmsAdmin={userIsTdmsAdmin}
    />
    <ProvidersTable
      providers={providers}
      onClickProvider={onClickProvider}
      onClickEditProvider={onClickEditProvider}
      onClcikRemoveProvider={onClcikRemoveProvider}
    />
  </div>
)

ProvidersListComponent.propTypes = {
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  userIsTdmsAdmin: PropTypes.bool,
  onAddProvider: PropTypes.func,
  onClickProvider: PropTypes.func,
  onClickEditProvider: PropTypes.func,
  onClcikRemoveProvider: PropTypes.func,
}
