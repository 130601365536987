import { tdmsClient } from '../apiClient'

export const fetchPlatformPackages = async () =>
  tdmsClient.client.platformBookingPackagesMethods.list()

export const postPlatformPackage = async (packageData) =>
  tdmsClient.client.platformBookingPackagesMethods.create(packageData)

export const putPlatformPackage = async (packageData, packageId) =>
  tdmsClient.client.platformBookingPackagesMethods.update(
    packageId,
    packageData,
  )

export const archivePlatformServicePackages = async (servicePackageId) =>
  tdmsClient.client.platformBookingPackagesMethods.archive(servicePackageId)

export const fetchPackagePriceVariants = async (servicePackageSlug) =>
  tdmsClient.client.platformBookingPackagesMethods.listPriceVariants(
    servicePackageSlug,
  )

export const createPackagePriceVariants = async (servicePackageSlug, pvData) =>
  tdmsClient.client.platformBookingPackagesMethods.createPriceVariant(
    servicePackageSlug,
    pvData,
  )

export const updatePackagePriceVariants = async (
  servicePackageSlug,
  priceVariantSlug,
  pvData,
) =>
  tdmsClient.client.platformBookingPackagesMethods.updatePriceVariant(
    servicePackageSlug,
    priceVariantSlug,
    pvData,
  )

export const deletePackagePriceVariants = async (
  servicePackageSlug,
  priceVariantSlug,
) =>
  tdmsClient.client.platformBookingPackagesMethods.deletePriceVariant(
    servicePackageSlug,
    priceVariantSlug,
  )

export const postPlatformPackageService = async (packageId, serviceId) =>
  tdmsClient.client.platformBookingPackagesMethods.attachServiceToPackage(
    packageId,
    serviceId,
  )

export const deletePlatformPackageService = async (packageId, serviceId) =>
  tdmsClient.client.platformBookingPackagesMethods.detachServiceFromPackage(
    packageId,
    serviceId,
  )

export const fetchPlatformPackageServices = async (packageId) =>
  tdmsClient.client.platformBookingPackagesMethods.listServices(packageId)

export const postServicePackagePrice = async ({
  body,
  servicePackageSlug,
  priceVariantSlug,
}) =>
  tdmsClient.client.platformBookingPackagesMethods.createPvPrice(
    servicePackageSlug,
    priceVariantSlug,
    body,
  )

export const batchUpdateServicePackagePrices = async (
  servicePackageSlug,
  priceVariantSlug,
  pvPrices,
) =>
  tdmsClient.client.platformBookingPackagesMethods.batchUpdatePvPrices(
    servicePackageSlug,
    priceVariantSlug,
    pvPrices,
  )

export const putServicePackagePrice = async ({
  body,
  servicePackageSlug,
  priceVariantSlug,
  priceId,
}) =>
  tdmsClient.client.platformBookingPackagesMethods.updatePvPrice(
    servicePackageSlug,
    priceVariantSlug,
    priceId,
    body,
  )

export const fetchPrices = async (servicePackageSlug, priceVariantSlug) =>
  tdmsClient.client.platformBookingPackagesMethods.listPvPrices(
    servicePackageSlug,
    priceVariantSlug,
  )
