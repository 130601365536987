import React from 'react'
import PropTypes from 'prop-types'
import { ProcessingIndicator } from '../../../../ProcessingIndicator'

export const MemberDetails = ({ member, loading }) => (
  <div
    className="wb-margin-bottom-xxs"
    style={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}
  >
    <div>
      <div className="wb-type-copy-tertiary">
        {member.firstName} {member.lastName}
      </div>
      <div className="wb-type-hint">{member.email}</div>
      <div className="wb-type-hint">{member.daimlerId}</div>
    </div>
    <div className="wb-margin-start-xxs">
      {loading ? <ProcessingIndicator /> : null}
    </div>
  </div>
)

MemberDetails.propTypes = {
  member: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    daimlerId: PropTypes.string,
  }),
  loading: PropTypes.bool,
}
