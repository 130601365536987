import { SET_APIKEYS } from '../../actionsTypes'

const initialState = {
  keys: [],
}

export const apikeyReducer = (state = initialState, action) => {
  if (action.type === SET_APIKEYS) {
    return {
      ...state,
      keys: [...action.payload],
    }
  } else {
    return state
  }
}
