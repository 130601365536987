import React from 'react'
import PropTypes from 'prop-types'

export const TooltipHost = ({ position, message, children }) => (
  <wb-tooltip-host position={position}>
    {children}
    <wb-tooltip>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>{message}</div>
    </wb-tooltip>
  </wb-tooltip-host>
)

TooltipHost.propTypes = {
  position: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
