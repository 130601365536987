import React from 'react'
import PropTypes from 'prop-types'
import { ErrorControl } from '../ErrorControl'
import { Hint } from '../Hint'

export const Input = ({
  type,
  value,
  label,
  disabled,
  style,
  error,
  hint,
  onChange,
  button,
  ...rest
}) => (
  <wb-input-control style={{ width: '100%', marginBottom: '10px' }}>
    <span>
      <wb-input>
        <label>{label}</label>
        <input
          type={type}
          value={value}
          onChange={onChange}
          disabled={disabled}
          style={style}
          {...rest}
        />
        {button && (
          <wb-input-action>
            <button
              className="wb-round-button wb-round-button--small"
              type="button"
              onClick={() => navigator.clipboard.writeText(value)}
            >
              <wb-icon class="wb-round-button__icon" name="bds/link/24" />
            </button>
          </wb-input-action>
        )}
      </wb-input>
      <ErrorControl error={error} />
      <Hint text={hint} />
    </span>
  </wb-input-control>
)
Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  style: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  onChange: PropTypes.func,
  button: PropTypes.string,
}
