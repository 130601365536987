import React from 'react'
import PropTypes from 'prop-types'
import { ServiceItem } from './ServiceItem'

export const PackageServicesFormComponent = ({
  selectedPackage,
  services,
  handleSubmitService,
}) => (
  <>
    <h2>Package Services</h2>
    <ul className="packages-editor__list package-services__list">
      {services
        .filter(
          (service) => service.providerSlug === selectedPackage.providerSlug,
        )
        .map((service) => (
          <ServiceItem
            key={service.id}
            readOnly={!selectedPackage.isEditable}
            service={service}
            handleSubmit={handleSubmitService}
          />
        ))}
    </ul>
  </>
)

export const platformServiceProp = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  provider: PropTypes.string,
  serviceKey: PropTypes.string,
  slug: PropTypes.string,
  isEditable: PropTypes.bool,
})

PackageServicesFormComponent.propTypes = {
  selectedPackage: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
    isEditable: PropTypes.bool,
    providerSlug: PropTypes.string,
  }),
  services: PropTypes.arrayOf(platformServiceProp),
  handleSubmitService: PropTypes.func,
}
