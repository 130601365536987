import React from 'react'
import PropTypes from 'prop-types'

export const ProcessingIndicator = ({ withText }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}
    className="wb-margin-start-xxs"
  >
    <wb-spinner
      theme="dark"
      style={{ width: '16px', height: '16px' }}
    ></wb-spinner>
    <div className="wb-margin-start-xxs text">
      {withText ? 'Processing...' : ''}
    </div>
  </div>
)

ProcessingIndicator.propTypes = {
  withText: PropTypes.bool,
}
