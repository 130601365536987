import React from 'react'
import PropTypes from 'prop-types'
import {
  UsersAsyncMultiSelect,
  ToggleSwitch,
  ModalContent,
  ModalFooter,
  Button,
} from '../../../../../../../../common'

export const UserFormComponent = ({
  header,
  users,
  provider,
  addAsAdmin,
  actions: { setAddAsAdmin, onCancel, onSubmit, filterOutputFn, setUsers },
}) => (
  <ModalContent headline={header}>
    <div className="wb-margin-bottom-xs">
      <UsersAsyncMultiSelect
        selectedUsers={users}
        onUserSelect={setUsers}
        filterOutputFn={filterOutputFn}
      />
    </div>
    {provider.isEditable ? (
      <div className="wb-margin-bottom-xs">
        <ToggleSwitch
          label="Add users as admin"
          checked={addAsAdmin}
          onChange={() => setAddAsAdmin(!addAsAdmin)}
        />
      </div>
    ) : null}

    <ModalFooter>
      <Button variant="secondary" size="medium" onClick={onCancel}>
        Close
      </Button>
      <Button
        variant="primary"
        size="medium"
        onClick={onSubmit}
        disabled={!users.length}
      >
        Save
      </Button>
    </ModalFooter>
  </ModalContent>
)

UserFormComponent.propTypes = {
  header: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  provider: PropTypes.shape({
    cloudProvider: PropTypes.bool,
    createDate: PropTypes.string,
    deletedAt: PropTypes.string,
    deletedBy: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    isEditable: PropTypes.bool,
    isManagable: PropTypes.bool,
    isRemovable: PropTypes.bool,
    name: PropTypes.string,
    slug: PropTypes.string,
    updateDate: PropTypes.string,
  }),
  addAsAdmin: PropTypes.bool,
  actions: PropTypes.shape({
    setAddAsAdmin: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    filterOutputFn: PropTypes.func,
    setUsers: PropTypes.func,
  }),
}
