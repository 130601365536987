import React from 'react'
import PropTypes from 'prop-types'
import { TenantMemberActions } from '../TenantMemberActions'
import { Modal, ModalContent, ModalFooter, Button } from '../../../../common'

export const TenantLogsDialog = ({ tenant, tenants, onClose }) => (
  <Modal mode="edit" onCloseModal={onClose} className="tenant-dialog">
    <ModalContent headline="Tenant logs" class="tenant-members-dialog__content">
      <TenantMemberActions tenant={tenant} tenants={tenants} />
    </ModalContent>
    <ModalFooter class="tenant-members-dialog__footer">
      <div></div>
      <Button variant="secondary" size="large" onClick={() => onClose()}>
        Close
      </Button>
    </ModalFooter>
  </Modal>
)

TenantLogsDialog.propTypes = {
  tenant: PropTypes.object,
  tenants: PropTypes.array,
  onClose: PropTypes.func,
}
