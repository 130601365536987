import React from 'react'
import PropTypes from 'prop-types'
import { TableRow } from './components/TableRow'

export const ProvidersTable = ({
  providers,
  onClickProvider,
  onClickEditProvider,
  onClcikRemoveProvider,
}) => (
  <table className="wb-table wb-table--border-horizontal providers-table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Slug</th>
        <th>Description</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {providers.map((provider) => (
        <TableRow
          key={provider.id}
          provider={provider}
          actions={{
            onClickProvider,
            onClickEditProvider,
            onClcikRemoveProvider,
          }}
        />
      ))}
    </tbody>
  </table>
)

ProvidersTable.propTypes = {
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  onClickProvider: PropTypes.func,
  onClickEditProvider: PropTypes.func,
  onClcikRemoveProvider: PropTypes.func,
}
