import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ReactSelect } from '../../../../../../../common'
import { TaggedRolesList } from './TaggedRolesList'

export const RolesSelector = ({
  rowOptions = [],
  disabled,
  editMode,
  onRolesInputChange,
  roles,
}) => {
  const [value, setValue] = useState(rowOptions)
  return (
    <div className="tenant-members__roles-form">
      {editMode ? (
        <ReactSelect
          isMulti
          disabled={disabled}
          options={roles}
          closeMenuOnSelect={false}
          value={value}
          onChange={setValue}
          onBlur={() => onRolesInputChange(value)}
          onMenuClose={() => onRolesInputChange(value)}
        />
      ) : (
        <TaggedRolesList tags={rowOptions} rolesToDisplay={2} />
      )}
    </div>
  )
}

RolesSelector.propTypes = {
  rowOptions: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })),
  disabled: PropTypes.bool,
  editMode: PropTypes.bool,
  roles: PropTypes.array,
  onRolesInputChange: PropTypes.func,
}
