import { SET_ROLES, SET_ALL_USERS } from '../../actionsTypes'

const initialState = {
  roles: [],
  users: [],
}

export const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROLES:
      return { ...state, roles: action.payload.roles }
    case SET_ALL_USERS:
      return { ...state, users: action.payload.users }
    default:
      return state
  }
}
