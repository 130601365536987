import { getMemberByRoles } from '../../../../util'

export const ParseTenantInfo = (tenant) => {
  const memberPM = getMemberByRoles(tenant.members, ['PM'])
  const memberPO = getMemberByRoles(tenant.members, ['PO'])

  const extractInfo = (item) =>
    item ? `${item.firstName}, ${item.lastName}, ${item.email}` : '-'

  return {
    acronym: tenant.acronym,
    po: memberPO.length ? extractInfo(memberPO[0]) : '-',
    pm: memberPM.length ? extractInfo(memberPM[0]) : '-',
  }
}
