import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { acronymFromPath, scrollTop, goToPage } from '../../util'
import { changeNavMenu } from '../../data/actions'
import { DocsComponent } from './component'
import { generateStateFromDocContent, generateStateFromNavItems } from './utils'

const DocsContainer = ({
  match,
  sectionHash = 'sla',
  sectionFolder = 'sla',
  navigate,
}) => {
  const [state, setState] = useState({
    navItems: [],
    pages: {},
    isloading: false,
    error: false,
    docContentStatus: [],
  })
  useEffect(() => {
    const setNavItems = async () => {
      const newState = await generateStateFromNavItems(sectionFolder, state)
      setState(newState)
    }
    setNavItems()
    navigate(sectionHash)
  }, [])

  const { navItems, pages, error, isloading } = state

  const handleClickPage = (e) => {
    const link = e.target
    if (link.hasAttribute('href')) {
      if (link.getAttribute('href')[0] === '/') {
        e.preventDefault()
        goToPage(link.getAttribute('href'))
        scrollTop()
      }
    }
  }

  const slug = acronymFromPath(match.params.slug) || navItems?.[1]?.id || ''
  const pageInfo = navItems.find((x) => x.id === slug)
  useEffect(() => {
    if (slug && !pages[slug] && !isloading && navItems.length) {
      const setPageContent = async () => {
        const newState = await generateStateFromDocContent(pageInfo, state)
        setState(newState)
      }
      setPageContent()
    }
  }, [slug, navItems])

  return (
    <DocsComponent
      sectionHash={sectionHash}
      sectionFolder={sectionFolder}
      slug={slug}
      navItems={navItems}
      error={error}
      isloading={isloading}
      pages={pages}
      pageInfo={pageInfo}
      handleClickPage={handleClickPage}
    />
  )
}

DocsContainer.propTypes = {
  match: PropTypes.shape({ params: PropTypes.object }),
  sectionHash: PropTypes.string,
  sectionFolder: PropTypes.string,
  navigate: PropTypes.func,
}

export default connect(
  ({ global: { navMenuSelected } }) => ({
    navMenuSelected,
  }),
  {
    navigate: changeNavMenu,
  },
)(DocsContainer)
