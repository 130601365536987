import React from 'react'
import PropTypes from 'prop-types'
import { Button, ReactSelect } from '../../../../common'
import { BookingItems } from '../components/BookingItems'

export const BookingsComponent = ({
  tenants,
  selectedTenant,
  addBookingDisabled,
  bookings,
  actions: { setSelectedTenant, onEditBooking, onDeleteBooking, onAddBooking },
}) => (
  <div className="backoffice-bookings wb-margin-vertical-xs wb-type-copy">
    <h2>Tenant bookings</h2>
    <hr />
    <div className="wb-margin-top-xs backoffice-bookings__header">
      <div className="backoffice-bookings__header-left">
        <ReactSelect
          name="Choose tenant"
          placeholder="Choose Tenant"
          options={tenants}
          value={selectedTenant}
          closeMenuOnSelect={true}
          onChange={setSelectedTenant}
        />
      </div>
      <div className="backoffice-bookings__header-right">
        <Button
          variant="primary"
          size="medium"
          className="wb-margin-start-xs"
          onClick={onAddBooking}
          disabled={addBookingDisabled}
          withIcon="plus"
        >
          Add a booking to the tenant
        </Button>
      </div>
    </div>

    <div className="wb-margin-top-xs">
      <BookingItems
        bookings={bookings}
        onEditBooking={onEditBooking}
        onDeleteBooking={onDeleteBooking}
      />
    </div>
  </div>
)

const bookingPropType = PropTypes.shape({
  id: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  comment: PropTypes.string,
  tenantName: PropTypes.string,
  tenantAcronym: PropTypes.string,
  packageName: PropTypes.string,
  packageDisplayName: PropTypes.string,
  packageAllowBookingPermissions: PropTypes.bool,
  packageSlug: PropTypes.string,
  provider: PropTypes.string,
  variantName: PropTypes.string,
  variantSlug: PropTypes.string,
})

const optionProp = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
})

BookingsComponent.propTypes = {
  tenants: PropTypes.arrayOf(optionProp),
  selectedTenant: optionProp,
  addBookingDisabled: PropTypes.bool,
  bookings: PropTypes.arrayOf(bookingPropType),
  actions: PropTypes.shape({
    setSelectedTenant: PropTypes.func,
    onEditBooking: PropTypes.func,
    onDeleteBooking: PropTypes.func,
    onAddBooking: PropTypes.func,
  }),
}
