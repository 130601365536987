import {
  fetchPrices,
  postServicePackagePrice,
  fetchPackagePriceVariants,
  createPackagePriceVariants,
  updatePackagePriceVariants,
  deletePackagePriceVariants,
  batchUpdateServicePackagePrices,
} from '../../../../../http'
import {
  setPkgPriceVariants,
  addPkgPriceVariant,
  updatePkgPriceVariant,
  removePkgPriceVariant,
  updatePackagePrice,
  setModal,
  setPackagePrices,
} from '../shared'
import { postNotification } from '../../..'

export const getPackagePriceVariants = () => async (dispatch, getState) => {
  try {
    const activePackageSlug = getState().backOffice.package.slug
    const priceVariants = await fetchPackagePriceVariants(activePackageSlug)
    dispatch(setPkgPriceVariants(activePackageSlug, priceVariants))
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while retrieving package priceVariants!`,
        mode: 'error',
      }),
    )
  }
}

export const addPackagePriceVariant =
  (priceVariant) => async (dispatch, getState) => {
    try {
      const activePackageSlug = getState().backOffice.package.slug
      const response = await createPackagePriceVariants(
        activePackageSlug,
        priceVariant,
      )
      if (response.errorMessages) {
        throw new Error(response.errorMessages[0].message)
      }
      if (!response.prices) {
        response.prices = []
      }
      dispatch(addPkgPriceVariant(activePackageSlug, response))
      dispatch(
        postNotification({
          message: 'New Package PriceVariant was added!',
        }),
      )
      dispatch(setModal({}))
    } catch (err) {
      dispatch(
        postNotification({
          message: err.message,
          mode: 'error',
        }),
      )
    }
  }

export const modifyPackagePriceVariant =
  (priceVariant) => async (dispatch, getState) => {
    try {
      const activePackageSlug = getState().backOffice.package.slug
      const response = await updatePackagePriceVariants(
        activePackageSlug,
        priceVariant.slug,
        priceVariant,
      )

      if (response.errorMessages) {
        throw new Error(response.errorMessages[0].message)
      }

      dispatch(updatePkgPriceVariant(activePackageSlug, response))
      dispatch(
        postNotification({
          message: 'Package PriceVariant was updated!',
        }),
      )
      dispatch(setModal({}))
    } catch (err) {
      dispatch(
        postNotification({
          message: err.message,
          mode: 'error',
        }),
      )
    }
  }

export const removePackagePriceVariant =
  (priceVariantSlug) => async (dispatch, getState) => {
    try {
      const activePackageSlug = getState().backOffice.package.slug
      const res = await deletePackagePriceVariants(
        activePackageSlug,
        priceVariantSlug,
      )
      if (res.errorMessages) {
        throw new Error(res.errorMessages[0].message)
      }
      dispatch(removePkgPriceVariant(activePackageSlug, priceVariantSlug))
      dispatch(
        postNotification({
          message: 'Package PriceVariant was removed!',
        }),
      )
      dispatch(setModal({}))
    } catch (err) {
      dispatch(
        postNotification({
          message: err.message,
          mode: 'error',
        }),
      )
    }
  }

export const updateServicePackagePrices =
  (priceVariantSlug, prices) => async (dispatch, getState) => {
    try {
      const state = getState().backOffice
      const activePackageSlug = state.package.slug
      const res = await batchUpdateServicePackagePrices(
        activePackageSlug,
        priceVariantSlug,
        prices,
      )
      if (res.errorMessages) {
        throw new Error(res.errorMessages[0].message)
      }
      const priceVariants = JSON.parse(
        JSON.stringify(state.priceVariants[activePackageSlug]),
      )
      const targetPv = priceVariants.find((pv) => pv.slug === priceVariantSlug)
      targetPv.prices = res
      dispatch(setPkgPriceVariants(activePackageSlug, priceVariants))
      dispatch(
        postNotification({
          message: 'Package PriceVariant prices were updated successfully!',
        }),
      )
      dispatch(setModal({}))
    } catch (err) {
      dispatch(
        postNotification({
          message: err.message,
          mode: 'error',
        }),
      )
    }
  }

export const getPackagePrices = (packageId) => async (dispatch) => {
  try {
    const prices = await fetchPrices(packageId)
    dispatch(setPackagePrices(prices))
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while retrieving packagePrices - ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const createPackagePrice =
  (price, priceVariant) => async (dispatch, getState) => {
    const body = JSON.stringify(price)
    const packageSlug = getState().backOffice.package.slug
    const priceId = price.id
    try {
      const returnedPrice = await postServicePackagePrice(
        body,
        packageSlug,
        priceVariant.slug,
      )

      if (!returnedPrice.id) {
        throw new Error(returnedPrice.message)
      }
      if (!priceId) {
        dispatch(addPackagePrice(returnedPrice))
      } else {
        dispatch(updatePackagePrice(returnedPrice))
      }
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while changing package price - ${err.message}!`,
          mode: 'error',
        }),
      )
    }
  }
