import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { dismissNotification } from '../../../data/actions'

const NotificationsContainer = ({ notifications, clearNotification }) => {
  const notificationsRef = useRef(null)
  useEffect(() => {
    if (notificationsRef.current?.children) {
      for (const notification of notificationsRef.current.children) {
        notification.addEventListener('wbexpire', () => {
          clearNotification(notification.getAttribute('data-id'))
        })
        notification.addEventListener('wbdismiss', () =>
          clearNotification(notification.getAttribute('data-id')),
        )
      }
    }
    return () => {
      if (notificationsRef.current?.children) {
        for (const notification of notificationsRef.current.children) {
          notification.removeEventListener('wbexpire', () =>
            clearNotification(notification.getAttribute('data-id')),
          )
          notification.addEventListener('wbdismiss', () =>
            clearNotification(notification.getAttribute('data-id')),
          )
        }
      }
    }
  }, [notifications])

  const onClickNotification = (notification) => {
    notificationsRef.current?.children[
      notificationsRef.current.children.length - 1
    ].remove()
    clearNotification(notification.id)
  }

  return (
    <wb-notification-host position="bottom-end">
      <div ref={notificationsRef}>
        {notifications.map((notification) => {
          return (
            <wb-notification
              lifetime="5000"
              mode={notification.mode || 'success'}
              key={notification.id}
              class="wb-margin-bottom-s"
              data-id={notification.id}
              onClick={() => onClickNotification(notification)}
            >
              <wb-notification-content>
                {notification.message}
              </wb-notification-content>
            </wb-notification>
          )
        })}
      </div>
    </wb-notification-host>
  )
}

NotificationsContainer.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
      mode: PropTypes.string,
    }),
  ),
  clearNotification: PropTypes.func,
}

export const Notifications = connect(
  ({ ui: { notifications } }) => ({ notifications }),
  {
    clearNotification: dismissNotification,
  },
)(NotificationsContainer)
