import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../../../common'
import { ServicesListItem } from './ServiceListItem'

export const ServicesListComponent = ({
  services,
  selectedService,
  setService,
  onAddService,
}) => (
  <div className="wb-type-copy wb-padding-top-xs">
    <h2>Services List</h2>
    <hr />
    <Button
      variant="secondary"
      size="small"
      onClick={onAddService}
      className="packages-editor__button"
    >
      Add
    </Button>
    <ul className="packages-editor__list">
      {services.map((service) => (
        <ServicesListItem
          key={service.id}
          service={service}
          isActive={service.id === selectedService?.id}
          onClick={() => setService(service)}
        />
      ))}
    </ul>
  </div>
)

const serviceProp = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  provider: PropTypes.string,
  description: PropTypes.string,
})

ServicesListComponent.propTypes = {
  services: PropTypes.arrayOf(serviceProp),
  selectedService: serviceProp,
  setService: PropTypes.func,
  onAddService: PropTypes.func,
}
