import React from 'react'
import PropTypes from 'prop-types'

export const DatepickerControl = ({ className = '', children }) => (
  <wb-datepicker-control class={className}>{children}</wb-datepicker-control>
)

DatepickerControl.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
