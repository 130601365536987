import React from 'react'
import PropTypes from 'prop-types'
import { CheckBox, ErrorControl } from '../../../../../../../../../../../common'
import { MemberDetails } from '../MemberDetails'

export const MemberItem = ({
  member,
  checked,
  editMode,
  disabled,
  loggedInUserDaimlerId,
  onSelectMember,
}) =>
  editMode ? (
    <MemberDetails member={member} loading={disabled} />
  ) : (
    <div className="wb-padding-bottom-xs">
      <CheckBox
        checked={checked}
        label={
          <>
            <MemberDetails member={member} loading={disabled} />
            {loggedInUserDaimlerId === member.daimlerId ? (
              <ErrorControl error="You cannot assign permission to yourself. Please contact other PO or PM" />
            ) : null}
          </>
        }
        disabled={disabled || loggedInUserDaimlerId === member.daimlerId}
        onChange={() => onSelectMember(member)}
      />
    </div>
  )

MemberItem.propTypes = {
  member: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    daimlerId: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  }),
  checked: PropTypes.bool,
  editMode: PropTypes.bool,
  disabled: PropTypes.bool,
  loggedInUserDaimlerId: PropTypes.string,
  onSelectMember: PropTypes.func,
}
