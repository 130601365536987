import {
  SET_TENANT_BOOKINGS,
  SET_CURRENT_BOOKING_MEMBERS,
  SET_CURRENT_BOOKING_SERVICES,
  SET_CURRENT_BOOKING_SERVICE_PROPERTIES,
  SET_CURRENT_BOOKING_SERVICE_PROPERTY,
  REMOVE_BOOKING_SERVICE_PROPERTY,
  UPDATE_BOOKING_SERVICE_PROPERTY,
  SET_CURRENT_BOOKING_PERMISSIONS,
  SET_CURRENT_BOOKING_SERVICE_PERMISSIONS,
  DELETE_BOOKING_SERVICE_PERMISSION,
  ADD_WAITING_PERMISSIONS,
  REMOVE_WAITING_PERMISSIONS,
} from '../../actionsTypes'

const initialState = {
  currentBookings: [],
  currentServicePermissions: {},
  tenantMembers: [],
  services: {},
  bookingServicePermissions: {},
  properties: {},
  property: null,
  waitingPermissions: {},
}

export const tenantBookingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TENANT_BOOKINGS:
      return {
        ...state,
        currentBookings: action.payload,
      }
    case SET_CURRENT_BOOKING_MEMBERS:
      return {
        ...state,
        tenantMembers: action.payload,
      }
    case SET_CURRENT_BOOKING_SERVICES: {
      const { bookingSlug, services } = action.payload
      return {
        ...state,
        services: {
          ...state.services,
          [bookingSlug]: services,
        },
      }
    }
    case SET_CURRENT_BOOKING_SERVICE_PROPERTIES: {
      const { bookingPackageSlug, serviceNameSlug, properties } = action.payload
      return {
        ...state,
        properties: {
          ...state.properties,
          [bookingPackageSlug]: {
            ...state.properties[bookingPackageSlug],
            [serviceNameSlug]: properties,
          },
        },
      }
    }
    case SET_CURRENT_BOOKING_SERVICE_PROPERTY:
      return {
        ...state,
        property: action.payload,
      }
    case REMOVE_BOOKING_SERVICE_PROPERTY:
      return {
        ...state,
        properties: {
          ...state.properties,
          [action.payload.bookingSlug]: {
            ...state.properties[action.payload.bookingSlug],
            [action.payload.serviceSlug]: [
              ...state.properties[action.payload.bookingSlug][
                action.payload.serviceSlug
              ].filter(
                (prop) =>
                  prop.platformServicePropertyId !== action.payload.propertyId,
              ),
            ],
          },
        },
      }
    case UPDATE_BOOKING_SERVICE_PROPERTY: {
      const bookingProperties = [
        ...state.properties[action.payload.bookingSlug][
          action.payload.serviceSlug
        ],
      ]
      const targetIndex = bookingProperties.findIndex(
        (prop) =>
          prop.platformServicePropertyId ===
          action.payload.property.platformServicePropertyId,
      )
      bookingProperties[targetIndex] = {
        ...bookingProperties[targetIndex],
        ...action.payload.property,
      }
      return {
        ...state,
        properties: {
          ...state.properties,
          [action.payload.bookingSlug]: {
            ...state.properties[action.payload.bookingSlug],
            [action.payload.serviceSlug]: bookingProperties,
          },
        },
      }
    }
    case SET_CURRENT_BOOKING_PERMISSIONS: {
      const { bookingId, permissions } = action.payload
      return {
        ...state,
        bookingPermisions: {
          ...state.bookingPermisions,
          [bookingId]: permissions,
        },
      }
    }
    case SET_CURRENT_BOOKING_SERVICE_PERMISSIONS: {
      const {
        bookingName,
        serviceSlug,
        bookingServicePermissions,
        servicePermissions,
        tenantMembers,
      } = action.payload
      return {
        ...state,
        bookingServicePermissions: {
          ...state.bookingServicePermissions,
          [bookingName]: {
            ...state.bookingServicePermissions[bookingName],
            [serviceSlug]: { servicePermissions, bookingServicePermissions },
          },
        },
        tenantMembers,
      }
    }
    case DELETE_BOOKING_SERVICE_PERMISSION: {
      const { booking, service, permissionId } = action.payload
      return {
        ...state,
        bookingServicePermissions: {
          ...state.bookingServicePermissions,
          [booking]: {
            ...state.bookingServicePermissions[booking],
            [service]: {
              ...state.bookingServicePermissions[booking][service],
              bookingServicePermissions: [
                ...state.bookingServicePermissions[booking][service]
                  .bookingServicePermissions,
              ].filter((perm) => perm.id !== permissionId),
            },
          },
        },
      }
    }
    case ADD_WAITING_PERMISSIONS: {
      const { bookingId, serviceSlug, daimlerIds } = action.payload
      const waitingPermissions = { ...state.waitingPermissions }
      if (!waitingPermissions[bookingId]) {
        waitingPermissions[bookingId] = {}
      }
      if (!waitingPermissions[bookingId][serviceSlug]) {
        waitingPermissions[bookingId][serviceSlug] = []
      }
      const newIds = [...waitingPermissions[bookingId][serviceSlug]]
      newIds.push(...daimlerIds)
      waitingPermissions[bookingId][serviceSlug] = newIds
      return {
        ...state,
        waitingPermissions,
      }
    }
    case REMOVE_WAITING_PERMISSIONS: {
      const { bookingId, serviceSlug, daimlerIds } = action.payload
      const newPerms = [
        ...state.waitingPermissions[bookingId][serviceSlug],
      ].filter((perm) => !daimlerIds.includes(perm))
      const wPerms = {
        ...state.waitingPermissions,
        [bookingId]: {
          ...state.waitingPermissions[bookingId],
          [serviceSlug]: newPerms,
        },
      }
      return {
        ...state,
        waitingPermissions: wPerms,
      }
    }

    default:
      return state
  }
}
