import React from 'react'
import PropTypes from 'prop-types'

export const ServicesReportByMonthContent = ({
  title,
  packages = [],
  sign,
}) => (
  <div>
    {title && <h3>{title}</h3>}
    {packages.map((items, index) => (
      <p key={`${title}_${sign}_${index}`}>
        {items.packages.map((pkg, i) => (
          <span key={`${title}_${sign}_${index}_${i}`}>
            <b>{items.acronym}</b>
            {` ${sign} ${pkg.package}${pkg.comment ? ' - ' + pkg.comment : ''}`}
            <br />
          </span>
        ))}
      </p>
    ))}
  </div>
)

ServicesReportByMonthContent.propTypes = {
  title: PropTypes.string,
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      acronym: PropTypes.string,
      packages: PropTypes.arrayOf(
        PropTypes.shape({
          package: PropTypes.string,
          comment: PropTypes.string,
        }),
      ),
    }),
  ),
  sign: PropTypes.string,
}
