import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

export const IndeterminateCheckbox = ({
  indeterminate,
  className = '',
  ...rest
}) => {
  const ref = useRef(null)

  useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <wb-checkbox-control class="wb-margin-bottom-xs">
      <label style={{ height: '15px', lineHeight: '20px' }}>
        <input
          type="checkbox"
          ref={ref}
          className={className + ' cursor-pointer'}
          {...rest}
        />
      </label>
    </wb-checkbox-control>
  )
}

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool,
  className: PropTypes.string,
}
