import React from 'react'
import PropTypes from 'prop-types'

export const Popover = ({ children }) => (
  <wb-popover style={{ maxHeight: 'unset' }}>{children}</wb-popover>
)

Popover.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
