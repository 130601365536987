import React from 'react'
import PropTypes from 'prop-types'
import { List } from '../../../../../common'

export const generateLinkLists = (ghoValue) => [
  {
    title: `GitHub Organization: ${ghoValue}`,
    url: `https://git.i.mercedes-benz.com/${ghoValue}`,
  },
  {
    title: 'Build Pipeline',
    url: `https://jenkins-build.cicd.intra.oneweb.mercedes-benz.io/job/build-${ghoValue}`,
  },
  {
    title: 'Non-PROD Deploy Pipelines',
    url: `https://jenkins-build.cicd.intra.oneweb.mercedes-benz.io/job/deploy-${ghoValue}`,
  },
  {
    title: 'PROD Deploy Pipelines',
    url: `https://jenkins.cicd.intra.oneweb.mercedes-benz.io/job/deploy-${ghoValue}`,
  },
]

const linkSorter = (a, b) => a.value.localeCompare(b.value)

export const GhTabs = ({ items }) => {
  const sortedItems = [...items].sort(linkSorter)

  return sortedItems.length > 1 ? (
    <wb-tabs selected={sortedItems[0].value}>
      <wb-tab-bar fitted>
        {sortedItems.map((item) => (
          <wb-tab name={item.value} key={item.value}>
            {item.value}
          </wb-tab>
        ))}
      </wb-tab-bar>

      {sortedItems.map(({ value }) => (
        <wb-tab-content name={value} key={value}>
          <List items={generateLinkLists(value)} />
        </wb-tab-content>
      ))}
    </wb-tabs>
  ) : (
    <List items={generateLinkLists(items[0].value)} />
  )
}

GhTabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
}
