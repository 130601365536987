import {
  CHANGE_AUTH,
  SET_USER_DATA,
  SET_PROVIDER_ROLES,
} from '../../actionsTypes'

const initialState = {
  tokens: null,
  isAuthenticated: false,
  username: '',
  roles: [],
  appRoles: {},
  userData: null,
  providerRoles: {},
}

export const ssoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA: {
      const { userData } = action.payload
      return {
        ...state,
        userData,
      }
    }
    case CHANGE_AUTH: {
      const { tokens, isAuthenticated, userData } = action.payload
      return { ...state, tokens, isAuthenticated, ...userData }
    }
    case SET_PROVIDER_ROLES:
      return {
        ...state,
        providerRoles: action.payload,
      }
    default:
      return state
  }
}
