import React from 'react'
import { Nav } from './nav'
import { Tenant } from './tenant'

const AppsContainer = () =>
  navigator.onLine ? (
    <div className="body body__sidebar">
      <Nav />
      <div className="content">
        <Tenant />
      </div>
    </div>
  ) : (
    <div>
      <h3>Apps menu is not available in offline mode.</h3>
    </div>
  )

export default AppsContainer
