import { POST_NOTIFICATION, DISMISS_NOTIFICATION } from '../../actionsTypes'
import { v4 as uuidv4 } from 'uuid'

const initialState = {
  notifications: [],
}

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          { ...action.payload, id: uuidv4() },
        ],
      }
    case DISMISS_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications].filter(
          (notification) => notification.id !== action.payload,
        ),
      }
    default:
      return state
  }
}
