import React from 'react'
import { platformPackageProp } from '../PackagesList/component'
import { PackageServicesForm } from '../Forms/PackageServiceForm'
import { PriceVariants } from '../PriceVariants'

export const PackageDetails = ({ currentPackage }) =>
  currentPackage ? (
    <div>
      <div className="package-details packages-editor__list">
        <dl>
          <dt>Package Name: </dt>
          <dd>{currentPackage.name}</dd>
          <dt>Package Description: </dt>
          <dd>{currentPackage.description}</dd>
        </dl>
      </div>
      <PriceVariants />
      <PackageServicesForm selectedPackage={currentPackage} />
    </div>
  ) : (
    <div>No package selected</div>
  )

PackageDetails.propTypes = {
  currentPackage: platformPackageProp,
}
