import { tdmsClient } from '../apiClient'

export const fetchWebHooks = async () => tdmsClient.client.webHookMethods.list()

export const fetchWebHook = async (webHookId) =>
  tdmsClient.client.webHookMethods.getById(webHookId)

export const createWebHook = async (newWebHook) =>
  tdmsClient.client.webHookMethods.create(newWebHook)

export const updateWebHook = async (newWebHook) =>
  tdmsClient.client.webHookMethods.update(newWebHook.id, newWebHook)

export const deleteWebHook = async (webHookId) =>
  tdmsClient.client.webHookMethods.delete(webHookId)

export const fetchWebHookProducers = async () =>
  tdmsClient.client.webHookMethods.producers()

export const fetchWebHookProducer = async (producerSlug) =>
  tdmsClient.client.webHookMethods.producer(producerSlug)

export const fetchWebHookEvents = async (producerSlug) =>
  tdmsClient.client.webHookMethods.events(producerSlug)

export const fetchWebHookProviders = async (producerSlug) =>
  tdmsClient.client.webHookMethods.providers(producerSlug)
