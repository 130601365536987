import React from 'react'
import './styles.scss'

const ErrorPage = () => (
  <div className="mainpage-main">
    <div className="mainpage-header">
      <div className="mainpage-header__left">
        <img src="/assets/Nebula_TDMS_logo.png" alt="Nebula Logo" />
      </div>
      <div className="mainpage-header__right">
        <button
          onClick={() => window.location.replace(process.env.LOGOUT_URL)}
          style={{ cursor: 'pointer' }}
        >
          Log out
        </button>
      </div>
    </div>
    <div className="mainpage-content">
      <div className="lock"></div>
      <div className="message" style={{ textAlign: 'center' }}>
        <h1 className="header__title">Access to TDMS is restricted</h1>
        <p className="tenant-body__block-value">
          Please check with your PO if you have proper permissions to access
          TDMS.
        </p>
        <p style={{ marginTop: '10px' }} className="tenant-body__block-value">
          If you still have doubts, please{' '}
          <a
            href="mailto:nebula@mercedes-benz.com"
            style={{ color: '#00adef' }}
          >
            contact us
          </a>
        </p>
      </div>
    </div>
  </div>
)

export default ErrorPage
