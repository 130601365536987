import React from 'react'
import PropTypes from 'prop-types'
import { Button, WbIcon } from '../../../../../../../../../common'

export const BookingServicePropertiesComponent = ({
  isEditable,
  serviceProperties,
  onClickCopy,
  onClickEdit,
}) =>
  serviceProperties.length ? (
    <table className="wb-table wb-table--striped">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Slug</th>
          <th scope="col">Type</th>
          <th scope="col">Required</th>
          <th scope="col">Used Default</th>
          <th scope="col">Value</th>
        </tr>
      </thead>
      <tbody>
        {serviceProperties.map((property) => (
          <tr key={property.slug}>
            <th scope="row">{property.name}</th>
            <td>{property.slug}</td>
            <td>{property.type}</td>
            <td>
              {property.required ? (
                <WbIcon
                  icon="bds/checkmark-circle/24"
                  className="wb-button__icon"
                  fill="red"
                />
              ) : null}
            </td>
            <td>
              {property.defaultUneditedValue ? (
                <WbIcon
                  icon="bds/checkmark-circle/24"
                  className="wb-button__icon"
                  fill="red"
                />
              ) : null}
            </td>
            <td>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>{property.value}</span>
                <div>
                  <Button
                    className="wb-round-button wb-round-button--small"
                    onClick={() => onClickCopy(property)}
                    withIcon="bds/link/24"
                    iconOnly
                  />
                  {isEditable ? (
                    <Button
                      className="wb-round-button wb-round-button--small wb-margin-start-xxs"
                      onClick={() => onClickEdit(property)}
                      withIcon="bds/pencil/24"
                      iconOnly
                    />
                  ) : null}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <div>No Properties found for selected service</div>
  )

BookingServicePropertiesComponent.propTypes = {
  isEditable: PropTypes.bool,
  serviceProperties: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      required: PropTypes.bool,
      defaultUneditedValue: PropTypes.bool,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  onClickCopy: PropTypes.func,
  onClickEdit: PropTypes.func,
}
