import React from 'react'
import PropTypes from 'prop-types'
import { ErrorControl, ReactSelect, CheckBox } from '../../../../../../common'

export const MultiSelectComponent = ({
  allSelected,
  modelName,
  error,
  multiSelectOptions,
  selectedItems,
  onClickSelectAll,
  onChange,
}) => (
  <div role="radiogroup" className="multi-select-with-select-all">
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <div>
        <ReactSelect
          isMulti
          disabled={allSelected}
          name="Select items"
          placeholder={`Choose specific ${modelName}`}
          options={multiSelectOptions}
          value={selectedItems}
          closeMenuOnSelect={false}
          onChange={(options) => onChange(options)}
        />
      </div>
      <div className="wb-margin-start-xs">
        <CheckBox
          checked={allSelected}
          onChange={onClickSelectAll}
          label={`Register for all ${modelName}`}
        />
      </div>
    </div>
    <ErrorControl error={error} />
  </div>
)

MultiSelectComponent.propTypes = {
  allSelected: PropTypes.bool,
  modelName: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  multiSelectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  selectedItems: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  ]),
  onClickSelectAll: PropTypes.func,
  onChange: PropTypes.func,
}
