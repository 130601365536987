import { tdmsClient } from '../apiClient'

export const fetchServiceProperties = async (serviceId) =>
  tdmsClient.client.platformBookingServicesMethods.listProperties(serviceId)

export const postServiceProperties = async (serviceId, propertyData) =>
  tdmsClient.client.platformBookingServicesMethods.createProperty(
    serviceId,
    propertyData,
  )

export const putServiceProperties = async (propertyId, propertyData) =>
  tdmsClient.client.platformBookingServicesMethods.updateProperty(
    propertyId,
    propertyData,
  )

export const removeServiceProperty = async (servicePropertyId) =>
  tdmsClient.client.platformBookingServicesMethods.removeProperty(
    servicePropertyId,
  )

export const fetchBookingServiceProperties = async (bookingId, serviceSlug) =>
  tdmsClient.client.platformServicePropertiesMethods.listByBookingIdAndServiceSlug(
    bookingId,
    serviceSlug,
  )

export const fetchBookingServiceProperty = async (
  platformBookingId,
  serviceSlug,
  propertySlug,
) =>
  tdmsClient.client.platformServicePropertiesMethods.getBookingProperty(
    platformBookingId,
    serviceSlug,
    propertySlug,
  )

export const fetchServiceProperty = async (propertyId) =>
  tdmsClient.client.platformServicePropertiesMethods.getProperty(propertyId)

export const putBookingServiceProperty = async (
  bookingId,
  propertyId,
  propertyData,
) =>
  tdmsClient.client.platformServicePropertiesMethods.updateProperty(
    bookingId,
    propertyId,
    propertyData,
  )
