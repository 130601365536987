import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from '../../../../../../common'

const rolesSorter = (a, b) => a.name.localeCompare(b.name)

export const TaggedMemberRoles = ({ roles }) => {
  return (
    <p className="members-content__item__row">
      <span className="members-content__item-label">Roles</span>
      {[...roles].sort(rolesSorter).map(({ name, description }) => (
        <Tag
          key={name}
          label={description}
          variant="tertiary"
          className="wb-margin-3xs"
        >
          {description}
        </Tag>
      ))}
    </p>
  )
}

TaggedMemberRoles.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
}
