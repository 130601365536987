import { tdmsClient } from '../apiClient'

export const fetchPlatformServices = async () =>
  tdmsClient.client.platformBookingServicesMethods.list()

export const postPlatFormService = async ({ body }) =>
  tdmsClient.client.platformBookingServicesMethods.create(body)

export const archivePlatformServices = async (platformServiceId) =>
  tdmsClient.client.platformBookingServicesMethods.archive(platformServiceId)

export const putPlatFormService = async ({ id, body }) =>
  tdmsClient.client.platformBookingServicesMethods.update(id, body)
