import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table'
import { TanstackTableWrapper, Button } from '../../../../../common'

export const ApiTable = ({ keys, onClickPreview, onRemove }) => {
  const tokenPreview = ({ row, getValue }) => (
    <Button
      onClick={() => onClickPreview(row.original)}
      size="small"
      variant="tertiary"
    >
      {getValue()}
    </Button>
  )
  const trashIcon = ({ row }) => (
    <Button
      iconOnly
      withIcon="bds/trash/24"
      onClick={() => onRemove(row.original)}
      size="small"
      variant="tertiary"
    />
  )
  const columns = useMemo(
    () => [
      {
        accessorKey: 'tokenPreview',
        header: 'Key Preview',
        cell: tokenPreview,
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'permission',
        header: 'Permissions',
      },
      {
        accessorKey: 'createdAt',
        header: 'Created At',
        cell: ({ getValue }) => new Date(getValue()).toLocaleString(),
      },
      {
        accessorKey: 'lastUsedAt',
        header: 'Last Used',
        cell: ({ getValue }) =>
          getValue() ? new Date(getValue()).toLocaleString() : '',
      },
      {
        header: 'Action',
        cell: trashIcon,
      },
    ],
    [],
  )

  const data = useMemo(() => keys, [keys])

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 7,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  return (
    <>
      <TanstackTableWrapper table={table} />
      {table.getPageCount() > 1 && (
        <div
          className="wb-margin-vertical-xs"
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Button
            iconOnly
            withIcon="bds/chevron-left/16"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            size="small"
            variant="tertiary"
          />
          <span
            className="wb-margin-horizontal-xs"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <span className="table-pagination__item__page-number">
              {table.getState().pagination.pageIndex + 1} /{' '}
              {table.getPageCount()}
            </span>
          </span>
          <Button
            iconOnly
            withIcon="bds/chevron-right/16"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            size="small"
            variant="tertiary"
          />
        </div>
      )}
    </>
  )
}

ApiTable.propTypes = {
  keys: PropTypes.array,
  onClickPreview: PropTypes.func,
  onRemove: PropTypes.func,
}
