import React from 'react'
import PropTypes from 'prop-types'
import { HttpWebHookForm } from '../HttpWebHookForm'
import { GithubEndpointGenerator } from '../GithubEndpointGenerator'
import { Select } from '../../../../../common'

export const SELECT_OPTIONS = [
  { id: 'http', name: 'HTTP' },
  { id: 'github', name: 'Github Action' },
]

export const ProtocolGenerator = ({
  value,
  onChange,
  subFormState,
  onChangeSubformInput,
}) => {
  const SubFormComponent =
    value === 'http' ? HttpWebHookForm : GithubEndpointGenerator
  return (
    <div>
      <Select
        className="wb-margin-bottom-xs"
        label="Webhook type"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        options={SELECT_OPTIONS}
      />
      <SubFormComponent state={subFormState} setState={onChangeSubformInput} />
    </div>
  )
}

ProtocolGenerator.propTypes = {
  value: PropTypes.string,
  subFormState: PropTypes.object,
  onChange: PropTypes.func,
  onChangeSubformInput: PropTypes.func,
}
