import React from 'react'
import {
  Button,
  IndeterminateCheckbox,
} from '../../../../../../../../../common'

export const generateColumns = (onClickDelete, provider) => {
  const columns = [
    {
      header: 'Name',
      accessorKey: 'name',
      accessorFn: (row) => `${row.user.firstName} ${row.user.lastName}`,
    },
    {
      header: 'Email',
      accessorKey: 'email',
      accessorFn: (row) => row.user.email,
    },
    {
      header: 'DaimlerId',
      accessorKey: 'daimlerId',
      accessorFn: (row) => row.user.daimlerId,
    },
    {
      header: 'Admin',
      accessorKey: 'role',
      cell: ({ row }) =>
        row.original.role === 'ADMIN' ? (
          <wb-icon
            class="wb-button__icon"
            aria-hidden="true"
            name="bds/checkmark-circle/24"
          ></wb-icon>
        ) : (
          ''
        ),
    },
    {
      header: 'Actions',
      cell: (row) => (
        <Button
          size="small"
          iconOnly
          variant="secondary"
          withIcon="bds/trash/24"
          disabled={!provider.isManagable}
          onClick={() => onClickDelete(row, provider)}
        >
          Delete
        </Button>
      ),
    },
  ]

  if (provider.isEditable) {
    columns.unshift({
      id: 'select',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <div>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    })
  }
  return columns
}
