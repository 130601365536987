import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { generateOptionLabel } from '../../../../../common'
import { TDMS_ROLES_LEGACY } from '../../constants'
import { TdmsUserFormComponent } from './component'

export const TdmsUserForm = ({
  header,
  tdmsUserDaimlerIds,
  user: currentUser,
  onCancel,
  onSave,
}) => {
  const filterOutputFn = ({ daimlerId }) =>
    !tdmsUserDaimlerIds.includes(daimlerId)

  const targetUser = currentUser
    ? {
        value: currentUser.daimlerId,
        label: generateOptionLabel(currentUser),
      }
    : null

  const targetRoles = currentUser?.userInfo?.roles
    ? currentUser.userInfo.roles.map((currentRole) => {
        return TDMS_ROLES_LEGACY[currentRole]
      })
    : []

  const [user, setUser] = useState(targetUser)
  const [roles, setRoles] = useState(targetRoles)

  const onSubmit = () =>
    onSave(
      user.value,
      roles.map(({ value }) => value),
    )

  return (
    <TdmsUserFormComponent
      header={header}
      user={user}
      roles={roles}
      editMode={Boolean(currentUser)}
      actions={{
        onUserSelect: setUser,
        filterOutputFn,
        setRoles,
        onCancel,
        onSubmit,
      }}
    />
  )
}

TdmsUserForm.propTypes = {
  header: PropTypes.string,
  tdmsUserDaimlerIds: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.shape({
    daimlerId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    userInfo: PropTypes.shape({
      roles: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}
