import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  addServicePermission,
  editServicePermission,
} from '../../../../../../../../data/actions'
import { PermissionsFormComponent } from '../component'

const PermissionsFormContainer = ({
  mode,
  permission,
  onCancel,
  editPermission,
  addPermission,
}) => {
  const basePermission = {
    name: '',
    description: '',
    providerOnly: false,
  }
  const [formErrors, setFormErrors] = useState({ name: '' })
  const [currentPermission, setCurrentPermission] = useState(
    (mode === 'edit' && permission) || basePermission,
  )
  const updateInputState = (variant, value) => {
    setCurrentPermission({
      ...currentPermission,
      [variant]: value,
    })
  }
  const handleSubmit = async () => {
    if (!currentPermission.name.length) {
      setFormErrors({ name: 'The name field is required!' })
      return
    }
    const fn = currentPermission.id ? editPermission : addPermission
    fn(currentPermission, onCancel)
  }
  const header = mode === 'edit' ? 'Edit permission' : 'Add Permission'

  return (
    currentPermission && (
      <PermissionsFormComponent
        header={header}
        formErrors={formErrors}
        permission={currentPermission}
        handleSubmit={handleSubmit}
        handleClose={onCancel}
        updateInputState={updateInputState}
      />
    )
  )
}

PermissionsFormContainer.propTypes = {
  mode: PropTypes.string,
  permission: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    providerOnly: PropTypes.bool,
  }),
  onCancel: PropTypes.func,
  editPermission: PropTypes.func,
  addPermission: PropTypes.func,
}

export const PermissionsForm = connect(
  ({ backOffice: { permission } }) => ({
    permission,
  }),
  {
    editPermission: editServicePermission,
    addPermission: addServicePermission,
  },
)(PermissionsFormContainer)
