import { connect } from 'react-redux'
import {
  invalidateAkamaiCache,
  cleanAkamaiState,
} from '../../../../../data/actions'
import { InvalidateAkamaiCacheDialogComponent } from './component'

export const InvalidateAkamaiCacheDialog = connect(
  ({ akamai: { loading, selectedCpCodes, invalidating } }) => ({
    loading,
    invalidating,
    disabled: !Object.keys(selectedCpCodes).length,
  }),
  {
    invalidateAkamaiCache,
    cleanAkamaiState,
  },
)(InvalidateAkamaiCacheDialogComponent)
