export const processAnalyticsData = (analyticsData) => {
  const { dataLayer } = window
  if (!dataLayer) {
    console.warn(
      'analytics::processAnalyticsData() -> window.dataLayer is not defined',
    )
    return
  }
  if (process.env.NODE_ENV === 'production') {
    dataLayer.push(analyticsData)
  } else {
    console.log(
      'analytics::trackPageView() -> development mode. data:',
      analyticsData,
    )
  }
}

export const trackPageView = ({ name, hier1, hier2, hier3 }) =>
  processAnalyticsData({
    application: {
      id: 'nebula_applications',
    },
    event: 'pageview',
    page: { name, hier1, hier2, hier3 },
  })

export const trackLinkOutClick = (url) =>
  processAnalyticsData({
    application: {
      id: 'nebula_applications',
    },
    event: 'click',
    linkTracking: url,
  })
