import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'

export const AkamaiFormComponent = ({
  cacheItems,
  disabled,
  selectedCpCodes,
  onChangeCpCode,
}) => {
  return (
    <div className="akamai-form">
      {cacheItems.map(
        ({
          cpCodeName,
          cpCodeId,
          lastInvalidationRequestBy,
          lastInvalidationRequestDate,
        }) => {
          const hintArr = [`cpcodeID: ${cpCodeId}`]
          if (lastInvalidationRequestDate && lastInvalidationRequestBy) {
            hintArr.push(
              `Status: successfully triggered on ${moment(
                lastInvalidationRequestDate,
              ).format('YYYY-MM-DD HH:mm:ss')}`,
            )
          }
          return (
            <wb-checkbox-control
              class="wb-margin-bottom-xs akamai-form__checkbox"
              key={cpCodeId}
            >
              <label
                className={classnames({
                  'akamai-form__checkbox--selected': selectedCpCodes[cpCodeId],
                })}
              >
                <input
                  type="checkbox"
                  name="newsletter"
                  disabled={disabled}
                  checked={selectedCpCodes[cpCodeId] || false}
                  onChange={() => onChangeCpCode(cpCodeId)}
                />
                {cpCodeName}
              </label>
              <wb-control-hint class="wb-type-hint hydrated">
                {hintArr.join('  |  ')}
              </wb-control-hint>
            </wb-checkbox-control>
          )
        },
      )}
    </div>
  )
}

AkamaiFormComponent.propTypes = {
  cacheItems: PropTypes.arrayOf(
    PropTypes.shape({
      cpCodeName: PropTypes.string,
      cpCodeId: PropTypes.number,
      lastInvalidationRequestBy: PropTypes.string,
      lastInvalidationRequestDate: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  selectedCpCodes: PropTypes.object,
  onChangeCpCode: PropTypes.func,
}
