import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setPackage, setModal } from '../../../../../../../data/actions'
import { PackagesListComponent } from '../component'

const PackagesListContainer = ({
  platformPackages,
  selectedPackage,
  handlePackageClick,
  dispatchModal,
}) => {
  const onAddPackage = () =>
    dispatchModal({
      variant: 'package',
      mode: 'add',
      onCancel: () => dispatchModal({}),
    })

  return (
    <PackagesListComponent
      platformPackages={platformPackages}
      selectedPackage={selectedPackage}
      handlePackageClick={handlePackageClick}
      onAddPackage={onAddPackage}
    />
  )
}

const packageProp = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  packageKey: PropTypes.string,
  description: PropTypes.string,
})

PackagesListContainer.propTypes = {
  platformPackages: PropTypes.arrayOf(packageProp),
  selectedPackage: packageProp,
  handlePackageClick: PropTypes.func,
  dispatchModal: PropTypes.func,
}

export const PackagesList = connect(
  ({ backOffice }) => ({
    platformPackages: backOffice.packages,
    selectedPackage: backOffice.package,
  }),
  (dispatch) => ({
    handlePackageClick: (pkg) => dispatch(setPackage(pkg)),
    dispatchModal: (modalObj) => dispatch(setModal(modalObj)),
  }),
)(PackagesListContainer)
