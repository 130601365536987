import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavigationItem } from '../../../../../../../common'

export const PackageListItem = ({ servicePackage, isActive, onClick }) => (
  <NavigationItem onClick={onClick}>
    <li
      className={classnames('packages-editor__list-item', {
        'packages-editor__list-item--active': isActive,
        'packages-editor__list-item--disabled': !servicePackage.isEditable,
      })}
    >
      <div>{servicePackage.name}</div>
      <div className="packages-editor__list-item-description">
        {servicePackage.packageKey}
      </div>
      <div className="packages-editor__list-item-description">
        {servicePackage.description}
      </div>
    </li>
  </NavigationItem>
)
PackageListItem.propTypes = {
  servicePackage: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    packageKey: PropTypes.string,
    description: PropTypes.string,
    isEditable: PropTypes.bool,
  }),
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}
