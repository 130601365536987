import React from 'react'
import PropTypes from 'prop-types'
import { TanstackTableWrapper, TablePagination } from '../../../../../../common'
import { TableHead } from './TableHead'

export const PermissionsTableComponent = ({
  searchTerm,
  selectedRoles,
  table,
  actions: { onAddUser, onChangeSearch, setSelectedRoles },
}) => (
  <div className="tdms-users">
    <TableHead
      searchTerm={searchTerm}
      selectedRoles={selectedRoles}
      actions={{
        onAddUser,
        onChangeSearch,
        setSelectedRoles,
      }}
    />
    <TanstackTableWrapper table={table} />
    <TablePagination table={table} />
  </div>
)

PermissionsTableComponent.propTypes = {
  searchTerm: PropTypes.string,
  table: PropTypes.object,
  selectedRoles: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  actions: PropTypes.shape({
    onAddUser: PropTypes.func,
    onChangeSearch: PropTypes.func,
    setSelectedRoles: PropTypes.func,
  }),
}
