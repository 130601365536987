import {
  getProviderUsers,
  addAdminUsersToProvider,
  addUsersToProvider,
  removeUsersFromProvider,
  saveUserRole,
  getTdmsUsers,
} from '../../../../../http'
import { postNotification } from '../../../'
import { setProviderUsers, setModal, setTdmsUsersWithRoles } from '../shared'
import { providerUsersSorter, usersSorter } from '../utils'

export const listProviderUsers = (providerSlug) => async (dispatch) => {
  try {
    const providerUsers = await getProviderUsers(providerSlug)
    const usersSorted = Array.from(providerUsers).sort(providerUsersSorter)
    dispatch(setProviderUsers(usersSorted))
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while retrieving providerUsers - ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const attachUsersToProvider =
  (providerSlug, userDaimlerIds) => async (dispatch) => {
    try {
      await addUsersToProvider(providerSlug, userDaimlerIds)
      dispatch(listProviderUsers(providerSlug))
      dispatch(setModal({}))
      dispatch(
        postNotification({
          message: `Users were added successfully to the provider!`,
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while adding users to providers - ${err.message}`,
          mode: 'error',
        }),
      )
    }
  }

export const attachAdminUsersToProvider =
  (providerSlug, userDaimlerIds) => async (dispatch) => {
    try {
      await addAdminUsersToProvider(providerSlug, userDaimlerIds)
      dispatch(listProviderUsers(providerSlug))
      dispatch(setModal({}))
      dispatch(
        postNotification({
          message: `Users were added successfully to the provider admins list!`,
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while adding admin users to providers - ${err.message}`,
          mode: 'error',
        }),
      )
    }
  }

export const detachUsersFromProvider =
  (providerSlug, userDaimlerIds) => async (dispatch) => {
    try {
      await removeUsersFromProvider(providerSlug, userDaimlerIds)
      dispatch(listProviderUsers(providerSlug))
      dispatch(setModal({}))
      dispatch(
        postNotification({
          message: `Users were removed successfully from the provider!`,
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while removing users from providers - ${err.message}`,
          mode: 'error',
        }),
      )
    }
  }

export const getUsersWithPermissions = () => async (dispatch) => {
  try {
    const response = await getTdmsUsers()
    const tdmsUsers = Array.from(response).sort(usersSorter)
    dispatch(setTdmsUsersWithRoles(tdmsUsers))
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while retrieving TDMSUsers - ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const updateUserRoles = (daimlerId, roles) => async (dispatch) => {
  try {
    await saveUserRole({ daimlerId, roles })
    dispatch(getUsersWithPermissions())
    dispatch(
      postNotification({
        message: `User roles for ${daimlerId} were updated successfully!`,
      }),
    )
    dispatch(setModal({}))
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while changing user roles - ${err.message}`,
        mode: 'error',
      }),
    )
  }
}
