import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updateBookingServiceProperty } from '../../../../../../../../../../../../data/actions'
import { BookingServicePropertyFormComponent } from '../component'

const BookingServicePropertyFormContainer = ({
  property,
  serviceProperty,
  updateProperty,
  onCancel: onDismissModal,
}) => {
  const [formErrors, setFormErrors] = useState({})
  const [value, setValue] = useState('')

  const availableValues = serviceProperty?.availableValues.filter(
    (val) => val !== property.value,
  )

  const onSubmit = () => {
    try {
      validateState()
      updateProperty(property.platformServicePropertyId, { value }, (err) =>
        err ? setFormErrors(err) : onDismissModal(),
      )
    } catch (err) {
      console.log({ err })
    }
  }
  const isJson = (val) => {
    try {
      JSON.parse(val)
      return true
    } catch (er) {
      return false
    }
  }

  const postErrors = (err) => {
    setFormErrors({ currentValue: err })
    throw new Error(err)
  }

  const validateState = () => {
    const err = {
      value: 'The value field is mandatory',
      json: 'Please enter valid JSON',
      same: 'You are trying to submit the same value, please specify different value',
    }
    if (!value) {
      postErrors(err.value)
    }
    if (property.type === 'JSON' && !isJson(value)) {
      postErrors(err.json)
    }
    if (property.value === value) {
      postErrors(err.same)
    }
  }

  return (
    <BookingServicePropertyFormComponent
      property={property}
      formErrors={formErrors}
      availableValues={availableValues}
      currentValue={value}
      setCurrentValue={setValue}
      handleClose={onDismissModal}
      handleSubmit={onSubmit}
    />
  )
}

BookingServicePropertyFormContainer.propTypes = {
  property: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    platformServicePropertyId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    type: PropTypes.string,
  }),
  serviceProperty: PropTypes.shape({
    availableValues: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  }),
  updateProperty: PropTypes.func,
  onCancel: PropTypes.func,
}

export const BookingServicePropertyForm = connect(
  ({ tenantBookings: { property }, backOffice }) => ({
    property,
    serviceProperty: backOffice.property,
  }),
  {
    updateProperty: updateBookingServiceProperty,
  },
)(BookingServicePropertyFormContainer)
