import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getTenantComplianceData,
  updateComplianceData,
} from '../../../../../../../data/actions'
import { isUserTenantEditor } from '../../../../../../../util'
import { ComplianceFormContainer } from './container'

const ComplianceFormWrapper = ({
  complianceInfo,
  isEditable,
  getData,
  updateData,
}) => {
  useEffect(() => {
    getData()
  }, [])
  return complianceInfo ? (
    <ComplianceFormContainer
      updateComplianceData={updateData}
      complianceInfo={complianceInfo}
      isEditable={isEditable}
    />
  ) : null
}

export const ComplianceForm = connect(
  ({
    tenants: { complianceInfo, selectedTenant, allTenants },
    sso: { username },
  }) => ({
    complianceInfo,
    isEditable: Boolean(
      isUserTenantEditor(allTenants[selectedTenant], username),
    ),
  }),
  {
    getData: getTenantComplianceData,
    updateData: updateComplianceData,
  },
)(ComplianceFormWrapper)

ComplianceFormWrapper.propTypes = {
  complianceInfo: PropTypes.shape({
    processOnBehalf: PropTypes.shape({
      mbagOwned: PropTypes.bool,
      mbagAgreementEntity: PropTypes.string,
      thirdPartyAccess: PropTypes.bool,
      personalData: PropTypes.bool,
      agreementSubject: PropTypes.string,
      riskAssessment: PropTypes.arrayOf(PropTypes.string),
      dataProcessingLocation: PropTypes.string,
      personalDataScope: PropTypes.string,
      personalDataTypes: PropTypes.string,
      personalDataTargetGroups: PropTypes.string,
    }),
    automatedDecisionMaking: PropTypes.bool,
    dataAnalytics: PropTypes.bool,
    dataProtectionContactPerson: PropTypes.string,
  }),
  isEditable: PropTypes.bool,
  getData: PropTypes.func,
  updateData: PropTypes.func,
}
