import React from 'react'
import { useParams } from 'react-router-dom'
import { ProvidersList } from '../components/ProvidersList'
import { ProviderPage } from '../components/ProviderPage'

export const Providers = () => {
  const { param } = useParams()
  const Component = param ? ProviderPage : ProvidersList
  return (
    <div>
      <Component />
    </div>
  )
}
