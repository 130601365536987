import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TenantProviderOverView } from '../TenantProviderOverView'
import { numberToPrice } from '../../utils'
import { UnstiledButton } from '../../../../../../common'

export const TenantYearOverview = ({ entry, total }) => {
  const currentYear = new Date().getFullYear()
  const { bookingsDetails, year } = entry
  const [isExpanded, setIsExpanded] = useState(currentYear <= year)
  const toggle = () => setIsExpanded(!isExpanded)
  return (
    <>
      <UnstiledButton
        className="tenant-year-overview__headline"
        onClick={toggle}
      >
        <span>{year}</span>
        <span className="tenant-year-overview__costs-label">
          {numberToPrice('Cannot be calculated')(total)}
        </span>
      </UnstiledButton>
      {isExpanded && (
        <div>
          {bookingsDetails.map(
            ({ bookings, providerName, totalPrice, providerSlug }) => (
              <TenantProviderOverView
                data={bookings}
                provider={providerName}
                totalPrice={totalPrice}
                key={providerSlug}
              />
            ),
          )}
        </div>
      )}
    </>
  )
}

TenantYearOverview.propTypes = {
  entry: PropTypes.shape({
    bookingsDetails: PropTypes.arrayOf(
      PropTypes.shape({
        bookings: PropTypes.array,
        providerName: PropTypes.string,
        totalPrice: PropTypes.number,
        providerSlug: PropTypes.string,
      }),
    ),
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  total: PropTypes.number,
}
