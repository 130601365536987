import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  changeNavMenu,
  fetchSchemaIfNeeded,
  fetchTenantsIfNeeded,
  fetchWhitelistIfNeeded,
  getPackages,
} from '../../../data/actions'
import { fetchAllBookings, fetchPlatformServices } from '../../../http'
import { AdminsComponent } from '../component'

const AdminsContainer = (props) => {
  const {
    fetchWhitelist,
    fetchSchema,
    changeHashMenu,
    fetchTenants,
    fetchPackages,
    allTenants,
    isProviderUser,
    appRoles: { roleAdmin },
  } = props
  const [state, setState] = useState({
    bookings: [],
    services: [],
  })

  useEffect(() => {
    if (!(roleAdmin || isProviderUser)) {
      window.location.replace('#apps')
    }

    changeHashMenu('admins')
    fetchSchema()
    fetchWhitelist()
    fetchTenants()
    roleAdmin &&
      fetchAllBookings().then((bookings) => {
        setState({ ...state, bookings })
      })
    fetchPlatformServices().then((services) => {
      setState({ ...state, services })
    })
    fetchPackages()
  }, [])

  return allTenants ? <AdminsComponent {...props} {...state} /> : null
}

AdminsContainer.propTypes = {
  allTenants: PropTypes.object,
  crossChargingOverview: PropTypes.arrayOf(
    PropTypes.shape({
      packageName: PropTypes.string,
      priceVariantName: PropTypes.string,
      providerName: PropTypes.string,
      providerSlug: PropTypes.string,
      services: PropTypes.array,
      yearlyPrices: PropTypes.object,
    }),
  ),
  appRoles: PropTypes.shape({ roleAdmin: PropTypes.bool }),
  isProviderUser: PropTypes.bool,
  fetchWhitelist: PropTypes.func,
  fetchSchema: PropTypes.func,
  changeHashMenu: PropTypes.func,
  fetchTenants: PropTypes.func,
  fetchPackages: PropTypes.func,
}

export default connect(
  ({
    global: { navMenuSelected },
    sso: { username, appRoles, providerRoles },
    tenants: { isWhitelisted, costsManagement, allTenants, roles },
    reports: { crossChargingOverview },
    backOffice: { packages },
  }) => ({
    navMenuSelected,
    username,
    appRoles,
    isWhitelisted,
    costsManagement,
    allTenants,
    roles,
    crossChargingOverview,
    packages,
    isProviderUser: Boolean(Object.keys(providerRoles).length),
  }),
  {
    fetchWhitelist: fetchWhitelistIfNeeded,
    fetchSchema: fetchSchemaIfNeeded,
    fetchTenants: fetchTenantsIfNeeded,
    changeHashMenu: changeNavMenu,
    fetchPackages: getPackages,
  },
)(AdminsContainer)
