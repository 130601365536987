import React from 'react'
import PropTypes from 'prop-types'
import { EmailList } from '../components/EmailList'
import { ComplianceRequirements } from '../components/ComplianceRequirements'
import { ComplianceMissingInfo } from '../components/ComplianceMissingInfo'
import { DigitalHouseReport } from '../components/DigitalHouseReport'
import { ProgramReport } from '../components/ProgramReport'
import { TimelineReport } from '../components/TimelineReport'
import { CostChargingOverview } from '../components/CostChargingOverview'
import { TenantsStatusReport } from '../components/TenantsStatusReport'

export const AdminsComponent = (props) => (
  <div className="body">
    <div className="content">
      <div className="page-header">
        <h2 className="page-header__title">Admin Modules</h2>
      </div>
      {props.appRoles.roleAdmin && props.isProviderUser && (
        <div className="page-content admin-panel">
          <CostChargingOverview bookings={props.bookings} />
        </div>
      )}
      {props.appRoles.roleAdmin && (
        <>
          <div className="page-content admin-panel">
            <EmailList {...props} bookingsFromBackend={props.bookings} />
          </div>
          <div className="page-content admin-panel">
            <div className="page-body__section">
              <h3 className="wb-type-heading-m page-body__margin-bottom-s">
                Bookings Report
              </h3>
              <TenantsStatusReport
                {...props}
                bookingsFromBackend={props.bookings}
              />
              <TimelineReport bookingsFromDatabase={props.bookings} />
            </div>
          </div>
          <div className="page-content admin-panel">
            <ComplianceRequirements {...props} />
          </div>
          <div className="page-content admin-panel">
            <ComplianceMissingInfo {...props} />
          </div>
          <div className="page-content admin-panel">
            <DigitalHouseReport {...props} />
          </div>
          <div className="page-content admin-panel">
            <ProgramReport {...props} />
          </div>
        </>
      )}
    </div>
  </div>
)

AdminsComponent.propTypes = {
  appRoles: PropTypes.shape({
    roleAdmin: PropTypes.bool,
  }),
  isProviderUser: PropTypes.bool,
  bookings: PropTypes.arrayOf(
    PropTypes.shape({
      comment: PropTypes.string,
      endDate: PropTypes.string,
      id: PropTypes.number,
      packageAllowBookingPermissions: PropTypes.bool,
      packageDisplayName: PropTypes.string,
      packageName: PropTypes.string,
      packageSlug: PropTypes.string,
      prices: PropTypes.array,
      providerSlug: PropTypes.string,
      startDate: PropTypes.string,
      tenantAcronym: PropTypes.string,
      tenantName: PropTypes.string,
      variantName: PropTypes.string,
      variantSlug: PropTypes.string,
    }),
  ),
}
