import React from 'react'
import PropTypes from 'prop-types'
import { WbAccordion, Accordion } from '../../../../common'
import { ActionButtons } from '../components/ActionButtons'
import { FilterEmailsForm } from '../components/FilterEmailsForm'

export const EmailListComponent = ({
  emailList,
  packages,
  filter,
  actions: { handleSubmit, updateFilterState },
}) => (
  <div className="page-body__section">
    <h3 className="wb-type-heading-m page-body__margin-bottom-s">Exports</h3>
    <Accordion>
      <WbAccordion title="Export emails from tenants">
        <div className="tenant-body__table tenant-emails">
          <table
            width="100%"
            className="wb-table wb-table--striped wb-table--border-vertical wb-table--border-horizontal wb-type-copy-secondary"
          >
            <thead>
              <tr>
                <th className="column column--dark column--header" colSpan="10">
                  Export emails
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="column" width="20%">
                  <div className="tenant-emails__form">
                    <FilterEmailsForm
                      packages={packages}
                      filter={filter}
                      actions={{
                        handleSubmit,
                        updateFilterState,
                      }}
                    />
                  </div>
                </td>
                <td className="column" width="60%">
                  <legend>Emails to copy</legend>
                  <div className="tenant-emails__form__results">
                    {!emailList ? 'No results' : emailList}
                  </div>
                </td>
                <td className="column" width="20%">
                  <div>
                    <ActionButtons emailList={emailList} />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </WbAccordion>
    </Accordion>
  </div>
)

EmailListComponent.propTypes = {
  emailList: PropTypes.string,
  packages: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.object,
  actions: PropTypes.shape({
    handleSubmit: PropTypes.func,
    updateFilterState: PropTypes.func,
  }),
}
