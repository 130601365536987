import React from 'react'
import PropTypes from 'prop-types'
import { numberToPrice } from '../../../../../apps/tenant/components/tenantCostOverview/utils'

export const YearlyReportHeader = ({ year, total }) => (
  <div className="wb-margin-bottom-xs wb-margin-top-xs cross-charging__list-item__head wb-margin-bottom-xs">
    <div>{year}</div>
    <div style={{ fontWeight: 'bold' }}>Total Sum {numberToPrice()(total)}</div>
  </div>
)

YearlyReportHeader.propTypes = {
  year: PropTypes.number,
  total: PropTypes.number,
}
