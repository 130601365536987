import React from 'react'
import PropTypes from 'prop-types'
import { CheckboxesGroup } from '../CheckboxesGroup'
import { roles } from '../../utils'

export const RolesOptions = ({ checkedRoles, onItemCheck }) => {
  const roleItems = roles.map(({ value, label }) => ({
    name: 'tenant_role',
    id: `role_${value}`,
    label: label,
    checked: checkedRoles.includes(value),
    value,
  }))
  const onChange = (role) => {
    const newRoles = [...checkedRoles]
    const roleIndex = newRoles.indexOf(role)
    if (roleIndex > -1) {
      newRoles.splice(roleIndex, 1)
    } else {
      newRoles.push(role)
    }
    onItemCheck(newRoles)
  }
  return (
    <div>
      <legend>Filter by roles</legend>
      <CheckboxesGroup items={roleItems} onClickItem={onChange} />
    </div>
  )
}

RolesOptions.propTypes = {
  checkedRoles: PropTypes.arrayOf(PropTypes.string),
  onItemCheck: PropTypes.func,
}
