export const roles = [
  { value: 'PO', label: 'PO' },
  { value: 'PM', label: 'PM' },
  { value: 'BUDGET_APPROVER', label: 'Budget Approver' },
  { value: 'CONTROLLER', label: 'Controller' },
  { value: 'ISA', label: 'ISA' },
  { value: 'SECURITY_CHAMPION', label: 'Security Champion' },
  { value: 'CLOUD_SECURITY_RESPONSIBLE', label: 'Cloud Security Responsible' },
  { value: 'PROGRAM_LEAD', label: 'Program Lead' },
  { value: 'PROGRAM_FINANCE_LEAD', label: 'Program Finance Lead' },
  { value: 'PROGRAM_TECH_LEAD', label: 'Program Tech Lead' },
  {
    value: 'INFORMATION_SECURITY_OFFICER',
    label: 'ISO (Information Security Officer)',
  },
  { value: 'SITE_RELIABILITY_ENGINEER', label: 'Site Reliability Engineer' },
  { value: 'FRONTEND_DEVELOPER', label: 'Front-end Developer' },
  { value: 'BACKEND_DEVELOPER', label: 'Back-end Developer' },
  { value: 'SCRUM_MASTER', label: 'Scrum Master' },
  { value: 'SERVICE_MANAGER', label: 'Service Manager' },
  { value: 'OTHER', label: 'OTHER' },
]

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const groupPackagesByProviders = (packages) => {
  const groupedPackages = {}
  packages.forEach((pkg) => {
    if (!groupedPackages[pkg.providerSlug]) {
      groupedPackages[pkg.providerSlug] = []
    }
    groupedPackages[pkg.providerSlug].push(pkg)
  })
  return groupedPackages
}
