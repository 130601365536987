import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

export const Modal = ({
  className = '',
  activeLevel = 1,
  onCloseModal,
  children,
}) => {
  const ref = useRef(null)
  useEffect(() => {
    ref?.current?.addEventListener('wbclose', onCloseModal)
    return () => ref?.current?.removeEventListener('wbclose', onCloseModal)
  }, [])
  return (
    <wb-modal
      mode="prompt"
      class={className}
      ref={ref}
      theme="dark"
      close-on-backdrop-click
      close-on-escape-key
      scroll-lock
      active-level={activeLevel}
    >
      {children}
    </wb-modal>
  )
}

Modal.propTypes = {
  activeLevel: PropTypes.number,
  className: PropTypes.string,
  onCloseModal: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
