import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  fetchApiKeys,
  postApiKey,
  removeApiKey,
} from '../../../../../../data/actions'
import { ApiKeyComponent } from './component'

const ApiKeysContainer = ({ getKeys, createKey, deleteKey, ...rest }) => {
  const [modal, setModal] = useState(null)

  useEffect(() => {
    getKeys()
  }, [])

  return (
    <ApiKeyComponent
      modal={modal}
      setModal={setModal}
      onClickCreate={() =>
        setModal({
          variant: 'create',
          title: 'Create new API Key',
          onApprove: (newKey, cb) => createKey(newKey, cb),
        })
      }
      onClickPreview={(row) =>
        setModal({
          variant: 'preview',
          title: 'Details',
          row,
        })
      }
      onClickRemove={(row) =>
        setModal({
          variant: 'remove',
          title: 'Delete API Key?',
          row,
          onApprove: (id) => {
            deleteKey(id)
            setModal(null)
          },
        })
      }
      onCloseModal={() => setModal(null)}
      {...rest}
    />
  )
}

ApiKeysContainer.propTypes = {
  keys: PropTypes.array,
  loading: PropTypes.bool,
  getKeys: PropTypes.func,
  createKey: PropTypes.func,
  deleteKey: PropTypes.func,
}

export const MyApiKeys = connect(
  ({ apiKeys: { keys } }) => ({
    keys,
  }),
  { getKeys: fetchApiKeys, createKey: postApiKey, deleteKey: removeApiKey },
)(ApiKeysContainer)
