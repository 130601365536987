import React from 'react'
import PropTypes from 'prop-types'
import { Button, SearchInput } from '../../../../../../../../../common'

export const ProviderUsersTableHead = ({
  searchTerm,
  showBatchActions,
  isEditable,
  actions: {
    deselectAllUsers,
    batchRemoveMembers,
    addNewMembers,
    onChangeSearch,
  },
}) => (
  <div className="wb-margin-vertical-xs wb-margin-top-xl provider-users__head">
    <div className="provider-users__left-head">
      <SearchInput
        label="Search Users"
        placeholder="Please enter search word"
        value={searchTerm}
        onChange={onChangeSearch}
      />

      {showBatchActions ? (
        <div>
          <Button
            variant="tertiary"
            size="medium"
            onClick={deselectAllUsers}
            withIcon="bds/close/24"
          >
            Deselect All
          </Button>

          <Button
            variant="secondary"
            size="medium"
            onClick={batchRemoveMembers}
            disabled={!isEditable}
            withIcon="bds/trash/24"
          >
            Batch remove user(s)
          </Button>
        </div>
      ) : null}
    </div>
    <div className="provider-users__right-head">
      <Button
        variant="primary"
        size="medium"
        withIcon="bds/plus/24"
        disabled={!isEditable}
        onClick={addNewMembers}
      >
        Add user(s)
      </Button>
    </div>
  </div>
)

ProviderUsersTableHead.propTypes = {
  showBatchActions: PropTypes.bool,
  searchTerm: PropTypes.string,
  isEditable: PropTypes.bool,
  actions: PropTypes.shape({
    deselectAllUsers: PropTypes.func,
    batchRemoveMembers: PropTypes.func,
    addNewMembers: PropTypes.func,
    onChangeSearch: PropTypes.func,
  }),
}
