import React from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { UnstiledButton, Spinner } from '../../common'
import { DocsNav } from '../nav'

import 'github-markdown-css/github-markdown.css'

export const sanitizeHtml = (html) =>
  DOMPurify.sanitize(html, {
    USE_PROFILES: { html: true },
    ADD_TAGS: ['wb-icon'],
  })

export const DocsComponent = ({
  sectionHash,
  sectionFolder,
  slug,
  navItems,
  error,
  isloading,
  pages,
  pageInfo,
  handleClickPage,
}) => (
  <div className="body body__sidebar">
    <DocsNav
      sectionHash={sectionHash}
      sectionFolder={sectionFolder}
      slug={slug}
      navItems={navItems}
    />
    <div className="content">
      <div className="page-content">
        {!isloading && Object.keys(pages).length > 0 && pages[slug] ? (
          <>
            <div className="page-header">
              <h2 className="page-header__title">
                {pageInfo ? pageInfo.text : ''}
              </h2>
            </div>
            <div className="page-body">
              {pageInfo.file.endsWith('.md') ? (
                <div
                  className="page-body__section markdown-body"
                  style={{ background: '#fff' }}
                >
                  <ReactMarkdown remarkPlugins={[gfm]}>
                    {pages[slug].html}
                  </ReactMarkdown>
                </div>
              ) : (
                <UnstiledButton onClick={handleClickPage}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(pages[slug].html),
                    }}
                  />
                </UnstiledButton>
              )}
            </div>
          </>
        ) : (
          <div>
            {!error ? (
              <div className="page-body__section">
                <div className="loader-container">
                  <Spinner mode="dark" />
                </div>
              </div>
            ) : (
              <div className="page-body page-body__message_box">
                Error loading page content
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  </div>
)

DocsComponent.propTypes = {
  sectionHash: PropTypes.string,
  sectionFolder: PropTypes.string,
  slug: PropTypes.string,
  navItems: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.bool,
  isloading: PropTypes.bool,
  pages: PropTypes.objectOf(PropTypes.shape({ html: PropTypes.string })),
  pageInfo: PropTypes.shape({
    text: PropTypes.string,
    file: PropTypes.string,
    id: PropTypes.string,
  }),
  handleClickPage: PropTypes.func,
}
