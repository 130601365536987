import {
  REQUEST_TENANTS,
  CHANGE_API_ACCESS,
  RECEIVE_WHITELIST,
  RECEIVE_ROLES,
  RECEIVE_COSTSMANAGEMENT,
  RECEIVE_SCHEMA,
  SET_ARTS,
  RECEIVE_TENANTS,
  SET_CURRENT_TENANT_MEMBERS,
  SET_MEMBER_EXPIRING_DATE_RANGE,
  FILTER_TENANTS,
  SELECT_TENANT,
  DELETE_TENANT,
  CHANGE_EDITING,
  CHANGE_CREATING,
  CHANGE_DELETING,
  CHANGE_SAVING,
  UPDATE_TENANT,
  CHANGE_SERVER_ERRORS,
  SET_TENANT_COMPLIANCE_INFO,
  SET_TENANT_ROLES,
  CHANGE_FORM_DATA,
} from '../../actionsTypes'

const initialServerErrorsObj = {
  message: null,
  hasErrors: false,
}

const initialState = {
  isFetching: true,
  apiAccess: true,
  allTenants: {},
  nebulaMembers: {},
  filterTerm: '',
  filteredTenants: {},
  selectedTenant: '',
  memberExpiringDateRange: {
    min: '',
    max: '',
  },
  complianceInfo: null,
  currentTenantMembers: [],
  schema: null,
  sample: null,
  tenantRoles: [],
  whitelist: null,
  roles: null,
  costsManagement: {},
  isWhitelisted: false,
  isAlmighty: false,
  isEditing: false,
  isSaving: false,
  isCreating: false,
  isDeleting: false,
  serverErrors: { ...initialServerErrorsObj },
  formData: null,
  arts: [],
}

export const tenantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_TENANTS:
      return {
        ...state,
        isFetching: true,
      }
    case CHANGE_API_ACCESS:
      return {
        ...state,
        isFetching: false,
        apiAccess: action.payload.apiAccess,
      }
    case RECEIVE_WHITELIST:
      return {
        ...state,
        whitelist: { ...action.payload.whitelist },
        isWhitelisted: action.payload.isWhitelisted,
        isAlmighty: action.payload.isAlmighty,
      }
    case RECEIVE_ROLES:
      return {
        ...state,
        roles: [...action.payload.roles],
      }
    case RECEIVE_COSTSMANAGEMENT:
      return {
        ...state,
        costsManagement: { ...action.payload.costsManagement },
      }
    case RECEIVE_SCHEMA:
      return {
        ...state,
        schema: { ...action.payload.schema },
        sample: { ...action.payload.sample },
      }
    case SET_ARTS:
      return {
        ...state,
        arts: action.payload,
      }
    case RECEIVE_TENANTS:
      return {
        ...state,
        allTenants: { ...action.payload.allTenants },
        nebulaMembers: { ...action.payload.nebulaMembers },
      }
    case SET_CURRENT_TENANT_MEMBERS:
      return {
        ...state,
        currentTenantMembers: action.payload.tenantMembers,
      }
    case SET_MEMBER_EXPIRING_DATE_RANGE:
      return {
        ...state,
        memberExpiringDateRange: { ...action.payload.memberExpiringDateRange },
      }
    case FILTER_TENANTS:
      return {
        ...state,
        filterTerm: action.payload.filterTerm,
        filteredTenants: { ...action.payload.filteredTenants },
      }
    case SELECT_TENANT:
      return {
        ...state,
        selectedTenant: action.payload.selectedTenant,
        currentTenantMembers:
          state[action.payload.selectedTenant]?.members || [],
      }
    case DELETE_TENANT:
      return {
        ...state,
        allTenants: Object.keys(state.allTenants)
          .filter((key) => key !== action.payload.acronym)
          .reduce((newTenantsObj, cur) => {
            newTenantsObj[cur] = state.allTenants[cur]
            return newTenantsObj
          }, {}),
      }
    case CHANGE_EDITING:
      return {
        ...state,
        isEditing: action.payload.isEditing,
      }
    case CHANGE_CREATING:
      return {
        ...state,
        isCreating: action.payload.isCreating,
      }
    case CHANGE_DELETING:
      return {
        ...state,
        isDeleting: action.payload.isDeleting,
      }
    case CHANGE_SAVING:
      return {
        ...state,
        isSaving: action.payload.isSaving,
      }
    case UPDATE_TENANT: {
      const filteredTenants = { ...state.filteredTenants }
      const currentTenantActonym = Object.keys(action.payload.updatedTenant)[0]
      if (Object.keys(state.filteredTenants).includes(currentTenantActonym)) {
        filteredTenants[currentTenantActonym] = {
          ...action.payload.updatedTenant[currentTenantActonym],
        }
      }

      return {
        ...state,
        allTenants: {
          ...state.allTenants,
          ...action.payload.updatedTenant,
        },
        filteredTenants,
      }
    }
    case CHANGE_SERVER_ERRORS:
      return {
        ...state,
        serverErrors: {
          ...state.serverErrors,
          ...(action.payload.serverErrors || initialServerErrorsObj),
        },
      }
    case CHANGE_FORM_DATA: {
      return {
        ...state,
        formData: { ...action.payload.formData },
      }
    }
    case SET_TENANT_COMPLIANCE_INFO:
      return {
        ...state,
        complianceInfo: action.payload.complianceInfo,
      }
    case SET_TENANT_ROLES:
      return {
        ...state,
        tenantRoles: action.payload.roles,
      }
    default:
      return state
  }
}
