import React from 'react'
import PropTypes from 'prop-types'

export const FormSectionItem = ({ title, children }) => (
  <div className="wb-margin-vertical-xs compliance-form__input">
    <div>{title}</div>
    {children}
  </div>
)

FormSectionItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
