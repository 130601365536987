import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { handleCrossChargingOverviewExport } from '../../../../../../../data/actions'
import { ExportButtonComponent } from '../component'

export const ExportButton = ({ title, format, providers }) => {
  const [loading, setLoading] = useState(false)
  const onClickExport = async () => {
    setLoading(true)
    try {
      await handleCrossChargingOverviewExport(providers, format)
      setLoading(false)
    } catch (err) {
      alert(`Error occured while exporting - ${err.message}`)
      setLoading(false)
    }
  }

  return (
    <ExportButtonComponent
      loading={loading}
      title={title}
      onClickExport={onClickExport}
    />
  )
}

ExportButton.propTypes = {
  title: PropTypes.string,
  format: PropTypes.oneOf(['xlsx', 'csv']),
  providers: PropTypes.string,
}
