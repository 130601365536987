import { tdmsClient } from '../apiClient'

export const fetchBookings = async ({ acronym, details, current }) =>
  tdmsClient.client.platformBookingMethods.listByTenantAcronym(acronym, {
    details,
    current,
  })

export const fetchAllBookings = async () =>
  tdmsClient.client.platformBookingMethods.list()

export const postBooking = async ({ tenantAcr, priceVariantId, bookingData }) =>
  tdmsClient.client.platformBookingMethods.createBooking(
    tenantAcr,
    priceVariantId,
    bookingData,
  )

export const putBooking = async ({ bookingId, data }) =>
  tdmsClient.client.platformBookingMethods.updateBooking(bookingId, data)

export const deleteBooking = async (bookingId) =>
  tdmsClient.client.platformBookingMethods.deleteBooking(bookingId)
