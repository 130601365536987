import React from 'react'
import PropTypes from 'prop-types'
import { ListItem } from './ListItem'

export const List = ({ items }) => (
  <ul className="wb-list wb-list--unordered wb-list--offset-xs">
    {items.map(({ url, title }) => (
      <ListItem url={url} title={title} key={title} />
    ))}
  </ul>
)

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
}
