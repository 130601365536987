import { tdmsClient } from '../apiClient'

export const fetchBookingServicePermissions = async ({
  bookingId,
  serviceSlug,
}) =>
  tdmsClient.client.platformBookingPermissionsMethods.listServicePermissions(
    bookingId,
    serviceSlug,
  )

export const fetchTenantMemberPermissions = async (tenantAcr) =>
  tdmsClient.client.platformBookingPermissionsMethods.listByTenantAcronym(
    tenantAcr,
  )

export const putMembersToBookingServicePermission = async ({
  bookingPermissionIds,
  servicePermissionSlug,
  tenantAcronym,
}) =>
  tdmsClient.client.platformBookingPermissionsMethods.updateBookingServicePermission(
    bookingPermissionIds,
    servicePermissionSlug,
    tenantAcronym,
  )

export const postMembersToBookingServicePermission = async ({
  members,
  bookingId,
  servicePermissionId,
  tenantAcronym,
}) =>
  Array.isArray(members)
    ? tdmsClient.client.platformBookingPermissionsMethods.batchCreateBookingServicePermissions(
        members,
        bookingId,
        servicePermissionId,
        tenantAcronym,
      )
    : tdmsClient.client.platformBookingPermissionsMethods.createBookingServicePermission(
        members,
        bookingId,
        servicePermissionId,
        tenantAcronym,
      )

export const deleteBookingServicePermission = async (
  permissionId,
  tenantAcronym,
) =>
  tdmsClient.client.platformBookingPermissionsMethods.deleteBookingServicePermissions(
    permissionId,
    tenantAcronym,
  )

export const fetchBookingPermissions = async ({ bookingId }) =>
  tdmsClient.client.platformBookingPermissionsMethods.bookingPermissions(
    bookingId,
  )
