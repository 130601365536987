import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  AccordionToggle,
  AccordionSection,
  AccordionMobileHeader,
  AccordionContent,
} from '..'

export const WbAccordion = ({ title, children }) => {
  const accordionRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const onClickAccordion = () => {
    const currentOpen = !accordionRef.current?.hasAttribute('open')
    setIsOpen(currentOpen)
  }
  return (
    <wb-accordion-item ref={accordionRef} prevent-mobile-flyout>
      <AccordionToggle onClick={onClickAccordion}>{title}</AccordionToggle>
      <AccordionSection>
        <AccordionMobileHeader>Close</AccordionMobileHeader>
        <AccordionContent>{isOpen ? children : ''}</AccordionContent>
      </AccordionSection>
    </wb-accordion-item>
  )
}

WbAccordion.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
