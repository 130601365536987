import React from 'react'
import PropTypes from 'prop-types'

export const ListItem = ({ title, url }) => (
  <li className="wb-list__item">
    {
      <a
        className="wb-link wb-link--standalone"
        target="_blank"
        rel="noreferrer"
        href={url}
      >
        {title}
      </a>
    }
  </li>
)

ListItem.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
}
