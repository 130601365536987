import React from 'react'
import moment from 'moment'
import { createColumnHelper } from '@tanstack/react-table'
import { UnstiledButton } from '../../../../common'

const columnHelper = createColumnHelper()

const generateObjectFromOperationTypes = (operationTypes) => {
  const obj = {}
  operationTypes.forEach(({ type, name }) => {
    obj[type] = name
  })
  return obj
}

export const generateColumns = (selectedTenant, operationTypes) => {
  const typesObject = generateObjectFromOperationTypes(operationTypes)
  const columns = [
    columnHelper.accessor(
      (row) => {
        return (
          row.metadata?.userName ||
          row.metadata?.user ||
          row.metadata?.userDaimlerId
        )
      },
      {
        header: () => <span>User ID</span>,
        id: 'userId',
        cell: ({ row, getValue }) => (
          <div
            style={{
              paddingLeft: `${row.depth * 2}rem`,
            }}
          >
            {row.getCanExpand() ? (
              <UnstiledButton
                {...{
                  onClick: row.getToggleExpandedHandler(),
                }}
              >
                {row.getIsExpanded() ? (
                  <wb-icon
                    className="wb-round-button__icon"
                    name="bds/chevron-down/16"
                  />
                ) : (
                  <wb-icon
                    className="wb-round-button__icon"
                    name="bds/chevron-right/16"
                  />
                )}
              </UnstiledButton>
            ) : (
              ''
            )}{' '}
            {getValue()}
          </div>
        ),
      },
    ),
    columnHelper.accessor('metadata', {
      id: 'targetUserId',
      header: () => 'Target User',
      cell: (info) => {
        const val = info.getValue()
        return val?.targetUserName
          ? `${val.targetUserName} (${val.targetUser})`
          : ''
      },
    }),
    columnHelper.accessor((row) => typesObject[row.operationType], {
      id: 'operationTypes',
      header: () => 'Operation Type',
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('createDate', {
      cell: ({ getValue }) => (
        <div>{moment(getValue()).format('YYYY-MM-DD HH:mm')}</div>
      ),
      header: () => <span>Created At</span>,
    }),
  ]
  if (!selectedTenant) {
    columns.splice(
      1,
      0,
      columnHelper.accessor((row) => row.targetTenant?.acronym, {
        id: 'targetTenant',
        cell: (info) => <i>{info.getValue()}</i>,
        header: () => <span>Target Tenant ID</span>,
      }),
    )
  }
  return columns
}

export const renderSubComponent = ({ row }) => (
  <pre>
    <code>
      {JSON.stringify(
        row.original.payload.diff || row.original.payload.changes,
        null,
        2,
      )}
    </code>
  </pre>
)
