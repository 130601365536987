import React from 'react'
import PropTypes from 'prop-types'
import { BookingList } from './components/BookingList'
import {
  Modal,
  ModalFooter,
  ModalContent,
  ModalLevel,
  ModalHeader,
  Button,
} from '../../../../common'

export const TenantBookingsDialog = ({ onClose }) => (
  <Modal className="tenant-dialog" onCloseModal={onClose}>
    <ModalHeader />
    <ModalLevel>
      <ModalContent
        headline="Tenant Bookings"
        className="tenant-dialog__content"
      >
        <BookingList />
      </ModalContent>
      <ModalFooter class="tenant-dialog__footer">
        <div></div>
        <Button size="large" variant="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalLevel>
  </Modal>
)

TenantBookingsDialog.propTypes = {
  onClose: PropTypes.func,
}
