import { POST_NOTIFICATION, DISMISS_NOTIFICATION } from '../../actionsTypes'
import { generateExport } from '../../../http'
import { downloadBlob } from '../../../util'

export const postNotification = (notification) => ({
  type: POST_NOTIFICATION,
  payload: notification,
})

export const dismissNotification = (notificationId) => ({
  type: DISMISS_NOTIFICATION,
  payload: notificationId,
})

export const handleCrossChargingOverviewExport = async (
  providers,
  format = 'xlsx',
) => {
  const blob = await generateExport(providers, format)
  const fileName = `CostOverviewReport.${format}`
  downloadBlob(blob, fileName)
}
