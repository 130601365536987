import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  addServiceProperties,
  updateServiceProperty,
} from '../../../../../../../../data/actions'
import { PropertiesFormComponent } from '../component'

const isJson = (val) => {
  try {
    JSON.parse(val)
    return true
  } catch (err) {
    return false
  }
}

const errorsObj = {
  name: 'The name field is mandatory',
  type: 'The type field is mandatory',
  defaultValue: 'The defaultValue field is mandatory',
  currentAvailableValue: 'Option should be unique',
  select: 'Please add at least one select option',
  json: 'Please enter valid JSON',
}

const validateForEmptyProps = (property) => {
  const propsToBePresent = ['name', 'type', 'required']
  propsToBePresent.forEach((prop) => {
    if (!property[prop]) {
      throw new Error(prop)
    }
  })
}

const validateDefault = (property) => {
  if (property.required && !property.defaultValue) {
    throw new Error('defaultValue')
  }
}

const validateSelect = (property) => {
  if (property.type === 'SELECT' && !property.availableValues.length) {
    throw new Error('select')
  }
}

const validateJson = (property) => {
  if (
    property.type === 'JSON' &&
    property.required &&
    !isJson(property.defaultValue)
  ) {
    throw new Error('json')
  }
}

const generateNewPropertyState = (state) => {
  const newState = { ...state }
  if (newState.type !== 'SELECT') {
    newState.availableValues = []
  }
  if (!newState.required) {
    newState.defaultValue = ''
  }
  return newState
}

const PropertiesFormContainer = ({
  mode,
  property,
  onCancel,
  updateProperty,
  addProperty,
}) => {
  const baseProperty = {
    name: '',
    type: 'INT',
    required: false,
    defaultValue: '',
    availableValues: [],
  }

  const baseState = (mode === 'edit' && property) || baseProperty

  const [currentAvailableValue, setCurrentAvailableValue] = useState('')
  const [formErrors, setFormErrors] = useState({})
  const [currentProperty, setCurrentProperty] = useState(baseState)

  const validateState = (targetProperty) => {
    try {
      validateForEmptyProps(targetProperty)
      validateSelect(targetProperty)
      validateJson(targetProperty)
      validateDefault(targetProperty)
      setFormErrors({})
    } catch (err) {
      setFormErrors({ [err.message]: errorsObj[err.message] })
    }
  }

  const updateCurrentProperty = (newProperty) => {
    validateState(newProperty)
    setCurrentProperty(newProperty)
  }

  const addAvailableValue = () => {
    if (currentAvailableValue.length) {
      const currentSelectOptions = [...currentProperty.availableValues]
      if (currentSelectOptions.includes(currentAvailableValue)) {
        setFormErrors({
          currentAvailableValue: errorsObj.currentAvailableValue,
        })
        return
      }
      currentSelectOptions.push(currentAvailableValue)
      updateCurrentProperty({
        ...currentProperty,
        availableValues: currentSelectOptions,
      })
      setCurrentAvailableValue('')
    }
  }

  const deleteAvailableValue = (valueIndex) => {
    const newVals = [...currentProperty.availableValues]
    newVals.splice(valueIndex, 1)
    updateCurrentProperty({ ...currentProperty, availableValues: newVals })
  }

  const editAvailableValue = (val, index) => {
    const newVals = [...currentProperty.availableValues]
    newVals[index] = val
    updateCurrentProperty({ ...currentProperty, availableValues: newVals })
  }

  const onSubmit = () => {
    validateState()
    handleSubmit((err) => (err ? setFormErrors(err) : onCancel()))
  }

  const updateInputState = (variant, value) => {
    const newState = generateNewPropertyState({
      ...currentProperty,
      [variant]: value,
    })
    updateCurrentProperty(newState)
  }

  const handleSubmit = (cb) => {
    const fn = mode === 'edit' ? updateProperty : addProperty
    fn(currentProperty, cb)
  }

  return (
    <PropertiesFormComponent
      header={mode === 'edit' ? 'Edit property' : 'Add Property'}
      formErrors={formErrors}
      property={currentProperty}
      currentAvailableValue={currentAvailableValue}
      actions={{
        handleSubmit: onSubmit,
        handleClose: onCancel,
        updateInputState: updateInputState,
        setCurrentAvailableValue: setCurrentAvailableValue,
        addAvailableValue: addAvailableValue,
        editAvailableValue: editAvailableValue,
        deleteAvailableValue: deleteAvailableValue,
      }}
    />
  )
}

PropertiesFormContainer.propTypes = {
  mode: PropTypes.string,
  property: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    slug: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    defaultValue: PropTypes.string,
    platformServiceId: PropTypes.number,
    availableValues: PropTypes.array,
  }),
  onCancel: PropTypes.func,
  updateProperty: PropTypes.func,
  addProperty: PropTypes.func,
}

export const PropertiesForm = connect(
  ({ backOffice: { property } }) => ({
    property,
  }),
  {
    updateProperty: updateServiceProperty,
    addProperty: addServiceProperties,
  },
)(PropertiesFormContainer)
