import React from 'react'
import PropTypes from 'prop-types'
import { ErrorControl, Hint, RadioButton } from '..'

export const RadioButtonGroup = ({
  items,
  checkedValue,
  label,
  hint,
  error,
  name,
  onChange,
}) => (
  <div>
    <div>{label}</div>
    <div role="radiogroup" style={{ display: 'flex' }}>
      {items.map((item) => (
        <span key={item.value} className="wb-margin-end-xs">
          <RadioButton
            label={item.label}
            checked={item.value === checkedValue}
            name={name}
            value={item.value}
            onClick={onChange}
          />
        </span>
      ))}
    </div>
    <Hint text={hint} />
    <ErrorControl error={error} />
  </div>
)

RadioButtonGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }),
  ),
  checkedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hint: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
}
