import React from 'react'
import PropTypes from 'prop-types'
import { numberToPrice } from '../../../../../../apps/tenant/components/tenantCostOverview/utils'

export const ItemHeader = ({ provider, total }) => (
  <div className="cross-charging__provider-title">
    <div style={{ flex: 1 }}>{provider}</div>
    <div style={{ flex: 1 }}>SUM ({numberToPrice()(total)})</div>
  </div>
)

ItemHeader.propTypes = {
  provider: PropTypes.string,
  total: PropTypes.number,
}
