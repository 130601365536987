import React from 'react'
import PropTypes from 'prop-types'
import { UnstiledButton } from '../../../'

const providerColorMatcher = {
  nebula: 'var(--wb-red-70)',
  sip: 'var(--wb-blue-70)',
  hyperion: 'var(--wb-yellow-70)',
  b2xcore: 'var(--wb-green-50)',
  gbs: 'var(--wb-grey-70)',
  support: 'var(--wb-green-70)',
}

export const MenuListItem = ({
  text,
  isSelected,
  type,
  status,
  provider,
  onItemClick,
}) => {
  const onClickItem = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onItemClick()
  }

  const linkTitle = (
    <>
      {text}{' '}
      {provider ? (
        <sup style={{ color: providerColorMatcher[provider] }}>
          [{provider}]
        </sup>
      ) : (
        ''
      )}
    </>
  )
  return (
    <li
      className={[
        'nav-list__item',
        type === 'child' ? 'nav-list__child' : 'nav-list__folder',
        status ? 'nav-list__item--status nav-list__item--status-' + status : '',
        isSelected ? 'nav-list__item--active' : '',
      ].join(' ')}
    >
      {text && type === 'child' ? (
        <UnstiledButton
          className={[
            'wb-link wb-link--standalone wb-link--small wb-link--theme-dark wb-type-copy-secondary',
            isSelected ? 'nav-list__item--active' : '',
          ].join(' ')}
          title={`Status: ${status}`}
          onClick={onClickItem}
        >
          {linkTitle}
        </UnstiledButton>
      ) : (
        <p>{linkTitle}</p>
      )}
    </li>
  )
}

MenuListItem.propTypes = {
  text: PropTypes.string.isRequired,
  provider: PropTypes.string,
  isSelected: PropTypes.bool,
  type: PropTypes.string,
  status: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
}
