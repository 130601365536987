import React from 'react'
import PropTypes from 'prop-types'

export const TenantDashboardSection = ({ values, title, children }) => (
  <div className="tenant-body__block">
    <label className="tenant-body__block-label">{title}</label>
    <div className="tenant-body__block-inner-grid">
      {values.map((column) => (
        <div key={column[0][0]}>
          {column.map(([key, value]) => (
            <div className="tenant-body__block" key={key}>
              <label className="tenant-body__block-label warning__label">
                {key}
              </label>
              <p className="tenant-body__block-value">{value}</p>
            </div>
          ))}
        </div>
      ))}
      {children}
    </div>
  </div>
)

TenantDashboardSection.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      ),
    ),
  ),
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
