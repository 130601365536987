import React from 'react'
import PropTypes from 'prop-types'

export const AccordionSection = ({ children }) => (
  <wb-accordion-section>{children}</wb-accordion-section>
)

AccordionSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
