import {
  getProvider,
  getProviders,
  updateProvider,
  createProvider,
  deleteProvider,
} from '../../../../../http'
import { postNotification } from '../../..'
import { setProviders, setProvider, setModal } from '../shared'
import {
  providersSorter,
  providersPermissinoMapper,
  applyProviderPermissions,
} from '../utils'

export const listProviders = () => async (dispatch, getState) => {
  try {
    const providers = await getProviders()
    const { sso } = getState()

    providers.sort(providersSorter)
    dispatch(
      setProviders(
        providersPermissinoMapper(providers, sso.roles, sso.providerRoles),
      ),
    )
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while retrieving providers - ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const addProvider = (providerObj) => async (dispatch) => {
  try {
    await createProvider(providerObj)
    dispatch(listProviders())
    dispatch(setModal({}))
    dispatch(
      postNotification({
        message: `Provider was created successfully!`,
      }),
    )
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while adding provider - ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const updateCurrentProvider = (providerObj) => async (dispatch) => {
  try {
    await updateProvider(providerObj)
    dispatch(listProviders())
    dispatch(retrieveProvider(providerObj.slug))
    dispatch(
      postNotification({
        message: `Provider was updated successfully!`,
      }),
    )
    dispatch(setModal({}))
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while updating provider - ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const removeProvider = (providerSlug) => async (dispatch) => {
  try {
    await deleteProvider(providerSlug)
    dispatch(
      postNotification({
        message: `Provider was removed successfully!`,
      }),
    )
    dispatch(setModal({}))
    dispatch(listProviders())
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while removing provider - ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const retrieveProvider =
  (providerSlug) => async (dispatch, getState) => {
    try {
      const provider = await getProvider(providerSlug)
      const { sso } = getState()
      dispatch(
        setProvider(
          applyProviderPermissions(provider, sso.roles, sso.providerRoles),
        ),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while retrieving provider - ${err.message}`,
          mode: 'error',
        }),
      )
    }
  }

export const clearProvider = () => (dispatch) => dispatch(setProvider(null))
