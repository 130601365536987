import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  attachUsersToProvider,
  attachAdminUsersToProvider,
} from '../../../../../../../../../data/actions'
import { UserFormComponent } from '../component'

const UserFormContainer = ({
  header,
  onCancel,
  provider,
  providerUsers,
  addUsersToProvider,
  addAdminUsersToProvider,
}) => {
  const [users, setUsers] = useState([])
  const [addAsAdmin, setAddAsAdmin] = useState(false)

  const filterOutputFn = (userItem) =>
    !providerUsers
      .map(({ user }) => user.daimlerId)
      .includes(userItem.daimlerId)

  const onSubmit = () => {
    addAsAdmin
      ? addAdminUsersToProvider(
          provider.slug,
          users.map(({ value }) => value),
        )
      : addUsersToProvider(
          provider.slug,
          users.map(({ value }) => value),
        )
  }
  return (
    <UserFormComponent
      header={header}
      users={users}
      provider={provider}
      addAsAdmin={addAsAdmin}
      actions={{ setAddAsAdmin, onCancel, onSubmit, filterOutputFn, setUsers }}
    />
  )
}

UserFormContainer.propTypes = {
  header: PropTypes.string,
  provider: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
  }),
  providerUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  onCancel: PropTypes.func,
  addUsersToProvider: PropTypes.func,
  addAdminUsersToProvider: PropTypes.func,
}

export const UserForm = connect(
  ({ backOffice: { provider, providerUsers } }) => ({
    provider,
    providerUsers,
  }),
  {
    addUsersToProvider: attachUsersToProvider,
    addAdminUsersToProvider: attachAdminUsersToProvider,
  },
)(UserFormContainer)
