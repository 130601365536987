import React from 'react'
import PropTypes from 'prop-types'

export const ServiceItemLabel = ({ service }) => (
  <li className="packages-editor__list-item">
    <div>{service.name}</div>
    <div className="packages-editor__list-item-description">{service.slug}</div>
    <div className="packages-editor__list-item-description">
      {service.description}
    </div>
  </li>
)

ServiceItemLabel.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.string,
    checked: PropTypes.bool,
  }),
}
