import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '../../../../../../../common'

export const PriceForm = ({ disabled, priceControls, onChange }) => (
  <div className="price-form">
    <div className="wb-margin-vertical-xxs" style={{ maxWidth: '195px' }}>
      <Input
        label="Year"
        value={priceControls.year.value}
        type="number"
        disabled={disabled}
        error={
          priceControls.year.touched &&
          !priceControls.year.valid &&
          'Please provide valid unique year'
        }
        onChange={(e) => onChange('year', e.target.value)}
      />
    </div>
    <div className="wb-margin-vertical-xxs wb-margin-start-xxs">
      <Input
        label="Monthly Price:"
        value={priceControls.monthlyPrice.value}
        type="number"
        error={
          priceControls.monthlyPrice.touched &&
          !priceControls.monthlyPrice.valid &&
          'Monthly price should be numeric'
        }
        onChange={(e) => onChange('monthlyPrice', e.target.value)}
      />
    </div>
  </div>
)
const controlProp = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valid: PropTypes.bool,
  touched: PropTypes.bool,
  validationRules: PropTypes.object,
})

PriceForm.propTypes = {
  disabled: PropTypes.bool,
  priceControls: PropTypes.shape({
    year: controlProp,
    monthlyPrice: controlProp,
  }),
  onChange: PropTypes.func,
}
