import React from 'react'
import PropTypes from 'prop-types'

export const ModalFooter = ({ children, style, className }) => {
  return (
    <wb-modal-footer style={style} className={className}>
      {children}
    </wb-modal-footer>
  )
}

ModalFooter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  style: PropTypes.object,
  className: PropTypes.string,
}
