import {
  SET_ROLES,
  RECEIVE_ROLES,
  RECEIVE_WHITELIST,
  SET_ALL_USERS,
} from '../../actionsTypes'
import { fetchUserRoles, fetchAllUsers } from '../../../http'
import { postNotification } from '../'

export const generateUserInRolesObject = (userId, roles) =>
  roles.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: [userId.toUpperCase()],
    }),
    {},
  )

export const requestRoles = () => async (dispatch) => {
  try {
    const { userId, roles } = await fetchUserRoles()
    const userInRoles = generateUserInRolesObject(userId, roles)
    dispatch(receiveRoles(roles))
    dispatch(
      receiveWhitelist(
        userInRoles,
        roles.includes('tenantadmin'),
        roles.includes('admin'),
      ),
    )
  } catch (err) {
    dispatch(postNotification({ message: err.message, mode: 'error' }))
  }
}

export const getAllUsers = () => async (dispatch) => {
  try {
    const users = await fetchAllUsers()
    dispatch(setUsers(users))
  } catch (err) {
    dispatch(postNotification({ message: err.message, mode: 'error' }))
  }
}

export const fetchWhitelist = () => (dispatch) => dispatch(requestRoles())

export const fetchWhitelistIfNeeded = () => (dispatch, getState) =>
  !getState().tenants.whitelist ? dispatch(fetchWhitelist()) : undefined

export const setRoles = (roles) => ({
  type: SET_ROLES,
  payload: { roles },
})

export const receiveRoles = (roles) => ({
  type: RECEIVE_ROLES,
  payload: {
    roles,
  },
})

export const setUsers = (users) => ({
  type: SET_ALL_USERS,
  payload: {
    users,
  },
})

export const receiveWhitelist = (whitelist, isWhitelisted, isAlmighty) => ({
  type: RECEIVE_WHITELIST,
  payload: {
    whitelist,
    isWhitelisted,
    isAlmighty,
  },
})
