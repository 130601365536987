import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  setModal,
  detachUsersFromProvider,
  attachAdminUsersToProvider,
} from '../../../../../../../../../data/actions'
import { UsersTable } from '../components/UsersTable'

const UsersListContainer = ({
  providerUsers,
  provider,
  dispatchModal,
  removeUsers,
  addAdminUsers,
}) => (
  <UsersTable
    data={providerUsers}
    provider={provider}
    dispatchModal={dispatchModal}
    addAdminUsers={addAdminUsers}
    removeProviderUsers={removeUsers}
  />
)

UsersListContainer.propTypes = {
  providerUsers: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string,
      user: PropTypes.shape({
        daimlerId: PropTypes.string,
        mbioId: PropTypes.string,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    }),
  ),
  provider: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
    namestring: PropTypes.string,
    description: PropTypes.string,
    createDate: PropTypes.string,
    updateDate: PropTypes.string,
  }),
  dispatchModal: PropTypes.func,
  removeUsers: PropTypes.func,
  addAdminUsers: PropTypes.func,
}

export const UsersList = connect(
  ({ backOffice: { provider, providerUsers } }) => ({
    provider,
    providerUsers,
  }),
  {
    dispatchModal: setModal,
    removeUsers: detachUsersFromProvider,
    addAdminUsers: attachAdminUsersToProvider,
  },
)(UsersListContainer)
