import React from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  Button,
  TextArea,
  ModalContent,
  ModalFooter,
  Tooltip,
  Select,
} from '../../../../../../../../../../../common'

const NumberInput = ({ value, error, onChange }) => (
  <Input
    label="New Value"
    type="number"
    value={value}
    error={error}
    className="service-property-form__field"
    onChange={(e) => onChange(e.target.value)}
  />
)
const StringInput = ({ value, error, onChange }) => (
  <Input
    label="New Value"
    type="text"
    value={value}
    error={error}
    className="service-property-form__field"
    onChange={(e) => onChange(e.target.value)}
  />
)
const JsonInput = ({ value, error, onChange }) => (
  <TextArea
    label="Service"
    value={value}
    error={error}
    className="service-property-form__field"
    onChange={(e) => onChange(e.target.value)}
  />
)
const SelectInput = ({ value, error, onChange, options }) => (
  <Select
    label="New Value"
    placeholder="Please choose"
    value={value}
    options={options}
    error={error}
    onChange={(e) => onChange(e.target.value)}
  />
)
const inputTypes = {
  INT: NumberInput,
  STRING: StringInput,
  JSON: JsonInput,
  SELECT: SelectInput,
}

export const BookingServicePropertyFormComponent = ({
  property,
  formErrors,
  availableValues = [],
  currentValue,
  handleSubmit,
  setCurrentValue,
  handleClose,
}) => (
  <ModalContent className="service-property-form">
    <h3 className="service-property-form__header wb-type-heading-xs wb-margin-bottom-xs">
      Edit Property
      <Tooltip
        message="Please enter new value for selected property"
        className="wb-margin-start-xs"
        position="right"
        icon="bds/question-mark/16"
      />
    </h3>
    <div className="service-property-form__field wb-margin-bottom-xs">
      <Input
        label="Current Value"
        value={property.value}
        onChange={console.log}
        disabled
      />
    </div>
    <div className="service-property-form__field wb-margin-bottom-xs">
      {inputTypes[property.type]?.({
        value: currentValue,
        error: formErrors.currentValue,
        onChange: setCurrentValue,
        options: availableValues?.map((val) => ({ name: val, slug: val })),
      })}
    </div>
    <ModalFooter>
      <Button variant="secondary" size="small" onClick={handleClose}>
        Cancel
      </Button>
      <Button onClick={handleSubmit} variant="primary" size="small">
        Submit
      </Button>
    </ModalFooter>
  </ModalContent>
)

const inputProps = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  onChange: PropTypes.func,
}

NumberInput.propTypes = inputProps
StringInput.propTypes = inputProps
JsonInput.propTypes = inputProps
SelectInput.propTypes = {
  ...inputProps,
  options: PropTypes.arrayOf(PropTypes.string),
}

BookingServicePropertyFormComponent.propTypes = {
  property: PropTypes.shape({
    defaultUneditedValue: PropTypes.bool,
    name: PropTypes.string,
    platformServicePropertyId: PropTypes.number,
    required: PropTypes.bool,
    serviceName: PropTypes.string,
    serviceSlug: PropTypes.string,
    slug: PropTypes.string,
    type: PropTypes.oneOf(['STRING', 'INT', 'JSON', 'SELECT']),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  formErrors: PropTypes.object,
  availableValues: PropTypes.arrayOf(PropTypes.string),
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSubmit: PropTypes.func,
  setCurrentValue: PropTypes.func,
  handleClose: PropTypes.func,
}
