import { tdmsClient } from '../apiClient'
import { BASE_URL, rolesUrl, csrfRef } from '../constants'
import { _fetch } from '../customFetch'

export const fetchCsrfToken = async () => {
  const response = await _fetch(`${BASE_URL}/csrf`)
  return response.json()
}

export const fetchUserData = async () => {
  const response = await _fetch(`${rolesUrl}/me`)
  return response.json()
}

export const getProviderUserRoles = async () => {
  const response = await _fetch(`${rolesUrl}/me/providers`)
  return response.json()
}

export const fetchLoggedInUserData = async () => {
  const promises = await Promise.all([fetchUserData(), fetchCsrfToken()])
  const response = {
    ssoData: await promises[0],
    csrfData: await promises[1],
  }
  tdmsClient.initialize(response.csrfData)
  csrfRef.current = response.csrfData
  return response.ssoData
}
