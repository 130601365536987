import React from 'react'
import PropTypes from 'prop-types'
import { ProcessingIndicator } from '../../../../../../apps/tenant/components/tenantBookingsDialog/components/BookingItem/components/ProcessingIndicator'
import { Button, ModalContent, ModalFooter } from '../../../../../../common'

export const CustomPromptComponent = ({
  header,
  okText = 'Approve',
  waitingMode,
  onApprove,
  onCancel,
}) => {
  return (
    <ModalContent headline={header}>
      <ModalFooter>
        <Button variant="secondary" size="medium" onClick={onCancel}>
          {waitingMode ? 'Close' : 'Cancel'}
        </Button>
        <Button
          variant="primary"
          size="medium"
          onClick={onApprove}
          disabled={waitingMode}
        >
          {waitingMode ? <ProcessingIndicator withText /> : okText}
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}

CustomPromptComponent.propTypes = {
  header: PropTypes.string,
  okText: PropTypes.string,
  waitingMode: PropTypes.bool,
  onApprove: PropTypes.func,
  onCancel: PropTypes.func,
}
