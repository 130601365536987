import { SET_APIKEYS } from '../../actionsTypes'
import { listAllApiKeys, deleteApiKey, createApiKey } from '../../../http'
import { postNotification } from '../ui'

export const setApiKeys = (keys) => ({
  type: SET_APIKEYS,
  payload: keys,
})

export const fetchApiKeys = () => async (dispatch) => {
  try {
    const response = await listAllApiKeys()
    dispatch(setApiKeys(response))
  } catch (err) {
    dispatch(postNotification({ message: err.message, mode: 'error' }))
  }
}

export const postApiKey = (apiKeyFormData, cb) => async (dispatch) => {
  try {
    const token = await createApiKey(apiKeyFormData)
    cb?.(token)
    dispatch(fetchApiKeys())
    dispatch(postNotification({ message: 'Success' }))
  } catch (err) {
    dispatch(postNotification({ message: err.message, mode: 'error' }))
  }
}

export const removeApiKey = (apiKeyId) => async (dispatch) => {
  try {
    await deleteApiKey(apiKeyId)
    dispatch(fetchApiKeys())
    dispatch(postNotification({ message: 'Api key was removed successfully' }))
  } catch (err) {
    dispatch(postNotification({ message: err.message, mode: 'error' }))
  }
}
