import React from 'react'
import PropTypes from 'prop-types'

export const MemberPermissions = ({ permissions }) => (
  <div className="members-content__item--scroll-container">
    <p className="wb-padding-start-xxs">
      <span className="members-content__item-label">Member permissions:</span>
    </p>
    <div>
      {permissions ? (
        Object.keys(permissions).map((permission) => (
          <div
            className="members-content__item-label members-content__item--black wb-margin-start-xxs"
            key={permission}
          >
            {permission}
            <ul className="wb-list wb-list--offset-m wb-list--unordered">
              {permissions[permission].map((perm) => (
                <li className="wb-list__item" key={perm}>
                  {perm}
                </li>
              ))}
            </ul>
          </div>
        ))
      ) : (
        <span className="members-content__item-label wb-margin-start-xxs">
          N/A
        </span>
      )}
    </div>
  </div>
)

MemberPermissions.propTypes = {
  permissions: PropTypes.object,
}
