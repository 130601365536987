import React from 'react'
import PropTypes from 'prop-types'
import { PermissionsList } from '../PermissionsList'
import { PropertiesList } from '../PropertiesList'

export const ServiceDetails = ({ service }) => (
  <div className="package-details packages-editor__list">
    <dl>
      <dt>Name: </dt>
      <dd>{service.name}</dd>
      <dt>Provider: </dt>
      <dd>{service.providerName}</dd>
      <dt>Slug: </dt>
      <dd>{service.slug}</dd>
      <dt>Description: </dt>
      <dd>{service.description}</dd>
    </dl>
    <PermissionsList />
    <PropertiesList />
  </div>
)

ServiceDetails.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
    name: PropTypes.string,
    providerName: PropTypes.string,
    description: PropTypes.string,
  }),
}
