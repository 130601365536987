import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '../../../../../../../../../../../common'

export const PermissionsSelectorComponent = ({
  withHelp,
  withEmptySelector,
  disabled,
  currentPermission,
  onSelect,
  currentServicePermissions,
}) => (
  <div className="permissions-selector">
    <wb-select-control compact>
      <wb-select>
        <label htmlFor="permissionSelector">Choose permission</label>
        <select
          disabled={disabled}
          defaultValue={currentPermission?.id}
          id="permissionSelector"
          onChange={(e) =>
            onSelect(
              currentServicePermissions.find(
                (perm) => perm.id === parseInt(e.target.value),
              ),
            )
          }
        >
          {withEmptySelector && <option value="">Choose permission</option>}
          {currentServicePermissions.map((permission) => (
            <option value={permission.id} key={permission.id}>
              {permission.name}
            </option>
          ))}
        </select>
      </wb-select>
    </wb-select-control>
    {withHelp && (
      <Tooltip
        className="wb-round-button wb-round-button--solid-level-0 wb-round-button--medium wb-margin-start-xs"
        message="Permission will be applied to all selected Members"
        position="top"
        icon="question-mark-outline"
      />
    )}
  </div>
)

const permissionProp = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  providerOnly: PropTypes.bool,
  slug: PropTypes.string,
})

PermissionsSelectorComponent.propTypes = {
  withHelp: PropTypes.bool,
  withEmptySelector: PropTypes.bool,
  disabled: PropTypes.bool,
  currentPermission: permissionProp,
  onSelect: PropTypes.func,
  currentServicePermissions: PropTypes.arrayOf(permissionProp),
}
