import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { ServicesReportByMonth } from '../ServicesReportByMonth/index'

const startAndEnd = (startDate, endDate) => {
  const [startYearString, startMonthString] = startDate.split('-')
  const [endYearString, endMonthString] = endDate.split('-')
  const startYear = parseInt(startYearString) - 2000
  const startMonth = parseInt(startMonthString)
  const startMonthAsKey = `${startYear}-${startMonth}`
  const endYear = !!endDate && parseInt(endYearString) - 2000
  const endMonth = !!endDate && parseInt(endMonthString)
  const endMonthAsKey = !!endDate && `${endYear}-${endMonth}`
  return {
    startYear,
    startMonth,
    endYear,
    endMonth,
    startMonthAsKey,
    endMonthAsKey,
  }
}

const fillDataForYear = (target, year, startMonth, endMonth) => {
  for (let i = startMonth; i <= endMonth; i++) {
    const key = `${year}-${i}`
    target[key] = {
      key: `column_${year}-${i}`,
      label: key,
      onboarding: {},
      offboarding: {},
    }
  }
}

const initData = (startDate, endDate) => {
  const { startYear, startMonth, endYear, endMonth } = startAndEnd(
    startDate,
    endDate,
  )
  const allInOneYear = startYear === endYear
  const data = {}
  fillDataForYear(data, startYear, startMonth, allInOneYear ? endMonth : 12)
  for (let i = startYear + 1; i < endYear; i++) {
    fillDataForYear(data, i, 1, 12)
  }
  fillDataForYear(data, endYear, 1, endMonth)
  return data
}

const componentDataFromBookings = (bookings, startDate, endDate) => {
  const data = initData(startDate, endDate)
  for (const booking of bookings) {
    const acronym = booking.tenantAcronym
    const { startMonthAsKey, endMonthAsKey } = startAndEnd(
      booking.startDate,
      booking.endDate,
    )
    if (data[startMonthAsKey]) {
      const entry = data[startMonthAsKey].onboarding
      if (!entry[acronym]) {
        entry[acronym] = []
      }
      entry[acronym].push({
        package: booking.packageSlug,
        comment: booking.comment,
      })
    }
    if (data[endMonthAsKey]) {
      const entry = data[endMonthAsKey].offboarding
      if (!entry[acronym]) {
        entry[acronym] = []
      }
      entry[acronym].push({
        package: booking.packageSlug,
        comment: booking.comment,
      })
    }
  }
  return data
}

export const ServicesReportList = ({
  startDate,
  endDate,
  bookingsFromDatabase,
}) => {
  const componentData = useMemo(
    () => componentDataFromBookings(bookingsFromDatabase, startDate, endDate),
    [bookingsFromDatabase, startDate, endDate],
  )
  if (!startDate || !endDate) {
    return (
      <div className="timereport-msg">
        Please provide start and end datetime.
      </div>
    )
  }

  return (
    <div className="report admin-panel-grid">
      {Object.values(componentData).map((component) => {
        return <ServicesReportByMonth key={component.key} {...component} />
      })}
    </div>
  )
}

ServicesReportList.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  bookingsFromDatabase: PropTypes.array,
}
