import React from 'react'
import PropTypes from 'prop-types'
import { ListGroupItem } from '../../../../../common'

export const FilterItems = ({ filters, filterCategories, onChange }) => (
  <div className="wb-list-group">
    {Object.keys(filters).map((provider) => {
      const { label, value, tenants } = filters[provider]
      return (
        <ListGroupItem
          key={value}
          checked={filterCategories.includes(provider)}
          onChange={() => onChange(value)}
          label={`${label} (${tenants.length})`}
        />
      )
    })}
  </div>
)

FilterItems.propTypes = {
  filters: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      tenants: PropTypes.array,
    }),
  ),
  filterCategories: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
}
