import {
  SET_CROSS_CHARGING_OVERVIEW,
  SET_CURRENT_TENANT_CROSS_CHARGING_OVERVIEW,
  SET_PACKAGE_PERMISSIONS,
} from '../../actionsTypes'

const initialState = {
  crossChargingOverview: null,
  currentTenantCrossCharging: [],
  packagePermissions: {},
}

export const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CROSS_CHARGING_OVERVIEW:
      return {
        ...state,
        crossChargingOverview: action.payload,
      }
    case SET_CURRENT_TENANT_CROSS_CHARGING_OVERVIEW:
      return {
        ...state,
        currentTenantCrossCharging: action.payload,
      }
    case SET_PACKAGE_PERMISSIONS: {
      const { packageSlug, permissions } = action.payload
      return {
        ...state,
        packagePermissions: {
          ...state.packagePermissions,
          [packageSlug]: permissions,
        },
      }
    }
    default:
      return state
  }
}
