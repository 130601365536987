import React from 'react'
import PropTypes from 'prop-types'

export const RadioButton = ({ label, value, checked, name, onClick }) => (
  <wb-radio-control class="wb-margin-bottom-xs">
    <label>
      <input
        type="radio"
        name={name}
        checked={checked}
        value={value}
        onChange={onClick}
      />
      {label}
    </label>
  </wb-radio-control>
)

RadioButton.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onClick: PropTypes.func,
}
