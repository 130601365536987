import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import validate from '../../../../../../../util/helpers/validate'
import { BookingFormComponent } from '../component'

const generatePackagesListForBookingForm = (packages, roles, providerRoles) =>
  roles.includes('admin')
    ? packages
    : packages.filter(({ providerSlug }) => providerRoles[providerSlug])

const BookingFormContainer = ({
  header,
  mode,
  packages,
  booking,
  onApprove,
  onCancel,
}) => {
  const baseBooking = {
    packageSlug: booking ? booking.packageSlug : '',
    priceVariant: booking ? booking.variantSlug : '',
    comment: booking ? booking.comment : '',
    startDate: booking ? booking.startDate : '',
    endDate: booking ? booking.endDate : '',
  }
  const controls = {
    packageSlug: {
      value: baseBooking.packageSlug,
      validationRules: {
        minLength: 1,
      },
      disabled: Boolean(booking),
      error: 'Please choose package',
      valid: Boolean(baseBooking.packageSlug),
      touched: Boolean(baseBooking.packageSlug),
    },
    priceVariant: {
      value: baseBooking.priceVariant,
      validationRules: {
        minLength: 1,
      },
      error: 'Please choose price variant',
      disabled: Boolean(booking),
      valid: Boolean(baseBooking.priceVariant),
      touched: Boolean(baseBooking.priceVariant),
    },
    comment: {
      value: baseBooking.comment,
      error: 'Please enter valid comment (minLength: 2)',
      validationRules: {},
      valid: true,
      touched: true,
    },
    startDate: {
      value: baseBooking.startDate,
      error: 'Please enter valid start date',
      validationRules: {
        minLength: 2,
      },
      valid: Boolean(baseBooking.startDate),
      touched: Boolean(baseBooking.startDate),
    },
    endDate: {
      value: baseBooking.endDate,
      error: 'Please enter valid end date',
      validationRules: {},
      valid: true,
      touched: true,
    },
  }

  const [state, setState] = useState(controls)

  const handleSubmit = () => {
    const bookingData = {
      packageSlug: state.packageSlug.value,
      priceVariant: state.priceVariant.value,
      comment: state.comment.value,
      startDate: state.startDate.value,
      endDate: state.endDate.value,
    }
    if (mode === 'add') {
      onApprove(state.priceVariant.value, bookingData)
    } else {
      onApprove({ ...booking, ...bookingData })
    }
  }

  const updateInputState = (control, value) => {
    setState({
      ...state,
      [control]: {
        ...state[control],
        value,
        touched: true,
        valid: validate(value, state[control].validationRules),
      },
    })
  }

  return (
    <BookingFormComponent
      header={header}
      mode={mode}
      packages={packages}
      controls={state}
      updateInputState={updateInputState}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    />
  )
}

BookingFormContainer.propTypes = {
  header: PropTypes.string,
  mode: PropTypes.string,
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  booking: PropTypes.shape({
    packageSlug: PropTypes.string,
    variantSlug: PropTypes.string,
    comment: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  onApprove: PropTypes.func,
  onCancel: PropTypes.func,
}

export const BookingForm = connect(
  ({ backOffice: { packages }, sso: { roles, providerRoles } }) => ({
    packages: generatePackagesListForBookingForm(
      packages.filter(({ archived }) => !archived),
      roles,
      providerRoles,
    ),
  }),
)(BookingFormContainer)
