import React from 'react'
import PropTypes from 'prop-types'
import { MemberItem } from '../../components/MemberItem'

export const MembersList = ({
  loggedInUserDaimlerId,
  editMode,
  members,
  checkSelected,
  onSelectMember,
}) =>
  members.length ? (
    members.map((member) => (
      <MemberItem
        key={member.daimlerId}
        member={member}
        editMode={editMode}
        loggedInUserDaimlerId={loggedInUserDaimlerId}
        disabled={member.waitingMode}
        checked={checkSelected(member.daimlerId)}
        onSelectMember={onSelectMember}
      />
    ))
  ) : (
    <div>No Members available</div>
  )

MembersList.propTypes = {
  loggedInUserDaimlerId: PropTypes.string,
  editMode: PropTypes.bool,
  members: PropTypes.array,
  checkSelected: PropTypes.func,
  onSelectMember: PropTypes.func,
}
