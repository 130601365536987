import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../../../../../../../common'

export const Header = ({
  handleAddMember,
  handleEditSelected,
  disabledAdd,
  disabledEdit,
}) => (
  <div className="booking-permissions__head wb-margin-bottom-xs">
    <Button
      variant="secondary"
      size="medium"
      disabled={disabledEdit}
      onClick={handleEditSelected}
    >
      Edit selected
    </Button>
    <Button
      variant="primary"
      size="medium"
      disabled={disabledAdd}
      onClick={handleAddMember}
    >
      Add member
    </Button>
  </div>
)

Header.propTypes = {
  handleAddMember: PropTypes.func,
  handleEditSelected: PropTypes.func,
  disabledAdd: PropTypes.bool,
  disabledEdit: PropTypes.bool,
}
