export const generatePackagesReport = (
  crossChargingOverview,
  allTenants,
  bookingsFromBackend,
) => {
  if (!crossChargingOverview) {
    return {}
  }
  const providerReport = initReportByProvider(crossChargingOverview)
  const totalProviderTenantsByStatus = {}
  for (const provider in providerReport) {
    const packages = Object.keys(providerReport[provider])

    bookingsFromBackend.forEach((booking) => {
      const currentPackage = providerReport[provider][booking.packageName]
      const currentTenant = allTenants[booking.tenantAcronym]

      if (packages.includes(booking.packageName)) {
        addBookingToPackage(currentPackage, booking)
        currentPackage.tenants = currentPackage.tenants || {}
        addTenantToPackage(currentPackage, currentTenant)
        addTenantToProviderByStatusReport(
          totalProviderTenantsByStatus,
          provider,
          currentTenant,
        )
      }
    })
  }
  return { providerReport, totalProviderTenantsByStatus }
}

export const initReportByProvider = (crossChargingOverview) => {
  const initReport = {}
  crossChargingOverview.forEach((pkg) => {
    initReport[pkg.providerSlug] = initReport[pkg.providerSlug] || {}
    initReport[pkg.providerSlug][pkg.packageName] = { ...pkg }
  })
  return initReport
}

export const addBookingToPackage = (pkg, booking) => {
  pkg.bookings = pkg.bookings || []
  pkg.bookings.push(booking)
}

export const addTenantToPackage = (pkg, tenant) => {
  pkg.tenants[tenant.status] = pkg.tenants[tenant.status] || []
  pkg.tenants[tenant.status].push(tenant)
}

export const addTenantToProviderByStatusReport = (
  totalProviderTenantsByStatus,
  provider,
  tenant,
) => {
  totalProviderTenantsByStatus[provider] =
    totalProviderTenantsByStatus[provider] || {}
  totalProviderTenantsByStatus[provider][tenant.status] =
    totalProviderTenantsByStatus[provider][tenant.status] || []
  totalProviderTenantsByStatus[provider][tenant.status].push(tenant)
}
