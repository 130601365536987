import React from 'react'
import PropTypes from 'prop-types'
import { PermissionItem } from '../PermissionItem'
import { Button } from '../../../../../../common'

export const PermissionsListComponent = ({
  readOnly,
  handleAddPermission,
  servicePermissions,
  currentPermission,
  onClickDelete,
  onClickUpdate,
}) => (
  <div className="permissions-list">
    <div className="permissions-list__header">
      <h2>Permissions List</h2>
      <Button
        onClick={handleAddPermission}
        disabled={readOnly}
        variant="secondary"
        size="small"
        className="packages-editor__button"
      >
        Add permission
      </Button>
    </div>
    <ul className="packages-editor__list">
      {servicePermissions.length ? (
        servicePermissions.map((permission) => (
          <PermissionItem
            key={permission.id}
            permission={permission}
            readOnly={readOnly}
            currentPermission={currentPermission}
            setPermission={onClickUpdate}
            deleteServicePermission={onClickDelete}
          />
        ))
      ) : (
        <div>No Permissions for given service</div>
      )}
    </ul>
  </div>
)

const permissionPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  slug: PropTypes.string,
  readOnly: PropTypes.bool,
  description: PropTypes.string,
  providerOnly: PropTypes.bool,
})
PermissionsListComponent.propTypes = {
  readOnly: PropTypes.bool,
  handleAddPermission: PropTypes.func,
  servicePermissions: PropTypes.arrayOf(permissionPropType),
  currentPermission: permissionPropType,
  onClickDelete: PropTypes.func,
  onClickUpdate: PropTypes.func,
}
