import React from 'react'
import PropTypes from 'prop-types'
import { StatusReport, ReportsAccordion } from '../utils'
import { generatePackagesReport } from './helpers'
import { tenantPropType } from '../../../apps/nav/container/utils'

export const TenantsStatusReport = ({
  allTenants,
  bookingsFromBackend,
  crossChargingOverview,
}) => {
  const { reportByProvider, totalProviderTenantsByStatus } =
    generatePackagesReport(
      crossChargingOverview,
      allTenants,
      bookingsFromBackend,
    )

  const reports = reportByProvider ? Object.keys(reportByProvider) : []
  return reports.map((provider) => (
    <ReportsAccordion title={`Status Dashboard ${provider}`} key={provider}>
      {totalProviderTenantsByStatus[provider]?.active && (
        <StatusReport
          title={`Total bookings for ${provider}`}
          status={totalProviderTenantsByStatus[provider]}
          isTotal={true}
        />
      )}
      {Object.keys(reportByProvider[provider]).map(
        (pkg) =>
          reportByProvider[provider][pkg].tenants && (
            <StatusReport
              key={pkg}
              title={pkg}
              status={reportByProvider[provider][pkg].tenants}
              isTotal={false}
            />
          ),
      )}
    </ReportsAccordion>
  ))
}

TenantsStatusReport.propTypes = {
  allTenants: PropTypes.objectOf(PropTypes.shape(tenantPropType)),
  bookingsFromBackend: PropTypes.array,
  crossChargingOverview: PropTypes.arrayOf(
    PropTypes.shape({
      packageName: PropTypes.string,
      priceVariantName: PropTypes.string,
      providerName: PropTypes.string,
      providerSlug: PropTypes.string,
      services: PropTypes.arrayOf(
        PropTypes.shape({
          providerName: PropTypes.string,
          providerSlug: PropTypes.string,
          serviceName: PropTypes.string,
        }),
      ),
      yearlyPrices: PropTypes.objectOf(
        PropTypes.shape({
          estimatedValue: PropTypes.bool,
          tenantFundingShare: PropTypes.objectOf(PropTypes.number),
          yearlyPrice: PropTypes.number,
        }),
      ),
    }),
  ),
}
