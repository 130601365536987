import { tdmsClient } from '../apiClient'

export const getProviders = async () => tdmsClient.client.providerMethods.list()

export const getAllProviders = async () =>
  tdmsClient.client.providerMethods.listAll()

export const getProvider = async (slug) =>
  tdmsClient.client.providerMethods.getBySlug(slug)

export const createProvider = async (providersObj) =>
  tdmsClient.client.providerMethods.create(providersObj)

export const updateProvider = async (providersObj) =>
  tdmsClient.client.providerMethods.update(providersObj.slug, providersObj)

export const deleteProvider = async (providerSlug) =>
  tdmsClient.client.providerMethods.delete(providerSlug)

export const getProviderUsers = async (providerSlug) =>
  tdmsClient.client.providerMethods.listUsers(providerSlug)

export const addUsersToProvider = async (providerSlug, userDaimlerIds) =>
  tdmsClient.client.providerMethods.addUsers(providerSlug, userDaimlerIds)

export const removeUsersFromProvider = async (providerSlug, userDaimlerIds) =>
  tdmsClient.client.providerMethods.removeUsers(providerSlug, userDaimlerIds)

export const addAdminUsersToProvider = async (providerSlug, userDaimlerIds) =>
  tdmsClient.client.providerMethods.addAdminUsers(providerSlug, userDaimlerIds)
