import React from 'react'
import UserVerificationModal from './components/userVerificationModal/UserVerificationModal'
const AppsContainer = React.lazy(() => import('./components/apps'))
const DocsContainer = React.lazy(() => import('./components/docs'))
const AdminsContainer = React.lazy(() =>
  import('./components/admins/container'),
)
const BackOfficeContainer = React.lazy(() => import('./components/backOffice'))
const MemberContainer = React.lazy(() => import('./components/member/Member'))

export const routes = [
  {
    path: '/',
    key: 'home',
    component: UserVerificationModal,
  },
  {
    path: '/apps/:acronym?/:dialog?',
    key: 'apps',
    component: AppsContainer,
  },
  {
    path: '/sla/:slug?',
    key: 'sla',
    component: DocsContainer,
  },
  {
    path: '/member/:tab/:param?',
    key: 'members',
    component: MemberContainer,
  },
  {
    path: '/admins',
    key: 'admin',
    component: AdminsContainer,
  },
  {
    path: '/backoffice/:tab/:param?',
    key: 'backOffice',
    component: BackOfficeContainer,
  },
  {
    path: '/',
    key: 'root',
    exact: true,
    component: AppsContainer,
  },
]
