import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  retrieveProvider,
  listProviderUsers,
  clearProvider,
} from '../../../../../../../data/actions'
import { ProviderPageComponent } from '../component'

const ProviderPageContainer = ({
  getProvider,
  getProviderUsers,
  cleanProviderData,
}) => {
  const history = useHistory()
  const { param: providerSlug } = useParams()

  useEffect(() => {
    getProvider(providerSlug)
    getProviderUsers(providerSlug)
    return () => cleanProviderData()
  }, [])

  const goBack = () => history.replace(`/backoffice/providers`)

  return <ProviderPageComponent goBack={goBack} />
}

ProviderPageContainer.propTypes = {
  getProvider: PropTypes.func,
  getProviderUsers: PropTypes.func,
  cleanProviderData: PropTypes.func,
}

export const ProviderPage = connect(undefined, {
  getProvider: retrieveProvider,
  getProviderUsers: listProviderUsers,
  cleanProviderData: clearProvider,
})(ProviderPageContainer)
