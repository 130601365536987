import React from 'react'
import PropTypes from 'prop-types'
import { CheckBox } from '../../../../../../../../common'
import { ServiceItemLabel } from '../ServiceItemLabel'

export const ServiceItem = ({ readOnly, service, handleSubmit }) => (
  <CheckBox
    disabled={readOnly}
    checked={Boolean(service.checked)}
    onChange={() => handleSubmit(service, service.checked)}
    label={<ServiceItemLabel service={service} />}
  />
)

ServiceItem.propTypes = {
  readOnly: PropTypes.bool,
  service: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.string,
    checked: PropTypes.bool,
  }),
  handleSubmit: PropTypes.func,
}
