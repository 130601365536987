import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import {
  listProviders,
  setModal,
  removeProvider,
} from '../../../../../../../data/actions'
import { ProvidersListComponent } from '../component'

const ProvidersListContainer = ({
  providers,
  userIsTdmsAdmin,
  getProviders,
  dispatchModal,
  deleteProvider,
}) => {
  const history = useHistory()
  useEffect(() => {
    getProviders()
  }, [])

  const onCancel = () => dispatchModal({})
  const onAddProvider = () =>
    dispatchModal({
      variant: 'providerForm',
      header: 'Add new provider',
      onCancel,
    })

  const onClickProvider = (providerSlug) =>
    history.push(`/backoffice/providers/${providerSlug}`)

  const onClickEditProvider = (providerSlug) =>
    dispatchModal({
      variant: 'providerForm',
      mode: 'edit',
      cleanDataAfrerRemovingComponent: true,
      providerSlug,
      header: 'Edit provider',
      onCancel,
    })

  const onClcikRemoveProvider = (provider) =>
    dispatchModal({
      variant: 'prompt',
      onApprove: () => deleteProvider(provider.slug),
      header: `Are you sure you want to remove the provider - ${provider.name.toUpperCase()}`,
      okText: 'Remove',
      onCancel,
    })

  return (
    <ProvidersListComponent
      providers={providers}
      userIsTdmsAdmin={userIsTdmsAdmin}
      onAddProvider={onAddProvider}
      onClickProvider={onClickProvider}
      onClickEditProvider={onClickEditProvider}
      onClcikRemoveProvider={onClcikRemoveProvider}
    />
  )
}

ProvidersListContainer.propTypes = {
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  userIsTdmsAdmin: PropTypes.bool,
  getProviders: PropTypes.func,
  dispatchModal: PropTypes.func,
  deleteProvider: PropTypes.func,
}
export const ProvidersList = connect(
  ({ backOffice: { providers }, sso: { roles } }) => ({
    providers,
    userIsTdmsAdmin: roles.includes('admin'),
  }),
  {
    getProviders: listProviders,
    dispatchModal: setModal,
    deleteProvider: removeProvider,
  },
)(ProvidersListContainer)
