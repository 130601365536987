import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { fetchPackagePermissions } from '../../../../../../http'
import { CheckboxesGroup } from '../CheckboxesGroup'

export const PermissionsOptions = ({
  packageSlug,
  checkedPermissions,
  onItemCheck,
}) => {
  const [permissionItems, setPermissionItems] = useState([])
  const permissions = permissionItems.map(({ name, slug }) => ({
    name: name,
    id: name,
    label: name,
    checked: checkedPermissions.includes(slug),
    value: slug,
  }))

  const onClick = (permissionSlug) => {
    const newPermissions = [...checkedPermissions]
    const targetIndex = newPermissions.indexOf(permissionSlug)
    if (targetIndex > -1) {
      newPermissions.splice(targetIndex, 1)
    } else {
      newPermissions.push(permissionSlug)
    }
    onItemCheck(newPermissions)
  }

  useEffect(() => {
    const getPermissionItems = async () => {
      const items = await fetchPackagePermissions(packageSlug)
      setPermissionItems(items)
    }
    getPermissionItems()
  }, [packageSlug])

  return (
    <div>
      {permissions.length ? <legend>Permissions</legend> : null}
      <CheckboxesGroup items={permissions} onClickItem={onClick} />
    </div>
  )
}

PermissionsOptions.propTypes = {
  packageSlug: PropTypes.string,
  checkedPermissions: PropTypes.arrayOf(PropTypes.string),
  onItemCheck: PropTypes.func,
}
