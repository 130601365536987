import {
  CHANGE_AUTH,
  SET_USER_DATA,
  SET_PROVIDER_ROLES,
} from '../../actionsTypes'
import {
  fetchLoggedInUserData,
  updateUserProfileFromAlice,
  submitCurrentUser,
  getUser,
  getProviderUserRoles,
} from '../../../http'
import { datadogRum } from '@datadog/browser-rum'
import { postNotification, receiveTenants } from '../index'

export const updateTenantsWithCurrentUserData = (allTenants, userData) => {
  if (!allTenants) {
    throw new Error('Tenants object was not provided!')
  }
  const newTenants = JSON.parse(JSON.stringify(allTenants))
  Object.values(newTenants).forEach((tenant) => {
    const currentUserIndex = tenant.members.findIndex(
      (m) => m.daimlerId === userData.daimlerId,
    )
    if (currentUserIndex > -1) {
      tenant.members[currentUserIndex] = {
        ...tenant.members[currentUserIndex],
        ...userData,
      }
    }
  })
  return newTenants
}

export const generateProviderRolesObject = (providerRoles) => {
  if (!providerRoles) {
    throw new Error('Provider roles was not provided!')
  }
  const obj = {}
  providerRoles.forEach(({ provider: { slug }, role }) => {
    obj[slug] = role
  })
  return obj
}

export const getUserData = () => async (dispatch) => {
  try {
    const response = await fetchLoggedInUserData()
    const roleAdmin =
      response.roles?.includes('tenantadmin') ||
      response.roles?.includes('admin')
    datadogRum.setUser({
      id: response.userDigest,
      email: response.userDigest,
    })

    dispatch(
      changeAuth({}, true, {
        ...response,
        username: response.userId,
        appRoles: { roleAdmin },
      }),
    )
  } catch (err) {
    dispatch(
      postNotification({
        message: err.message,
        mode: 'error',
      }),
    )
  }
}

export const fetchCurrentUser = () => async (dispatch, getState) => {
  try {
    const daimlerId = getState().sso.userId.toLowerCase()
    const userData = await getUser(daimlerId)
    dispatch(setUserData(userData))
  } catch (err) {
    dispatch(postNotification({ message: err.message, mode: 'error' }))
  }
}

export const updateUser = (userData, cb) => async (dispatch, getState) => {
  try {
    const response = await submitCurrentUser(userData.daimlerId, userData)
    const tenants = updateTenantsWithCurrentUserData(
      getState().tenants.allTenants,
      response,
    )

    dispatch(receiveTenants(tenants))

    dispatch(
      postNotification({
        message: `User was updated successfully`,
      }),
    )
    dispatch(fetchCurrentUser())
    cb?.()
  } catch (err) {
    dispatch(postNotification({ message: err.message, mode: 'error' }))
    cb?.()
  }
}

export const updateUserProfile = (cb) => async (dispatch, getState) => {
  const daimlerId = getState().sso.userId
  const messageTemplates = {
    USER_NOT_FOUND_IN_ALICE: {
      message: `User (${daimlerId}) could not be found in Alice API`,
      mode: 'error',
    },
    UP_TO_DATE: {
      message: `Your Profile is up-to-date`,
      mode: 'success',
    },
    ALICE_DISABLED: {
      message: 'Alice API is temporarily disabled, please try again later',
      mode: 'warning',
    },
    UPDATED: {
      message: `Your Profile was updated successfully`,
      mode: 'success',
    },
    OTHER: {
      message: 'Unknown error occured!',
      mode: 'error',
    },
  }
  try {
    const response = await updateUserProfileFromAlice(daimlerId)
    if (response.aliceUpdateResult === 'UPDATED') {
      dispatch(postNotification(messageTemplates[response.aliceUpdateResult]))
      dispatch(setUserData(response.userDto))
      cb?.()
      return
    }
    if (!messageTemplates[response.aliceUpdateResult]) {
      throw new Error('OTHER')
    } else {
      throw new Error(response.aliceUpdateResult)
    }
  } catch (err) {
    dispatch(postNotification(messageTemplates[err.message]))
    cb?.()
  }
}

export const fetchProviderUserRoles = () => async (dispatch) => {
  try {
    const providerUserRoles = await getProviderUserRoles()
    dispatch(setProviderRoles(generateProviderRolesObject(providerUserRoles)))
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while retrieving provider user roles - ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const changeAuth = (tokens, isAuthenticated, userData) => ({
  type: CHANGE_AUTH,
  payload: {
    tokens,
    isAuthenticated,
    userData,
  },
})

export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  payload: {
    userData,
  },
})

export const setProviderRoles = (providerRoles) => ({
  type: SET_PROVIDER_ROLES,
  payload: providerRoles,
})
