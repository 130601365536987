import { CHANGE_NAV_MENU } from '../../actionsTypes'

const initialState = {
  navMenuSelected: 'apps',
}

export const globalStateReducer = (state = initialState, action) => {
  if (action.type === CHANGE_NAV_MENU) {
    return {
      ...state,
      navMenuSelected: action.payload.navMenuSelected,
    }
  }
  return state
}
