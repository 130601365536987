import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import { getUserData, fetchProviderUserRoles } from '../../data/actions'
import { animationSettings } from '../../config'
import 'url-search-params-polyfill'

const OidcComponent = ({
  onGetUserData,
  getProviderUserRoles,
  isAuthenticated,
  children,
}) => {
  useEffect(() => {
    const doAuth = async () => {
      const online = navigator.onLine
      if (online) {
        onGetUserData()
        getProviderUserRoles()
      }
    }

    if (!isAuthenticated) {
      doAuth()
    }
  }, [isAuthenticated])
  const _timeout = animationSettings.timeout
  return (
    <>
      <CSSTransition
        in={!isAuthenticated}
        timeout={_timeout}
        classNames="loader-container"
        unmountOnExit
      >
        <div className="loader-container">
          <div className="loader">
            <wb-spinner mode="dark"></wb-spinner>
          </div>
        </div>
      </CSSTransition>
      {isAuthenticated && children}
    </>
  )
}

OidcComponent.propTypes = {
  isAuthenticated: PropTypes.bool,
  onGetUserData: PropTypes.func,
  getProviderUserRoles: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default connect(
  ({ sso: { isAuthenticated } }) => ({
    isAuthenticated,
  }),
  { onGetUserData: getUserData, getProviderUserRoles: fetchProviderUserRoles },
)(OidcComponent)
