import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  setService,
  setModal,
  getServices,
  createService,
} from '../../../../../../../data/actions'
import { ServicesListComponent } from '../component'

const ServicesListContainer = ({
  services,
  selectedService,
  createNewService,
  listServices,
  setCurrentService,
  dispatchModal,
}) => {
  useEffect(() => {
    listServices()
  }, [])

  const onAddServiceClick = () =>
    dispatchModal({
      variant: 'service',
      mode: 'add',
      onCancel: () => dispatchModal({}),
      onApprove: createNewService,
    })

  return (
    <ServicesListComponent
      getServices={listServices}
      setService={setCurrentService}
      onAddService={onAddServiceClick}
      services={services}
      selectedService={selectedService}
    />
  )
}

const ServiceProp = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
})

ServicesListContainer.propTypes = {
  services: PropTypes.arrayOf(ServiceProp),
  selectedService: ServiceProp,
  createNewService: PropTypes.func,
  listServices: PropTypes.func,
  setCurrentService: PropTypes.func,
  dispatchModal: PropTypes.func,
}

export const ServicesList = connect(
  ({ backOffice }) => ({
    services: backOffice.services,
    selectedService: backOffice.service,
  }),
  {
    setCurrentService: setService,
    dispatchModal: setModal,
    listServices: getServices,
    createNewService: createService,
  },
)(ServicesListContainer)
