import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button } from '../../../../../../common'

export const PermissionItem = ({
  permission,
  readOnly,
  currentPermission,
  setPermission,
  deleteServicePermission,
}) => (
  <li
    className={classnames('packages-editor__list-item', {
      'pacages-editor__list-item--active':
        permission.id === currentPermission?.id,
    })}
  >
    <div className="permission-item">
      <div className="permission-item__info">
        <div>{permission.name}</div>
        <div className="packages-editor__list-item-description">
          {permission.slug}
        </div>
        <div className="packages-editor__list-item-description">
          {permission.description}
        </div>
        <div className="packages-editor__list-item-description">
          {permission.providerOnly ? 'Restricted to Provider Members' : ''}
        </div>
      </div>
      <div className="permission-item__buttons">
        <Button
          withIcon="bds/pencil/24"
          onClick={() => setPermission(permission)}
          disabled={readOnly}
          variant="secondary"
          size="small"
          iconOnly
        />
        <Button
          withIcon="bds/trash/24"
          variant="secondary"
          onClick={() => deleteServicePermission(permission.id)}
          disabled={readOnly}
          size="small"
          iconOnly
        />
      </div>
    </div>
  </li>
)

export const permissionPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  slug: PropTypes.string,
  readOnly: PropTypes.bool,
  description: PropTypes.string,
  providerOnly: PropTypes.bool,
})

PermissionItem.propTypes = {
  readOnly: PropTypes.bool,
  permission: permissionPropType,
  currentPermission: permissionPropType,
  setPermission: PropTypes.func,
  deleteServicePermission: PropTypes.func,
}
