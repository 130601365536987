import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../../../../common'

export const ListHeaderComponent = ({ onAddProvider, userIsTdmsAdmin }) => (
  <div className="wb-margin-vertical-xs providers-list__head wb-type-copy">
    <h2>Providers List</h2>
    <Button
      variant="primary"
      size="medium"
      disabled={!userIsTdmsAdmin}
      onClick={onAddProvider}
      withIcon="bds/plus/24"
    >
      Add provider
    </Button>
  </div>
)

ListHeaderComponent.propTypes = {
  onAddProvider: PropTypes.func,
  userIsTdmsAdmin: PropTypes.bool,
}
