import React from 'react'
import PropTypes from 'prop-types'

export const ListGroupItem = ({ checked, label, onChange }) => (
  <button
    type="button"
    className="wb-list-group__item"
    style={{ cursor: 'unset' }}
  >
    <wb-checkbox-control>
      <label>
        <input
          type="checkbox"
          name="One"
          checked={checked}
          onChange={onChange}
        />
        {label}
      </label>
    </wb-checkbox-control>
  </button>
)

ListGroupItem.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
}
