import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  getAkamaiCpCodes,
  toggleCpCode,
} from '../../../../../../../data/actions'
import { LoadingIndicator } from '../../../../../../common'
import { AkamaiFormComponent } from './component'

const FormComponent = ({
  cacheItems,
  loading,
  invalidating,
  selectedCpCodes,
  getCpCodes,
  toggleCode,
}) => {
  useEffect(() => {
    getCpCodes()
  }, [])

  if ((loading || invalidating) && !cacheItems.length) {
    return <LoadingIndicator />
  }

  if (!cacheItems.length) {
    return <div className="no-cp-codes">No CP codes for a given Tenant</div>
  }
  return (
    <AkamaiFormComponent
      cacheItems={cacheItems}
      selectedCpCodes={selectedCpCodes}
      onChangeCpCode={toggleCode}
      disabled={loading}
    />
  )
}

FormComponent.propTypes = {
  cacheItems: PropTypes.array,
  loading: PropTypes.bool,
  invalidating: PropTypes.bool,
  selectedCpCodes: PropTypes.object,
  getCpCodes: PropTypes.func,
  toggleCode: PropTypes.func,
}

export const AkamaiForm = connect(
  ({ akamai: { cpCodes, selectedCpCodes, loading, invalidating } }) => ({
    cacheItems: cpCodes,
    selectedCpCodes,
    loading,
    invalidating,
  }),
  { getCpCodes: getAkamaiCpCodes, toggleCode: toggleCpCode },
)(FormComponent)
