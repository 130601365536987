import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getTenantMembersWithPermissions } from '../../../../../../data/actions'
import { TenantMembersComponent } from '../component'

const TenantMembersContainer = ({ getPermissions, ...props }) => {
  useEffect(() => {
    getPermissions()
  }, [props.tenantInfo.acronym])

  return <TenantMembersComponent {...props} />
}

TenantMembersContainer.propTypes = {
  tenantInfo: PropTypes.shape({
    acronym: PropTypes.string,
  }),
  getPermissions: PropTypes.func,
}

export const TenantMembers = connect(
  ({ tenants: { currentTenantMembers, tenantRoles } }) => ({
    members: currentTenantMembers.map((member) => {
      member.labels = member.labels === '' ? [] : member.labels
      return member
    }),
    tenantRoles,
  }),
  { getPermissions: getTenantMembersWithPermissions },
)(TenantMembersContainer)
