export const sortMembers = (members) => {
  const orderOfRoles = ['PO', 'PM', 'ISA', 'BUDGET_APPROVER']
  return [...members].sort((member1, member2) => {
    for (const role of orderOfRoles) {
      if (member1.roles.includes(role) && !member2.roles.includes(role)) {
        return -1
      } else if (
        !member1.roles.includes(role) &&
        member2.roles.includes(role)
      ) {
        return 1
      }
    }
    return member1.firstName > member2.firstName ? 1 : -1
  })
}
