import React from 'react'
import PropTypes from 'prop-types'

export const AccordionToggle = ({ onClick, children }) => (
  <wb-accordion-toggle onClick={onClick}>{children}</wb-accordion-toggle>
)

AccordionToggle.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
