import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { ServicesReportByMonthContent } from '../ServicesReportByMonthContent'
import { getBoardingData } from '../utils'

export const ServicesReportByMonth = ({ label, onboarding, offboarding }) => {
  const on = getBoardingData(label, onboarding)
  const off = getBoardingData(label, offboarding)
  return (
    <div className="admin-panel-grid-column">
      <div className="timereport-header">
        {moment('20' + label).format('YYYY-MM')}
      </div>

      <div className="timereport-column-info">
        {on.length > 0 && (
          <ServicesReportByMonthContent
            title="On-boarding"
            packages={on}
            sign="+"
          />
        )}
        {off.length > 0 && (
          <ServicesReportByMonthContent
            title="Off-boarding"
            packages={off}
            sign="-"
          />
        )}
      </div>
    </div>
  )
}

ServicesReportByMonth.propTypes = {
  label: PropTypes.string,
  onboarding: PropTypes.objectOf(PropTypes.array),
  offboarding: PropTypes.objectOf(PropTypes.array),
}
