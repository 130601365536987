import React from 'react'
import PropTypes from 'prop-types'
import { Button, ModalContent, ModalFooter } from '../../../../../common'

export const KeyPreview = ({ row, title, onClose }) => (
  <ModalContent headline={title}>
    <div className="wb-margin-bottom-xm">
      <div className="wb-margin-bottom-xs">
        <p>Key Preview: </p>
        <p>{row.tokenPreview}</p>
      </div>
      {row.externalAuthSystemTokenIssuer && (
        <div className="wb-margin-bottom-xs">
          <p>External auth token issuer URL: </p>
          <p>{row.externalAuthSystemTokenIssuer}</p>
        </div>
      )}
    </div>
    <ModalFooter style={{ display: 'flex', flexDirection: 'row-reverse' }}>
      <Button variant="primary" size="medium" onClick={onClose}>
        close
      </Button>
    </ModalFooter>
  </ModalContent>
)

KeyPreview.propTypes = {
  row: PropTypes.object,
  title: PropTypes.string,
  onClose: PropTypes.func,
}
