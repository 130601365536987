import React from 'react'
import PropTypes from 'prop-types'
import { formOptions } from '../container/formOptions'
import {
  Input,
  RadioButtonGroup,
  CheckBox,
  Button,
} from '../../../../../../../common'
import { FormSection } from './components/FormSection'
import { FormSectionItem } from './components/FormSectionItem'
import { LabeledHint } from './components/LabeledHint'

export const ComplianceFormComponent = ({
  controls,
  isEditable,
  onSubmit,
  updateInputState,
}) => {
  const checkStatus = (control, variant) => {
    const touched = variant
      ? controls[variant][control].touched
      : controls[control].touched
    const valid = variant
      ? controls[variant][control].valid
      : controls[control].valid
    return touched && !valid ? 'error' : ''
  }

  const updateRistAssessment = (value) => {
    const selectedOptions = [...controls.processOnBehalf.riskAssessment.value]
    const index = selectedOptions.indexOf(value)
    if (index > -1) {
      selectedOptions.splice(index, 1)
    } else {
      selectedOptions.push(value)
    }
    updateInputState('riskAssessment', selectedOptions, 'processOnBehalf')
  }
  return (
    <div className="compliance-form">
      <FormSection title="Processing on Behalf">
        <div className="wb-margin-vertical-xs">
          <Input
            label="Data Protection Contact Person"
            disabled={!isEditable}
            value={controls.dataProtectionContactPerson.value}
            onChange={(e) =>
              updateInputState('dataProtectionContactPerson', e.target.value)
            }
            error={checkStatus('dataProtectionContactPerson')}
          />
        </div>
        <div className="wb-margin-vertical-xs compliance-form__input">
          <LabeledHint
            label="Information Owner of Mercedes-Benz AG"
            hint="Does Mercedes-Benz AG acts as the controller of the data and is
                MB.io processing the data on their behalf? (When in doubt reach
                out to Data Compliance)"
          />
          <div role="radiogroup" className="compliance-form__radio-group">
            <RadioButtonGroup
              items={formOptions.mbagOwned}
              name="mbagOwned"
              checkedValue={controls.processOnBehalf.mbagOwned.value}
              disabled={!isEditable}
              onChange={(e) =>
                updateInputState(
                  'mbagOwned',
                  e.target.value === 'true',
                  'processOnBehalf',
                )
              }
            />
          </div>
        </div>
        {controls.processOnBehalf.mbagOwned.value ? (
          <>
            <div className="wb-margin-vertical-xs compliance-form__input">
              <LabeledHint
                label="Service Agreement concluded between MBAG and..."
                hint="Is MB.io Germany or MB.io Portugal ordered by MBAG?"
              />
              <div role="radiogroup" className="compliance-form__radio-group">
                <RadioButtonGroup
                  items={formOptions.mbagAgreementEntity}
                  name="mbagAgreementEntity"
                  checkedValue={
                    controls.processOnBehalf.mbagAgreementEntity.value
                  }
                  disabled={!isEditable}
                  onChange={(e) =>
                    updateInputState(
                      'mbagAgreementEntity',
                      e.target.value,
                      'processOnBehalf',
                    )
                  }
                />
              </div>
            </div>
            <div className="wb-margin-vertical-xs compliance-form__input">
              <LabeledHint
                label="External Partners involved which can access personal data"
                hint="Are external Partners working in your product and are they
                    able to access personal data?"
              />
              <div role="radiogroup" className="compliance-form__radio-group">
                <RadioButtonGroup
                  items={formOptions.thirdPartyAccess}
                  name="thirdPartyAccess"
                  checkedValue={controls.processOnBehalf.thirdPartyAccess.value}
                  disabled={!isEditable}
                  onChange={(e) =>
                    updateInputState(
                      'thirdPartyAccess',
                      e.target.value === 'true',
                      'processOnBehalf',
                    )
                  }
                />
              </div>
            </div>
            <div className="wb-margin-vertical-xs compliance-form__input">
              <LabeledHint
                label="Personal Data Involved"
                hint="Are you handling personal data within your product?"
              />
              <div role="radiogroup" className="compliance-form__radio-group">
                <RadioButtonGroup
                  items={formOptions.personalData}
                  name="personalData"
                  checkedValue={controls.processOnBehalf.personalData.value}
                  disabled={!isEditable}
                  onChange={(e) =>
                    updateInputState(
                      'personalData',
                      e.target.value === 'true',
                      'processOnBehalf',
                    )
                  }
                />
              </div>
            </div>
            <div className="wb-margin-vertical-xs">
              <Input
                value={controls.processOnBehalf.agreementSubject.value}
                onChange={(e) =>
                  updateInputState(
                    'agreementSubject',
                    e.target.value,
                    'processOnBehalf',
                  )
                }
                hint="Confirmation if there a service agreement (planned), to
                    which this agreement on processing on behalf relates, and
                    can you provide a short summary of the product."
                disabled={!isEditable}
                label="Subject Matter of the Agreement"
                error={checkStatus('agreementSubject', 'processOnBehalf')}
              />
            </div>
            <div className="wb-margin-vertical-xs">
              <Input
                value={controls.processOnBehalf.personalDataTypes.value}
                onChange={(e) =>
                  updateInputState(
                    'personalDataTypes',
                    e.target.value,
                    'processOnBehalf',
                  )
                }
                hint="Please list relevant data types, e.g. name, address,
                    user-ID, vehicle data, credit card information, customer
                    profiles, etc."
                disabled={!isEditable}
                label="Types of Personal Data processed"
                error={checkStatus('personalDataTypes', 'processOnBehalf')}
              />
            </div>
            <div className="wb-margin-vertical-xs">
              <div>
                <LabeledHint
                  label="Rist Assessment"
                  hint={`Which risk (normal vs. increased risk) does the requested
                  Processing on Behalf show under data protection aspects?
                  Please have a look at the fields you selected in "Types of
                  Personal Data processed". If 5 or more of the following
                  evaluation criteria are apply we have the evidence for an
                  increased risk. In this cases plase approach Data
                  Compliance.`}
                />
                <div className="wb-margin-top-xs compliance-form__checkbox-group">
                  <div style={{ width: '40%' }}>
                    {formOptions.riskAssessment
                      .slice(
                        0,
                        Math.ceil(formOptions.riskAssessment.length / 2),
                      )
                      .map(({ label, value }) => (
                        <CheckBox
                          className="wb-margin-bottom-xs"
                          key={label}
                          disabled={!isEditable}
                          value={value}
                          checked={controls.processOnBehalf.riskAssessment.value.includes(
                            value,
                          )}
                          onChange={(e) => updateRistAssessment(e.target.value)}
                          label={label}
                        />
                      ))}
                  </div>
                  <div style={{ width: '60%' }}>
                    {formOptions.riskAssessment
                      .slice(
                        Math.ceil(formOptions.riskAssessment.length / 2),
                        formOptions.riskAssessment.length,
                      )
                      .map(({ label, value }) => (
                        <CheckBox
                          className="wb-margin-bottom-xs"
                          key={label}
                          disabled={!isEditable}
                          value={value}
                          checked={controls.processOnBehalf.riskAssessment.value.includes(
                            value,
                          )}
                          onChange={(e) => updateRistAssessment(e.target.value)}
                          label={label}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="wb-margin-vertical-xs">
              <Input
                error={checkStatus('dataProcessingLocation', 'processOnBehalf')}
                label="Locations of Data Processing"
                disabled={!isEditable}
                value={controls.processOnBehalf.dataProcessingLocation.value}
                onChange={(e) =>
                  updateInputState(
                    'dataProcessingLocation',
                    e.target.value,
                    'processOnBehalf',
                  )
                }
                hint="Please list all the locations where personal data is being
                    processed - for example data centers. Relevant from MB.io
                    side here is only the provider of the Cloud Storage we use
                    incl. location (city, country) as well as the MB.io
                    datacenter (city, country). Locations of data processing by
                    the external partner (processor) need to be added by them."
              />
            </div>
            <div className="wb-margin-vertical-xs">
              <Input
                error={checkStatus('personalDataScope', 'processOnBehalf')}
                label="Scope, Nature and Purpose of Processing of Personal Data"
                disabled={!isEditable}
                value={controls.processOnBehalf.personalDataScope.value}
                onChange={(e) =>
                  updateInputState(
                    'personalDataScope',
                    e.target.value,
                    'processOnBehalf',
                  )
                }
                hint={`Please describe which data processing operations the
                    Processor performs with regard to Controller's personal
                    data. Processing of personal data means almost any kind of
                    handling of personal data, such as collection, adaption,
                    retrieval, use or disclosure by transmission. If suppliers
                    perform different services, a brief description like
                    "Development, support, maintenance of the product in context
                    of the given service agreement" would be sufficient.`}
              />
            </div>
            <div className="wb-margin-vertical-xs">
              <Input
                error={checkStatus(
                  'personalDataTargetGroups',
                  'processOnBehalf',
                )}
                label="The groups of people affected by the handling of their
                      personal data in the context of the present agreement"
                disabled={!isEditable}
                value={controls.processOnBehalf.personalDataTargetGroups.value}
                onChange={(e) =>
                  updateInputState(
                    'personalDataTargetGroups',
                    e.target.value,
                    'processOnBehalf',
                  )
                }
                hint={`Please point out whose data is affected by the service, e.g.
                    employees of company XY , customer of service A, users of
                    application B, drivers, suppliers, etc.`}
              />
            </div>
          </>
        ) : null}
      </FormSection>
      <FormSection title="Data Analytics">
        <FormSectionItem title="Data Analytics Involved">
          <div role="radiogroup" className="compliance-form__radio-group">
            <RadioButtonGroup
              items={formOptions.dataAnalytics}
              name="dataAnalytics"
              checkedValue={controls.dataAnalytics.value}
              disabled={!isEditable}
              onChange={(e) =>
                updateInputState('dataAnalytics', e.target.value === 'true')
              }
            />
          </div>
        </FormSectionItem>
      </FormSection>
      <FormSection title="Automated Individual Decision-Making">
        <FormSectionItem title="Automated Individual Decision-Making incl. Profiling involved">
          <div role="radiogroup" className="compliance-form__radio-group">
            <RadioButtonGroup
              items={formOptions.automatedDecisionMaking}
              name="automatedDecisionMaking"
              checkedValue={controls.automatedDecisionMaking.value}
              disabled={!isEditable}
              onChange={(e) =>
                updateInputState(
                  'automatedDecisionMaking',
                  e.target.value === 'true',
                )
              }
            />
          </div>
        </FormSectionItem>
      </FormSection>
      <div className="wb-margin-vertical-xs compliance-form__footer">
        <Button
          disabled={!isEditable}
          variant="primary"
          size="large"
          onClick={onSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

ComplianceFormComponent.propTypes = {
  updateInputState: PropTypes.func,
  controls: PropTypes.shape({
    processOnBehalf: PropTypes.shape({
      agreementSubject: PropTypes.shape({
        value: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
      }),
      dataProcessingLocation: PropTypes.shape({
        value: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
      }),
      mbagAgreementEntity: PropTypes.shape({
        value: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
      }),
      mbagOwned: PropTypes.shape({
        value: PropTypes.bool,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
      }),
      personalData: PropTypes.shape({
        value: PropTypes.bool,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
      }),
      personalDataScope: PropTypes.shape({
        value: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
      }),
      personalDataTargetGroups: PropTypes.shape({
        value: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
      }),
      personalDataTypes: PropTypes.shape({
        value: PropTypes.string,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
      }),
      riskAssessment: PropTypes.shape({
        value: PropTypes.array,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
      }),
      thirdPartyAccess: PropTypes.shape({
        value: PropTypes.bool,
        valid: PropTypes.bool,
        touched: PropTypes.bool,
      }),
    }),
    dataProtectionContactPerson: PropTypes.shape({
      value: PropTypes.string,
      valid: PropTypes.bool,
      touched: PropTypes.bool,
    }),
    dataAnalytics: PropTypes.shape({
      value: PropTypes.bool,
      valid: PropTypes.bool,
      touched: PropTypes.bool,
    }),
    automatedDecisionMaking: PropTypes.shape({
      value: PropTypes.bool,
      valid: PropTypes.bool,
      touched: PropTypes.bool,
    }),
  }),
  isEditable: PropTypes.bool,
  onSubmit: PropTypes.func,
}
