import React from 'react'
import PropTypes from 'prop-types'
import { DebouncedInput } from '../../container/tableUtils'
import { Button } from '../../../../../../../common'

export const TableHeader = ({
  globalFilter,
  setGlobalFilter,
  onClickAddNew,
}) => (
  <div className="wb-padding-vertical-xs wh-table__head">
    <div className="wb-margin-top-xs">
      <DebouncedInput
        value={globalFilter ?? ''}
        onChange={(value) => setGlobalFilter(String(value))}
        placeholder="Search all columns..."
      />
    </div>
    <Button
      variant="primary"
      size="medium"
      withIcon="bds/plus/24"
      onClick={onClickAddNew}
    >
      Add new
    </Button>
  </div>
)

TableHeader.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  onClickAddNew: PropTypes.func,
}
