import React from 'react'
import PropTypes from 'prop-types'
import { Input, DatepickerControl } from '..'

export const Datepicker = ({ label, value, min, max, error, onChange }) => (
  <DatepickerControl>
    <Input
      label={label}
      type="date"
      min={min}
      className="datepicker-control"
      max={max}
      onChange={onChange}
      value={value}
      error={error}
    />
  </DatepickerControl>
)

Datepicker.propTypes = {
  label: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func,
}
