import React from 'react'
import PropTypes from 'prop-types'
import { CheckboxesGroup } from '../CheckboxesGroup'
import { capitalizeFirstLetter } from '../../utils'

export const ProviderOptions = ({
  items = [],
  checkedProviders,
  onItemCheck,
}) => {
  const providers = items.map((provider) => ({
    name: provider,
    id: provider,
    label: capitalizeFirstLetter(provider),
    checked: checkedProviders.includes(provider),
    value: provider,
  }))
  const onClick = (provider) => {
    const newProviders = [...checkedProviders]
    const providerIndex = newProviders.indexOf(provider)
    if (providerIndex > -1) {
      newProviders.splice(providerIndex, 1)
    } else {
      newProviders.push(provider)
    }
    onItemCheck(newProviders)
  }
  return (
    <div>
      <legend>Filter by providers</legend>
      <CheckboxesGroup items={providers} onClickItem={onClick} />
    </div>
  )
}

ProviderOptions.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  checkedProviders: PropTypes.arrayOf(PropTypes.string),
  onItemCheck: PropTypes.func,
}
