export const animationSettings = {
  timeout: {
    enter: 300,
    exit: 0,
  },
  timeoutInOut: {
    enter: 300,
    exit: 300,
  },
}
