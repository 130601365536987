import React from 'react'
import PropTypes from 'prop-types'
import { PackageForm } from '../../Packages/components/Forms/PackageForm'
import { PermissionsForm } from '../../Services/components/Forms/PermissionsForm'
import { PropertiesForm } from '../../Services/components/Forms/PropertiesForm'
import { ServicesForm } from '../../Services/components/Forms/ServicesForm'
import { BookingForm } from '../../Bookings/components/BookingForm'
import { BookingServicePropertyForm } from '../../../../apps/tenant/components/tenantBookingsDialog/components/BookingItem/components/BookingServiceProperties/forms/BookingServicePropertyForm'
import { PriceVariantsForm } from '../../Packages/components/PriceVariants/components/PriceVariantsForm'
import { PricesForm as PvPricesForm } from '../../Packages/components/PriceVariants/components/PricesForm'
import { AddUserForm } from '../../../../apps/tenant/components/tenantMembersDialog/AddUserForm'
import { AcceptBulkActionModal } from '../../../../apps/tenant/components/tenantMembersDialog/AcceptBulkActionModal'
import { WebHooksForm } from '../../WebHooks/components/WebHooksForm'
import { ProviderForm } from '../../Providers/components/ProviderForm'
import { UserForm } from '../../Providers/components/ProviderPage/components/UserForm'
import { TdmsUserForm } from '../../Permissions/components/TdmsUserForm'
import { CustomPrompt } from './CustomPrompt'
import { ModalHeader, ModalLevel, Modal } from '../../../../common'

export const ModalComponent = ({ modal }) => {
  const modals = {
    package: PackageForm,
    prices: PvPricesForm,
    service: ServicesForm,
    tenantMembersModal: AcceptBulkActionModal,
    priceVariants: PriceVariantsForm,
    booking: BookingForm,
    addUser: AddUserForm,
    addWebHooks: WebHooksForm,
    tdmsUserForm: TdmsUserForm,
    providerUsers: UserForm,
    providerForm: ProviderForm,
    prompt: CustomPrompt,
    permission: PermissionsForm,
    property: PropertiesForm,
    bookingServiceProperty: BookingServicePropertyForm,
  }

  const Component = modals[modal.variant]
  return modal?.variant ? (
    <Modal onCloseModal={modal.onCancel}>
      <ModalHeader />
      <ModalLevel>
        <div className="wb-padding-horizontal-s">
          {Component ? <Component {...modal} mode={modal.mode} /> : null}
        </div>
      </ModalLevel>
    </Modal>
  ) : null
}

ModalComponent.propTypes = {
  modal: PropTypes.shape({
    variant: PropTypes.string,
    mode: PropTypes.string,
    onCancel: PropTypes.func,
    fullScreen: PropTypes.bool,
  }).isRequired,
}
