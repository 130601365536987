import React from 'react'
import PropTypes from 'prop-types'
import { Select } from '../../../../../../common'

export const ProducersSelectorComponent = ({
  producers,
  value,
  error,
  onChange,
}) => (
  <wb-grid-row className="tenant-selection wb-margin-bottom-xs">
    <wb-grid-col mq1="6">
      <Select
        value={value}
        label="Select producer"
        options={producers.map(({ name, slug }) => ({
          name,
          id: slug,
        }))}
        onChange={(e) => onChange(e.target.value)}
        error={error}
      />
    </wb-grid-col>
  </wb-grid-row>
)

ProducersSelectorComponent.propTypes = {
  producers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
}
