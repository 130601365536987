import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MultiSelectComponent } from '../component'

export const MultiSelectWithSelectAll = ({
  items,
  selectedItems,
  invalid,
  modelName,
  onChange,
}) => {
  const [allSelected, setAllSelected] = useState(false)
  const multiSelectOptions = items.map(({ name, slug }) => ({
    label: name,
    value: slug,
  }))

  useEffect(() => {
    setAllSelected(selectedItems === '*')
  }, [selectedItems])

  const onClickSelectAll = () => {
    allSelected ? onChange([]) : onChange('*')
    setAllSelected(!allSelected)
  }
  return (
    <MultiSelectComponent
      allSelected={allSelected}
      modelName={modelName}
      multiSelectOptions={multiSelectOptions}
      selectedItems={selectedItems}
      onClickSelectAll={onClickSelectAll}
      onChange={onChange}
      error={invalid && 'Please select at least one item'}
    />
  )
}

MultiSelectWithSelectAll.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  selectedItems: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  ]),
  modelName: PropTypes.string,
  invalid: PropTypes.bool,
  onChange: PropTypes.func,
}
