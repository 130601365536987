import {
  SET_TENANT_BOOKINGS,
  SET_CURRENT_BOOKING_MEMBERS,
  SET_CURRENT_BOOKING_SERVICES,
  SET_CURRENT_BOOKING_SERVICE_PROPERTIES,
  SET_CURRENT_BOOKING_SERVICE_PROPERTY,
  REMOVE_BOOKING_SERVICE_PROPERTY,
  UPDATE_BOOKING_SERVICE_PROPERTY,
  SET_CURRENT_BOOKING_PERMISSIONS,
  SET_CURRENT_BOOKING_SERVICE_PERMISSIONS,
  DELETE_BOOKING_SERVICE_PERMISSION,
  ADD_WAITING_PERMISSIONS,
  REMOVE_WAITING_PERMISSIONS,
} from '../../actionsTypes'
import {
  fetchPlatformPackageServices,
  fetchBookings,
  fetchBookingServicePermissions,
  fetchServicePermissions,
  fetchBookingServiceProperties,
  postMembersToBookingServicePermission,
  putMembersToBookingServicePermission,
  deleteBookingServicePermission,
} from '../../../http'
import { selectCurrentTenantMembers } from '../../selectors/tenants'
import { sortArrayOfObjectsByProperty } from '../../../util/data'
import { postNotification, getTenantMembersWithPermissions } from '..'

export const getCurrentTenantBookings = () => async (dispatch, getState) => {
  try {
    const acronym = getState().tenants.selectedTenant
    const bookings = await fetchBookings({
      acronym,
      current: true,
      details: true,
    })
    dispatch(
      setTenantBookings(sortArrayOfObjectsByProperty(bookings, 'packageName')),
    )
  } catch (err) {
    dispatch(
      postNotification({
        message: err.message,
        mode: 'error',
      }),
    )
  }
}

export const getCurrentBookingServices = (booking) => async (dispatch) => {
  try {
    const response = await fetchPlatformPackageServices(booking.packageSlug)
    if (response.errorMessages) {
      throw new Error(response.message)
    }
    dispatch(
      setCurrentBookingServices(
        booking.packageSlug,
        sortArrayOfObjectsByProperty(response, 'name'),
      ),
    )
  } catch (err) {
    dispatch(
      postNotification({
        message: err.message,
        mode: 'error',
      }),
    )
  }
}

export const updateMembersToBookingServicePermission =
  (booking, bookingPermissionIds, servicePermissionSlug, service, cb) =>
  async (dispatch, getState) => {
    const permissions =
      getState().tenantBookings.bookingServicePermissions[booking.id][
        service.slug
      ].bookingServicePermissions
    const userDaimlerIds = permissions
      .filter((perm) => bookingPermissionIds.includes(perm.id))
      .map((member) => member.userDaimlerId)
    try {
      const tenantAcronym = getState().tenants.selectedTenant
      dispatch(addWaitingPermissions(booking.id, service.slug, userDaimlerIds))
      const response = await putMembersToBookingServicePermission({
        bookingPermissionIds,
        servicePermissionSlug,
        tenantAcronym,
      })
      if (response.errorMessages) {
        throw new Error(response.message)
      }
      dispatch(getCurrentBookingServicePersmissions(booking, service))
      dispatch(getTenantMembersWithPermissions())
      dispatch(
        postNotification({
          message: 'Permission was updated succesfully!',
        }),
      )
      dispatch(
        removeWaitingPermissions(booking.id, service.slug, userDaimlerIds),
      )
      cb?.()
    } catch (err) {
      dispatch(
        removeWaitingPermissions(booking.id, service.slug, userDaimlerIds),
      )
      dispatch(
        postNotification({
          message: `Error occured while updating permissions - ${err.message}`,
          mode: 'error',
        }),
      )
    }
  }

export const addMembersToBookingServicePermission =
  (members, booking, servicePermission, service, cb) =>
  async (dispatch, getState) => {
    try {
      dispatch(addWaitingPermissions(booking.id, service.slug, members))
      const tenantAcronym = getState().tenants.selectedTenant
      const response = await postMembersToBookingServicePermission({
        members,
        bookingId: booking.id,
        servicePermissionId: servicePermission.id,
        tenantAcronym,
      })
      if (response.errorMessages) {
        throw new Error(response.message)
      } else {
        dispatch(getCurrentBookingServicePersmissions(booking, service))
        dispatch(getTenantMembersWithPermissions())
        dispatch(
          postNotification({
            message: 'Members were added successfully!',
          }),
        )
        dispatch(removeWaitingPermissions(booking.id, service.slug, members))
        cb?.()
      }
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while adding members - ${err.message}`,
          mode: 'error',
        }),
      )
      dispatch(removeWaitingPermissions(booking.id, service.slug, members))
    }
  }

export const removeBookingPermission =
  (booking, service, permissionId, cb) => async (dispatch, getState) => {
    const permissions =
      getState().tenantBookings.bookingServicePermissions[booking.id][
        service.slug
      ].bookingServicePermissions
    const userDaimlerIds = permissions
      .filter((perm) => permissionId === perm.id)
      .map((member) => member.userDaimlerId)
    try {
      const tenantAcronym = getState().tenants.selectedTenant
      dispatch(addWaitingPermissions(booking.id, service.slug, userDaimlerIds))
      const response = await deleteBookingServicePermission(
        permissionId,
        tenantAcronym,
      )
      if (response.errorMessages) {
        throw new Error(response.message)
      }
      dispatch(deleteBookingPermission(booking.id, service.slug, permissionId))
      dispatch(getTenantMembersWithPermissions())
      dispatch(
        removeWaitingPermissions(booking.id, service.slug, userDaimlerIds),
      )
      dispatch(
        postNotification({
          message: 'Permission was removed successfully!',
        }),
      )
      cb?.()
    } catch (err) {
      dispatch(
        removeWaitingPermissions(booking.id, service.slug, userDaimlerIds),
      )
      dispatch(
        postNotification({
          message: 'Error occured while removing permission!',
          mode: 'error',
        }),
      )
    }
  }

export const getCurrentBookingServicePersmissions =
  (booking, service) => async (dispatch, getState) => {
    const state = getState()
    try {
      const bookingServicePermissionsResponse =
        await fetchBookingServicePermissions({
          bookingId: booking.id,
          serviceSlug: service.slug,
        })
      if (bookingServicePermissionsResponse.errorMessages) {
        throw new Error(bookingServicePermissionsResponse.message)
      }
      const servicePermissionsResponse = await fetchServicePermissions(
        service.id,
      )
      if (servicePermissionsResponse.errorMessages) {
        throw new Error(servicePermissionsResponse.message)
      }
      const currentTenantMembers = selectCurrentTenantMembers(state)
      dispatch(
        setBookingServicePermissions(
          booking.id,
          service.slug,
          bookingServicePermissionsResponse,
          servicePermissionsResponse,
          currentTenantMembers,
        ),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: err.message,
          mode: 'error',
        }),
      )
    }
  }

export const getBookingServiceProperties =
  (booking, service) => async (dispatch) => {
    try {
      const response = await fetchBookingServiceProperties(
        booking.id,
        service.slug,
      )
      if (response.errorMessages) {
        throw new Error(response.message)
      }
      dispatch(setBookingServiceProperties(booking.id, service.slug, response))
    } catch (err) {
      dispatch(
        postNotification({
          message: err.message,
          mode: 'error',
        }),
      )
    }
  }

export const setTenantBookings = (bookings) => ({
  type: SET_TENANT_BOOKINGS,
  payload: bookings,
})

export const setBookingMembers = (members) => ({
  type: SET_CURRENT_BOOKING_MEMBERS,
  payload: members,
})

export const setCurrentBookingServices = (bookingSlug, services) => ({
  type: SET_CURRENT_BOOKING_SERVICES,
  payload: { bookingSlug, services },
})

export const setBookingServiceProperties = (
  bookingPackageSlug,
  serviceNameSlug,
  properties,
) => ({
  type: SET_CURRENT_BOOKING_SERVICE_PROPERTIES,
  payload: { bookingPackageSlug, serviceNameSlug, properties },
})

export const setBookingServiceProperty = (property) => ({
  type: SET_CURRENT_BOOKING_SERVICE_PROPERTY,
  payload: property,
})

export const removeBookingServiceProperty = (payload) => ({
  type: REMOVE_BOOKING_SERVICE_PROPERTY,
  payload,
})

export const editBookingServiceProperty = (payload) => ({
  type: UPDATE_BOOKING_SERVICE_PROPERTY,
  payload,
})

export const setBookingPermissions = (bookingId, permissions) => ({
  type: SET_CURRENT_BOOKING_PERMISSIONS,
  payload: { bookingId, permissions },
})

export const setBookingServicePermissions = (
  bookingName,
  serviceSlug,
  bookingServicePermissions,
  servicePermissions,
  tenantMembers,
) => ({
  type: SET_CURRENT_BOOKING_SERVICE_PERMISSIONS,
  payload: {
    bookingName,
    serviceSlug,
    bookingServicePermissions,
    servicePermissions,
    tenantMembers,
  },
})

export const deleteBookingPermission = (booking, service, permissionId) => ({
  type: DELETE_BOOKING_SERVICE_PERMISSION,
  payload: { booking, service, permissionId },
})

export const addWaitingPermissions = (bookingId, serviceSlug, daimlerIds) => ({
  type: ADD_WAITING_PERMISSIONS,
  payload: { bookingId, serviceSlug, daimlerIds },
})

export const removeWaitingPermissions = (
  bookingId,
  serviceSlug,
  daimlerIds,
) => ({
  type: REMOVE_WAITING_PERMISSIONS,
  payload: { bookingId, serviceSlug, daimlerIds },
})
