import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { CSSTransition } from 'react-transition-group'
import classnames from 'classnames'

import { scrollTop, goToPage } from '../../../util'
import { animationSettings } from '../../../config'
import { trackPageView } from '../../../util/analytics'
import { Select, MenuList } from '../../common'

export const DocsNav = ({ navItems, sectionFolder, slug, sectionHash }) => {
  const [state, setState] = useState({
    narrowViewport: window.innerWidth < 768,
  })

  const handleResize = () =>
    setState({ narrowViewport: window.innerWidth < 768 })

  useEffect(() => {
    window.addEventListener('resize', debounce(handleResize), 200)
    return () =>
      window.removeEventListener('resize', debounce(handleResize), 200)
  }, [])

  const handleSelectionChange = (selectedItemId) => {
    if (!selectedItemId) {
      return
    }
    const index = navItems.findIndex((item) => item.id === selectedItemId)
    let hier2
    for (let i = index - 1; i >= 0; i--) {
      if (navItems[i].folder) {
        hier2 = navItems[i].id
        break
      }
    }
    trackPageView({
      name: selectedItemId,
      hier1: 'sla',
      hier2,
    })
  }

  const onItemClick = (pageSlug) => {
    scrollTop()
    goToPage(`/${sectionHash}/${pageSlug}`)
  }

  const { narrowViewport } = state
  const _timeout = animationSettings.timeout
  const navClassNames = classnames('nav')
  let DocumentationTitle = ''

  if (sectionFolder?.length) {
    const [firstLetter, ...restText] = Array.from(sectionFolder)
    DocumentationTitle = firstLetter.toUpperCase() + restText.join('')
  }
  return (
    <nav className={navClassNames}>
      <CSSTransition
        in={narrowViewport}
        timeout={_timeout}
        classNames="wb-e-dropd-1"
        unmountOnExit
      >
        <Select
          options={navItems.map(({ id, text }) => ({ name: text, id }))}
          value={slug}
          onChange={(e) => onItemClick(e.target.value)}
          label={`${DocumentationTitle} Documentation`}
          unselectedText="Select a page.."
        />
      </CSSTransition>

      <CSSTransition
        in={!narrowViewport}
        timeout={_timeout}
        classNames="wb-e-linklist-1"
        unmountOnExit
      >
        <MenuList
          items={navItems}
          selectedItem={slug}
          onItemClick={(itemId) => {
            onItemClick(itemId)
            handleSelectionChange(itemId)
          }}
        />
      </CSSTransition>
    </nav>
  )
}

DocsNav.propTypes = {
  navItems: PropTypes.array,
  sectionFolder: PropTypes.string,
  slug: PropTypes.string,
  sectionHash: PropTypes.string,
}
