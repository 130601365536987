import React from 'react'
import PropTypes from 'prop-types'

export const Tag = ({ label, variant, className }) => {
  return (
    <wb-tag variant={variant} className={className}>
      {label}
    </wb-tag>
  )
}

Tag.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  className: PropTypes.string,
}
