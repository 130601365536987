import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getWebHookProducers } from '../../../../../../../data/actions'
import { ProducersSelectorComponent } from '../component'

const ProducersContainer = ({
  producers,
  valid,
  touched,
  value,
  onChange,
  getProducers,
}) => {
  useEffect(() => {
    getProducers()
  }, [])

  const error = touched && !valid && 'Please select producer'
  return (
    <ProducersSelectorComponent
      producers={producers}
      value={value}
      onChange={onChange}
      error={error}
    />
  )
}

export const ProducersSelector = connect(
  ({ hermes: { producers } }) => ({ producers }),
  { getProducers: getWebHookProducers },
)(ProducersContainer)

ProducersContainer.propTypes = {
  producers: PropTypes.array,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.string,
  ]),
  touched: PropTypes.bool,
  onChange: PropTypes.func,
  getProducers: PropTypes.func,
}
