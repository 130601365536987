import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getServicePermissions,
  setServicePermission,
  setModal,
  deleteServicePermission,
} from '../../../../../../../data/actions'
import { PermissionsListComponent } from '../component'

const PermissionsListContainer = ({
  service,
  servicePermissions,
  dispatchModal,
  getPermissions,
  setPermission,
  deletePermission,
}) => {
  useEffect(() => {
    getPermissions(service.id)
  }, [service])

  const onClickDelete = (permissionId) =>
    dispatchModal({
      variant: 'prompt',
      onApprove: () => deletePermission(permissionId, () => dispatchModal({})),
      header: 'Are you sure you want to delete?',
      onCancel: () => dispatchModal({}),
      okText: 'Remove',
    })

  const onClickUpdate = (permission) => {
    setPermission(permission)
    dispatchModal({
      variant: 'permission',
      mode: 'edit',
      onCancel: () => dispatchModal({}),
    })
  }

  const handleAddPermission = () =>
    dispatchModal({
      variant: 'permission',
      mode: 'add',
      onCancel: () => dispatchModal({}),
    })

  return (
    <PermissionsListComponent
      readOnly={!service.isEditable}
      servicePermissions={servicePermissions}
      handleAddPermission={handleAddPermission}
      onClickDelete={onClickDelete}
      onClickUpdate={onClickUpdate}
    />
  )
}

PermissionsListContainer.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    provider: PropTypes.string,
    description: PropTypes.string,
    isEditable: PropTypes.bool,
  }),
  dispatchModal: PropTypes.func,
  servicePermissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
      description: PropTypes.string,
      providerOnly: PropTypes.bool,
    }),
  ),
  getPermissions: PropTypes.func,
  setPermission: PropTypes.func,
  deletePermission: PropTypes.func,
}

export const PermissionsList = connect(
  ({ backOffice: { service, servicePermissions } }) => ({
    service,
    servicePermissions,
  }),
  {
    dispatchModal: setModal,
    getPermissions: getServicePermissions,
    setPermission: setServicePermission,
    deletePermission: deleteServicePermission,
  },
)(PermissionsListContainer)
