import React from 'react'
import PropTypes from 'prop-types'
import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension'

import { Provider } from 'react-redux'

export default function configureStore(preloadedState) {
  const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 10,
  })

  return createStore(
    rootReducer,
    preloadedState,
    process.env.NODE_ENV === 'development'
      ? composeEnhancers(applyMiddleware(thunk))
      : applyMiddleware(thunk),
  )
}

export const ReduxProvider = ({ children }) => (
  <Provider store={configureStore()}>{children}</Provider>
)

ReduxProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
