import React from 'react'
import PropTypes from 'prop-types'
import { YearSelector } from '../YearSelector'
import { ExportButton } from '../ExportButton'

export const CCOHeader = ({ selectedYear, years, handleYearChange }) => (
  <div className="cross-charging_head">
    <div className="wb-margin-top-xs wb-margin-bottom-xs">
      <ExportButton title="Export All" />
    </div>

    <div className="cross-charging__sub-header">
      <div className="cross-charging__sub-header__start">
        Please choose for a year, to get the detailed report on cross charging
        topics per platform solution.
      </div>
      <div className="cross-charging__sub-header__end">
        <YearSelector
          selectedYear={selectedYear}
          handleYearChange={handleYearChange}
          years={years}
        />
      </div>
    </div>
  </div>
)

CCOHeader.propTypes = {
  selectedYear: PropTypes.number,
  years: PropTypes.arrayOf(PropTypes.number),
  handleYearChange: PropTypes.func,
}
