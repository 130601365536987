import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { fetchEmailList } from '../../../../../http'
import { EmailListComponent } from '../component'

export const EmailList = ({ packages }) => {
  const [filter, setFilter] = useState({
    roles: [],
    providers: [],
    packages: {},
  })
  const [emailList, setEmailList] = useState()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { roles: roleNames, providers: providerSlugs } = filter
    const permissionSlugs = []
    const packageSlugs = []
    Object.keys(filter.packages).forEach((packageSlug) => {
      packageSlugs.push(packageSlug)
      permissionSlugs.push(...filter.packages[packageSlug])
    })
    const emails = await fetchEmailList({
      roleNames,
      permissionSlugs,
      providerSlugs,
      packageSlugs,
    })
    setEmailList(emails.join(', '))
  }

  const updateFilterState = (filterControl, value) =>
    setFilter({
      ...filter,
      [filterControl]: value,
    })

  return (
    <EmailListComponent
      emailList={emailList}
      packages={packages}
      filter={filter}
      actions={{
        handleSubmit,
        updateFilterState,
      }}
    />
  )
}

EmailList.propTypes = {
  packages: PropTypes.array,
}
