export const generateReportByYear = (crossChargingOverview) => {
  if (!crossChargingOverview) {
    return null
  }
  const reportByYear = {}
  for (const pkg of crossChargingOverview) {
    const currentProvider = pkg.providerSlug
    for (const year in pkg.yearlyPrices) {
      const packagePrice = pkg.yearlyPrices[year].yearlyPrice
      const packageData = {
        name: pkg.packageName,
        price: packagePrice,
        priceVariantName: pkg.priceVariantName,
        provider: currentProvider,
        services: pkg.services,
        fundingShare: pkg.yearlyPrices[year].tenantFundingShare,
      }
      if (!reportByYear[year]) {
        reportByYear[year] = {}
      }
      if (!reportByYear[year][currentProvider]) {
        reportByYear[year][currentProvider] = {
          sum: 0,
          packages: [],
        }
      }
      reportByYear[year][currentProvider].sum += packagePrice
      reportByYear[year][currentProvider].packages.push(packageData)
    }
  }
  return reportByYear
}

export const copyToClipBoard = (textToCopy) => {
  navigator.clipboard.writeText(textToCopy)
}
