import React from 'react'
import PropTypes from 'prop-types'

export const FormSection = ({ title, children }) => (
  <div className="wb-padding-s compliance-form__section">
    <div className="wb-card wb-card--white wb-type-copy wb-padding-s">
      <h2>{title}</h2>
      {children}
    </div>
  </div>
)

FormSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
