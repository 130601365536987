import animateScrollTo from 'animated-scroll-to'
import { history } from '../data/history'

export const acronymFromPath = (path) => {
  let cleanPath

  if (!path) {
    return ''
  }

  cleanPath = path.substring(path.lastIndexOf('/') + 1)
  const isAuthInpath = cleanPath.indexOf('&')

  if (isAuthInpath > -1) {
    cleanPath = cleanPath.substring(0, isAuthInpath)
  }

  return cleanPath
}

export const scrollTop = () => {
  const scrollOptions = {
    minDuration: 200,
    maxDuration: 600,
  }

  animateScrollTo(0, scrollOptions)
}

export const tenantErrorMessage = (
  isValidTenant,
  hasUnselectedError,
  hasServerError,
  serverErrors,
  selectedTenant,
) => {
  if (hasUnselectedError) {
    return 'Please choose an Application.'
  }
  if (hasServerError) {
    return `An error occurred: ${serverErrors.message}.`
  }
  if (isValidTenant) {
    return ''
  }
  return `Sorry but apparently we are missing information for ${selectedTenant}.`
}

export const isUserTenantEditor = (tenantInfo, username) => {
  return tenantInfo
    ? (Array.isArray(tenantInfo.members) &&
        tenantInfo.members.find((member) => {
          // user is the tenant's PO or PM?
          let isPoOrPm = false
          if (member.roles) {
            for (const role of member.roles) {
              if (['PO', 'PM'].includes(role)) {
                isPoOrPm = true
                break
              }
            }
          } else if (member.role) {
            isPoOrPm = ['PO', 'PM'].includes(member.role)
          }

          return (
            isPoOrPm &&
            member.daimlerId &&
            username &&
            member.daimlerId.toLowerCase() === username.toLowerCase()
          )
        })) ||
        false
    : false
}

export const isUserDeveloper = (tenantInfo, username) => {
  const developerRoles = [
    'BACKEND_DEVELOPER',
    'FRONTEND_DEVELOPER',
    'SITE_RELIABILITY_ENGINEER',
  ]
  return tenantInfo
    ? (Array.isArray(tenantInfo.members) &&
        tenantInfo.members.find((member) => {
          let isDev = false
          if (member.roles) {
            for (const role of member.roles) {
              if (developerRoles.includes(role)) {
                isDev = true
                break
              }
            }
          } else if (member.role) {
            isDev = developerRoles.includes(member.role)
          }

          return (
            isDev &&
            member.daimlerId &&
            username &&
            member.daimlerId.toLowerCase() === username.toLowerCase()
          )
        })) ||
        false
    : false
}

export const objValuesToLowerCaseArray = (obj, whichKeys) => {
  return !obj || typeof obj !== 'object' || !Array.isArray(whichKeys)
    ? []
    : Object.keys(obj).reduce((arr, key) => {
        if (whichKeys.includes(key)) {
          arr.push(obj[key] ? obj[key].toLowerCase() : '')
        }
        return arr
      }, [])
}

export const generateFilteredTenants = (filterTerm, allTenants, roles = []) =>
  filterTerm.length
    ? filterTenantsWithTerm(filterTerm, allTenants, roles)
    : { ...allTenants }

export const filterTenantsWithTerm = (filterTerm, allTenants, roles = []) => {
  if (!filterTerm || typeof filterTerm !== 'string') {
    return allTenants
  }
  if (!allTenants || typeof allTenants !== 'object') {
    return null
  }
  filterTerm = filterTerm.toLowerCase()
  // which member props to look for the term
  const memberPropsToLookFor = [
    'daimlerId',
    'mbioId',
    'email',
    'firstName',
    'lastName',
    'fullName',
  ]
  return Object.keys(allTenants).reduce(function (previous, key) {
    const tenant = allTenants[key],
      members = tenant.members

    if (
      key.toLowerCase().indexOf(filterTerm) > -1 ||
      tenant.name.toLowerCase().indexOf(filterTerm) > -1
    ) {
      previous[key] = { ...tenant }
    }
    if (!previous[key]) {
      const foundInMembers = members.some((member) => {
        const decoratedMember = {
          ...member,
          fullName: `${member.firstName} ${member.lastName}`,
        }
        return objValuesToLowerCaseArray(
          decoratedMember,
          memberPropsToLookFor,
        ).find((value) => {
          return value.indexOf(filterTerm) > -1
        })
      })
      if (foundInMembers) {
        previous[key] = { ...tenant }
      }
    }
    return previous
  }, {})
}

export const getMemberByRoles = (members, roles) => {
  return (
    members?.filter((member) =>
      member.roles.some((role) => roles?.includes(role)),
    ) || []
  )
}

export const normalizeServerErrors = (response) => {
  if (
    response &&
    typeof response === 'object' &&
    !Array.isArray(response) &&
    !response.hasOwnProperty('message') &&
    !response.hasOwnProperty('error')
  ) {
    return response
  }
  if (!response || Array.isArray(response)) {
    response = {}
  }
  if (!response.message || typeof response.message !== 'string') {
    response.message =
      response.error && typeof response.error === 'string'
        ? response.error
        : 'Unknown'
  }
  response.hasErrors = true
  return response
}

export const generateSampleObjFromJSONSchema = (schemaJson, staticJson) => {
  if (
    typeof schemaJson !== 'object' ||
    !Object.keys(schemaJson).length ||
    schemaJson.properties === 'undefined'
  ) {
    return {}
  }

  let sample = {}

  if (schemaJson.hasOwnProperty('properties')) {
    try {
      const props = Object.keys(schemaJson.properties)
      props.forEach((key) => {
        const properties = schemaJson.properties[key]
          ? schemaJson.properties[key]
          : null
        if (properties !== null) {
          sample[key] = parseTenantItem(key, properties)
        }
      })

      //parsing members
      const members = schemaJson.properties.members
      if (members?.hasOwnProperty('items')) {
        sample.members = []
        const member = {}
        const memberItemProps = Object.keys(members.items.properties)
        memberItemProps.forEach((key) => {
          const item = members.items.properties[key]
          member[key] = parseTenantItem(key, item)
        })
        sample.members.push(member)
      }
    } catch (e) {
      console.error(e.message)
      return {}
    }
  }

  //static info
  if (typeof staticJson === 'object') {
    sample = { ...sample, ...staticJson }
  }
  return sample
}

//get default value for each tenant prop
function parseTenantItem(key, item) {
  let value

  if (item.type) {
    switch (item.type) {
      case 'number':
        value = 0
        break
      case 'boolean':
        value = !1
        break
      case 'array':
        value = []
        break
      case 'string':
      default:
        value = null
    }

    //has default
    if (item.default !== undefined) {
      value = item.default
    }

    //without any pattern.. hardcoded
    if (['tenantMemberUntil', 'tenant'].indexOf(key) >= 0) {
      value = ''
    }

    //if is multiple option string and item 'unknown' is available, select it, otherwise select first item of the list
    if (item.type === 'string' && item.enum) {
      value = item.enum.indexOf('unknown') > 0 ? 'unknown' : item.enum[0]
    }
  }

  return value
}

export const goToPage = (path) => {
  let currentPath = history.location.pathname
  const i = currentPath.indexOf('&')

  // for comparison and if needed, remove extra params from the url
  if (i !== -1) {
    currentPath = currentPath.substr(0, i)
  }

  if (path !== currentPath) {
    if (window.location.hash !== `#${path}`) {
      history.push(path)
    }
    return true
  }

  return false
}

export const generateTenantBookingPermissions = (permissions, userRoles) => {
  if (userRoles.some((role) => ['admin', 'tenantadmin'].includes(role))) {
    return permissions
  }
  return permissions.filter((perm) => !perm.providerOnly)
}

export const generateNebulaMembers = (allTenants) => {
  const nebulaMembers = {}
  const tenantAcrs = ['tools', 'nebula', 'monitoring', 'tdms']
  tenantAcrs.forEach((acr) =>
    allTenants[acr]?.members.forEach((member) => {
      nebulaMembers[member.daimlerId] = member
    }),
  )
  return nebulaMembers
}

export const slugify = (str) => {
  str = str.trim().toLowerCase()
  const from = [
    'а',
    'б',
    'в',
    'г',
    'д',
    'е',
    'ж',
    'з',
    'и',
    'й',
    'к',
    'л',
    'м',
    'н',
    'о',
    'п',
    'р',
    'с',
    'т',
    'у',
    'ф',
    'х',
    'ц',
    'ч',
    'ш',
    'щ',
    'ъ',
    'ь',
    'ю',
    'я',
  ]
  const to = [
    'a',
    'b',
    'v',
    'g',
    'd',
    'e',
    'zh',
    'z',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'r',
    's',
    't',
    'u',
    'f',
    'h',
    'c',
    'ch',
    'sh',
    'sht',
    'y',
    '',
    'iu',
    'ia',
  ]
  for (const val of from) {
    str = str.replace(new RegExp(val, 'g'), to[key])
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}

export const flat = (list, depth) => {
  return list.reduce(function (flatArr, toFlatten) {
    return flatArr.concat(
      Array.isArray(toFlatten) && depth > 1
        ? toFlatten.flat(depth - 1)
        : toFlatten,
    )
  }, [])
}

export const mapTenantMembersWithPermissions = (members, permissions) => {
  return members.map((member) => {
    const permission = permissions.find(
      (perm) => perm.daimlerId === member.daimlerId,
    )
    return {
      ...member,
      permissions: permission?.packageBookingPermissionsPrettyMap,
    }
  })
}

export const generateErrorMessage = (err) => {
  return err.message || err.errorMessages.map((error) => error.message)
}

export const downloadBlob = (blob, name) => {
  const blobUrl = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = blobUrl
  link.download = name

  document.body.appendChild(link)

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  )
  document.body.removeChild(link)
}

export const modifyComplianceDataResponceBody = (complianceInfo) => {
  const newData = structuredClone(complianceInfo)
  const targetBaseProps = [
    'automatedDecisionMaking',
    'dataAnalytics',
    'processOnBehalf.mbagOwned',
    'processOnBehalf.thirdPartyAccess',
    'processOnBehalf.mbagAgreementEntity',
    'processOnBehalf.personalData',
  ]
  targetBaseProps.forEach((prop) => {
    const [prop1, prop2] = prop.split('.')
    if (newData[prop1] === '') {
      newData[prop1] = null
    }
    if (newData[prop1]?.[prop2] === '') {
      newData[prop1][prop2] = null
    }
  })
  if (newData.processOnBehalf.riskAssessment === '') {
    newData.processOnBehalf.riskAssessment = []
  }
  return newData
}
