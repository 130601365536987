import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  getUsersWithPermissions,
  setModal,
  updateUserRoles,
} from '../../../../../data/actions'
import { PermissionsTableContainer } from '../components/UsersWithPermissionsTable'

export const PermissionsContainer = ({
  tdmsUsers,
  fetchUsersWithRoles,
  dispatchModal,
  saveUserRoles,
}) => {
  useEffect(() => {
    fetchUsersWithRoles()
  }, [])
  const onSave = (daimlerId, roles) => saveUserRoles(daimlerId, roles)
  const onCancel = () => dispatchModal({})
  const tdmsUserDaimlerIds = tdmsUsers.map(({ daimlerId }) => daimlerId)
  const onClickAddUsers = () =>
    dispatchModal({
      variant: 'tdmsUserForm',
      header: 'Add new User to TDMS',
      tdmsUserDaimlerIds,
      onSave,
      onCancel,
    })

  const onClickEditUser = (user) =>
    dispatchModal({
      variant: 'tdmsUserForm',
      header: 'Change User roles',
      user,
      tdmsUserDaimlerIds,
      onSave,
      onCancel,
    })

  return (
    <PermissionsTableContainer
      data={tdmsUsers}
      onAddUser={onClickAddUsers}
      onEditUser={onClickEditUser}
    />
  )
}

PermissionsContainer.propTypes = {
  tdmsUsers: PropTypes.arrayOf(
    PropTypes.shape({
      daimlerId: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      userInfo: PropTypes.shape({
        roles: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  ),
  fetchUsersWithRoles: PropTypes.func,
  dispatchModal: PropTypes.func,
  saveUserRoles: PropTypes.func,
}

export const Permissions = connect(
  ({ backOffice: { tdmsUsers } }) => ({
    tdmsUsers,
  }),
  {
    fetchUsersWithRoles: getUsersWithPermissions,
    dispatchModal: setModal,
    saveUserRoles: updateUserRoles,
  },
)(PermissionsContainer)
