import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { TenantsSelectorComponent } from '../component'

const MainComponent = ({ allTenants, valid, value, touched, onChange }) => {
  const tenantAcronyms = Object.keys(allTenants)

  const tenantsArray = tenantAcronyms.map((tenantAcr) => ({
    name: allTenants[tenantAcr].name,
    slug: tenantAcr,
  }))

  return (
    <TenantsSelectorComponent
      tenantsArray={tenantsArray}
      value={value}
      invalid={touched && !valid}
      onClickTenant={onChange}
    />
  )
}

MainComponent.propTypes = {
  allTenants: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  ]),
  touched: PropTypes.bool,
  onChange: PropTypes.func,
}

export const TenantsSelector = connect(({ tenants: { allTenants } }) => ({
  allTenants,
}))(MainComponent)
