export const initialGithubProtocolState = {
  organization: {
    value: '',
    touched: false,
    validationRules: {
      minLength: 2,
    },
    valid: false,
  },
  repository: {
    value: '',
    touched: false,
    validationRules: { minLength: 2 },
    valid: false,
  },
  workflowFileName: {
    value: '',
    touched: false,
    validationRules: { minLength: 2 },
    valid: false,
  },
  gitReference: {
    value: '',
    touched: false,
    validationRules: { minLength: 2 },
    valid: false,
  },
  githubToken: {
    value: '',
    touched: false,
    validationRules: {},
    valid: true,
  },
}

export const initialHttpProtocolState = {
  url: {
    value: '',
    valid: false,
    touched: false,
    validationRules: {
      isValidUrl: true,
      minLength: 10,
    },
  },
  headers: {
    value: [],
    valid: true,
    touched: false,
    validationRules: {},
  },
  auth: {
    value: 'none',
    valid: true,
    touched: false,
    validationRules: {
      oneOf: ['none', 'basic', 'token'],
    },
  },
  username: {
    value: '',
    valid: false,
    touched: false,
    validationRules: {
      requireIf: { condition: { auth: 'basic' }, rule: { minLength: 2 } },
    },
  },
  password: {
    value: '',
    valid: false,
    touched: false,
    validationRules: {
      requireIf: { condition: { auth: 'basic' }, rule: { minLength: 2 } },
    },
  },
  token: {
    value: '',
    valid: false,
    touched: false,
    validationRules: {
      requireIf: { condition: { auth: 'token' }, rule: { minLength: 2 } },
    },
  },
  timeout: {
    value: 100,
    valid: true,
    touched: false,
    validationRules: {
      isNumeric: true,
    },
  },
}

export const baseState = {
  protocol: {
    value: 'http',
    valid: true,
    touched: true,
    validationRules: {
      oneOf: ['http', 'github'],
    },
  },
  producer: {
    value: '',
    valid: false,
    touched: false,
    validationRules: {
      minLength: 1,
    },
  },
  providers: {
    value: [],
    valid: false,
    touched: false,
    validationRules: {
      minLength: 1,
      orAll: true,
    },
  },
  tenants: {
    value: [],
    valid: false,
    touched: false,
    validationRules: {
      minLength: 1,
      orAll: true,
    },
  },
  events: {
    value: [],
    valid: false,
    touched: false,
    validationRules: {
      minLength: 1,
      orAll: true,
    },
  },
}
