import { tdmsClient } from '../apiClient'

export const fetchEmailList = async (body) =>
  tdmsClient.client.platformBookingReportsMethods.emailsReport(body)

export const fetchCrossChargingOverview = async () =>
  tdmsClient.client.platformBookingReportsMethods.crossChargingOverview()

export const fetchTenantsCrossChargingOverview = async (acronym) =>
  tdmsClient.client.platformBookingReportsMethods.tenantsCrossChargingOverview(
    acronym,
  )

export const generateExport = async (provider, format) =>
  tdmsClient.client.platformBookingReportsMethods.exportCrossChargingOverview(
    format.toUpperCase(),
    provider,
  )
