import React from 'react'
import PropTypes from 'prop-types'
import { Header } from '../components/Header'
import { PermissionsTable } from '../components/PermissionsTable'

export const BookingServicePermissionsComponent = ({
  disabledAdd,
  disabledEdit,
  members,
  booking,
  service,
  currentServicePermissions,
  loggedInPoPmDaimlerId,
  actions: { handleAddMember, handleEditSelected, onSelectMember },
}) => (
  <div className="booking-permissions">
    <Header
      handleAddMember={handleAddMember}
      handleEditSelected={handleEditSelected}
      disabledAdd={disabledAdd}
      disabledEdit={disabledEdit}
    />
    <PermissionsTable
      onSelectMember={onSelectMember}
      members={members}
      booking={booking}
      service={service}
      currentServicePermissions={currentServicePermissions}
      loggedInPoPmDaimlerId={loggedInPoPmDaimlerId}
    />
  </div>
)

BookingServicePermissionsComponent.propTypes = {
  disabledAdd: PropTypes.bool,
  disabledEdit: PropTypes.bool,
  members: PropTypes.array,
  booking: PropTypes.object,
  service: PropTypes.object,
  currentServicePermissions: PropTypes.array,
  loggedInPoPmDaimlerId: PropTypes.string,
  actions: PropTypes.shape({
    handleAddMember: PropTypes.func,
    handleEditSelected: PropTypes.func,
    onSelectMember: PropTypes.func,
  }),
}
