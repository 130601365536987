import React from 'react'
import PropTypes from 'prop-types'

export const ListGroup = ({ children }) => (
  <div className="wb-list-group">{children}</div>
)

ListGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
