import moment from 'moment'

export const monthDiff = (dateFrom, dateTo) =>
  dateTo.getMonth() -
  dateFrom.getMonth() +
  12 * (dateTo.getFullYear() - dateFrom.getFullYear())

export const getServiceReportData = (
  startDate,
  endDate,
  bookings,
  servicePackages,
) => {
  const numMonths = monthDiff(new Date(startDate), new Date(endDate)) + 1
  const startMonth = moment(`${startDate}-01`)

  const componentData = {}
  for (let month = 0; month < numMonths; month++) {
    const currMonth = moment(startMonth).add(month, 'M').format('YY-M')
    componentData[currMonth] = {
      key: `column_${month}`,
      label: currMonth,
      onboarding: {},
      offboarding: {},
    }
  }

  const componentDataKeys = Object.keys(componentData)
  Object.keys(bookings).forEach((acronym) => {
    bookings[acronym].forEach((booking) => {
      const { service, bookInfo } = booking
      if (servicePackages[service].provider !== 'Nebula') {
        return
      }
      const startMonthAsKey = `${bookInfo.startYear}-${bookInfo.startMonth}`
      const endMonthAsKey = `${bookInfo.endYear}-${bookInfo.endMonth}`
      if (componentDataKeys.includes(startMonthAsKey)) {
        if (!componentData[startMonthAsKey].onboarding[acronym]) {
          componentData[startMonthAsKey].onboarding[acronym] = []
        }
        componentData[startMonthAsKey].onboarding[acronym].push({
          service: service,
          comment: bookInfo.comment,
        })
      }
      if (componentDataKeys.includes(endMonthAsKey)) {
        if (!componentData[endMonthAsKey].offboarding[acronym]) {
          componentData[endMonthAsKey].offboarding[acronym] = []
        }
        componentData[endMonthAsKey].offboarding[acronym].push({
          service: service,
          comment: bookInfo.comment,
        })
      }
    })
  })

  return componentData
}

export const getBoardingData = (label, list) => {
  const items = []
  for (const [acronym, packages] of Object.entries(list)) {
    items.push({
      key: `${label}-${acronym}`,
      acronym: acronym,
      packages: packages,
    })
  }
  return items
}
