import React from 'react'
import PropTypes from 'prop-types'
import { ComplianceForm } from './components/ComplianceForm'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
} from '../../../../common'

export const TenantComplianceDialog = ({ onClose }) => (
  <Modal mode="edit" className="tenant-dialog" onCloseModal={onClose}>
    <ModalHeader />
    <ModalContent
      headline="Tenant Compliance Info"
      class="tenant-dialog__content"
    >
      <ComplianceForm />
    </ModalContent>
    <ModalFooter className="tenant-dialog__footer">
      <Button variant="secondary" size="large" onClick={onClose}>
        Close
      </Button>
    </ModalFooter>
  </Modal>
)
TenantComplianceDialog.propTypes = {
  onClose: PropTypes.func,
}
