import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

export const MessageWithTimer = ({ estimatedSeconds }) => {
  const [seconds, setSeconds] = useState(estimatedSeconds)
  useEffect(() => {
    const interval = setInterval(
      () => seconds >= 0 && setSeconds(seconds - 1),
      1000,
    )
    return () => clearInterval(interval)
  }, [seconds])
  return (
    <div className="message-with-timer">
      Invalidation will take approx. {seconds} seconds. Status will be updated
      per CP code.
    </div>
  )
}

MessageWithTimer.propTypes = {
  estimatedSeconds: PropTypes.number,
}
