import { connect } from 'react-redux'
import { PermissionsSelectorComponent } from '../component'
import { selectCurrentBookingServicePermissions } from '../../../../../../../../../../../../data/selectors/tenantBookings'
import { generateTenantBookingPermissions } from '../../../../../../../../../../../../util'

export const PermissionsSelector = connect(
  (
    { tenantBookings: { bookingServicePermissions }, sso: { roles } },
    ownProps,
  ) => ({
    currentServicePermissions: generateTenantBookingPermissions(
      selectCurrentBookingServicePermissions(
        ownProps.booking.id,
        ownProps.service.slug,
        bookingServicePermissions,
      ),
      roles,
    ),
  }),
)(PermissionsSelectorComponent)
