import React from 'react'
import PropTypes from 'prop-types'

import { MyProfile } from '../components/MyProfile'
import { MyApiKeys } from '../components/MyApiKey'

const MemberFormComponent = ({
  selectedTab,
  onTabChange,
  properties,
  formData,
  loading,
  actions: { handleValueChange, handleUserUpdate, handleSubmit },
}) => {
  const tabMapper = {
    myprofile: {
      component: MyProfile,
      title: 'My Profile',
    },
    apikeys: {
      component: MyApiKeys,
      title: 'API Keys',
    },
  }

  const Component = tabMapper[selectedTab].component || null
  const tabsList = Object.keys(tabMapper).filter((key) => tabMapper[key])

  return (
    <div className="body">
      <div className="content">
        <div className="page-header">
          <h2 className="page-header__title">My Profile</h2>
        </div>
        <div className="page-body member-form__content">
          <wb-tabs selected={selectedTab.toUpperCase()}>
            <wb-tab-bar>
              {tabsList.map((currentTab) => (
                <wb-tab
                  key={currentTab}
                  name={currentTab.toUpperCase()}
                  onClick={() => onTabChange(currentTab)}
                >
                  {tabMapper[currentTab].title}
                </wb-tab>
              ))}
            </wb-tab-bar>
            <div className="wb-padding-xs">
              {Component && (
                <Component
                  properties={properties}
                  formData={formData}
                  loading={loading}
                  handleValueChange={handleValueChange}
                  handleUserUpdate={handleUserUpdate}
                  handleSubmit={handleSubmit}
                />
              )}
            </div>
          </wb-tabs>
        </div>
      </div>
    </div>
  )
}

MemberFormComponent.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string })),
  formData: PropTypes.object,
  loading: PropTypes.bool,
  actions: PropTypes.shape({
    handleValueChange: PropTypes.func,
    handleUserUpdate: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
  selectedTab: PropTypes.string,
  onTabChange: PropTypes.func,
}

export { MemberFormComponent }
