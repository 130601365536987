import React from 'react'
import PropTypes from 'prop-types'
import { Button, TooltipHost } from '../../../../../../common'

export const AddUserButtonComponent = ({ dispatchModal }) => (
  <TooltipHost
    position="top"
    message="User needs to login to TDMS once and complete profile"
  >
    <Button
      variant="primary"
      size="medium"
      onClick={() =>
        dispatchModal({
          variant: 'addUser',
          header: 'Add new User(s) to your Tenant',
          onCancel: () => dispatchModal({}),
        })
      }
      withIcon="bds/plus/24"
    >
      Add User
    </Button>
  </TooltipHost>
)

AddUserButtonComponent.propTypes = {
  dispatchModal: PropTypes.func,
}
