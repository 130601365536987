import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getTenantsCrossChargingOverview } from '../../../../../data/actions'
import { TenantYearOverview } from './components/TenantYearOverview'

const TenantCostOverviewContainer = ({
  selectedTenant,
  currentTenantCrossCharging,
  getCCR,
}) => {
  useEffect(() => {
    getCCR()
  }, [selectedTenant])
  return (
    <div className="tenant-cost-overview-new">
      <h3
        className="tenant-body__block-label"
        style={{ padding: '20px 0 0 30px', textAlign: 'left' }}
      >
        Costs Overview
      </h3>
      <div className="tenant-cost-overview-new__content">
        {currentTenantCrossCharging?.map((entry) => (
          <div key={entry.year} className="tenant-cost-overview-new__section">
            <TenantYearOverview entry={entry} total={entry.totalYearPrice} />
          </div>
        ))}
      </div>
    </div>
  )
}

TenantCostOverviewContainer.propTypes = {
  selectedTenant: PropTypes.string,
  currentTenantCrossCharging: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.string,
      totalYearPrice: PropTypes.number,
    }),
  ),
  getCCR: PropTypes.func,
}

export const TenantCostOverview = connect(
  ({
    reports: { currentTenantCrossCharging },
    tenants: { selectedTenant },
  }) => ({
    currentTenantCrossCharging,
    selectedTenant,
  }),
  { getCCR: getTenantsCrossChargingOverview },
)(TenantCostOverviewContainer)
