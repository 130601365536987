import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../../../common'
import { ProviderDetails } from '../components/ProviderDetails'
import { UsersList } from '../components/UsersList'

export const ProviderPageComponent = ({ goBack }) => (
  <div>
    <Button
      onClick={goBack}
      withIcon="chevron-left"
      className="wb-margin-bottom-xs"
      variant="tertiary"
      size="large"
    >
      Providers List
    </Button>
    <div style={{ width: '50%' }}>
      <ProviderDetails />
    </div>
    <div>
      <UsersList />
    </div>
  </div>
)

ProviderPageComponent.propTypes = {
  goBack: PropTypes.func,
}
