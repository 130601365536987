import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Button } from '../../../common'

export const MenuItem = ({ title, isActive, onClick, ...rest }) => (
  <li
    className={classnames('main-menu__item', {
      'main-menu__item--active': isActive,
    })}
  >
    <Button
      variant="tertiary"
      size="small"
      className="main-menu__item-link"
      onClick={onClick}
      {...rest}
    >
      {title}
    </Button>
  </li>
)

MenuItem.propTypes = {
  title: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}
