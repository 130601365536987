import React from 'react'
import PropTypes from 'prop-types'

export const AccordionItem = ({ children }) => (
  <wb-accordion-item>{children}</wb-accordion-item>
)

AccordionItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
