export const combineMembersAndPermissions = (
  members,
  bookingPermissions,
  servicePermissions,
) =>
  sortArrayOfObjectsByProperty(members, 'firstName').map((member) => {
    const memberPermission = bookingPermissions.find(
      (perm) => perm.userDaimlerId === member.daimlerId,
    )
    const servicePermission =
      memberPermission &&
      servicePermissions.find(
        (perm) => perm.id === memberPermission.permissionId,
      )
    const memberServicePermission = servicePermission
      ? { ...servicePermission }
      : null
    if (servicePermission) {
      memberServicePermission.perm = { ...memberPermission }
    }
    member.permission = memberServicePermission
    return member
  })

export const combineTenantMemberWithNebulaMembership = (
  tenantMembers,
  nebulaMembershipArray,
) =>
  tenantMembers.map((member) => ({
    ...member,
    isNebulaMember: nebulaMembershipArray.some(
      (item) => item[member.daimlerId],
    ),
  }))

export const sortArrayOfObjectsByProperty = (array, property) =>
  [...array].sort((a, b) => {
    const textA = a[property].toLowerCase()
    const textB = b[property].toLowerCase()
    return textA.localeCompare(textB)
  })
