import React from 'react'
import PropTypes from 'prop-types'

export const Spinner = ({ withText, className, theme = 'light' }) => (
  <>
    {withText}
    <span className={className}>
      <wb-spinner
        theme={theme}
        style={{ width: '24px', height: '24px' }}
      ></wb-spinner>
    </span>
  </>
)

Spinner.propTypes = {
  withText: PropTypes.string,
  theme: PropTypes.string,
  className: PropTypes.string,
}
