import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { TenantInformation } from '../components/TenantInformation'
import { Edit } from '../components/edit'
import { DeleteConfirmationModalContainer } from '../components/deleteConfirmationModal'
import { ModalDialogs } from '../components/ModalDialogs'
import { Spinner } from '../../../common'
import { animationSettings } from '../../../../config'
import { isUserTenantEditor, tenantErrorMessage } from '../../../../util'

const _timeout = animationSettings.timeout

export const TenantComponent = ({
  isFetching,
  isSaving,
  isEditing,
  activeModalId,
  serverErrors,
  selectedTenant,
  match,
  username,
  isWhitelisted,
  arts,
  tenantInformation,
  allTenants,
  roles,
  actions: {
    setModal,
    onEditClick,
    changeDialog,
    onDeleteConfirmClick,
    onSaveClick,
    onCancelClick,
  },
}) => {
  const isValidTenant = tenantInformation?.name !== undefined

  const rolesSet = new Set(roles)
  const hasRolePermission = [...new Set(tenantInformation.type)].filter((x) =>
    rolesSet.has(x),
  )

  const isEditable = !!(
    (!isFetching &&
      isValidTenant &&
      (isWhitelisted ||
        isUserTenantEditor(tenantInformation, username) ||
        roles?.includes('admin'))) ||
    hasRolePermission.length
  )
  const hasServerError = !isFetching && serverErrors.hasErrors
  const hasUnselectedError =
    !isFetching && !hasServerError && !selectedTenant?.length

  const errorMsg = tenantErrorMessage(
    isValidTenant,
    hasUnselectedError,
    hasServerError,
    serverErrors,
    selectedTenant,
  )
  return (
    <>
      <CSSTransition
        in={isFetching}
        timeout={_timeout}
        classNames="loader-container"
        unmountOnExit
      >
        <div className="loader-container">
          <div className="loader">
            <Spinner mode="dark" />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={
          (!isEditing && hasServerError) ||
          (!isFetching && !!selectedTenant && !isValidTenant)
        }
        timeout={_timeout}
        classNames="tenants-info"
        unmountOnExit
      >
        <div className="page-content">
          <div className="page-body page-body__message_box  page-body__message_box-error">
            {errorMsg}
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={!isEditing && hasUnselectedError}
        timeout={_timeout}
        classNames="tenants-info"
        unmountOnExit
      >
        <div className="page-content">
          <div className="page-body page-body__message_box">{errorMsg}</div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={!isEditing && !hasServerError && !isFetching && isValidTenant}
        timeout={_timeout}
        classNames="tenant-content"
        unmountOnExit
      >
        <TenantInformation
          selectedTenant={selectedTenant}
          arts={arts}
          userRoles={roles}
          tenantInfo={{ ...tenantInformation }}
          username={username}
          isEditable={isEditable}
          isWhitelisted={isWhitelisted}
          onEditClick={onEditClick}
          onDeleteClick={() => setModal('DeleteConfirmationModal')}
          onMembersClick={() => changeDialog('members')}
          onLogsClick={() => changeDialog('logs')}
          onBookingsClick={() => changeDialog('bookings')}
          onAkamaiCacheInvalidation={() =>
            changeDialog('akamaiCacheInvalidation')
          }
          onChangeProviderClick={() => changeDialog('changeProvider')}
          onComplianceInfoClick={() => changeDialog('compliance')}
        />
      </CSSTransition>

      <CSSTransition
        in={isEditable && isEditing}
        timeout={_timeout}
        classNames="tenants-info"
        unmountOnExit
      >
        <Edit
          arts={arts}
          tenantInfo={{ ...tenantInformation }}
          isSaving={isSaving}
          serverErrors={serverErrors}
          onSaveClick={onSaveClick}
          onCancelClick={onCancelClick}
        />
      </CSSTransition>

      <CSSTransition
        in={isSaving}
        timeout={_timeout}
        classNames="loader-container"
        unmountOnExit
      >
        <div className="loader-container--saving">
          <div className="loader">
            <Spinner mode="dark" />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeModalId === 'DeleteConfirmationModal'}
        timeout={animationSettings.timeoutInOut}
        classNames="modal_fx"
        unmountOnExit={true}
      >
        <DeleteConfirmationModalContainer
          onDeleteConfirmClick={onDeleteConfirmClick}
          onDeleteCancelClick={() => setModal(null)}
          onDeleteCloseClick={() => setModal(null)}
        />
      </CSSTransition>
      <ModalDialogs
        tenantInfo={tenantInformation}
        allTenants={allTenants}
        match={match}
        onClose={() => changeDialog()}
      />
    </>
  )
}

TenantComponent.propTypes = {
  isFetching: PropTypes.bool,
  isSaving: PropTypes.bool,
  isEditing: PropTypes.bool,
  isWhitelisted: PropTypes.bool,
  activeModalId: PropTypes.string,
  serverErrors: PropTypes.object,
  selectedTenant: PropTypes.string,
  match: PropTypes.object,
  username: PropTypes.string,
  arts: PropTypes.array,
  tenantInformation: PropTypes.object,
  allTenants: PropTypes.objectOf(PropTypes.object),
  roles: PropTypes.array,
  actions: PropTypes.shape({
    setModal: PropTypes.func,
    onEditClick: PropTypes.func,
    changeDialog: PropTypes.func,
    onDeleteConfirmClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onCancelClick: PropTypes.func,
  }),
}
