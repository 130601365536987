import { combineReducers } from 'redux'

import { backOfficeReducer } from './backOffice/backOffice'
import { globalStateReducer } from './globalState'
import { modalReducer } from './modal'
import { reportsReducer } from './reports'
import { rolesReducer } from './roles'
import { ssoReducer } from './sso'
import { tenantBookingsReducer } from './tenantBookings'
import { tenantsReducer } from './tenants'
import { uiReducer } from './ui'
import { akamaiReducer } from './akamai'
import { hermesReducer } from './hermes'
import { apikeyReducer } from './apiKeys'

export default combineReducers({
  tenants: tenantsReducer,
  sso: ssoReducer,
  modal: modalReducer,
  global: globalStateReducer,
  roles: rolesReducer,
  backOffice: backOfficeReducer,
  tenantBookings: tenantBookingsReducer,
  reports: reportsReducer,
  ui: uiReducer,
  akamai: akamaiReducer,
  hermes: hermesReducer,
  apiKeys: apikeyReducer,
})
