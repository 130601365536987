import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ErrorControl } from '../../../../../../common'
import { ItemList } from './ItemList'

export const EventsSelectorComponent = ({
  eventTypes,
  value,
  dense,
  invalid,
  onCheckBoxClick,
  chooseForAll,
  openCloseEvents,
}) => {
  const [openSection, setOpenSection] = useState(null)

  const handleOpenSection = (index) => {
    setOpenSection((prevIndex) => (prevIndex === index ? null : index))
  }

  return (
    <>
      <div
        className="wh-events-selection"
        style={!openCloseEvents ? { display: 'none' } : { display: 'block' }}
      >
        {Object.keys(eventTypes).map((groupKey) => {
          const itemsList = eventTypes[groupKey].map((event) => (
            <ItemList
              onCheckBoxClick={onCheckBoxClick}
              value={value}
              event={event}
              id={groupKey}
              chooseForAll={chooseForAll}
              key={event.slug}
            />
          ))

          return (
            <div key={groupKey} className="wh-events-selection__item">
              <div
                style={{
                  display: 'inline-flex',
                  paddingLeft: '40px',
                  height: dense ? '50px' : '',
                }}
              >
                <wb-icon
                  name="bds/caret-down/24"
                  style={
                    openSection === groupKey
                      ? { transform: 'rotate(0deg)', cursor: 'pointer' }
                      : { transform: 'rotate(270deg)', cursor: 'pointer' }
                  }
                  onClick={() => handleOpenSection(groupKey)}
                />
                <button
                  onClick={() => handleOpenSection(groupKey)}
                  style={{
                    cursor: 'pointer',
                    background: 'none',
                    border: 'none',
                    fontSize: 'medium',
                    fontFamily: 'MBCorpo Text',
                    fontWeight: 700,
                  }}
                >
                  {groupKey}
                </button>
              </div>
              <div
                key={groupKey}
                style={
                  openSection === groupKey
                    ? { display: 'block', paddingLeft: '100px' }
                    : { display: 'none' }
                }
              >
                {itemsList}
              </div>
            </div>
          )
        })}
      </div>
      <ErrorControl error={invalid && 'Please select at least one item'} />
    </>
  )
}

EventsSelectorComponent.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  dense: PropTypes.bool,
  invalid: PropTypes.bool,
  eventTypes: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        producerId: PropTypes.number,
        slug: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        group: PropTypes.number,
      }),
    ),
  ),
  onCheckBoxClick: PropTypes.func,
  chooseForAll: PropTypes.bool,
  openCloseEvents: PropTypes.bool,
}
