import React from 'react'

export const Footer = () => (
  <div className="footer">
    <wb-grid>
      <wb-grid-row>
        <wb-grid-col mq1="12">
          <div className="footer__text-with-image">
            <p className="footer__text">{new Date().getFullYear()}</p>
            <img
              src="assets/mbio.png"
              className="footer__mbio"
              alt="Mercedes-Benz.io"
              loading="lazy"
            />
          </div>
        </wb-grid-col>
      </wb-grid-row>
    </wb-grid>
  </div>
)
