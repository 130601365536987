import React from 'react'
import CreatableSelect from 'react-select/creatable'
import { PropTypes } from 'prop-types'

export const ReactSelectCreatable = ({
  value,
  onLabelInputChange,
  options,
}) => {
  return (
    <CreatableSelect
      value={value}
      isMulti
      onChange={onLabelInputChange}
      isClearable
      options={options}
      className="autocomplete"
      classNamePrefix="as"
      placeholder="Add or Create"
    />
  )
}

const optionProp = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
})

ReactSelectCreatable.propTypes = {
  value: PropTypes.oneOfType([PropTypes.arrayOf(optionProp)]),
  onLabelInputChange: PropTypes.func,
  options: PropTypes.arrayOf(optionProp),
}
