import React from 'react'
import PropTypes from 'prop-types'
import { ProviderOptions } from '../ProviderOptions'
import { RolesOptions } from '../RolesOptions'
import { PackageOptions } from '../PackageOptions'
import { Button } from '../../../../../common'
import { groupPackagesByProviders } from '../../utils'

export const FilterEmailsForm = ({
  packages,
  filter,
  actions: { handleSubmit, updateFilterState },
}) => (
  <form onSubmit={handleSubmit}>
    <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
      <PackageOptions
        items={packages}
        checkedPackages={filter.packages}
        onItemCheck={(value) => updateFilterState('packages', value)}
      />
    </div>
    <ProviderOptions
      items={Object.keys(groupPackagesByProviders(packages))}
      checkedProviders={filter.providers}
      onItemCheck={(value) => updateFilterState('providers', value)}
    />
    <RolesOptions
      checkedRoles={filter.roles}
      onItemCheck={(value) => updateFilterState('roles', value)}
    />
    <Button type="submit" variant="primary" size="small" withIcon="search">
      Filter
    </Button>
  </form>
)

FilterEmailsForm.propTypes = {
  packages: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.shape({
    packages: PropTypes.object,
    providers: PropTypes.array,
    roles: PropTypes.array,
  }),
  actions: PropTypes.shape({
    handleSubmit: PropTypes.func,
    updateFilterState: PropTypes.func,
  }),
}
