import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../../../common'
import { ApiTable } from '../../ApiTable'
import { ApiModal } from '../../ApiModal'

export const ApiKeyComponent = ({
  loading,
  keys,
  modal,
  onCloseModal,
  onClickCreate,
  onClickPreview,
  onClickRemove,
}) => (
  <div>
    <div className="member-apikey">
      <h3 className="wb-type-heading-l wb-margin-top-m wb-margin-bottom-s">
        API Keys
      </h3>
      <Button
        disabled={loading}
        variant="primary"
        size="small"
        className="wb-margin-start-xs"
        onClick={onClickCreate}
      >
        + Create New API Key
      </Button>
    </div>
    {keys.length === 0 ? (
      <p className="wb-margin-top-m wb-type-copy">
        There are no keys issued yet
      </p>
    ) : (
      <ApiTable
        keys={keys}
        onClickPreview={onClickPreview}
        onRemove={onClickRemove}
      />
    )}
    {modal && <ApiModal onCloseModal={onCloseModal} modal={modal} />}
  </div>
)

ApiKeyComponent.propTypes = {
  keys: PropTypes.array,
  loading: PropTypes.bool,
  modal: PropTypes.object,
  onCloseModal: PropTypes.func,
  onClickCreate: PropTypes.func,
  onClickPreview: PropTypes.func,
  onClickRemove: PropTypes.func,
}
