import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { member as memberSchema } from './schema'
import { useFormReactivity } from '../../../hooks'
import { useParams, useHistory } from 'react-router-dom'
import { MemberFormComponent } from '../component'

const MemberForm = ({
  withInfo,
  onFormDataChange,
  onUpdateUser,
  updateUser,
  userData,
  startValues = {},
}) => {
  const { tab } = useParams()
  const history = useHistory()

  const onTabChange = (currentTab) => {
    history.replace(`/member/${currentTab}`)
  }

  const selectedTab = tab || 'myprofile'

  const { properties } = memberSchema

  const { formData, setFormData, handleValueChange } = useFormReactivity({
    onFormDataChange,
    startValues,
  })

  useEffect(() => {
    setFormData(userData)
  }, [userData])

  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    setLoading(true)
    updateUser(
      {
        team: formData.team,
        mbioId: formData.mbioId,
        daimlerId: formData.daimlerId,
      },
      () => setLoading(false),
    )
  }

  const handleUserUpdate = () => {
    setLoading(true)
    onUpdateUser(() => setLoading(false))
  }

  return (
    <MemberFormComponent
      properties={properties}
      withInfo={withInfo}
      formData={formData}
      loading={loading}
      actions={{
        handleValueChange,
        handleUserUpdate,
        handleSubmit,
      }}
      selectedTab={selectedTab}
      onTabChange={onTabChange}
    />
  )
}

MemberForm.propTypes = {
  withInfo: PropTypes.bool,
  userData: PropTypes.object,
  startValues: PropTypes.object,
  onFormDataChange: PropTypes.func,
  onUpdateUser: PropTypes.func,
  updateUser: PropTypes.func,
}

export { MemberForm }
