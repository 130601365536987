import React from 'react'
import PropTypes from 'prop-types'
import { ServiceDetails } from '../../ServiceDetails'
import { Button } from '../../../../../../common'

export const CurrentServiceComponent = ({
  service,
  onMoveToArchive,
  onEditService,
}) =>
  service ? (
    <div className="wb-type-copy wb-padding-top-xs">
      <h2>Service</h2>
      <Button
        onClick={onEditService}
        disabled={!service.isEditable}
        variant="primary"
        size="small"
        className="packages-editor__button"
      >
        Edit
      </Button>
      <Button
        onClick={() => onMoveToArchive(service)}
        disabled={!service.isEditable}
        variant="secondary"
        size="small"
        className="packages-editor__button"
      >
        Archive
      </Button>
      <ServiceDetails service={service} />
    </div>
  ) : (
    <div>No service selected</div>
  )

CurrentServiceComponent.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    provider: PropTypes.string,
    description: PropTypes.string,
    isEditable: PropTypes.bool,
  }),
  onMoveToArchive: PropTypes.func,
  onEditService: PropTypes.func,
}
