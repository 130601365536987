import React from 'react'
import PropTypes from 'prop-types'
import { PriceForm } from '../../PriceForm'
import {
  Button,
  ModalContent,
  ModalFooter,
} from '../../../../../../../../common'

export const checkModified = (procesControl, priceVariantPrices) => {
  if (procesControl.length !== priceVariantPrices.length) {
    return true
  }
  for (const price of procesControl) {
    const targetPrice = priceVariantPrices.find(
      (pr) => pr.year === price.year.value,
    )
    if (targetPrice.monthlyPrice !== parseFloat(price.monthlyPrice.value)) {
      return true
    }
  }
  return false
}
export const PricesFormComponent = ({
  header,
  controls,
  priceVariant,
  actions: {
    onCancel,
    handleSubmit,
    updatePriceVariants,
    onRemovePrice,
    onAddPrice,
  },
}) => {
  const checkDisabled = () =>
    !controls.prices.valid ||
    !checkModified(controls.prices.value, priceVariant.prices)
  return (
    <>
      <ModalContent headline={header}>
        <div className="prices-form">
          <div className="wd-margin-vertical-xs price-form__header">
            <div>
              {controls.prices.value?.length
                ? 'Prices:'
                : `No Prices available for ${priceVariant.name}`}
            </div>
            <div>
              <Button
                type="button"
                variant="secondary"
                size="medium"
                onClick={onAddPrice}
              >
                Add Price
              </Button>
            </div>
          </div>
          {controls.prices.value.map((price, index) => (
            <div className="prices-form__item" key={index}>
              <div>
                <PriceForm
                  disabled={Boolean(priceVariant.prices[index])}
                  priceControls={price}
                  onChange={(control, value) =>
                    updatePriceVariants(index, control, value)
                  }
                />
              </div>
              <div className="wb-margin-top-xxs">
                {!priceVariant.prices[index] ? (
                  <Button
                    withIcon="bds/trash/16"
                    variant="secondary"
                    type="button"
                    size="medium"
                    onClick={() => onRemovePrice(index)}
                  >
                    Remove
                  </Button>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          type="button"
          variant="secondary"
          size="medium"
          onClick={onCancel}
        >
          Close
        </Button>
        <Button
          type="button"
          variant="primary"
          size="medium"
          disabled={checkDisabled()}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </ModalFooter>
    </>
  )
}

PricesFormComponent.propTypes = {
  actions: PropTypes.shape({
    onCancel: PropTypes.func,
    handleSubmit: PropTypes.func,
    updatePriceVariants: PropTypes.func,
    onRemovePrice: PropTypes.func,
    onAddPrice: PropTypes.func,
  }),
  controls: PropTypes.shape({
    prices: PropTypes.shape({
      valid: PropTypes.bool,
      value: PropTypes.arrayOf(
        PropTypes.shape({ year: PropTypes.shape({ value: PropTypes.string }) }),
      ),
    }),
  }),
  header: PropTypes.string,
  priceVariant: PropTypes.shape({
    name: PropTypes.string,
    prices: PropTypes.array,
  }),
}
