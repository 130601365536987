import React from 'react'
import PropTypes from 'prop-types'
import {
  ModalContent,
  ModalFooter,
  Button,
  ReactSelect,
  UsersAsyncMultiSelect,
} from '../../../../../../common'

export const UserFormComponent = ({
  header,
  selectedRoles,
  members,
  roles,
  disabled,
  actions: { onCancel, setMembers, filterOutputFn, setSelectedRoles, onSubmit },
}) => (
  <div className="add-user-form">
    <ModalContent headline={header} className="tenant-users-dialog__content">
      <div>
        <div className="wb-margin-top-xs">
          <UsersAsyncMultiSelect
            selectedUsers={members}
            onUserSelect={setMembers}
            isMulti={true}
            placeholder="Select user"
            filterOutputFn={filterOutputFn}
            loadOptionsOnMenuOpen={false}
            closeMenuOnSelect={true}
          />
        </div>
        <div className="wb-margin-top-xs">
          <ReactSelect
            value={selectedRoles}
            isMulti={true}
            name={'Select Roles'}
            options={roles}
            placeholder="Select role"
            onChange={(newRoles) => setSelectedRoles(newRoles)}
          />
        </div>
      </div>
    </ModalContent>
    <ModalFooter>
      <Button variant="secondary" size="small" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        variant="primary"
        size="small"
        disabled={disabled}
        onClick={onSubmit}
      >
        Add users
      </Button>
    </ModalFooter>
  </div>
)

UserFormComponent.propTypes = {
  header: PropTypes.string,
  selectedRoles: PropTypes.array,
  members: PropTypes.array,
  roles: PropTypes.array,
  disabled: PropTypes.bool,
  actions: PropTypes.shape({
    onCancel: PropTypes.func,
    setMembers: PropTypes.func,
    filterOutputFn: PropTypes.func,
    setSelectedRoles: PropTypes.func,
    onSubmit: PropTypes.func,
  }),
}
