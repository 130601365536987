import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../../../common'
import { PackageListItem } from './PackageListItem'

export const PackagesListComponent = ({
  platformPackages,
  selectedPackage,
  handlePackageClick,
  onAddPackage,
}) => (
  <div className="wb-padding-top-xs">
    <h2>Packages List</h2>
    <hr />
    <Button
      onClick={onAddPackage}
      variant="secondary"
      size="small"
      className="packages-editor__button"
    >
      Add
    </Button>
    <ul className="packages-editor__list">
      {platformPackages.map((servicePackage) => (
        <PackageListItem
          key={servicePackage.id}
          isActive={servicePackage.id === selectedPackage?.id}
          onClick={() => handlePackageClick(servicePackage)}
          servicePackage={servicePackage}
        />
      ))}
    </ul>
  </div>
)

export const platformPackageProp = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  packageKey: PropTypes.string,
  description: PropTypes.string,
})

PackagesListComponent.propTypes = {
  platformPackages: PropTypes.arrayOf(platformPackageProp),
  selectedPackage: platformPackageProp,
  handlePackageClick: PropTypes.func,
  onAddPackage: PropTypes.func,
}
