import React from 'react'
import PropTypes from 'prop-types'

export const Hint = ({ text, className }) =>
  text ? (
    <wb-control-hint id="IdOfHint" className={className}>
      {text}
    </wb-control-hint>
  ) : null

Hint.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
}
