import React from 'react'
import PropTypes from 'prop-types'
import {
  ModalContent,
  ModalFooter,
  Input,
  Button,
} from '../../../../../../../../common'

export const PriceVariantsFormComponent = ({
  header,
  controls,
  priceVariant,
  actions: { updateInputState, onCancel, handeSubmit },
}) => {
  const checkDisabled = () =>
    !controls.name.valid ||
    (priceVariant?.id && controls.name.value === priceVariant.name)

  return (
    <div className="price-variants-form">
      <ModalContent headline={header}>
        <div className="wb-margin-top-xs">
          <Input
            label="name"
            value={controls.name.value}
            error={
              controls.name.touched &&
              !controls.name.valid &&
              'MinLneght should be 3'
            }
            onChange={(e) => updateInputState('name', e.target.value)}
          />
        </div>
      </ModalContent>

      <ModalFooter>
        <Button
          type="button"
          variant="secondary"
          size="medium"
          onClick={onCancel}
        >
          Close
        </Button>
        <Button
          type="button"
          onClick={handeSubmit}
          disabled={checkDisabled()}
          variant="primary"
          size="medium"
        >
          {priceVariant?.id ? 'Update' : 'Add'} Price Variant
        </Button>
      </ModalFooter>
    </div>
  )
}

PriceVariantsFormComponent.propTypes = {
  header: PropTypes.string,
  controls: PropTypes.object,
  priceVariant: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
    prices: PropTypes.array,
  }),
  actions: PropTypes.shape({
    updateInputState: PropTypes.func,
    onCancel: PropTypes.func,
    handeSubmit: PropTypes.func,
  }),
}
