import React from 'react'
import PropTypes from 'prop-types'
import { Select } from '../../../../../common'

export const YearSelector = ({ selectedYear, years, handleYearChange }) => (
  <Select
    label="Select Year"
    value={selectedYear}
    onChange={handleYearChange}
    placeHolder="Please choose a year"
    options={years.map((year) => ({ name: year }))}
  />
)

YearSelector.propTypes = {
  selectedYear: PropTypes.number,
  handleYearChange: PropTypes.func,
  years: PropTypes.arrayOf(PropTypes.number),
}
