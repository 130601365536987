import React from 'react'
import PropTypes from 'prop-types'
import { PackageDetails } from '../../PackageDetails'
import { Button } from '../../../../../../common'

export const CurrentPackageComponent = ({
  activePackage,
  onClickEdit,
  onClickArchive,
}) => (
  <div className="current-package wb-type-copy wb-padding-top-xs">
    <h2>Package</h2>
    <Button
      onClick={onClickEdit}
      disabled={!activePackage.isEditable}
      className="packages-editor__button"
      variant="primary"
      size="small"
    >
      Edit
    </Button>
    <Button
      onClick={onClickArchive}
      disabled={!activePackage.isEditable}
      className="packages-editor__button"
      variant="secondary"
      size="small"
    >
      Archive
    </Button>
    <PackageDetails currentPackage={activePackage} />
  </div>
)

CurrentPackageComponent.propTypes = {
  activePackage: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    packageKey: PropTypes.string,
    description: PropTypes.string,
    isEditable: PropTypes.bool,
  }),
  onClickEdit: PropTypes.func,
  onClickArchive: PropTypes.func,
}
