import { BASE_URL, csrfRef, COMMON_HEADERS } from '../constants'

const controller = new AbortController()
const signal = controller.signal

const withAuth = (options) => ({
  ...(options || {}),
  signal,
  headers: {
    ...(options?.headers || {}),
  },
  ...COMMON_HEADERS,
})

export const generateHeaderOptions = (options, csrfObj) => {
  const headers = { ...options?.headers }
  if (csrfObj?.headerName) {
    headers[csrfObj.headerName] = csrfObj.token
  }
  return { ...options, headers }
}

export const handleUnauthorizedRedirect = () => {
  const redirectUrl = encodeURIComponent(window.location.href)
  window.location.replace(
    `${BASE_URL}/oauth2/authorization/gas?redirect_url=${redirectUrl}&authorization_failed_redirect_url=${redirectUrl}`,
  )
}

export const handleForbiddenRedirect = () => {
  window.location.replace(`${window.location.origin}/#/403`)
}

export const actionHandlers = {
  401: handleUnauthorizedRedirect,
  403: handleForbiddenRedirect,
}

export const handleRedirect = (statusCode) => {
  controller.abort()
  actionHandlers[statusCode]()
}

export const _fetch = async (url, options) => {
  const res = await fetch(
    url,
    withAuth(generateHeaderOptions(options, csrfRef.current)),
  )
  actionHandlers[res.status] && handleRedirect(res.status)
  // To handle BE errors (cause we do not know the structure)
  if (!res.ok) {
    let errorMessage = ''
    try {
      const response = await res.json()
      errorMessage = generateErrorMessage(response)
    } catch (err) {
      errorMessage = res.status
    }
    throw new Error(errorMessage)
  }
  return res
}
