import React from 'react'
import PropTypes from 'prop-types'

export const SearchInput = ({
  value,
  label,
  placeholder,
  disabled,
  onChange,
}) => (
  <wb-input-control>
    <wb-input>
      <label>{label}</label>
      <input
        placeholder={placeholder}
        required
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
      <wb-input-action>
        <button
          className="wb-round-button wb-round-button--small"
          type="button"
        >
          <wb-icon className="wb-round-button__icon" name="bds/search/16" />
        </button>
      </wb-input-action>
    </wb-input>
  </wb-input-control>
)

SearchInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}
