import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  changeCreating,
  changeDeleting,
  changeEditing,
  changeModal,
  changeServerErrors,
  selectTenant,
  startSaving,
} from '../../../data/actions'
import { scrollTop } from '../../../util'
import { TenantComponent } from './component'

const TenantContainer = ({
  isFetching,
  arts,
  sample,
  allTenants,
  selectedTenant,
  isEditing,
  isCreating,
  isSaving,
  isWhitelisted,
  match,
  history,
  roles,
  serverErrors,
  username,
  activeModalId,
  setEditingState,
  setCreatingState,
  setDeletingState,
  saveTenant,
  setServerErrors,
  setModal,
  setTenant,
}) => {
  const { acronym } = match.params
  useEffect(() => {
    if (acronym) {
      setTenant(acronym)
    }
    return () => setTenant('')
  }, [acronym])

  const onDeleteConfirmClick = () => {
    setDeletingState(true)
    saveTenant(selectedTenant)
    setModal(null)
  }
  const onCancelClick = () => {
    if (isCreating) {
      setCreatingState(false)
    }
    setEditingState(false)
    setServerErrors(null)
  }

  const onSaveClick = (data) => {
    delete data.members
    let tenantAcronym
    if (isCreating) {
      data.acronym = data.acronym.toLowerCase()
      tenantAcronym = data.acronym
    } else {
      tenantAcronym = selectedTenant
    }
    saveTenant(tenantAcronym, data)
    scrollTop()
  }

  const changeDialog = (newDialog) => {
    let url = `/apps/${acronym}`
    if (newDialog) {
      url += `/${newDialog}`
    }
    history.replace(url)
  }

  const tenantInfo = () => {
    if (isCreating) {
      return sample
    }
    return allTenants[selectedTenant] || {}
  }

  const tenantInformation = tenantInfo()
  return (
    <TenantComponent
      isFetching={isFetching}
      isSaving={isSaving}
      isEditing={isEditing}
      activeModalId={activeModalId}
      serverErrors={serverErrors}
      selectedTenant={selectedTenant}
      match={match}
      username={username}
      isWhitelisted={isWhitelisted}
      arts={arts}
      tenantInformation={tenantInformation}
      allTenants={allTenants}
      roles={roles}
      actions={{
        setModal,
        onEditClick: () => setEditingState(true),
        changeDialog,
        onDeleteConfirmClick,
        onSaveClick,
        onCancelClick,
      }}
    />
  )
}

TenantContainer.propTypes = {
  arts: PropTypes.array,
  match: PropTypes.shape({
    params: PropTypes.shape({
      dialog: PropTypes.string,
      acronym: PropTypes.string,
    }),
  }),
  selectedTenant: PropTypes.string,
  isCreating: PropTypes.bool,
  allTenants: PropTypes.objectOf(PropTypes.object),
  sample: PropTypes.object,
  history: PropTypes.shape({ replace: PropTypes.func }),
  isFetching: PropTypes.bool,
  isEditing: PropTypes.bool,
  isSaving: PropTypes.bool,
  isWhitelisted: PropTypes.bool,
  roles: PropTypes.array,
  serverErrors: PropTypes.shape({ hasErrors: PropTypes.bool }),
  username: PropTypes.string,
  activeModalId: PropTypes.string,
  setEditingState: PropTypes.func,
  setCreatingState: PropTypes.func,
  setDeletingState: PropTypes.func,
  saveTenant: PropTypes.func,
  setServerErrors: PropTypes.func,
  setModal: PropTypes.func,
  setTenant: PropTypes.func,
}

export const Tenant = connect(
  ({
    tenants: {
      isFetching,
      sample,
      allTenants,
      selectedTenant,
      isEditable,
      isEditing,
      isCreating,
      isSaving,
      isWhitelisted,
      roles,
      arts,
      serverErrors,
    },
    sso: { username },
    modal: { activeModalId },
  }) => ({
    isFetching,
    arts,
    sample,
    allTenants,
    selectedTenant,
    isEditable,
    isEditing,
    isCreating,
    isSaving,
    isWhitelisted,
    roles,
    serverErrors,
    username,
    activeModalId,
  }),
  {
    setEditingState: changeEditing,
    setCreatingState: changeCreating,
    setDeletingState: changeDeleting,
    saveTenant: startSaving,
    setServerErrors: changeServerErrors,
    setModal: changeModal,
    setTenant: selectTenant,
  },
)(withRouter(TenantContainer))
