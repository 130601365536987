import React from 'react'
import PropTypes from 'prop-types'
import { costOverviewColumns, numberToPrice } from '../../utils'

export const TenantProviderOverView = ({ provider, totalPrice, data }) => (
  <div className="tenant-provider-overview">
    <h3 className="tenant-provider-overview__headline">
      <span>{provider}</span>
      <span className="tenant-provider-overview__total-label">
        {numberToPrice('Cannot be calculated')(totalPrice)}
      </span>
    </h3>
    <table className="tenant-provider-overview__table">
      <thead>
        <tr>
          {costOverviewColumns.map((column) => (
            <th
              className="tenant-provider-overview__header-cell"
              key={column.label}
              width={column.width}
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.bookingId}>
            {costOverviewColumns.map((column) => (
              <td
                className="tenant-provider-overview__body-cell"
                align={column.right ? 'right' : 'left'}
                key={column.label}
              >
                {(column.format || ((x) => x))(row[column.property])}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

TenantProviderOverView.propTypes = {
  provider: PropTypes.string,
  totalPrice: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      bookingId: PropTypes.number,
    }),
  ),
}
