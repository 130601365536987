import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import { MemberLinks } from '../../components/MemberLinks'
import { MemberPermissions } from '../../components/MemberPermissions'
import { MemberRoles } from '../../components/MemberRoles'
import { TaggedMemberRoles } from '../../components/TaggedMemberRoles'
import { TaggedMemberLabels } from '../TaggedMemberLabels'

export const MemberItem = ({
  tenantRoles,
  member: {
    tenantMemberUntil,
    daimlerId,
    email,
    roles,
    mbioId,
    firstName,
    lastName,
    comment,
    permissions,
    labels,
  },
}) => (
  <div
    className={classNames(
      'members-content__item members-content__item--flex members-content__item--highlight wb-margin-vertical-xxs',
      {
        'members-content__item--expired': moment(tenantMemberUntil).isBefore(
          moment(),
        ),
      },
    )}
    key={`${daimlerId}_${email}`}
  >
    <div>
      <MemberRoles roles={roles} />
      <p className="">
        <span className="members-content__item-label">Name</span>
        {firstName && `${firstName} ${lastName}`}
      </p>
      <TaggedMemberRoles
        roles={roles.map((role) =>
          tenantRoles.find(({ name }) => name === role),
        )}
      />
      {comment && (
        <p>
          <span className="members-content__item-label">Comment</span>
          {comment}
        </p>
      )}
      <MemberLinks
        label="DaimlerId"
        prop={daimlerId}
        linkStart="https://teams.app.corpintra.net/teams/person/"
      />
      <MemberLinks
        label="MBioId"
        prop={mbioId}
        linkStart="https://confluence.mercedes-benz.io/display/~"
      />
      <MemberLinks label="Email" prop={email} linkStart="mailto:" />
      <p className="members-until">
        <span className="members-content__item-label">Member Until</span>
        {tenantMemberUntil}
      </p>
      <TaggedMemberLabels label="Labels" labels={labels} />
    </div>
    <hr />
    <MemberPermissions permissions={permissions} />
  </div>
)

MemberItem.propTypes = {
  member: PropTypes.shape({
    tenantMemberUntil: PropTypes.string,
    daimlerId: PropTypes.string,
    email: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    mbioId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    comment: PropTypes.string,
    permissions: PropTypes.object,
    labels: PropTypes.arrayOf(PropTypes.string),
  }),
  tenantRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
}
