import {
  SET_AKAMAI_CP_CODES,
  TOGGLE_CP_CODE,
  TOGGLE_AKAMAI_LOADING_STATE,
  CLEAN_AKAMAI_SELECTED_CP_CODES,
  CLEAN_AKAMAI_SERVER_MESSAGES,
  SET_AKAMAI_SERVER_MESSAGES,
  TOGGLE_AKAMAI_INVALIDATING_STATE,
  CLEAN_AKAMAI_STATE,
} from '../../actionsTypes'
import { invalidateAkamaiCpCodes, fetchAkamaiCpCodes } from '../../../http'
import { postNotification } from '../ui'

export const generateSuccessMessageForCacheInvalidation = (serverMessages) =>
  serverMessages.map(
    ({ akamaiCpCodesInvalidateResponseDto: { estimatedSeconds }, cpCodes }) =>
      `CP codes ${cpCodes.join(
        ', ',
      )} will be finished in aproximately ${estimatedSeconds} seconds`,
  )

export const getAkamaiCpCodes = (cb) => async (dispatch, getState) => {
  try {
    dispatch(setAkamaiStateLoading(true))
    const tenantAcr = getState().tenants.selectedTenant
    if (!tenantAcr) {
      throw new Error('Tenant is not selected, please select tenant first!')
    }
    const response = await fetchAkamaiCpCodes(tenantAcr)
    if (response.errorMessages) {
      throw new Error(response.message)
    }
    dispatch(setAkamaiCpCodes(response))
    dispatch(setAkamaiStateLoading(false))
    cb?.()
  } catch (err) {
    dispatch(postNotification({ message: err.message, mode: 'error' }))
    dispatch(setAkamaiStateLoading(false))
    cb?.()
  }
}

export const invalidateAkamaiCache = (cb) => async (dispatch, getState) => {
  const cpCodes = Object.keys(getState().akamai.selectedCpCodes)
  const tenantAcr = getState().tenants.selectedTenant
  try {
    dispatch(setAkamaiStateInvalidating(true))
    const response = await invalidateAkamaiCpCodes(tenantAcr, cpCodes)
    if (response.errorMessages) {
      throw new Error(response.message)
    }
    dispatch(
      postNotification({
        message: `Your request was successfully delivered to Server, execution for ${generateSuccessMessageForCacheInvalidation(
          response,
        ).join(' and ')}`,
      }),
    )
    dispatch(setAkamaiServerMessages(response))
    dispatch(cleanAkamaiSelectedCodes())
    dispatch(getAkamaiCpCodes())
    dispatch(setAkamaiStateInvalidating(false))
    cb?.()
  } catch (err) {
    dispatch(postNotification({ message: err.message, mode: 'error' }))
    dispatch(setAkamaiStateInvalidating(false))
    cb?.()
  }
}

export const toggleCpCode = (cpCodeId) => ({
  type: TOGGLE_CP_CODE,
  payload: {
    cpCodeId,
  },
})

export const cleanAkamaiState = () => (dispatch) => {
  dispatch(clearAkamaiState())
}

export const clearAkamaiServerMessages = () => (dispatch) =>
  dispatch(clearServerMessages())

export const setAkamaiStateLoading = (loading) => ({
  type: TOGGLE_AKAMAI_LOADING_STATE,
  payload: {
    loading,
  },
})

export const setAkamaiStateInvalidating = (invalidating) => ({
  type: TOGGLE_AKAMAI_INVALIDATING_STATE,
  payload: {
    invalidating,
  },
})

export const setAkamaiCpCodes = (cpCodes) => ({
  type: SET_AKAMAI_CP_CODES,
  payload: {
    cpCodes,
  },
})

export const cleanAkamaiSelectedCodes = () => ({
  type: CLEAN_AKAMAI_SELECTED_CP_CODES,
})

export const setAkamaiServerMessages = (serverMessages) => ({
  type: SET_AKAMAI_SERVER_MESSAGES,
  payload: {
    serverMessages,
  },
})

export const clearAkamaiState = () => ({
  type: CLEAN_AKAMAI_STATE,
})

export const clearServerMessages = () => ({
  type: CLEAN_AKAMAI_SERVER_MESSAGES,
})
