import React from 'react'
import PropTypes from 'prop-types'
import { TenantList } from '../TenantList'
import { ParseTenantInfo } from '../utils/ParseTenantInfo'
import { Accordion, Spinner } from '../../../common'

const getRequirements = (tenantsList) => {
  const requirements = {
    insideDigitalHouse: [],
    outsideDigitalHouse: [],
  }

  tenantsList.forEach((tenant) =>
    tenant.partOfDigitalHouse
      ? requirements.insideDigitalHouse.push(ParseTenantInfo(tenant))
      : requirements.outsideDigitalHouse.push(ParseTenantInfo(tenant)),
  )

  return requirements
}

export const DigitalHouseReport = ({ allTenants = {} }) => {
  const tenantsList = Object.values(allTenants)
  if (!tenantsList.length) {
    return (
      <div className="page-body__section">
        <div className="loader-container">
          <Spinner mode="dark" />
        </div>
      </div>
    )
  }
  const requirements = getRequirements(tenantsList)

  return (
    <div className="page-body__section">
      <h3 className="wb-type-heading-m page-body__margin-bottom-s">
        Digital House
      </h3>

      <Accordion>
        <TenantList
          title="Part of Digital House"
          list={requirements.insideDigitalHouse}
        />
        <TenantList
          title="Outside of Digital House"
          list={requirements.outsideDigitalHouse}
        />
      </Accordion>
    </div>
  )
}

DigitalHouseReport.propTypes = {
  allTenants: PropTypes.objectOf(
    PropTypes.shape({
      status: PropTypes.string,
      confidentiality: PropTypes.string,
      availability: PropTypes.string,
      continuity: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    }),
  ),
}
