import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { CustomPromptComponent } from '../component'

const CustomPromptContainer = ({
  header,
  okText,
  waitingProps,
  waitingPermissions,
  onApprove,
  onCancel,
}) => {
  const waitingMode = waitingPermissions?.[waitingProps?.bookingId]?.[
    waitingProps?.serviceSlug
  ]?.includes(waitingProps?.daimlerId)
  return (
    <CustomPromptComponent
      header={header}
      okText={okText}
      waitingMode={waitingMode}
      onApprove={onApprove}
      onCancel={onCancel}
    />
  )
}

CustomPromptContainer.propTypes = {
  header: PropTypes.string,
  okText: PropTypes.string,
  waitingProps: PropTypes.objectOf(PropTypes.string),
  waitingPermissions: PropTypes.objectOf(
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  ),
  onApprove: PropTypes.func,
  onCancel: PropTypes.func,
}

export const CustomPrompt = connect(
  ({ tenantBookings: { waitingPermissions } }) => ({
    waitingPermissions,
  }),
)(CustomPromptContainer)
