import React from 'react'
import PropTypes from 'prop-types'
import { GhTabs } from './GhTabs'

export const TenantLinks = ({ githubOrganizations }) => (
  <div className="tenant-body__block">
    <div className="tenant-body__block-label">Links</div>
    <GhTabs items={githubOrganizations} />
  </div>
)

TenantLinks.propTypes = {
  githubOrganizations: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ),
}
