import React from 'react'
import { connect } from 'react-redux'
import {
  setModal,
  removePackagePriceVariant,
  addPackagePriceVariant,
  modifyPackagePriceVariant,
  updateServicePackagePrices,
} from '../../../../../../../../../data/actions'
import { PriceVariantsListComponent } from '../component'
import PropTypes from 'prop-types'

const PriceVariantsListContainer = ({
  readOnly,
  activePackage,
  priceVariants,
  dispatchModal,
  addPv,
  removePv,
  modifyPv,
  updatePrices,
}) => {
  const packagePriceVariants =
    (activePackage?.slug && priceVariants[activePackage.slug]) || []

  const onCancel = () => dispatchModal({})
  const onAddPriceVariant = () => {
    dispatchModal({
      header: 'Add price variant',
      onCancel,
      onSave: addPv,
      variant: 'priceVariants',
      mode: 'add',
    })
  }

  const onEditPriceVariant = (pv) => {
    dispatchModal({
      header: 'Edit price variant',
      priceVariant: pv,
      onCancel,
      onSave: modifyPv,
      variant: 'priceVariants',
      mode: 'edit',
    })
  }

  const onEditPrices = (pv) => {
    dispatchModal({
      header: 'Edit prices',
      priceVariant: pv,
      onCancel,
      onSave: updatePrices,
      variant: 'prices',
      mode: 'edit',
    })
  }

  const handleRemovePriceVariant = (pv) => {
    dispatchModal({
      variant: 'prompt',
      header: `Are you sure you want to remove price variant - (${pv.name})`,
      okText: 'Remove',
      onCancel,
      onApprove: () => removePv(pv.slug),
    })
  }

  packagePriceVariants?.sort((a, b) => {
    const aName = a.name.toLowerCase()
    const bName = b.name.toLowerCase()
    return aName.localeCompare(bName)
  })

  return (
    <PriceVariantsListComponent
      actions={{
        handleRemovePriceVariant,
        onEditPrices,
        onEditPriceVariant,
        onAddPriceVariant,
      }}
      readOnly={readOnly}
      packagePriceVariants={packagePriceVariants}
    />
  )
}

export const PriceVariantsList = connect(
  ({ backOffice: { priceVariants } }) => ({ priceVariants }),
  {
    dispatchModal: setModal,
    removePv: removePackagePriceVariant,
    addPv: addPackagePriceVariant,
    modifyPv: modifyPackagePriceVariant,
    updatePrices: updateServicePackagePrices,
  },
)(PriceVariantsListContainer)

PriceVariantsListContainer.propTypes = {
  readOnly: PropTypes.bool,
  activePackage: PropTypes.shape({
    slug: PropTypes.string,
    isEditable: PropTypes.bool,
  }),
  priceVariants: PropTypes.object,
  addPv: PropTypes.func,
  removePv: PropTypes.func,
  modifyPv: PropTypes.func,
  updatePrices: PropTypes.func,
  dispatchModal: PropTypes.func,
}
