import React from 'react'

const UserVerificationModal = () => (
  <wb-modal mode="prompt" style={{ display: 'none' }}>
    <wb-modal-content headline="Welcome to TDMS!">
      It looks like it's your first visit in TDMS. Complete your User Profile
      before using the Service.
    </wb-modal-content>
    <wb-modal-footer>
      <a
        type="button"
        className="wb-button wb-button--primary wb-button--large"
        href="#/member"
      >
        Complete My Profile
      </a>
    </wb-modal-footer>
  </wb-modal>
)

export default UserVerificationModal
