import { useState } from 'react'
import { useSelector } from 'react-redux'

export const useFormReactivity = ({ onFormDataChange, startValues = {} }) => {
  const [formData, setFormData] = useState(startValues)
  const handleValueChange = (value, key) => {
    setFormData({
      ...formData,
      [key]: value,
    })
    onFormDataChange(formData)
  }

  return {
    setFormData,
    formData,
    handleValueChange,
  }
}

export const useSelectedTenantData = () => {
  const selectedTenantData = useSelector(
    (state) =>
      state?.tenants?.allTenants?.[state?.tenants?.selectedTenant] || {},
  )
  return { selectedTenantData }
}
