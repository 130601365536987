import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getCurrentTenantBookings,
  getTenantMembersWithPermissions,
} from '../../../../../../../../data/actions'
import { BookingListComponent } from '../component'

const BookingListContainer = ({
  currentBookings,
  fetchTenant,
  fetchTenantBookings,
}) => {
  useEffect(() => {
    fetchTenant()
    fetchTenantBookings()
  }, [])
  return <BookingListComponent bookings={currentBookings} />
}

BookingListContainer.propTypes = {
  currentBookings: PropTypes.array,
  fetchTenant: PropTypes.func,
  fetchTenantBookings: PropTypes.func,
}

export const BookingList = connect(
  ({ tenantBookings: { currentBookings } }) => ({ currentBookings }),
  {
    fetchTenantBookings: getCurrentTenantBookings,
    fetchTenant: getTenantMembersWithPermissions,
  },
)(BookingListContainer)
