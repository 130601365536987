import React from 'react'
import PropTypes from 'prop-types'

export const Button = ({
  type = 'button',
  variant,
  size,
  withIcon,
  iconOnly,
  disabled,
  className,
  onClick,
  children,
}) => {
  const classes = ['wb-button']

  if (className) {
    classes.push(className)
  }

  if (size) {
    classes.push(`wb-button--${size}`)
  }
  if (variant) {
    classes.push(`wb-button--${variant}`)
  }
  if (iconOnly) {
    classes.push('wb-button--icon-only')
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={classes.join(' ')}
      disabled={disabled}
    >
      {withIcon ? (
        <wb-icon
          class="wb-button__icon"
          aria-hidden="true"
          flip-rtl
          name={withIcon}
        ></wb-icon>
      ) : null}
      {children}
    </button>
  )
}
Button.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  className: PropTypes.string,
  iconOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  withIcon: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
