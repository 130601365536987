import React, { useState } from 'react'
import PropTypes from 'prop-types'
import validate from '../../../../../../../../../util/helpers/validate'
import { PriceVariantsFormComponent } from '../component'

export const PriceVariantsForm = ({
  priceVariant,
  header,
  onSave,
  onCancel,
}) => {
  const controls = {
    name: {
      value: priceVariant?.name || '',
      valid: false,
      validationRules: {
        minLength: 3,
      },
      touched: false,
    },
  }
  const [state, setState] = useState(controls)

  const updateInputState = (control, value) => {
    setState({
      ...state,
      [control]: {
        ...state[control],
        value,
        valid: validate(value, state[control].validationRules),
        touched: true,
      },
    })
  }

  const handeSubmit = () => {
    onSave({
      ...priceVariant,
      name: state.name.value,
    })
  }

  return (
    <PriceVariantsFormComponent
      header={header}
      controls={state}
      priceVariant={priceVariant}
      actions={{ onCancel, handeSubmit, updateInputState }}
    />
  )
}

PriceVariantsForm.propTypes = {
  priceVariant: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    prices: PropTypes.array,
  }),
  header: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
}
