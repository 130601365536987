export const TDMS_ROLES_ACTUAL = {
  tenantadmin: { value: 'tenantadmin', label: 'Tenant admin' },
  admin: { value: 'admin', label: 'Admin' },
  compliance: { value: 'compliance', label: 'Compliance Officer' },
}
export const TDMS_ROLES_LEGACY = {
  tenantadmin: { value: 'tenantadmin', label: 'Tenant admin' },
  admin: { value: 'admin', label: 'Admin' },
  compliance: { value: 'compliance', label: 'Compliance Officer' },
  manifestadmin: { value: 'manifestadmin', label: 'Manifest Admin' },
}
