import React from 'react'
import PropTypes from 'prop-types'

export const Card = ({ variant = 'light-grey', children }) => (
  <wb-card
    variant={variant}
    className="wb-type-copy wb-padding-s wb-margin-bottom-xs"
    id="bookingItem"
  >
    {children}
  </wb-card>
)

Card.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
