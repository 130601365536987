import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { changeProvider } from '../../../../../../../../data/actions'
import { ProviderDialogFormComponent } from '../component'

export const checkDisabled = (currentProvider, activeProvider) =>
  activeProvider === '' || currentProvider === activeProvider

const ProviderDialogContainer = ({
  currentProvider,
  providers,
  onCancel,
  changeTenantProvider,
}) => {
  const [activeProvider, setActiveProvider] = useState('')

  useEffect(() => {
    currentProvider && setActiveProvider(currentProvider)
  }, [currentProvider])

  const onSubmit = () => changeTenantProvider(activeProvider)

  return (
    <ProviderDialogFormComponent
      disabled={checkDisabled(currentProvider, activeProvider)}
      activeProvider={activeProvider}
      providers={providers}
      setActiveProvider={setActiveProvider}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}

ProviderDialogContainer.propTypes = {
  currentProvider: PropTypes.string,
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onCancel: PropTypes.func,
  changeTenantProvider: PropTypes.func,
}

export const ProviderDialogForm = connect(
  ({ tenants: { allTenants, selectedTenant }, backOffice: { providers } }) => ({
    currentProvider: allTenants[selectedTenant]?.providerSlug,
    providers: providers
      .filter(({ cloudProvider, deleted_at }) => cloudProvider && !deleted_at)
      .map(({ slug, name }) => ({
        name,
        id: slug,
      })),
  }),
  {
    changeTenantProvider: changeProvider,
  },
)(ProviderDialogContainer)
