import React from 'react'
import PropTypes from 'prop-types'
import { BookingItem } from '../../BookingItem'
import { Card } from '../../../../../../../common'

export const BookingListComponent = ({ bookings }) => (
  <div className="wb-padding-s" style={{ background: '#ffffff' }}>
    {bookings.length ? (
      bookings.map((booking) => (
        <div key={booking.id} className="wb-margin-bottom-xs">
          <Card>
            <h2 className="wb-padding-vertical-xs wb-padding-start-xs">
              {booking.packageDisplayName}
            </h2>
            <BookingItem booking={booking} />
          </Card>
        </div>
      ))
    ) : (
      <div className="no_bookings_div">No Bookings found</div>
    )}
  </div>
)

const bookingProp = PropTypes.shape({
  comment: PropTypes.string,
  endDate: PropTypes.string,
  id: PropTypes.number,
  packageName: PropTypes.string,
  packageDisplayName: PropTypes.string,
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      monthlyPrice: PropTypes.number,
      platformServicePackageId: PropTypes.string,
      year: PropTypes.string,
    }),
  ),
  provider: PropTypes.string,
  slug: PropTypes.string,
  startDate: PropTypes.string,
  tenantAcronym: PropTypes.string,
  tenantName: PropTypes.string,
})

BookingListComponent.propTypes = {
  bookings: PropTypes.arrayOf(bookingProp),
}
