import React from 'react'
import Select from 'react-select'
import { PropTypes } from 'prop-types'

export const ReactSelect = ({
  value,
  isMulti = false,
  disabled = false,
  name,
  closeMenuOnSelect = false,
  options,
  placeholder = 'Select item',
  className = 'autocomplete',
  classNamePrefix = 'as',
  onChange,
  onBlur,
}) => (
  <Select
    isDisabled={disabled}
    value={value}
    isMulti={isMulti}
    name={name}
    styles={{
      menu: (styles) => ({
        ...styles,
        height: '250px',
      }),
      menuList: (styles) => ({
        ...styles,
        height: '250px',
      }),
    }}
    closeMenuOnSelect={closeMenuOnSelect}
    options={options}
    placeholder={placeholder}
    className={className}
    classNamePrefix={classNamePrefix}
    onChange={onChange}
    onBlur={onBlur}
  />
)

const optionProp = PropTypes.shape({
  color: PropTypes.string,
  isFixed: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
})

ReactSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(optionProp),
    optionProp,
    PropTypes.string,
  ]),
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  options: PropTypes.arrayOf(optionProp),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}
