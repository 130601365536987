import React from 'react'
import PropTypes from 'prop-types'
import { PermissionsEditor } from '../PermissionsEditor'
import { CheckBox } from '../../../../../../../../../../common'

export const PermissionsTable = ({
  members,
  booking,
  onSelectMember,
  service,
  loggedInPoPmDaimlerId,
}) =>
  members.length ? (
    <table className="wb-table wb-table--border-horizontal wb-table--border-vertical permissions-table">
      <thead className="permissions-table__head">
        <tr>
          <th scope="col"></th>
          <th scope="col">Name</th>
          <th scope="col">Permission</th>
        </tr>
      </thead>
      <tbody className="permissions-table__body">
        {members.map((member) => (
          <tr key={member.daimlerId} className="permissions-table__body__row">
            <th
              scope="row"
              style={{ verticalAlign: 'middle', textAlign: 'center' }}
            >
              <CheckBox
                className="wb-margin-start-xxs"
                checked={member.checked}
                disabled={
                  member.waitingMode ||
                  member.daimlerId === loggedInPoPmDaimlerId
                }
                onChange={() => onSelectMember(member)}
              />
            </th>
            <td>
              <div>
                {member.firstName} {member.lastName}
              </div>
            </td>
            <td>
              <PermissionsEditor
                currentMember={member}
                booking={booking}
                loggedInPoPmDaimlerId={loggedInPoPmDaimlerId}
                service={service}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <div>No Permissions found for selected service</div>
  )

export const bookingProp = PropTypes.shape({
  comment: PropTypes.string,
  endDate: PropTypes.string,
  id: PropTypes.number,
  loggedInPoPmDaimlerId: PropTypes.string,
  packageName: PropTypes.string,
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      year: PropTypes.string,
      monthlyPrice: PropTypes.number,
      platformServicePackageId: PropTypes.string,
    }),
  ),
  provider: PropTypes.string,
  slug: PropTypes.string,
  startDate: PropTypes.string,
  tenantAcronym: PropTypes.string,
  tenantName: PropTypes.string,
})

export const memberProp = PropTypes.shape({
  comment: PropTypes.string,
  daimlerId: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  mbioId: PropTypes.string,
  permission: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    providerOnly: PropTypes.bool,
    perm: PropTypes.shape({
      id: PropTypes.number,
      userDaimlerId: PropTypes.string,
      permissionId: PropTypes.number,
      permissionName: PropTypes.string,
      permissionSlug: PropTypes.string,
    }),
    slug: PropTypes.string,
  }),
  roles: PropTypes.arrayOf(PropTypes.string),
  tenantMemberUntil: PropTypes.string,
})

export const serviceProp = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  provider: PropTypes.string,
  serviceKey: PropTypes.string,
  slug: PropTypes.string,
})

PermissionsTable.propTypes = {
  members: PropTypes.arrayOf(memberProp),
  booking: bookingProp,
  loggedInPoPmDaimlerId: PropTypes.string,
  onSelectMember: PropTypes.func,
  service: serviceProp,
}
