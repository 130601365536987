import React from 'react'
import PropTypes from 'prop-types'

export const WbCard = ({ children, title, background = '#ffffff' }) => (
  <div style={{ background }} className="wb-margin-bottom-s">
    <wb-card class="wb-type-copy wb-padding-s">
      {title ? <h2 className="wb-margin-bottom-xs">{title}</h2> : null}
      {children}
    </wb-card>
  </div>
)

WbCard.propTypes = {
  title: PropTypes.string,
  background: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
