import React from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Select } from '..'

export const TablePagination = ({
  table,
  pageOptions = [10, 20, 40, 60, 80],
}) => (
  <div className="wb-margin-vertical-xxs table-pagination">
    <div className="table-pagination__buttons">
      <Button
        variant="secondary"
        className="wb-margin-end-xs"
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
      >
        {'<<'}
      </Button>
      <Button
        variant="secondary"
        className="wb-margin-end-xs"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {'<'}
      </Button>
      <Button
        variant="secondary"
        className="wb-margin-end-xs"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        {'>'}
      </Button>
      <Button
        variant="secondary"
        className="wb-button wb-button--secondary"
        type="button"
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
      >
        {'>>'}
      </Button>
    </div>
    <span className="table-pagination__item">
      <div className="wb-margin-end-xs">Page</div>
      <strong className="table-pagination__item__page-number">
        {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
      </strong>
    </span>

    <span className="table-pagination__item">
      <Input
        label="Go to page:"
        type="number"
        style={{ width: '150px' }}
        className="table-pagination__item__goto-page-input"
        value={table.getState().pagination.pageIndex + 1}
        onChange={(e) => {
          const page = e.target.value ? Number(e.target.value) - 1 : 0
          table.setPageIndex(page)
        }}
      />
    </span>

    <span className="table-pagination__item">
      <Select
        value={table.getState().pagination.pageSize}
        className="table-pagination__item__row-count-selector"
        onChange={(e) => {
          table.setPageSize(Number(e.target.value))
        }}
        options={pageOptions.map((item) => ({
          name: `Show ${item}`,
          id: item,
        }))}
      />
    </span>
  </div>
)

TablePagination.propTypes = {
  table: PropTypes.shape({
    setPageIndex: PropTypes.func,
    getCanPreviousPage: PropTypes.func,
    previousPage: PropTypes.func,
    nextPage: PropTypes.func,
    getCanNextPage: PropTypes.func,
    getPageCount: PropTypes.func,
    setPageSize: PropTypes.func,
    getState: PropTypes.func,
  }),
  pageOptions: PropTypes.arrayOf(PropTypes.number),
}
