import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { EditableInputComponent } from './component'

export const EditableInput = ({ value, onSave, onDelete }) => {
  const [mode, setMode] = useState('')
  const [currentInput, setCurrentInput] = useState(value)

  const onClickSave = () =>
    mode === 'edit' ? onSave(currentInput, () => setMode('')) : setMode('edit')

  const onClickRemove = () => (mode === 'edit' ? setMode() : onDelete())
  return (
    <EditableInputComponent
      mode={mode}
      value={currentInput}
      actions={{
        onChangeInput: (e) => setCurrentInput(e.target.value),
        onClickSave,
        onClickRemove,
      }}
    />
  )
}

EditableInput.propTypes = {
  value: PropTypes.string,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
}
