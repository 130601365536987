import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AkamaiForm } from '../components/AkamaiForm'
import { ServerMessageWithTimer } from '../components/ServerMessageWithTimer'

export const InvalidateAkamaiCacheDialogComponent = ({
  loading,
  invalidating,
  disabled,
  invalidateAkamaiCache,
  onClose,
  cleanAkamaiState,
}) => {
  let actionMessage = ''
  if (loading) {
    actionMessage = 'Loading...'
  }
  if (invalidating) {
    actionMessage = 'Invalidating...'
  }
  const ref = useRef(null)
  const onCloseFn = () => {
    onClose()
    cleanAkamaiState()
  }
  useEffect(() => {
    ref?.current?.addEventListener('wbclose', onCloseFn)
    return () => ref?.current?.removeEventListener('wbclose', onCloseFn)
  }, [])
  return (
    <wb-modal
      mode="info"
      ref={ref}
      close-on-backdrop-click
      close-on-escape-key
      scroll-lock
      active-level="1"
    >
      <wb-modal-header></wb-modal-header>
      <wb-modal-content headline="Invalidate CDN Caches">
        <ServerMessageWithTimer />
        <div className="wb-margin-vertical-xs">
          <AkamaiForm disabled={disabled} />
        </div>
      </wb-modal-content>
      <wb-modal-footer>
        <button
          type="button"
          className="wb-button wb-button--secondary wb-button--large"
          onClick={onCloseFn}
        >
          Cancel
        </button>
        <button
          type="button"
          disabled={invalidating || disabled}
          className="wb-button wb-button--primary wb-button--large"
          onClick={() => invalidateAkamaiCache()}
        >
          {loading || invalidating ? actionMessage : 'Invalidate'}
        </button>
      </wb-modal-footer>
    </wb-modal>
  )
}

InvalidateAkamaiCacheDialogComponent.propTypes = {
  loading: PropTypes.bool,
  invalidating: PropTypes.bool,
  disabled: PropTypes.bool,
  invalidateAkamaiCache: PropTypes.func,
  onClose: PropTypes.func,
  cleanAkamaiState: PropTypes.func,
}
