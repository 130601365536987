import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ComplianceFormComponent } from '../component'
import { generateControls } from './formOptions'
import validate from '../../../../../../../../util/helpers/validate'

export const ComplianceFormContainer = ({
  complianceInfo,
  isEditable,
  updateComplianceData,
}) => {
  const [state, setState] = useState(generateControls(complianceInfo))
  const updateInputState = (control, value, variant) => {
    const newState = { ...state }
    if (variant) {
      newState[variant] = {
        ...newState[variant],
        [control]: {
          ...newState[variant][control],
          value,
          valid: validate(value, state[variant][control].validationRules),
          touched: true,
        },
      }
    } else {
      newState[control] = {
        ...newState[control],
        value,
        valid: validate(value, state[control].validationRules),
        touched: true,
      }
    }
    setState(newState)
  }

  const generateProcessingOnBehalf = (constolState) => {
    const mbagOwned = constolState.processOnBehalf.mbagOwned.value || false
    const processOnBehalf = {}
    Object.keys(constolState.processOnBehalf).forEach(
      (field) =>
        (processOnBehalf[field] = mbagOwned
          ? constolState.processOnBehalf[field].value
          : null),
    )
    processOnBehalf.mbagOwned = mbagOwned
    return processOnBehalf
  }

  const onSubmit = () => {
    const payload = {
      automatedDecisionMaking: state.automatedDecisionMaking.value,
      dataAnalytics: state.dataAnalytics.value,
      dataProtectionContactPerson: state.dataProtectionContactPerson.value,
      processOnBehalf: generateProcessingOnBehalf(state),
    }
    updateComplianceData(payload)
  }

  return (
    <ComplianceFormComponent
      controls={state}
      isEditable={isEditable}
      updateInputState={updateInputState}
      onSubmit={onSubmit}
    />
  )
}

ComplianceFormContainer.propTypes = {
  complianceInfo: PropTypes.shape({
    processOnBehalf: PropTypes.shape({
      mbagOwned: PropTypes.bool,
      mbagAgreementEntity: PropTypes.string,
      thirdPartyAccess: PropTypes.bool,
      personalData: PropTypes.bool,
      agreementSubject: PropTypes.string,
      riskAssessment: PropTypes.arrayOf(PropTypes.string),
      dataProcessingLocation: PropTypes.string,
      personalDataScope: PropTypes.string,
      personalDataTypes: PropTypes.string,
      personalDataTargetGroups: PropTypes.string,
    }),
    automatedDecisionMaking: PropTypes.bool,
    dataAnalytics: PropTypes.bool,
    dataProtectionContactPerson: PropTypes.string,
  }),
  isEditable: PropTypes.bool,
  updateComplianceData: PropTypes.func,
}
