import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getWebHookEvents } from '../../../../../../../data/actions'
import { CheckBox } from '../../../../../../common'
import { EventsSelectorComponent } from '../component'

const groupEvents = (events) => {
  const eventTypes = {}
  events.forEach((event) => {
    eventTypes[event.category] = eventTypes[event.category] || []
    eventTypes[event.category].push(event)
  })
  return eventTypes
}

const EventsSelectorContainer = ({
  control,
  events,
  producerSlug,
  getProducerEvents,
  onChange,
}) => {
  const { valid, value, touched } = control
  const [chooseForAll, setChooseForAll] = useState(false)
  const [arrowStyle, setArrowStyle] = useState('rigth')
  const [openCloseEvents, setOpenCloseEvents] = useState(false)

  useEffect(() => {
    getProducerEvents(producerSlug)
  }, [producerSlug])

  useEffect(() => {
    const chooseAll = value === '*'
    if (chooseForAll !== chooseAll) {
      setChooseForAll(chooseAll)
    }
  }, [value])

  const onCheckBoxClick = (eventId) => {
    const newEventTypes = [...value]
    const eventIndex = newEventTypes.findIndex(
      (eventType) => eventType === eventId,
    )
    if (eventIndex > -1) {
      newEventTypes.splice(eventIndex, 1)
    } else {
      newEventTypes.push(eventId)
    }
    onChange(newEventTypes)
  }

  const onClickChooseForAll = () => {
    chooseForAll ? onChange([]) : onChange('*')
    setChooseForAll(!chooseForAll)
  }

  const onClickOpenCloseEvents = () => {
    setOpenCloseEvents(!openCloseEvents)
    setArrowStyle(openCloseEvents ? 'right' : 'down')
  }

  return (
    <>
      <div
        style={{
          display: 'inline-flex',
        }}
      >
        <wb-icon
          name="bds/caret-down/24"
          style={
            arrowStyle === 'down'
              ? { transform: 'rotate(0deg)', cursor: 'pointer' }
              : { transform: 'rotate(270deg)', cursor: 'pointer' }
          }
          onClick={onClickOpenCloseEvents}
        />
        <CheckBox
          checked={chooseForAll}
          onChange={onClickChooseForAll}
          label={`Register for all Events`}
        />
      </div>
      <EventsSelectorComponent
        invalid={touched && !valid}
        dense={!producerSlug}
        eventTypes={groupEvents(events)}
        value={value}
        onCheckBoxClick={onCheckBoxClick}
        openCloseEvents={openCloseEvents}
        chooseForAll={chooseForAll}
      />
    </>
  )
}

export const EventsSelector = connect(
  ({ hermes: { events } }) => ({ events }),
  {
    getProducerEvents: getWebHookEvents,
  },
)(EventsSelectorContainer)

EventsSelectorContainer.propTypes = {
  control: PropTypes.shape({
    valid: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    touched: PropTypes.bool,
  }),
  events: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
      category: PropTypes.string,
    }),
  ),
  producerSlug: PropTypes.string,
  getProducerEvents: PropTypes.func,
  onChange: PropTypes.func,
}
