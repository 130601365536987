import React, { useState, useEffect } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table'
import PropTypes from 'prop-types'
import { generateColumns } from './tableConfig'
import { PermissionsTableComponent } from '../component'

const dataFilterer = (users, selectedRoles) => {
  if (!selectedRoles.length) {
    return users
  }
  const filterRoles = selectedRoles.map(({ value }) => value)
  return users.filter(({ userInfo: { roles } }) =>
    roles.some((role) => filterRoles.includes(role)),
  )
}

export const PermissionsTableContainer = ({
  data = [],
  onAddUser,
  onEditUser,
}) => {
  const [users, setUsers] = useState(data)
  const [selectedRoles, setSelectedRoles] = useState([])
  const [globalFilter, setGlobalFilter] = useState('')

  const columns = generateColumns(onEditUser)

  useEffect(() => {
    setUsers(dataFilterer(data, selectedRoles))
  }, [data, selectedRoles])

  const table = useReactTable({
    data: users,
    columns,
    state: {
      globalFilter,
    },
    autoResetPageIndex: false,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
  })

  return (
    <PermissionsTableComponent
      searchTerm={globalFilter}
      table={table}
      actions={{
        onAddUser,
        onChangeSearch: (e) => setGlobalFilter(e.target.value),
        setSelectedRoles,
      }}
    />
  )
}

PermissionsTableContainer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      daimlerId: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      userInfo: PropTypes.shape({
        roles: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  ),
  onAddUser: PropTypes.func,
  onEditUser: PropTypes.func,
}
