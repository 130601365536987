import React from 'react'
import PropTypes from 'prop-types'

export const ToggleSwitch = ({ label, checked, disabled, onChange }) => (
  <wb-toggle-control>
    <label>
      <input
        name="data-saver"
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {label}
    </label>
  </wb-toggle-control>
)

ToggleSwitch.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}
