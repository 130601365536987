import React from 'react'
import PropTypes from 'prop-types'

export const ModalContent = ({ headline, className, children }) => (
  <wb-modal-content headline={headline} class={className}>
    {children}
  </wb-modal-content>
)

ModalContent.propTypes = {
  headline: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
