import React from 'react'
import PropTypes from 'prop-types'
import { Button, SearchInput, ReactSelect } from '../../../../../../../common'
import { TDMS_ROLES_LEGACY } from '../../../../constants'

export const TableHead = ({
  searchTerm,
  selectedRoles,
  actions: { onAddUser, onChangeSearch, setSelectedRoles },
}) => (
  <div className="wb-margin-vertical-xs tdms-users__head wb-type-copy">
    <h2 className="wb-margin-bottom-xs">TDMS Users</h2>
    <div className="tdms-users__table-head wb-margin-bottom-xm">
      <div className="tdms-users__left-head">
        <div className="wb-margin-bottom-xs tdms-users__multiselect">
          <ReactSelect
            value={selectedRoles}
            isMulti={true}
            name={'Select Roles'}
            options={Object.values(TDMS_ROLES_LEGACY)}
            placeholder="Filter by roles"
            onChange={setSelectedRoles}
          />
        </div>
        <div>
          <SearchInput
            label="Search Users"
            placeholder="Please enter search word"
            value={searchTerm}
            onChange={onChangeSearch}
          />
        </div>
      </div>
      <div className="tdms-users__right-head">
        <Button
          variant="primary"
          size="medium"
          withIcon="bds/plus/24"
          onClick={onAddUser}
        >
          Add user
        </Button>
      </div>
    </div>
  </div>
)

TableHead.propTypes = {
  searchTerm: PropTypes.string,
  selectedRoles: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  actions: PropTypes.shape({
    onAddUser: PropTypes.func,
    onChangeSearch: PropTypes.func,
    setSelectedRoles: PropTypes.func,
  }),
}
