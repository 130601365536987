import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { clearAkamaiServerMessages } from '../../../../../../../data/actions'
import { MessageWithTimer } from './MessageWithTimer'

const ServerMessageWithTimerComponent = ({
  serverMessages,
  clearServerMessages,
}) => {
  useEffect(() => {
    const timeOutFunctions = serverMessages.map(
      ({ akamaiCpCodesInvalidateResponseDto: { estimatedSeconds } }) =>
        () =>
          setTimeout(clearServerMessages, parseInt(estimatedSeconds) * 1000),
    )
    const fn = () => {
      timeOutFunctions.map((func) => func())
    }
    fn()
    return () => fn()
  }, [serverMessages])

  return (
    <div className="server-messages">
      {serverMessages.map(
        ({
          akamaiCpCodesInvalidateResponseDto: { estimatedSeconds, purgeId },
        }) => (
          <MessageWithTimer key={purgeId} estimatedSeconds={estimatedSeconds} />
        ),
      )}
    </div>
  )
}

ServerMessageWithTimerComponent.propTypes = {
  serverMessages: PropTypes.arrayOf(
    PropTypes.shape({
      akamaiCpCodesInvalidateResponseDto: PropTypes.shape({
        estimatedSeconds: PropTypes.number,
        purgeId: PropTypes.string,
      }),
    }),
  ),
  clearServerMessages: PropTypes.func,
}

export const ServerMessageWithTimer = connect(
  ({ akamai: { serverMessages } }) => ({
    serverMessages,
  }),
  { clearServerMessages: clearAkamaiServerMessages },
)(ServerMessageWithTimerComponent)
