import React from 'react'
import PropTypes from 'prop-types'

export const TenantDocLink = ({ docLink }) => (
  <div className="tenant-body__block">
    <div className="tenant-body__block-label">External Documentation</div>
    <ul className="wb-list wb-list--unordered wb-list--offset-xs">
      <li className="wb-list__item">
        <a
          className="wb-link wb-link--standalone"
          rel="noreferrer"
          target="_blank"
          href={docLink}
        >
          Doc Link
        </a>
      </li>
    </ul>
  </div>
)

TenantDocLink.propTypes = {
  docLink: PropTypes.string,
}
