import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getWebHookProviders } from '../../../../../../../data/actions'
import { ProvidersSelectorComponent } from '../component'

const ProvidersContainer = ({
  control,
  providers,
  producerSlug,
  getProviders,
  onChange,
}) => {
  const { valid, value, touched } = control

  useEffect(() => {
    getProviders(producerSlug)
  }, [producerSlug])

  return (
    <ProvidersSelectorComponent
      providers={providers}
      value={value}
      onClickProvider={onChange}
      invalid={touched && !valid}
    />
  )
}

export const ProvidersSelector = connect(
  ({ hermes: { providers } }) => ({ providers }),
  { getProviders: getWebHookProviders },
)(ProvidersContainer)

ProvidersContainer.propTypes = {
  control: PropTypes.shape({
    valid: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    ]),
    touched: PropTypes.bool,
  }),
  producerSlug: PropTypes.string,
  providers: PropTypes.array,
  onChange: PropTypes.func,
  getProviders: PropTypes.func,
}
