import React from 'react'
import PropTypes from 'prop-types'
import { WbIcon } from '../..'

export const DropdownMenuItem = ({ label, icon, onClick }) => (
  <button
    type="button"
    className="wb-list-group__item"
    aria-label={label}
    onClick={onClick}
  >
    <WbIcon icon={icon} className="wb-list-group__item-icon"></WbIcon>
    {label}
  </button>
)

DropdownMenuItem.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
}
