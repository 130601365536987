import React from 'react'
import PropTypes from 'prop-types'
import { PermissionsSelector } from '../../../components/PermissionsSelector'
import { MemberSelector } from '../MemberSelector'

export const MembersFormComponent = ({
  members,
  editMode,
  service,
  booking,
  loggedInUserDaimlerId,
  selectedMembers,
  actions: { onSelectMember, onSelectPermission, membersHaveSamePermission },
}) => (
  <>
    <MemberSelector
      members={members}
      editMode={editMode}
      loggedInUserDaimlerId={loggedInUserDaimlerId}
      selectedMembers={selectedMembers}
      onSelectMember={onSelectMember}
    />
    <div className="wb-margin-top-l">
      <PermissionsSelector
        withHelp
        withEmptySelector
        service={service}
        booking={booking}
        members={editMode ? members : selectedMembers}
        currentPermission={
          membersHaveSamePermission(selectedMembers)
            ? selectedMembers[0].permission
            : null
        }
        disabled={!members.length}
        onSelect={onSelectPermission}
      />
    </div>
  </>
)

MembersFormComponent.propTypes = {
  members: PropTypes.array,
  editMode: PropTypes.bool,
  service: PropTypes.object,
  booking: PropTypes.object,
  loggedInUserDaimlerId: PropTypes.string,
  selectedMembers: PropTypes.array,
  actions: PropTypes.shape({
    onSelectMember: PropTypes.func,
    onSelectPermission: PropTypes.func,
    membersHaveSamePermission: PropTypes.func,
  }),
}
