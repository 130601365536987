import React from 'react'
import PropTypes from 'prop-types'

export const CheckBox = ({
  checked,
  label,
  disabled,
  onChange,
  className = 'wb-margin-bottom-xs',
  ...rest
}) => (
  <wb-checkbox-control class={className}>
    <label style={{ height: '15px', lineHeight: '20px' }}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      />
      {label}
    </label>
  </wb-checkbox-control>
)

CheckBox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}
