import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table'
import { connect } from 'react-redux'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { generateColumns } from '../utils'
import { fetchTenantLogs, fetchOperationTypes } from '../../../../../../http'
import { TenantMemberActionsComponent } from '../component'

const queryClient = new QueryClient()

const TenantMemberActionsContainer = ({ tenant, tenants: allTenants }) => {
  const tenantsList = []
  if (tenant?.acronym) {
    tenantsList.push(tenant.acronym)
  }

  const [sorting, setSorting] = useState([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [selectedOpTypes, setSelectedOpTypes] = useState([])
  const [selectedTenants, setSelectedTenants] = useState(
    tenantsList.map((item) => ({ label: item, value: item })),
  )

  const [
    { pageIndex, pageSize, query, operationTypes, tenants },
    setPagination,
  ] = useState({
    pageIndex: 0,
    pageSize: 20,
    query: '',
    operationTypes: [],
    tenants: tenantsList,
  })

  const fetchDataOptions = {
    pageIndex,
    pageSize,
    sorting,
    query,
    operationTypes,
    tenants,
  }

  useEffect(() => {
    setPagination({
      pageIndex,
      pageSize,
      query: globalFilter,
      operationTypes: selectedOpTypes.map(({ value }) => value),
      tenants: selectedTenants.map(({ value }) => value),
    })
  }, [globalFilter, selectedOpTypes, selectedTenants])

  const dataQuery = useQuery(
    ['logs', fetchDataOptions],
    () => fetchTenantLogs(fetchDataOptions),
    { keepPreviousData: true, refetchOnWindowFocus: false },
  )

  const operationsQuery = useQuery(
    ['operationTypes', {}],
    () => fetchOperationTypes(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  )

  const defaultData = React.useMemo(() => [], [])

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
      query,
      operationTypes,
      tenants,
    }),
    [pageIndex, pageSize, query, operationTypes, tenants],
  )

  const logTypes = operationsQuery.data?.content || []

  const table = useReactTable({
    data: dataQuery.data?.content ?? defaultData,
    columns: generateColumns(tenant, logTypes),
    pageCount: dataQuery.data?.totalPages ?? -1,
    state: {
      pagination,
      sorting,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    getSubRows: (row) => row.payload?.diff || row.payload?.changes,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualFiltering: true,
  })

  useEffect(() => {
    if (
      typeof dataQuery?.data?.totalPages === 'number' &&
      dataQuery.data.totalPages - 1 < table.getState().pagination.pageIndex
    ) {
      table.setPageIndex(0)
    }
  }, [dataQuery?.data?.totalPages])

  return (
    <TenantMemberActionsComponent
      logTypes={logTypes}
      selectedOpTypes={selectedOpTypes}
      globalFilter={globalFilter}
      selectedTenants={selectedTenants}
      table={table}
      tenant={tenant}
      allTenants={allTenants}
      actions={{
        setSelectedOpTypes,
        setSelectedTenants,
        setQuery: setGlobalFilter,
      }}
    />
  )
}

TenantMemberActionsContainer.propTypes = {
  tenant: PropTypes.shape({
    acronym: PropTypes.string,
  }),
  tenants: PropTypes.arrayOf(PropTypes.string),
}

const TenantMembers = connect(({ tenants: { allTenants } }) => ({
  tenants: Object.keys(allTenants),
}))(TenantMemberActionsContainer)

export const TenantMemberActions = (props) => (
  <QueryClientProvider client={queryClient}>
    <TenantMembers {...props} />
  </QueryClientProvider>
)
