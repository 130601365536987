export const selectedMembersIncludesNonNebulaMembers = (
  selectedMembers,
  nebulaMembers,
) => selectedMembers.some(({ daimlerId }) => !nebulaMembers[daimlerId])

export const generateNewSelectedMemberState = (selectedMembers, member) => {
  const newMembers = [...selectedMembers]
  const currentMemberIndex = newMembers.findIndex(
    ({ daimlerId }) => daimlerId === member.daimlerId,
  )
  if (currentMemberIndex > -1) {
    newMembers.splice(currentMemberIndex, 1)
  } else {
    newMembers.push(member)
  }
  return newMembers
}

export const generateDaimlerIdsList = (membersToSubmit, permission) => {
  const daimlerIds = []
  for (const member of membersToSubmit) {
    if (!member.permission || member.permission?.id !== permission.id) {
      daimlerIds.push(member.daimlerId)
    }
  }
  return daimlerIds
}

export const generateBookingPermissionsList = (members, permission) => {
  const permissionIds = []
  for (const member of members) {
    if (member.permission && member.permission?.id !== permission.id) {
      permissionIds.push(member.permission.perm.id)
    }
  }
  return permissionIds
}

export const validateMembersAgainstNebulaPermissions = (
  permission,
  selectedMembers,
  nebulaMembers,
) =>
  permission?.providerOnly &&
  selectedMembersIncludesNonNebulaMembers(selectedMembers, nebulaMembers)

export const validateMembers = (
  editMode,
  membersWithWaitingMode,
  permission,
  selectedMembers,
) =>
  Boolean(
    editMode
      ? generateBookingPermissionsList(membersWithWaitingMode, permission)
          .length
      : selectedMembers.length,
  )

export const validateSelectedPermissionAndMembers = (
  editMode,
  permission,
  membersWithWaitingMode,
  selectedMembers,
) =>
  permission &&
  validateMembers(editMode, membersWithWaitingMode, permission, selectedMembers)

export const membersHaveSamePermission = (selectedMembers) => {
  const membersObj = {}
  selectedMembers.forEach((member) => {
    membersObj[member.permission ? member.permission.id : 'np'] = true
  })
  return Object.keys(membersObj).length === 1
}
