import React from 'react'
import PropTypes from 'prop-types'
import { TenantMembersAddUserSection } from '../../TenantMembersAddUserSection'
import { TenantMembersTable } from '../../TenantMembersTable'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
} from '../../../../../../common'

export const TenantMembersDialogComponent = ({ onClose }) => (
  <Modal mode="edit" className="tenant-dialog" onCloseModal={onClose}>
    <ModalHeader />
    <ModalContent
      headline="Edit members"
      style={{ height: '92%', overflow: 'hidden' }}
    >
      <div className="tenant-members-dialog__content-main">
        <div className="tenant-members-dialog__table">
          <TenantMembersTable />
        </div>
      </div>
    </ModalContent>
    <ModalFooter class="tenant-members-dialog__footer">
      <div></div>
      <div>
        <TenantMembersAddUserSection />
        <Button variant="secondary" size="medium" onClick={() => onClose()}>
          Close
        </Button>
      </div>
    </ModalFooter>
  </Modal>
)

TenantMembersDialogComponent.propTypes = {
  onClose: PropTypes.func,
}
