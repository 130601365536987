import React from 'react'
import PropTypes from 'prop-types'
import { PermissionsSelector } from '../../PermissionsSelector'
import { ProcessingIndicator } from '../../../../ProcessingIndicator'
import { ErrorControl, Button } from '../../../../../../../../../../../common'

export const PermissionsEditorComponent = ({
  mode,
  disabled,
  currentMember,
  booking,
  service,
  loggedInPoPmDaimlerId,
  onUpdatePermission,
  setMode,
  onRemovePermission,
  setPermission,
}) => {
  const waitingMode = currentMember.waitingMode
  const editMode = mode === 'edit'
  const notLoggedInMember = loggedInPoPmDaimlerId !== currentMember.daimlerId
  return (
    <div className="permissions-editor">
      <div className="permissions-editor__permission">
        {editMode && notLoggedInMember ? (
          <PermissionsSelector
            currentPermission={currentMember.permission}
            service={service}
            withEmptySelector
            disabled={waitingMode}
            booking={booking}
            members={[currentMember]}
            onSelect={setPermission}
          />
        ) : (
          currentMember.permission?.name
        )}
      </div>
      <div className="permissions-editor__controls">
        {waitingMode ? <ProcessingIndicator withText /> : null}
        {notLoggedInMember ? (
          <>
            <Button
              size="small"
              variant="secondary"
              disabled={waitingMode || disabled}
              onClick={() =>
                editMode ? onUpdatePermission() : setMode('edit')
              }
              withIcon={editMode ? 'checkmark' : 'bds/pencil/24'}
              iconOnly
            >
              {editMode ? 'Save' : 'Edit'}
            </Button>
            <Button
              disabled={waitingMode || disabled}
              size="small"
              variant="secondary"
              onClick={() =>
                editMode ? setMode() : onRemovePermission(currentMember)
              }
              withIcon={editMode ? 'close' : 'bds/trash/24'}
              iconOnly
            >
              {editMode ? 'Close' : 'Delete'}
            </Button>
          </>
        ) : (
          <ErrorControl
            error="You cannot change permissions assigned to yourself. Please contact
          other PO or PM"
          />
        )}
      </div>
    </div>
  )
}

PermissionsEditorComponent.propTypes = {
  currentMember: PropTypes.shape({
    waitingMode: PropTypes.bool,
    comment: PropTypes.string,
    daimlerId: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mbioId: PropTypes.string,
    permission: PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      providerOnly: PropTypes.bool,
      perm: PropTypes.shape({
        id: PropTypes.number,
        userDaimlerId: PropTypes.string,
        permissionId: PropTypes.number,
        permissionName: PropTypes.string,
        permissionSlug: PropTypes.string,
      }),
      slug: PropTypes.string,
    }),
    roles: PropTypes.arrayOf(PropTypes.string),
    tenantMemberUntil: PropTypes.string,
  }),
  loggedInPoPmDaimlerId: PropTypes.string,
  booking: PropTypes.shape({
    comment: PropTypes.string,
    endDate: PropTypes.string,
    id: PropTypes.number,
    packageName: PropTypes.string,
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        year: PropTypes.string,
        monthlyPrice: PropTypes.number,
        platformServicePackageId: PropTypes.string,
      }),
    ),
    provider: PropTypes.string,
    slug: PropTypes.string,
    startDate: PropTypes.string,
    tenantAcronym: PropTypes.string,
    tenantName: PropTypes.string,
  }),
  service: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    provider: PropTypes.string,
    serviceKey: PropTypes.string,
    slug: PropTypes.string,
  }),
  onUpdatePermission: PropTypes.func,
  setMode: PropTypes.func,
  disabled: PropTypes.bool,
  onRemovePermission: PropTypes.func,
  mode: PropTypes.string,
  setPermission: PropTypes.func,
}
