import React from 'react'
import { ServicesList } from '../components/ServicesList'
import { CurrentService } from '../components/CurrentService'

export const Services = () => (
  <div className="packages-manager">
    <ServicesList />
    <CurrentService />
  </div>
)
