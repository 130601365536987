import React from 'react'
import PropTypes from 'prop-types'
import { ErrorControl } from '../ErrorControl'
import { Hint } from '../Hint'

export const TextArea = ({ value, label, disabled, error, hint, onChange }) => (
  <wb-input-control>
    <wb-input>
      <label>{label}</label>
      <textarea onChange={onChange} value={value} disabled={disabled} />
    </wb-input>
    <ErrorControl error={error} />
    <Hint text={hint} />
  </wb-input-control>
)

TextArea.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
}
