import React from 'react'
import { flexRender } from '@tanstack/react-table'
import PropTypes from 'prop-types'

export const TanstackTableWrapper = ({ table }) => (
  <table
    className="wb-table wb-table--border-horizontal"
    style={{ width: '100%' }}
  >
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            return (
              <th key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder ? null : (
                  <div>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </div>
                )}
              </th>
            )
          })}
        </tr>
      ))}
    </thead>
    <tbody>
      {table.getRowModel().rows.map((row) => {
        return (
          <tr
            key={row.id}
            className={
              row.original?.isSubjectToRemoval
                ? 'wb-table__flagged-deletion'
                : ''
            }
          >
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        )
      })}
    </tbody>
  </table>
)

TanstackTableWrapper.propTypes = {
  table: PropTypes.shape({
    getHeaderGroups: PropTypes.func,
    getRowModel: PropTypes.func,
  }),
}
