import { tdmsClient } from '../apiClient'

export const fetchServicePermissions = async (serviceId) =>
  tdmsClient.client.platformBookingServicesMethods.listPermissions(serviceId)

export const fetchPackagePermissions = async (packageSlug) =>
  tdmsClient.client.platformBookingServicesMethods.listPackagePermissions(
    packageSlug,
  )

export const createServicePermission = async (serviceId, permissionData) =>
  tdmsClient.client.platformBookingServicesMethods.createPermission(
    serviceId,
    permissionData,
  )

export const updateServicePermission = async (
  servicePermissionId,
  permissionData,
) =>
  tdmsClient.client.platformBookingServicesMethods.updatePermission(
    servicePermissionId,
    permissionData,
  )

export const removeServicePermission = async (servicePermissionId) =>
  tdmsClient.client.platformBookingServicesMethods.removePermission(
    servicePermissionId,
  )
