import React from 'react'
import PropTypes from 'prop-types'
import { Popover, PopoverHost, Button, ListGroup } from '..'
import { DropdownMenuItem } from './DropdownMenuItem'

export const DropdownMenu = ({ buttonProps, items }) => (
  <PopoverHost>
    <Button {...buttonProps} />
    <Popover>
      <ListGroup>
        {items.map(({ label, ...rest }) => (
          <DropdownMenuItem {...rest} key={label} label={label} />
        ))}
      </ListGroup>
    </Popover>
  </PopoverHost>
)

DropdownMenu.propTypes = {
  buttonProps: PropTypes.shape({
    variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    className: PropTypes.string,
    iconOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    withIcon: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
}
