import React from 'react'
import PropTypes from 'prop-types'

export const LoadingIndicator = ({ withText }) => (
  <div className="loader-container">
    <div className="loader">
      <wb-spinner mode="dark" />
      <div className="wb-margin-start-xxs">
        {withText ? 'Processing...' : ''}
      </div>
    </div>
  </div>
)

LoadingIndicator.propTypes = {
  withText: PropTypes.bool,
}
