import React from 'react'
import PropTypes from 'prop-types'
import { PropertiesListItem } from '../PropertiesListItem'
import { Button } from '../../../../../../common'

export const PropertiesListComponent = ({
  readOnly,
  serviceProperties,
  onRemoveProperty,
  onAddProperty,
  onEditProperty,
}) => (
  <div className="permissions-list">
    <div className="permissions-list__header">
      <h2>Properties List</h2>
      <Button
        onClick={onAddProperty}
        disabled={readOnly}
        size="small"
        variant="secondary"
        className="packages-editor__button"
      >
        Add property
      </Button>
    </div>
    <ul className="packages-editor__list">
      {serviceProperties.length ? (
        serviceProperties.map((property) => (
          <PropertiesListItem
            key={property.id}
            property={property}
            readOnly={readOnly}
            onRemoveProperty={onRemoveProperty}
            onEditProperty={onEditProperty}
          />
        ))
      ) : (
        <div>No Properties for given service</div>
      )}
    </ul>
  </div>
)

PropertiesListComponent.propTypes = {
  readOnly: PropTypes.bool,
  serviceProperties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  onRemoveProperty: PropTypes.func,
  onAddProperty: PropTypes.func,
  onEditProperty: PropTypes.func,
}
