import React from 'react'
import PropTypes from 'prop-types'

export const ServiceItem = ({ service }) => (
  <li className="packages-editor__list-item">
    <div>{service.name}</div>
    <div className="packages-editor__list-item-description">{service.slug}</div>
    <div className="packages-editor__list-item-description">
      {service.description}
    </div>
  </li>
)

ServiceItem.propTypes = {
  service: PropTypes.shape({
    slug: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
}
