import React from 'react'
import PropTypes from 'prop-types'
import validate from '../../../../../../util/helpers/validate'
import { Input, Select } from '../../../../../common'
import { RequestHeaderBuilder } from '../RequestHeaderBuilder'

export const authVariations = [
  {
    name: 'None',
    id: 'none',
  },
  {
    name: 'Basic Auth',
    id: 'basic',
  },
  {
    name: 'Bearer Token',
    id: 'token',
  },
]

export const HttpWebHookForm = ({ state, setState }) => {
  const updateInputState = (control, value) => {
    const newState = {
      ...state,
      [control]: {
        ...state[control],
        value,
        touched: true,
        valid: validate(value, state[control].validationRules, state),
      },
    }
    if (control === 'token') {
      newState.username.value = ''
      newState.username.touched = true
      newState.username.valid = false
      newState.password.value = ''
      newState.password.valid = false
      newState.password.touched = true
    }
    if (control === 'username' || control === 'password') {
      newState.token.value = ''
      newState.token.touched = true
      newState.token.valid = false
    }
    setState(newState)
  }
  return (
    <div className="protocol-selection">
      <div className="wb-margin-bottom-xs">
        <Input
          label="URL"
          className="protocol-selection__url-input"
          value={state.url.value}
          onChange={(e) => updateInputState('url', e.target.value)}
          error={
            state.url.touched && !state.url.valid && 'Please enter a valid URL'
          }
        />
      </div>

      <div className="wb-margin-bottom-xs">
        <Select
          label="Authentication"
          value={state.auth.value}
          onChange={(e) => updateInputState('auth', e.target.value)}
          options={authVariations}
          error={
            state.auth.touched && !state.auth.valid && 'Choose auth method'
          }
        />
      </div>
      {state.auth.value === 'basic' ? (
        <wb-grid-row className="wb-margin-bottom-xs baseAuth">
          <wb-grid-col mq1="6">
            <Input
              label="Username"
              value={state.username.value}
              onChange={(e) => updateInputState('username', e.target.value)}
              error={
                state.username.touched &&
                !state.username.valid &&
                'Please enter a valid username'
              }
            />
          </wb-grid-col>

          <wb-grid-col mq1="6">
            <Input
              label="Password"
              type="password"
              value={state.password.value}
              onChange={(e) => updateInputState('password', e.target.value)}
              error={
                state.password.touched &&
                !state.password.valid &&
                'Please enter a valid password'
              }
            />
          </wb-grid-col>
        </wb-grid-row>
      ) : null}

      {state.auth.value === 'token' ? (
        <div className="wb-margin-bottom-xs tokenAuth">
          <Input
            label="Token"
            value={state.token.value}
            onChange={(e) => updateInputState('token', e.target.value)}
            error={
              state.token.touched &&
              !state.token.valid &&
              'Please enter a valid token'
            }
          />
        </div>
      ) : null}
      <div className="wb-margin-bottom-xs wb-margin-top-s">
        <h3 className="wb-margin-bottom-xs">Custom headers</h3>
        <RequestHeaderBuilder
          value={state.headers.value}
          valid={state.headers.touched && state.headers.valid}
          onChange={(val) => updateInputState('headers', val)}
          touched={state.headers.touched}
        />
      </div>
      <div className="wb-margin-bottom-xs">
        <Input
          label="Timeout"
          value={state.timeout.value}
          type="number"
          onChange={(e) => updateInputState('timeout', e.target.value)}
          error={
            state.timeout.touched &&
            !state.timeout.valid &&
            'Please enter a valid timeout'
          }
        />
      </div>
    </div>
  )
}

const InputProps = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  valid: PropTypes.bool,
  touched: PropTypes.bool,
  validationRules: PropTypes.shape({
    isValidUrl: PropTypes.bool,
  }),
})

HttpWebHookForm.propTypes = {
  state: PropTypes.shape({
    url: InputProps,
    auth: InputProps,
    headers: InputProps,
    username: InputProps,
    password: InputProps,
    token: InputProps,
    timeout: InputProps,
  }),
  setState: PropTypes.func,
}
