import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getCrossChargingOverview } from '../../../../../data/actions'
import { CrossChargingOverviewComponent } from '../component'
import { generateReportByYear } from '../../utils/helpers'

const CostOverviewContainer = ({
  providers,
  crossChargingOverview,
  fetchCrossChargingReport,
}) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  useEffect(() => {
    fetchCrossChargingReport()
  }, [])
  return (
    <CrossChargingOverviewComponent
      selectedYear={selectedYear}
      setSelectedYear={setSelectedYear}
      providers={providers}
      reportsByYear={generateReportByYear(crossChargingOverview)}
    />
  )
}

CostOverviewContainer.propTypes = {
  crossChargingOverview: PropTypes.arrayOf(
    PropTypes.shape({
      packageName: PropTypes.string,
      priceVariantName: PropTypes.string,
      providerName: PropTypes.string,
      providerSlug: PropTypes.string,
      services: PropTypes.arrayOf(
        PropTypes.shape({
          providerName: PropTypes.string,
          providerSlug: PropTypes.string,
          serviceName: PropTypes.string,
        }),
      ),
      yearlyPrices: PropTypes.objectOf(
        PropTypes.shape({
          estimatedValue: PropTypes.bool,
          tenantFundingShare: PropTypes.objectOf(PropTypes.number),
          yearlyPrice: PropTypes.number,
        }),
      ),
    }),
  ),
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      createDate: PropTypes.string,
      updateDate: PropTypes.string,
      cloudProvider: PropTypes.bool,
      deletedBy: PropTypes.string,
      deletedAt: PropTypes.string,
      isEditable: PropTypes.bool,
      isRemovable: PropTypes.bool,
      isManagable: PropTypes.bool,
    }),
  ),
  fetchCrossChargingReport: PropTypes.func,
}

export const CostChargingOverview = connect(
  ({ reports: { crossChargingOverview }, backOffice: { providers } }) => ({
    crossChargingOverview,
    providers,
  }),
  {
    fetchCrossChargingReport: getCrossChargingOverview,
  },
)(CostOverviewContainer)
