import PropTypes from 'prop-types'
import { flat } from '../../../../util'

export const generateFilters = (allTenants, providersList, loggedInUserId) => {
  const tenantsArr = Object.values(allTenants)
  const filtersObj = generateFiltersFromProviders(providersList)

  tenantsArr.forEach((tenant) => {
    if (userIsMemberOfTenant(tenant, loggedInUserId)) {
      filtersObj.myApps.tenants.push(tenant)
    }
    if (filtersObj[tenant.providerSlug]) {
      filtersObj[tenant.providerSlug].tenants.push(tenant)
    } else {
      filtersObj[tenant.providerSlug] = {
        value: tenant.providerSlug,
        label: tenant.providerSlug,
        tenants: [tenant],
      }
    }
  })
  return filtersObj
}

export const generateFiltersFromProviders = (providersList) => {
  const filtersObj = {
    myApps: { value: 'myApps', label: 'My Apps', tenants: [] },
  }
  providersList.forEach((provider) => {
    filtersObj[provider.value] = {
      ...provider,
      tenants: [],
    }
  })
  return filtersObj
}

export const userIsMemberOfTenant = (tenant, currentUserId) =>
  tenant.members.some(
    ({ daimlerId }) => daimlerId === currentUserId.toLowerCase(),
  )

export const tenantsSorter = (tenantA, tenantB) =>
  tenantA.acronym.toLowerCase().localeCompare(tenantB.acronym.toLowerCase())

export const tenantsFilterer = (filterTerm, tenant) => {
  const { acronym = '', name = '', members = [{}] } = tenant
  return `${acronym} ${name}} ${members
    .map((member) =>
      Object.values({
        ...member,
        fullName: `${member.firstName} ${member.lastName}`,
      }).join(' '),
    )
    .join(' ')}`
    .toLowerCase()
    .includes(filterTerm.toLowerCase())
}

export const generateFilteredTenants = (filterCategories, tenantFilters) =>
  filterCategories.map((filter) => tenantFilters[filter].tenants).flat()

export const generateFilteredTenantsList = (
  tenantFilters,
  { filterCategories, inputState },
  allTenants,
) => {
  const tenantsList = generateFlatTenantsList(
    filterCategories,
    tenantFilters,
    allTenants,
  )

  const filteredTenants = filterTenantsBySearchInput(inputState, tenantsList)

  return generateTenantsObjectFromFlatList(filteredTenants)
}

export const generateFlatTenantsList = (
  filterCategories,
  tenantFilters,
  allTenants,
) =>
  (filterCategories.length
    ? generateFilteredTenants(filterCategories, tenantFilters)
    : Object.values(allTenants)
  ).sort(tenantsSorter)

export const filterTenantsBySearchInput = (inputState, tenantsList) =>
  inputState
    ? tenantsList.filter((tenant) => tenantsFilterer(inputState, tenant))
    : tenantsList

export const generateTenantsObjectFromFlatList = (tenantsList) => {
  const tenantsObj = {}
  tenantsList.forEach((tenant) => {
    tenantsObj[tenant.acronym] = tenant
  })
  return tenantsObj
}

export const reduceTenants = (tenants) => {
  const statusKeys = { active: [], idle: [], archived: [] }
  Object.keys(tenants).forEach((key) => {
    const text = `${key.toUpperCase()} - ${tenants[key].name}`
    if (tenants[key].status) {
      statusKeys[tenants[key].status].push({
        id: key,
        name: text,
        text,
        status: tenants[key].status,
        provider: tenants[key].providerSlug,
      })
    }
  })

  return flat(Object.values(statusKeys), 1)
}

export const controllingInfoPropType = PropTypes.shape({
  costCenter: PropTypes.string,
  costNumber: PropTypes.string,
  pspNumber: PropTypes.string,
  isaNumber: PropTypes.string,
  purchaseOrder: PropTypes.string,
})

export const tenantPropType = {
  acronym: PropTypes.string,
  agileReleaseTrain: PropTypes.string,
  availability: PropTypes.string,
  awsAccountId: PropTypes.string,
  b2xCoreControllingInformation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  coNumber: PropTypes.string,
  confidentiality: PropTypes.string,
  continuity: PropTypes.bool,
  created: PropTypes.string,
  description: PropTypes.string,
  disabledPlanningIT: PropTypes.bool,
  docLink: PropTypes.string,
  experiences: PropTypes.array,
  gbsControllingInformation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  icto: PropTypes.number,
  integrity: PropTypes.string,
  memberExpiringDateRange: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }),
  members: PropTypes.arrayOf(
    PropTypes.shape({
      comment: PropTypes.string,
      daimlerId: PropTypes.string,
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      mbioId: PropTypes.string,
      roles: PropTypes.arrayOf(PropTypes.string),
      tenantMemberUntil: PropTypes.string,
    }),
  ),
  name: PropTypes.string,
  nebulaControllingInformation: PropTypes.oneOfType([
    PropTypes.string,
    controllingInfoPropType,
  ]),
  partOfDigitalHouse: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  personalData: PropTypes.bool,
  planningItId: PropTypes.number,
  programName: PropTypes.string,
  providerSlug: PropTypes.string,
  providers: PropTypes.arrayOf(PropTypes.string),
  springCloudServices: PropTypes.bool,
  status: PropTypes.string,
  type: PropTypes.arrayOf(PropTypes.string),
  updated: PropTypes.string,
  updatedBy: PropTypes.string,
}
