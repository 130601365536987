import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from '../../../../../../../../../../../common'
import { MembersList } from '../MembersList'
import { memberFilterer } from './helpers'

export const MemberSelector = ({
  members,
  editMode,
  loggedInUserDaimlerId,
  selectedMembers,
  onSelectMember,
}) => {
  const [filter, setFilter] = useState('')
  const filteredMembers = members.filter((member) =>
    memberFilterer(member, filter),
  )

  const checkSelected = (daimlerId) =>
    Boolean(selectedMembers.find((member) => member.daimlerId === daimlerId))

  return (
    <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
      <div className="wb-margin-bottom-xs">
        <Input
          label="Search..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <MembersList
        loggedInUserDaimlerId={loggedInUserDaimlerId}
        editMode={editMode}
        members={filteredMembers}
        checkSelected={checkSelected}
        onSelectMember={onSelectMember}
      />
    </div>
  )
}

MemberSelector.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      daimlerId: PropTypes.string,
      mbioId: PropTypes.string,
    }),
  ),
  editMode: PropTypes.bool,
  loggedInUserDaimlerId: PropTypes.string,
  selectedMembers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      daimlerId: PropTypes.string,
      mbioId: PropTypes.string,
    }),
  ),
  onSelectMember: PropTypes.func,
}
