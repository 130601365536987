import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  setModal,
  removeBookingPermission,
  updateMembersToBookingServicePermission,
  postNotification,
} from '../../../../../../../../../../../../data/actions'
import { PermissionsEditorComponent } from '../component'

const PermissionsEditorContainer = ({
  currentMember,
  nebulaMembers,
  booking,
  service,
  loggedInPoPmDaimlerId,
  dispatchModal,
  removePermission,
  updatePermission,
  notify,
}) => {
  const [mode, setMode] = useState()
  const [disabled, setDisabled] = useState(false)
  const [permission, setPermission] = useState(currentMember.permission)

  const onUpdatePermission = () => {
    try {
      if (!permission) {
        throw new Error('Please select permission!')
      }
      if (currentMember.permission.id === permission.id) {
        throw new Error(
          'The selected permission is already assigned to the user, please choose different one',
        )
      }
      if (permission.providerOnly && !nebulaMembers[currentMember.daimlerId]) {
        throw new Error(
          'You are trying to assing a nebula only permission to non-nebula member. (User should be member of Tools, Monitoring, NEBULA or TDMS tenants)!',
        )
      }
      updatePermission(
        booking,
        [currentMember.permission.perm.id],
        permission.slug,
        service,
        setMode,
      )
    } catch (err) {
      notify({ message: err.message, mode: 'error' })
    }
  }

  const onSetPermission = (newPermission) => {
    try {
      if (!newPermission) {
        throw new Error(
          'You cannot assign NEBULA_ONLY permission to non NEBULA tenant member',
        )
      }
      if (
        newPermission.providerOnly &&
        !nebulaMembers[currentMember.daimlerId]
      ) {
        setDisabled(true)
        throw new Error(
          'You cannot assign NEBULA_ONLY permission to non NEBULA tenant member',
        )
      }
      setPermission(newPermission)
      setDisabled(false)
    } catch (err) {
      notify({
        message: err.message,
        mode: 'error',
      })
    }
  }

  const onRemovePermission = (member) => {
    dispatchModal({
      variant: 'prompt',
      onApprove: () => {
        removePermission(booking, service, member.permission.perm.id, () =>
          dispatchModal({}),
        )
      },
      okText: 'Remove',
      waitingProps: {
        bookingId: booking.id,
        serviceSlug: service.slug,
        daimlerId: member.daimlerId,
      },
      header: 'Are you sure?',
      onCancel: () => dispatchModal({}),
    })
  }

  return (
    <PermissionsEditorComponent
      currentMember={currentMember}
      loggedInPoPmDaimlerId={loggedInPoPmDaimlerId}
      booking={booking}
      disabled={disabled}
      service={service}
      onUpdatePermission={onUpdatePermission}
      setMode={setMode}
      permission={permission}
      onRemovePermission={onRemovePermission}
      mode={mode}
      setPermission={onSetPermission}
    />
  )
}

PermissionsEditorContainer.propTypes = {
  currentMember: PropTypes.shape({
    comment: PropTypes.string,
    daimlerId: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mbioId: PropTypes.string,
    permission: PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      providerOnly: PropTypes.bool,
      perm: PropTypes.shape({
        id: PropTypes.number,
        userDaimlerId: PropTypes.string,
        permissionId: PropTypes.number,
        permissionName: PropTypes.string,
        permissionSlug: PropTypes.string,
      }),
      slug: PropTypes.string,
    }),
    roles: PropTypes.arrayOf(PropTypes.string),
    tenantMemberUntil: PropTypes.string,
  }),
  nebulaMembers: PropTypes.object,
  loggedInPoPmDaimlerId: PropTypes.string,
  service: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    provider: PropTypes.string,
    serviceKey: PropTypes.string,
    slug: PropTypes.string,
  }),
  booking: PropTypes.shape({
    comment: PropTypes.string,
    endDate: PropTypes.string,
    id: PropTypes.number,
    packageName: PropTypes.string,
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        year: PropTypes.string,
        monthlyPrice: PropTypes.number,
        platformServicePackageId: PropTypes.string,
      }),
    ),
    provider: PropTypes.string,
    slug: PropTypes.string,
    startDate: PropTypes.string,
    tenantAcronym: PropTypes.string,
    tenantName: PropTypes.string,
  }),
  dispatchModal: PropTypes.func,
  removePermission: PropTypes.func,
  updatePermission: PropTypes.func,
  notify: PropTypes.func,
}

export const PermissionsEditor = connect(
  ({ tenants: { nebulaMembers } }) => ({ nebulaMembers }),
  {
    dispatchModal: setModal,
    removePermission: removeBookingPermission,
    updatePermission: updateMembersToBookingServicePermission,
    notify: postNotification,
  },
)(PermissionsEditorContainer)
