import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getCurrentBookingServices } from '../../../../../../../../data/actions'
import { selectCurrentBookingServices } from '../../../../../../../../data/selectors/tenantBookings'
import { BookingItemComponent } from '../component'

const BookingItemContainer = ({
  booking,
  packageServices,
  getBookingServices,
}) => {
  useEffect(() => {
    getBookingServices(booking)
  }, [])
  return (
    <BookingItemComponent packageServices={packageServices} booking={booking} />
  )
}

export const bookingProp = PropTypes.shape({
  comment: PropTypes.string,
  endDate: PropTypes.string,
  id: PropTypes.number,
  packageName: PropTypes.string,
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      year: PropTypes.string,
      monthlyPrice: PropTypes.number,
      platformServicePackageId: PropTypes.string,
    }),
  ),
  provider: PropTypes.string,
  slug: PropTypes.string,
  startDate: PropTypes.string,
  tenantAcronym: PropTypes.string,
  tenantName: PropTypes.string,
})

BookingItemContainer.propTypes = {
  booking: bookingProp,
  packageServices: PropTypes.arrayOf(PropTypes.shape({})),
  getBookingServices: PropTypes.func,
}

export const BookingItem = connect(
  ({ tenantBookings: { services } }, ownProps) => ({
    packageServices: selectCurrentBookingServices(
      ownProps.booking.packageSlug,
      services,
      ownProps,
    ),
  }),
  {
    getBookingServices: getCurrentBookingServices,
  },
)(BookingItemContainer)
