import {
  fetchPlatformPackages,
  fetchPlatformPackageServices,
  postPlatformPackage,
  putPlatformPackage,
  postPlatformPackageService,
  deletePlatformPackageService,
  archivePlatformServicePackages,
} from '../../../../../http'
import { postNotification } from '../../..'
import {
  setPackages,
  addPackage,
  setPackage,
  updatePackage,
  addServiceToPackage,
  removeServiceFromPackage,
  setPackageServices,
  setModal,
} from '../shared'
import { packagePermissionsMapper, applyPackagePermissions } from '../utils'

export const getPackages = () => async (dispatch, getState) => {
  try {
    const packages = await fetchPlatformPackages()
    const { sso } = getState()
    dispatch(
      setPackages(
        packagePermissionsMapper(packages, sso.roles, sso.providerRoles),
      ),
    )
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while retrieving packages - ${err.message}!`,
        mode: 'error',
      }),
    )
  }
}

export const createPackage = (newPackage) => async (dispatch, getState) => {
  const body = JSON.stringify(newPackage)
  const { sso } = getState()
  const packageId = newPackage.id
  try {
    const returnedPackage = await (packageId
      ? putPlatformPackage(body, packageId)
      : postPlatformPackage(body))
    const packgeWithPermissions = applyPackagePermissions(
      returnedPackage,
      sso.roles,
      sso.providerRoles,
    )
    if (!packageId) {
      dispatch(addPackage(packgeWithPermissions))
    } else {
      dispatch(setPackage(packgeWithPermissions))
      dispatch(updatePackage(packgeWithPermissions))
    }
    dispatch(setModal({}))
    dispatch(
      postNotification({
        message: `Package was created successfully!`,
      }),
    )
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while creating package - ${err.message}!`,
        mode: 'error',
      }),
    )
  }
}

export const archivePackage = (activePackage, cb) => async (dispatch) => {
  try {
    const response = await archivePlatformServicePackages(activePackage.id)
    if (response.errorMessages) {
      throw new Error(response.message)
    }
    const newPackage = {
      ...activePackage,
      archived: true,
      isEditable: false,
      isRemovable: false,
    }
    dispatch(updatePackage(newPackage))
    dispatch(setPackage(newPackage))
    dispatch(
      postNotification({
        message: `The package (${activePackage.name}) was archived successfully!`,
      }),
    )
    cb?.()
  } catch (err) {
    dispatch(
      postNotification({
        message: err.message,
        mode: 'error',
      }),
    )
  }
}

export const attachServiceToPakcage =
  (service) => async (dispatch, getState) => {
    const currentPackage = getState().backOffice.package
    try {
      await postPlatformPackageService(currentPackage.id, service.id)
      dispatch(addServiceToPackage(service))
      dispatch(
        postNotification({
          message: `Service was added to package successfully!`,
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while attaching service to package - ${err.message}!`,
          mode: 'error',
        }),
      )
    }
  }

export const detachServiceFromPackage =
  (service) => async (dispatch, getState) => {
    const currentPackage = getState().backOffice.package
    try {
      await deletePlatformPackageService(currentPackage.id, service.id)
      dispatch(removeServiceFromPackage(service))
      dispatch(
        postNotification({
          message: `Service was removed from package successfully!`,
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while detaching service from package - ${err.message}!`,
          mode: 'error',
        }),
      )
    }
  }

export const bindServiceToPackage = (service, attached) => (dispatch) => {
  const fn = attached ? detachServiceFromPackage : attachServiceToPakcage
  dispatch(fn(service))
}

export const getPackageServices = (pkg) => async (dispatch) => {
  try {
    const response = await fetchPlatformPackageServices(pkg.id)
    if (response.errorMessages) {
      throw new Error(response.message)
    }
    dispatch(setPackageServices(response))
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured while retrieving packageServices - ${err.message}!`,
        mode: 'error',
      }),
    )
  }
}
