import React from 'react'
import PropTypes from 'prop-types'

export const MemberLinks = ({ label, prop, linkStart }) => (
  <p>
    <span className="members-content__item-label">{label}</span>
    {prop ? <a href={linkStart + prop}>{prop}</a> : <span>-</span>}
  </p>
)

MemberLinks.propTypes = {
  label: PropTypes.string,
  prop: PropTypes.string,
  linkStart: PropTypes.string,
}
