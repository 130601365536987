import React from 'react'
import PropTypes from 'prop-types'
import { ParseTenantInfo } from '../utils/ParseTenantInfo'
import { TenantList } from '../TenantList'
import { Spinner, Accordion } from '../../../common'

const propsToCheck = [
  {
    prop: 'planningItId',
    error: 'Missing APP-ID (LeanIX)',
  },
  {
    prop: 'icto',
    error: 'Missing ICTO',
  },
  {
    prop: 'confidentiality',
    error: 'Missing Confidentiality',
  },
  {
    prop: 'integrity',
    error: 'Missing Integrity',
  },
  {
    prop: 'availability',
    error: 'Missing Availability',
  },
  {
    prop: 'continuity',
    error: 'Missing Continuity',
  },
  {
    prop: 'personalData',
    error: 'Missing Personal Data',
  },
]

const generateTenantMessages = (tenant) => {
  const messages = []
  propsToCheck.forEach(({ prop, error }) => {
    if (!tenant[prop] || tenant[prop] === 'unknown') {
      messages.push(error)
    }
  })
  return messages
}

const generateMissingInfoMessages = (allTenants) => {
  const missingInfo = []
  allTenants.forEach((tenant) => {
    if (tenant.status === 'active') {
      const row = ParseTenantInfo(tenant)
      row.messages = generateTenantMessages(tenant)
      missingInfo.push(row)
    }
  })
  return missingInfo
}

export const ComplianceMissingInfo = ({ allTenants = {} }) => {
  const tenantsArr = Object.values(allTenants)
  if (tenantsArr.length === 0) {
    return (
      <div className="page-body__section">
        <div className="loader-container">
          <Spinner theme="dark" />
        </div>
      </div>
    )
  }
  return (
    <div className="page-body__section">
      <h3 className="wb-type-heading-m page-body__margin-bottom-s">
        Tenants with missing compliance info
      </h3>

      <Accordion>
        <TenantList
          title="Missing Data"
          list={generateMissingInfoMessages(tenantsArr)}
        />
      </Accordion>
    </div>
  )
}

ComplianceMissingInfo.propTypes = {
  allTenants: PropTypes.objectOf(
    PropTypes.shape({
      acronym: PropTypes.string,
      status: PropTypes.string,
    }),
  ),
}
