import React from 'react'
import PropTypes from 'prop-types'

export const ModalLevel = ({ level = 1, children }) => (
  <wb-modal-level level={level}>{children}</wb-modal-level>
)

ModalLevel.propTypes = {
  level: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
