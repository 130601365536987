import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ButtonGroup, ButtonGroupItem } from '../../../../../../common'

export const Tabs = ({ content, props }) => {
  const tabs = Object.keys(content)
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const Component = content[selectedTab]
  const onTabClick = (tab) => setSelectedTab(tab)
  return (
    <div>
      <div className="wb-margin-top-xs">
        <ButtonGroup>
          {tabs.map((tab) => (
            <ButtonGroupItem
              key={tab}
              selected={tab === selectedTab}
              onClick={() => onTabClick(tab)}
            >
              {tab}
            </ButtonGroupItem>
          ))}
        </ButtonGroup>
        <hr />
      </div>
      <div className="wb-padding-xs">
        <Component {...props} />
      </div>
    </div>
  )
}

Tabs.propTypes = {
  content: PropTypes.object,
  props: PropTypes.shape({
    service: PropTypes.object,
    booking: PropTypes.object,
  }),
}
