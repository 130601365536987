import React from 'react'
import { CurrentPackage } from '../components/CurrentPackage'
import { PackagesList } from '../components/PackagesList'

export const PackagesComponent = () => (
  <div className="packages-manager">
    <div className="packages-manager__list wb-type-copy">
      <PackagesList />
    </div>
    <CurrentPackage />
  </div>
)
