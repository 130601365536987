import React from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  TextArea,
  CheckBox,
  Button,
  ModalContent,
  ModalFooter,
} from '../../../../../../common'

export const ProviderFormComponent = ({
  header,
  mode,
  controls,
  updateInputState,
  onCancel,
  onSubmit,
}) => (
  <ModalContent headline={header}>
    <div className="wb-margin-bottom-xs wb-margin-top-xs">
      <Input
        label="Name"
        type="text"
        className="provider-form__field"
        value={controls.name.value}
        onChange={(e) => updateInputState('name', e.target.value)}
      />
    </div>
    <div className="wb-margin-bottom-xs">
      <TextArea
        label="Description"
        className="provider-form__field"
        value={controls.description.value}
        onChange={(e) => updateInputState('description', e.target.value)}
      />
    </div>
    <div className="wb-margin-bottom-xs">
      <CheckBox
        label="Add as cloud provider"
        className="provider-form__field"
        checked={controls.cloudProvider.value}
        onChange={() =>
          updateInputState('cloudProvider', !controls.cloudProvider.value)
        }
      />
    </div>
    <ModalFooter>
      <Button size="medium" variant="secondary" onClick={onCancel}>
        Close
      </Button>
      <Button
        size="medium"
        variant="primary"
        onClick={onSubmit}
        disabled={!controls.name.valid || !controls.description.valid}
      >
        {mode === 'edit' ? 'Update' : 'Save'}
      </Button>
    </ModalFooter>
  </ModalContent>
)

ProviderFormComponent.propTypes = {
  header: PropTypes.string,
  mode: PropTypes.string,
  controls: PropTypes.shape({
    name: PropTypes.shape({
      value: PropTypes.string,
      valid: PropTypes.bool,
      touched: PropTypes.bool,
    }),
    description: PropTypes.shape({
      value: PropTypes.string,
      valid: PropTypes.bool,
      touched: PropTypes.bool,
    }),
    cloudProvider: PropTypes.shape({
      value: PropTypes.bool,
      valid: PropTypes.bool,
      touched: PropTypes.bool,
    }),
  }),
  updateInputState: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
