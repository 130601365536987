import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getCurrentBookingServicePersmissions } from '../../../../../../../../../../data/actions'
import { MembersDialog } from '../MembersDialog'
import {
  selectCurrentBookingServicePermissions,
  selectBookingMembers,
  selectWaitingPermissions,
} from '../../../../../../../../../../data/selectors/tenantBookings'
import { BookingServicePermissionsComponent } from '../component'
import {
  getMembersWithPermissions,
  getPremissionFreeMembers,
  toggleMembersState,
  getMemberByDaimlerId,
  generateFullMembersListFromDaimlerIds,
} from './utils'

const BookingPermissionsContainer = ({
  booking,
  waitingPermissions,
  service,
  tenantMembers,
  currentServicePermissions,
  loggedInPoPmDaimlerId,
  getServicePermissions,
}) => {
  const [modal, setModal] = useState({})
  const [members, setMembers] = useState([])

  useEffect(() => {
    getServicePermissions(booking, service)
  }, [])

  const membersWithPermission = getMembersWithPermissions(tenantMembers)
  const premissionFreeMembers = getPremissionFreeMembers(tenantMembers)

  const membersWithWaitingMode = membersWithPermission.map((member) => ({
    ...member,
    waitingMode: waitingPermissions.includes(member.daimlerId),
  }))

  const handleAddMember = () =>
    setModal({
      mode: 'add',
      variant: 'members',
    })

  const handleEditSelected = () =>
    setModal({ mode: 'edit', variant: 'members' })

  const onSelectMember = (member) =>
    setMembers(toggleMembersState(member, members))
  return (
    <div className="booking-permissions">
      <BookingServicePermissionsComponent
        disabledAdd={Boolean(
          !premissionFreeMembers.length && currentServicePermissions.length,
        )}
        disabledEdit={Boolean(
          !members.length && currentServicePermissions.length,
        )}
        members={membersWithWaitingMode}
        booking={booking}
        service={service}
        currentServicePermissions={currentServicePermissions}
        loggedInPoPmDaimlerId={loggedInPoPmDaimlerId}
        actions={{
          handleAddMember,
          handleEditSelected,
          onSelectMember,
        }}
      />
      {modal.variant === 'members' && (
        <MembersDialog
          booking={booking}
          editMode={modal.mode === 'edit'}
          service={service}
          loggedInPoPmDaimlerId={loggedInPoPmDaimlerId}
          members={
            modal.mode === 'add'
              ? premissionFreeMembers
              : generateFullMembersListFromDaimlerIds(tenantMembers, members)
          }
          onClose={() => setModal({})}
        />
      )}
    </div>
  )
}

BookingPermissionsContainer.propTypes = {
  booking: PropTypes.shape({
    id: PropTypes.number,
  }),
  getServicePermissions: PropTypes.func,
  service: PropTypes.shape({
    slug: PropTypes.string,
  }),
  tenantMembers: PropTypes.arrayOf(
    PropTypes.shape({
      daimlerId: PropTypes.string,
      permission: PropTypes.object,
    }),
  ),
  currentServicePermissions: PropTypes.array,
  loggedInPoPmDaimlerId: PropTypes.string,
  waitingPermissions: PropTypes.arrayOf(PropTypes.string),
}

export const BookingServicePermissions = connect(
  (
    {
      tenantBookings: {
        bookingServicePermissions,
        tenantMembers,
        waitingPermissions,
      },
      sso,
    },
    ownProps,
  ) => {
    let loggedInPoPmDaimlerId = null
    const loggedInUserDaimlerId = sso.userId.toLowerCase()
    const loggedInUserMemberObject = getMemberByDaimlerId(
      loggedInPoPmDaimlerId,
      tenantMembers,
    )
    const userIsAdmin = sso.roles.includes('admin')
    const userIsPoPm =
      loggedInUserMemberObject?.roles.includes('PO') ||
      loggedInUserMemberObject?.roles.includes('PM')

    if (!userIsAdmin && loggedInUserMemberObject && userIsPoPm) {
      loggedInPoPmDaimlerId = loggedInUserDaimlerId
    }
    return {
      currentServicePermissions: selectCurrentBookingServicePermissions(
        ownProps.booking.id,
        ownProps.service.slug,
        bookingServicePermissions,
      ),
      tenantMembers: selectBookingMembers(
        tenantMembers,
        ownProps.booking.id,
        ownProps.service.slug,
        bookingServicePermissions,
      ),
      waitingPermissions: selectWaitingPermissions(
        waitingPermissions,
        ownProps.booking.id,
        ownProps.service.slug,
      ),
      loggedInPoPmDaimlerId,
    }
  },
  {
    getServicePermissions: getCurrentBookingServicePersmissions,
  },
)(BookingPermissionsContainer)
