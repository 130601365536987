import React from 'react'
import PropTypes from 'prop-types'

export const UnstiledButton = ({ className, title, onClick, children }) => (
  <button
    className={`unstiled-button ${className}`}
    title={title}
    onClick={onClick}
  >
    {children}
  </button>
)

UnstiledButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
