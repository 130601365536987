import React from 'react'
import PropTypes from 'prop-types'
import { TenantDashboardSection } from '../../../../common'

export const TenantControlling = ({
  info: {
    nebulaControllingInformation,
    gbsControllingInformation,
    b2xCoreControllingInformation,
    coNumber,
  },
}) => {
  const getControllingInformationValues = (controllingInformation) => [
    [
      [
        'Cost Center',
        controllingInformation?.costCenter || coNumber || 'not provided',
      ],
      [
        'Internal Order Number',
        controllingInformation?.costNumber || 'not provided',
      ],
    ],
    [
      ['PSP number', controllingInformation?.pspNumber || 'unknown'],
      [
        'Internal Service Agreement (ISA) number',
        controllingInformation?.isaNumber || 'unknown',
      ],
      ['Purchase Order', controllingInformation?.purchaseOrder || 'unknown'],
    ],
  ]

  return (
    <>
      <TenantDashboardSection
        values={getControllingInformationValues(nebulaControllingInformation)}
        title="Controlling Nebula"
      >
        {!(
          nebulaControllingInformation?.costCenter &&
          nebulaControllingInformation?.costNumber
        ) && (
          <div
            className="tenant-body__block-label"
            style={{ color: 'red', textAlign: 'left' }}
          >
            It is required to specify both the Cost Center and the Internal
            Order Number now. Please provide the information by editing this
            tenant. It won't be possible to apply other modifications e.g.
            adding or removing members before
          </div>
        )}
      </TenantDashboardSection>
      {gbsControllingInformation ? (
        <TenantDashboardSection
          values={getControllingInformationValues(gbsControllingInformation)}
          title="Controlling GBS"
        >
          {!(
            gbsControllingInformation?.costCenter &&
            gbsControllingInformation?.costNumber
          ) && (
            <div
              className="tenant-body__block-label"
              style={{ color: 'red', textAlign: 'left' }}
            >
              It is required to specify both the Cost Center and the Internal
              Order Number now. Please provide the information by editing this
              tenant. It won't be possible to apply other modifications e.g.
              adding or removing members before
            </div>
          )}
        </TenantDashboardSection>
      ) : null}
      {b2xCoreControllingInformation ? (
        <TenantDashboardSection
          values={getControllingInformationValues(
            b2xCoreControllingInformation,
          )}
          title="Controlling B2XCore"
        >
          {!(
            b2xCoreControllingInformation?.costCenter &&
            b2xCoreControllingInformation?.costNumber
          ) && (
            <div
              className="tenant-body__block-label"
              style={{ color: 'red', textAlign: 'left' }}
            >
              It is required to specify both the Cost Center and the Internal
              Order Number now. Please provide the information by editing this
              tenant. It won't be possible to apply other modifications e.g.
              adding or removing members before
            </div>
          )}
        </TenantDashboardSection>
      ) : null}
    </>
  )
}

TenantControlling.propTypes = {
  info: PropTypes.shape({
    nebulaControllingInformation: PropTypes.oneOfType([
      PropTypes.shape({
        costCenter: PropTypes.string,
        costNumber: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    gbsControllingInformation: PropTypes.oneOfType([
      PropTypes.shape({
        costCenter: PropTypes.string,
        costNumber: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    b2xCoreControllingInformation: PropTypes.oneOfType([
      PropTypes.shape({
        costCenter: PropTypes.string,
        costNumber: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    coNumber: PropTypes.string,
  }),
}
