import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { PackagesComponent } from '../component'
import { getPackages, getServices } from '../../../../../data/actions'

const PackagesContainer = ({ getPlatformPackages, getPlatformServices }) => {
  useEffect(() => {
    getPlatformPackages()
    getPlatformServices()
  }, [])
  return <PackagesComponent />
}

PackagesContainer.propTypes = {
  getPlatformPackages: PropTypes.func,
  getPlatformServices: PropTypes.func,
}

export const Packages = connect(undefined, {
  getPlatformPackages: getPackages,
  getPlatformServices: getServices,
})(PackagesContainer)
