import { cacheControl } from './helpers'

export const getNavigation = async (sectionFolder) => {
  const res = await fetch(
    `/assets/docs/${sectionFolder}/index.json?ttl=${cacheControl()}`,
  )
  return res.json()
}

export const getContent = async (pageData) => {
  const res = await fetch(pageData.file + `?ttl=${cacheControl()}`)
  return res.text()
}
