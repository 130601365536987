import React from 'react'
import { PriceVariantsList } from '../components/PriceVariantsList'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const PriceVariantsContainer = ({ activePackage }) => (
  <div className="wb-margin-bottom-xs">
    <h2>Price Variants:</h2>
    <PriceVariantsList
      activePackage={activePackage}
      readOnly={!activePackage.isEditable}
    />
  </div>
)

const PriceVariants = connect(({ backOffice: { package: activePackage } }) => ({
  activePackage,
}))(PriceVariantsContainer)

PriceVariantsContainer.propTypes = {
  activePackage: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    isEditable: PropTypes.bool,
  }),
}

export { PriceVariants }
