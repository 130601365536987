import React from 'react'
import PropTypes from 'prop-types'
import { FilterItems } from '../FilterItems'
import { Button, Popover, PopoverHost } from '../../../../../common'

export const FilterPopover = ({ filters, filterCategories, setFilter }) => (
  <PopoverHost position="left">
    <Button
      variant="tertiary"
      size="small"
      iconOnly
      className="wb-margin-bottom-xxs wb-margin-top-xs"
      withIcon="bds/filter/24"
    />
    <Popover>
      <FilterItems
        filters={filters}
        filterCategories={filterCategories}
        onChange={setFilter}
      />
    </Popover>
  </PopoverHost>
)

FilterPopover.propTypes = {
  filters: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      tenants: PropTypes.array,
    }),
  ),
  filterCategories: PropTypes.arrayOf(PropTypes.string),
  setFilter: PropTypes.func,
}
