import React from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  CheckBox,
  Button,
  ModalContent,
  ModalFooter,
} from '../../../../../../../common'

export const PermissionsFormComponent = ({
  header,
  formErrors,
  permission,
  handleSubmit,
  handleClose,
  updateInputState,
}) => (
  <ModalContent headline={header}>
    <div className="bookings-editor__form-field wb-margin-top-xs">
      <Input
        label="Permission name"
        value={permission.name}
        error={formErrors.name}
        onChange={(e) => updateInputState('name', e.target.value)}
      />
    </div>
    <div className="bookings-editor__form-field">
      <Input
        label="Permission description"
        value={permission.description}
        onChange={(e) => updateInputState('description', e.target.value)}
      />
    </div>
    <div className="bookings-editor__form-field">
      <CheckBox
        label="Restricted to Provider Members"
        className="wb-margin-top-xs"
        checked={permission.providerOnly}
        onChange={() =>
          updateInputState('providerOnly', !permission.providerOnly)
        }
      />
    </div>
    <ModalFooter>
      <Button variant="secondary" size="medium" onClick={handleClose}>
        Cancel
      </Button>
      <Button variant="primary" size="medium" onClick={handleSubmit}>
        Save
      </Button>
    </ModalFooter>
  </ModalContent>
)

PermissionsFormComponent.propTypes = {
  header: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  updateInputState: PropTypes.func,
  formErrors: PropTypes.shape({
    name: PropTypes.string,
  }),
  permission: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    providerOnly: PropTypes.bool,
  }),
}
