import React, { useState } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table'
import PropTypes from 'prop-types'
import { UsersListComponent } from '../UsersListComponent'
import { generateColumns } from './tableConfig'

export const generateModalHeader = (users) =>
  `Are you sure you want to remove users from the provider - ${users
    .map(({ user }) => user.daimlerId)
    .join(', ')} ?`

export const UsersTable = ({
  data,
  provider,
  dispatchModal,
  removeProviderUsers,
}) => {
  const [rowSelection, setRowSelection] = useState({})
  const [globalFilter, setGlobalFilter] = useState('')
  const selectedUsers = Object.keys(rowSelection).map((index) => data[index])
  const onClickDelete = ({ row }, currentProvider) =>
    dispatchModal({
      variant: 'prompt',
      onApprove: () =>
        removeProviderUsers(currentProvider.slug, [
          row.original.user.daimlerId,
        ]),
      header: `Are you sure you want to remove the user - ${row.original.user.daimlerId.toUpperCase()}?`,
      okText: 'Remove',
      onCancel: () => dispatchModal({}),
    })

  const batchRemoveMembers = () =>
    dispatchModal({
      variant: 'prompt',
      onApprove: () => {
        removeProviderUsers(
          provider.slug,
          selectedUsers.map(({ user }) => user.daimlerId),
        )
        setRowSelection({})
      },
      header: generateModalHeader(selectedUsers),
      okText: 'Remove users',
      onCancel: () => dispatchModal({}),
    })

  const addNewMembers = () =>
    dispatchModal({
      variant: 'providerUsers',
      header: 'Add users to provider',
      onCancel: () => {
        dispatchModal({})
      },
    })

  const columns = provider ? generateColumns(onClickDelete, provider) : []

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      globalFilter,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
  })

  const deselectAllUsers = () => {
    setRowSelection({})
  }

  return (
    <UsersListComponent
      searchTerm={globalFilter ?? ''}
      showBatchActions={Boolean(selectedUsers.length)}
      isEditable={provider?.isManagable}
      table={table}
      tableActions={{
        deselectAllUsers,
        batchRemoveMembers,
        addNewMembers,
        onChangeSearch: (e) => setGlobalFilter(e.target.value),
      }}
    />
  )
}

UsersTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      daimlerId: PropTypes.string,
      id: PropTypes.number,
      role: PropTypes.string,
    }),
  ),
  provider: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
    isEditable: PropTypes.bool,
    isManagable: PropTypes.bool,
  }),
  dispatchModal: PropTypes.func,
  removeProviderUsers: PropTypes.func,
}
