import React from 'react'
import PropTypes from 'prop-types'
import {
  Select,
  Button,
  ModalContent,
  ModalFooter,
} from '../../../../../../../common'

export const ProviderDialogFormComponent = ({
  disabled,
  activeProvider,
  providers,
  setActiveProvider,
  onSubmit,
  onCancel,
}) => (
  <ModalContent headline="Change tenant provider">
    <div className="wb-padding-xs">
      <Select
        label="Provider selection"
        placeHolder="Please choose provider"
        options={providers}
        value={activeProvider}
        onChange={(e) => setActiveProvider(e.target.value)}
      />
    </div>
    <ModalFooter>
      <Button variant="secondary" size="large" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        disabled={disabled}
        variant="primary"
        size="large"
        onClick={onSubmit}
      >
        Save
      </Button>
    </ModalFooter>
  </ModalContent>
)

ProviderDialogFormComponent.propTypes = {
  disabled: PropTypes.bool,
  activeProvider: PropTypes.string.isRequired,
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  setActiveProvider: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}
