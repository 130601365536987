import React from 'react'
import PropTypes from 'prop-types'

export const AccordionContent = ({ children }) => (
  <wb-accordion-content>{children}</wb-accordion-content>
)

AccordionContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
