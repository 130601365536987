import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ServicesFormComponent } from '../component'
import validate from '../../../../../../../../util/helpers/validate'

const serviceProvidersSelector = (serviceProviders, tdmsRoles, providerRoles) =>
  tdmsRoles.includes('admin')
    ? serviceProviders
    : serviceProviders.filter(({ slug }) => providerRoles[slug])

const ServicesFormContainer = ({
  mode,
  service,
  providers,
  onApprove,
  onCancel,
}) => {
  const baseService = {
    name: mode === 'edit' ? service.name : '',
    description: mode === 'edit' ? service.description : '',
    providerSlug: mode === 'edit' ? service.providerSlug : '',
  }

  const controls = {
    name: {
      value: baseService.name,
      valid: validate(baseService.name, { minLength: 1 }),
      touched: Boolean(service),
      validationRules: {
        minLength: 1,
      },
    },
    description: {
      value: baseService.description,
      valid: true,
      touched: Boolean(service),
      validationRules: {},
    },
    providerSlug: {
      value: baseService.providerSlug,
      valid: validate(baseService.name, { minLength: 1 }),
      touched: Boolean(service),
      validationRules: { minLength: 1 },
    },
  }

  const [state, setState] = useState(controls)

  const updateInputState = (control, value) => {
    setState({
      ...state,
      [control]: {
        ...state[control],
        value,
        touched: true,
        valid: validate(value, controls[control].validationRules),
      },
    })
  }

  const header = mode === 'add' ? 'Add service' : 'Edit service'

  const onSubmit = () => {
    onApprove(
      {
        name: state.name.value,
        description: state.description.value,
        providerSlug: state.providerSlug.value,
      },
      service?.id,
    )
  }
  return (
    <ServicesFormComponent
      header={header}
      providerOptions={providers.map(({ name, slug }) => ({ name, id: slug }))}
      controls={state}
      disableProviderInput={mode === 'edit'}
      actions={{
        onCancel,
        updateInputState: updateInputState,
        onSubmit,
      }}
    />
  )
}

ServicesFormContainer.propTypes = {
  mode: PropTypes.string,
  service: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    providerSlug: PropTypes.string,
    description: PropTypes.string,
  }),
  providers: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  onApprove: PropTypes.func,
  onCancel: PropTypes.func,
}

export const ServicesForm = connect(
  ({ backOffice: { service, providers }, sso: { roles, providerRoles } }) => ({
    service,
    providers: serviceProvidersSelector(
      providers.filter(({ deleted_at }) => !deleted_at),
      roles,
      providerRoles,
    ),
  }),
)(ServicesFormContainer)
