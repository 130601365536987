import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import validate from '../../../../../../../util/helpers/validate'
import {
  generateStateFromWebHook,
  generateInitialGithubProtocolState,
  generateInitialHttpProtocolState,
  generateGithubProperties,
  generateHttpProperties,
  generateWebHookPayload,
  touchState,
  checkStateValidity,
  generateCurrentWebHook,
} from './stateHelpers'
import { getWebHook, setWebHook } from '../../../../../../../data/actions'
import { WebHooksFormComponent } from '../component'

export const WebHooksFormContainer = ({
  webHookId,
  currentWebHook,
  fetchWebHook,
  setCurrentWebHook,
  onCancel,
  onApprove,
}) => {
  const [githubProtocolState, setGithubProtocolState] = useState(
    generateInitialGithubProtocolState(currentWebHook),
  )
  const [httpProtocolState, setHttpProtocolState] = useState(
    generateInitialHttpProtocolState(currentWebHook),
  )
  const [state, setState] = useState(generateStateFromWebHook(currentWebHook))

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (currentWebHook) {
      setGithubProtocolState(generateInitialGithubProtocolState(currentWebHook))
      setHttpProtocolState(generateInitialHttpProtocolState(currentWebHook))
      setState(generateStateFromWebHook(currentWebHook))
    }
  }, [currentWebHook])

  useEffect(() => {
    webHookId && fetchWebHook(webHookId)
  }, [webHookId])

  useEffect(() => {
    return () => setCurrentWebHook(null)
  }, [])

  const updateInputState = (control, value) => {
    setState({
      ...state,
      [control]: {
        ...state[control],
        value,
        touched: true,
        valid: validate(value, state[control].validationRules),
      },
    })
  }

  const ProtocolSubFormState = {
    github: {
      state: githubProtocolState,
      setState: setGithubProtocolState,
    },
    http: {
      state: httpProtocolState,
      setState: setHttpProtocolState,
    },
  }

  const saveWebHook = () => {
    const mainState = touchState(state)
    const isDirtyMainState = checkStateValidity(mainState)

    const protocolState = ProtocolSubFormState[mainState.protocol.value]

    const newProtocolState = touchState(protocolState.state)
    const isDirtyProtocolState = checkStateValidity(newProtocolState)

    if (isDirtyMainState || isDirtyProtocolState) {
      isDirtyMainState && setState(mainState)
      isDirtyProtocolState && protocolState.setState(newProtocolState)
      return
    }

    const protocolSpecs = newProtocolState.auth
      ? generateHttpProperties(newProtocolState)
      : generateGithubProperties(newProtocolState)

    const webHookObject = generateWebHookPayload(mainState, protocolSpecs)

    if (webHookId) {
      webHookObject.id = webHookId
    }
    setLoading(true)
    onApprove(webHookObject, () => {
      setLoading(false)
      onCancel()
    })
  }

  const onChangeProducer = (val) => {
    val
      ? updateInputState('producer', val)
      : setState({
          ...state,
          ['producer']: {
            value: '',
            touched: true,
            valid: false,
          },
          ['providers']: {
            value: [],
            touched: true,
            valid: false,
          },
          ['events']: {
            value: [],
            touched: true,
            valid: false,
          },
        })
  }

  const action = webHookId ? 'Update' : 'Add a new'

  return (
    <WebHooksFormComponent
      action={action}
      loading={loading}
      controls={state}
      ProtocolSubFormControls={ProtocolSubFormState}
      actions={{
        updateInputState,
        onChangeProducer,
        saveWebHook,
        onCancel,
      }}
    />
  )
}

WebHooksFormContainer.propTypes = {
  webHookId: PropTypes.string,
  currentWebHook: PropTypes.object,
  fetchWebHook: PropTypes.func,
  setCurrentWebHook: PropTypes.func,
  onCancel: PropTypes.func,
  onApprove: PropTypes.func,
}

export const WebHooksForm = connect(
  ({ hermes: { currentWebHook, providers }, tenants: { allTenants } }) => ({
    currentWebHook: generateCurrentWebHook(
      currentWebHook,
      providers,
      allTenants,
    ),
  }),
  {
    fetchWebHook: getWebHook,
    setCurrentWebHook: setWebHook,
  },
)(WebHooksFormContainer)
