import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  setModal,
  getServiceProperties,
  getServiceProperty,
  deleteServiceProperty,
} from '../../../../../../../data/actions'
import { PropertiesListComponent } from '../component'

const PropertiesListContainer = ({
  service,
  serviceProperties,
  dispatchModal,
  getProperties,
  deleteProperty,
  getProperty,
}) => {
  useEffect(() => {
    getProperties(service.id)
  }, [service])
  const onAddProperty = () => {
    dispatchModal({
      variant: 'property',
      mode: 'add',
      onCancel: () => dispatchModal({}),
    })
  }
  const onRemoveProperty = (propertyId) => {
    dispatchModal({
      variant: 'prompt',
      onApprove: () => deleteProperty(propertyId, () => dispatchModal({})),
      header: 'Are you sure you want to delete?',
      okText: 'Remove',
      onCancel: () => dispatchModal({}),
    })
  }
  const onEditProperty = (property) => {
    getProperty(property.id, () => {
      dispatchModal({
        variant: 'property',
        mode: 'edit',
        onCancel: () => dispatchModal({}),
      })
    })
  }
  return (
    <PropertiesListComponent
      onAddProperty={onAddProperty}
      readOnly={!service.isEditable}
      serviceProperties={serviceProperties}
      onRemoveProperty={onRemoveProperty}
      onEditProperty={onEditProperty}
    />
  )
}

PropertiesListContainer.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    isEditable: PropTypes.bool,
  }),
  serviceProperties: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number }),
  ),
  dispatchModal: PropTypes.func,
  getProperties: PropTypes.func,
  deleteProperty: PropTypes.func,
  getProperty: PropTypes.func,
}

export const PropertiesList = connect(
  ({ backOffice: { service, serviceProperties } }) => ({
    service,
    serviceProperties,
  }),
  {
    dispatchModal: setModal,
    getProperties: getServiceProperties,
    deleteProperty: deleteServiceProperty,
    getProperty: getServiceProperty,
  },
)(PropertiesListContainer)
