import React from 'react'
import PropTypes from 'prop-types'
import { Button, Input } from '../../../../../common'

export const MyProfile = ({
  properties,
  formData,
  loading,
  handleUserUpdate,
  handleSubmit,
  handleValueChange,
}) => (
  <>
    <p className="member-form__explanation">* Required Information</p>
    {properties.map(({ description, title, key }) => (
      <fieldset key={key} className="tenants-edit-form__field">
        <Input
          type="text"
          hint={description}
          label={title}
          value={formData[key]}
          onChange={(e) => handleValueChange(e.target.value, key)}
          disabled={['daimlerId', 'firstName', 'lastName', 'email'].includes(
            key,
          )}
        />
      </fieldset>
    ))}
    <div className="tenants-edit-form__main-button">
      <Button
        disabled={loading}
        variant="secondary"
        size="small"
        onClick={handleUserUpdate}
      >
        Update my profile from Alice
      </Button>
      <Button
        disabled={loading}
        variant="primary"
        size="small"
        className="wb-margin-start-xs"
        onClick={handleSubmit}
      >
        Save
      </Button>
    </div>
  </>
)

MyProfile.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string })),
  formData: PropTypes.object,
  loading: PropTypes.bool,
  handleValueChange: PropTypes.func,
  handleUserUpdate: PropTypes.func,
  handleSubmit: PropTypes.func,
}
