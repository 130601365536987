import React from 'react'
import PropTypes from 'prop-types'
import { WbAccordion } from '../../../common'

export const TenantList = ({ title, list = [] }) => (
  <WbAccordion title={title}>
    <div className="tenant-body__table">
      <table
        width="100%"
        className="wb-table wb-table--striped wb-table--border-vertical wb-table--border-horizontal wb-type-copy-secondary"
      >
        <thead>
          <tr>
            <th className="wb-grid-col-mq1-2">Tenant</th>
            <th className="wb-grid-col-mq1-5">Product Owner</th>
            <th className="wb-grid-col-mq1-5">Product Manager</th>
          </tr>
        </thead>
        <tbody>
          {list.map(({ acronym, po, pm }) => (
            <tr key={acronym}>
              <td className="wb-grid-col-mq1-2">
                <a target="_blank" href={`/#/apps/${acronym}`}>
                  {acronym.toUpperCase()}
                </a>
              </td>
              <td className="wb-grid-col-mq1-5">{po}</td>
              <td className="wb-grid-col-mq1-5">{pm}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </WbAccordion>
)

TenantList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      acronym: PropTypes.string,
      po: PropTypes.string,
      pm: PropTypes.string,
    }),
  ),
}
