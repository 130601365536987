import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../../../../../common'

export const ProviderDetailsComponent = ({ provider, onClickEdit }) => (
  <div className="provider-details wb-card wb-card--white wb-type-copy wb-padding-s">
    <div className="provider-details__head">
      <h2>Provider Details</h2>
      <Button
        variant="secondary"
        size="small"
        disabled={!provider.isEditable}
        onClick={onClickEdit}
        withIcon="bds/pencil/24"
      >
        Edit
      </Button>
    </div>
    <hr />
    <dl>
      <dt>Name: </dt>
      <dd>{provider.name}</dd>
      <dt>Slug: </dt>
      <dd>{provider.slug}</dd>
      <dt>Description: </dt>
      <dd>{provider.description}</dd>
    </dl>
  </div>
)

ProviderDetailsComponent.propTypes = {
  provider: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.string,
    isEditable: PropTypes.bool,
  }),
  onClickEdit: PropTypes.func,
}
