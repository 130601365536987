import React from 'react'
import PropTypes from 'prop-types'
import { Tag, TooltipHost } from '../../../../../../../../common'

export const TaggedLabelsList = ({ labelsToDisplay, tags }) => {
  const visibleLabels = tags
    .slice(0, labelsToDisplay)
    .map(({ label }) => <Tag key={label} label={label} variant="tertiary" />)

  const toolTipLabels = tags
    .slice(labelsToDisplay, tags.length)
    .map(({ label }) => (
      <Tag
        key={label}
        label={label}
        variant="tertiary"
        className="wb-margin-bottom-xxs"
      />
    ))

  return (
    <div className="tenant-members__tagged_labels">
      {visibleLabels}
      {toolTipLabels?.length ? (
        <TooltipHost position="bottom" message={toolTipLabels}>
          <span className="wb-button--tertiary wb-margin-start-xxs">{`+${
            tags.length - labelsToDisplay
          } Labels`}</span>
        </TooltipHost>
      ) : null}
    </div>
  )
}

TaggedLabelsList.propTypes = {
  labelsToDisplay: PropTypes.number,
  tags: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
}
