export const getServiceName = (service, costsManagement) => {
  return costsManagement?.servicePackages?.[service]?.serviceName
}

export const getTenantTypesFromBookings = (
  tenantAcronym,
  costsManagement,
  includedServices,
) => {
  const bookings = costsManagement.bookings

  if (
    tenantAcronym &&
    bookings &&
    Object.keys(bookings).length &&
    bookings[tenantAcronym]
  ) {
    const nebulaServices = bookings[tenantAcronym].filter((item) =>
      includedServices.includes(item.service),
    )

    return [
      ...new Set(
        nebulaServices.map((item) => {
          return item.service
        }),
      ),
    ]
  }

  return []
}
