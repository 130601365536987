const EMAIL_REGEX = /\S+@\S+\.\S+/
const URL_REGEX =
  /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/

const validate = (val, rules, baseState) => {
  let isValid = true
  for (const rule in rules) {
    isValid = isValid && validationObject[rule]?.(val, rules[rule], baseState)
  }
  return isValid
}

export const emailValidator = (val) => EMAIL_REGEX.test(val)

export const orAllValidator = (val) => Array.isArray(val) || val === '*'

export const urlValidator = (val) => URL_REGEX.test(val)
export const isUrlOrEmptyValidator = (val) => val === '' || urlValidator(val)
export const requireIfValidator = (value, validationRules, baseState) => {
  const conditions = Object.keys(validationRules.condition)
  let shouldCheck = false
  conditions.forEach((val) => {
    if (baseState[val].value === validationRules?.condition?.[val]) {
      shouldCheck = true
    }
  })
  return shouldCheck ? validate(value, validationRules.rule) : true
}

export const equalToValidator = (val, matchedVal) => val === matchedVal

export const minLengthValidator = (val, minLength) => val.length >= minLength

export const maxLengthValidator = (val, maxLength) => val.length <= maxLength

export const notInValidator = (val, arr) => !arr.includes(val)

export const numericValidator = (val) => !isNaN(val) && !isNaN(parseFloat(val))
export const numericOrEmptyValidator = (val) =>
  val === '' || numericValidator(val)

export const yearValidator = (val) => numericValidator(val) && val.length === 4

export const onOfValidator = (val, arr) => arr.includes(val)

const validationObject = {
  isEmail: emailValidator,
  isValidUrl: urlValidator,
  isUrlOrEmpty: isUrlOrEmptyValidator,
  isYear: yearValidator,
  isNumeric: numericValidator,
  isNumericOrEmpty: numericOrEmptyValidator,
  notIn: notInValidator,
  minLength: minLengthValidator,
  maxLength: maxLengthValidator,
  equalTo: equalToValidator,
  orAll: orAllValidator,
  requireIf: requireIfValidator,
  oneOf: onOfValidator,
}

export default validate
