import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TenantBookingsDialog } from '../tenantBookingsDialog'
import { TenantMembersDialog } from '../tenantMembersDialog/TenantMembersDialog'
import { TenantLogsDialog } from '../tenantLogsDialog'
import { TenantComplianceDialog } from '../tenantComplianceDialog'
import { ChangeProviderDialog } from '../ChangeProviderDialog'
import { InvalidateAkamaiCacheDialog } from '../InvalidateAkamaiCacheDialog'

const openModalClass = 'modal-open'

export const ModalDialogs = ({ tenantInfo, allTenants, match, onClose }) => {
  const { dialog } = match.params
  const [activeDialog, setActiveDialog] = useState(null)

  useEffect(() => {
    if ((dialog || null) !== activeDialog) {
      setActiveDialog(dialog)
    }
  }, [dialog])

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    if (activeDialog) {
      body.classList.add(openModalClass)
    } else {
      body.classList.remove(openModalClass)
    }
    return () => body.classList.remove(openModalClass)
  }, [activeDialog])

  const componentProps = {
    members: { tenant: tenantInfo, onClose },
    logs: { tenant: tenantInfo, tenants: Object.keys(allTenants), onClose },
    bookings: { onClose },
    compliance: { onClose },
    akamaiCacheInvalidation: { onClose },
    changeProvider: { onClose },
  }
  const dialogMapper = {
    members: TenantMembersDialog,
    logs: TenantLogsDialog,
    bookings: TenantBookingsDialog,
    compliance: TenantComplianceDialog,
    akamaiCacheInvalidation: InvalidateAkamaiCacheDialog,
    changeProvider: ChangeProviderDialog,
  }
  const Component = dialogMapper?.[activeDialog]
  const dialogProps = componentProps?.[activeDialog] || {}
  return Component ? <Component {...dialogProps} /> : null
}

ModalDialogs.propTypes = {
  tenantInfo: PropTypes.object,
  allTenants: PropTypes.objectOf(PropTypes.object),
  match: PropTypes.object,
  onClose: PropTypes.func,
}
