import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavigationItem } from '../../../../../../../common'

export const ServicesListItem = ({ service, isActive, onClick }) => (
  <NavigationItem onClick={onClick}>
    <li
      className={classnames('packages-editor__list-item', {
        'packages-editor__list-item--active': isActive,
        'packages-editor__list-item--archived': !service.isEditable,
      })}
    >
      <div>{service.name}</div>
      <div className="packages-editor__list-item-description">
        {service.slug}
      </div>
      <div className="packages-editor__list-item-description">
        {service.description}
      </div>
    </li>
  </NavigationItem>
)
ServicesListItem.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
    provider: PropTypes.string,
    description: PropTypes.string,
    isEditable: PropTypes.bool,
  }),
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}
