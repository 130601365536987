import React, { useEffect, Suspense } from 'react'
import { Provider } from 'react-redux'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'
import configureStore from './data/configureStore'
import Oidc from './components/auth/oidc'
import { Footer } from './components/footer'
import { Header } from './components/header'
import { routes } from './routes'
import { Modal } from './components/backOffice/components/Modal'
import { Notifications, LoadingIndicator } from './components/common'

import './styles/app.scss'

const ErrorPage = React.lazy(() => import('./components/pages/ErrorPage'))

export const store = configureStore()

const App = () => {
  useEffect(() => {
    window._TDMS_BE_VERSION = false
    const { DATADOG } = process.env
    datadogRum.setGlobalContextProperty('tenant', 'tdms')
    datadogRum.setGlobalContextProperty('platform', 'nebula')
    datadogRum.init(DATADOG)
  }, [])

  return (
    <Provider store={store}>
      <HashRouter>
        <Suspense fallback={<LoadingIndicator />}>
          <Switch>
            <Route
              path="/403"
              render={(params) => <ErrorPage {...params} />}
              exact={true}
            />
            <Oidc>
              <Header />
              {routes.map(({ path, component: Component, exact, key }) => (
                <Route
                  key={key}
                  path={path}
                  render={(params) => <Component {...params} />}
                  exact={exact}
                />
              ))}
              <Modal />
              <Notifications />
              <Footer />
            </Oidc>
          </Switch>
        </Suspense>
      </HashRouter>
    </Provider>
  )
}

export default App
