import React from 'react'
import PropTypes from 'prop-types'
import { Bookings } from '../components/Bookings'
import { Packages } from '../components/Packages'
import { Services } from '../components/Services'
import { Permissions } from '../components/Permissions'
import { WebHooks } from '../components/WebHooks'
import { Providers } from '../components/Providers'
import { TenantMemberActions } from '../../apps/tenant/components/TenantMemberActions'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const NoPermissionsMessage = () => (
  <div>You do not have permissions to view this page</div>
)

export const BackOfficeComponent = ({
  selectedTab,
  isProviderUser,
  isAdmin,
  onTabChange,
}) => {
  const tabMapper = {
    bookings: isAdmin || isProviderUser ? Bookings : NoPermissionsMessage,
    packages: Packages,
    services: Services,
    webHooks: isAdmin ? WebHooks : NoPermissionsMessage,
    providers: Providers,
    logs: isAdmin ? TenantMemberActions : NoPermissionsMessage,
    permissions: isAdmin ? Permissions : NoPermissionsMessage,
  }
  const Component = tabMapper[selectedTab] || null
  const tabsList = Object.keys(tabMapper).filter((key) => tabMapper[key])

  return (
    <div className="body">
      <div className="content">
        <div className="page-header">
          <h2 className="page-header__title">Backoffice</h2>
        </div>
        <div className="page-body bookings-editor__content">
          <wb-tabs selected={selectedTab.toUpperCase()}>
            <wb-tab-bar>
              {tabsList.map((currentTab) => (
                <wb-tab
                  key={currentTab}
                  name={currentTab.toUpperCase()}
                  onClick={() => onTabChange(currentTab)}
                >
                  {capitalizeFirstLetter(currentTab)}
                </wb-tab>
              ))}
            </wb-tab-bar>
            <div className="wb-padding-xs">{Component && <Component />}</div>
          </wb-tabs>
        </div>
      </div>
    </div>
  )
}

BackOfficeComponent.propTypes = {
  selectedTab: PropTypes.string,
  isAdmin: PropTypes.bool,
  isProviderUser: PropTypes.bool,
  onTabChange: PropTypes.func,
}
