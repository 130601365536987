import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  ModalContent,
  ModalFooter,
  Select,
  TextArea,
  Datepicker,
} from '../../../../../../common'
import { PackageServices } from '../../PackageServices'
import { PriceVariantsSelector } from '../../PriceVariantsSelector'

export const BookingFormComponent = ({
  header,
  packages,
  mode,
  controls,
  updateInputState,
  onCancel,
  onSubmit,
}) => {
  const targetPackage = packages.find(
    (pkg) => pkg.slug === controls.packageSlug.value,
  )
  const showError = (control) =>
    controls[control].touched &&
    !controls[control].valid &&
    controls[control].error

  const checkFormValidity = () =>
    !Object.keys(controls).some((control) => !controls[control].valid)

  return (
    <ModalContent headline={header}>
      <div className="bookings-editor__form-field bookings-editor__form-field--sm">
        <Select
          label="Booking package"
          placeholder="Select package"
          disabled={controls.packageSlug.disabled}
          value={controls.packageSlug.value}
          options={packages.map(({ name, slug }) => ({ name, id: slug }))}
          error={showError('packageSlug')}
          onChange={(e) => updateInputState('packageSlug', e.target.value)}
        />
      </div>
      <PackageServices packageId={targetPackage?.id} />
      <div className="bookings-editor__form-field bookings-editor__form-field--sm">
        <PriceVariantsSelector
          packageSlug={controls.packageSlug.value}
          control={controls.priceVariant}
          onChange={(val) => updateInputState('priceVariant', val)}
        />
      </div>
      <div className="bookings-editor__form-field bookings-editor__form-field--sm">
        <TextArea
          label="Comment"
          onChange={(e) => updateInputState('comment', e.target.value)}
          placeholder="Comment"
          value={controls.comment.value}
          error={showError('comment')}
        />
      </div>
      <div className="bookings-editor__form-field">
        <Datepicker
          label="Start date"
          value={controls.startDate.value}
          onChange={(e) => updateInputState('startDate', e.target.value)}
          error={showError('startDate')}
        />
      </div>
      <div className="bookings-editor__form-field">
        <Datepicker
          label="End date"
          value={controls.endDate.value}
          onChange={(e) => updateInputState('endDate', e.target.value)}
        />
      </div>
      <ModalFooter className="booking-form__actions">
        <Button
          variant="secondary"
          size="medium"
          className="wb-margin-end-xs"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          size="medium"
          onClick={onSubmit}
          disabled={!checkFormValidity()}
        >
          {`${mode === 'edit' ? 'Update' : 'Add'} booking`}
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}

const packagePropType = PropTypes.shape({
  id: PropTypes.number,
  slug: PropTypes.string,
  name: PropTypes.string,
})

const controlPropType = PropTypes.shape({
  value: PropTypes.string,
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  valid: PropTypes.bool,
})

BookingFormComponent.propTypes = {
  header: PropTypes.string,
  packages: PropTypes.arrayOf(packagePropType),
  mode: PropTypes.string,
  controls: PropTypes.shape({
    packageSlug: controlPropType,
    priceVariant: controlPropType,
    comment: controlPropType,
    startDate: controlPropType,
    endDate: controlPropType,
  }),
  updateInputState: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
