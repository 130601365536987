import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  WbAccordion,
  Accordion,
  Input,
  DatepickerControl,
} from '../../../../common'
import { ServicesReportList } from '../ServicesReportList'

export const TimelineReport = ({ bookingsFromDatabase }) => {
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  return (
    <Accordion>
      <WbAccordion title="Timeline Tracker">
        <p className="wb-margin-vertical-xs">
          Please provide start and end Date for create your service timeline
          report
        </p>
        <wb-grid full-width>
          <wb-grid-row>
            <wb-grid-column mq1="6">
              <DatepickerControl className="timeline-tracker">
                <Input
                  label="Start Date:"
                  type="date"
                  min="2020-01-01"
                  value={startDate}
                  onMouseDown={(e) => e.preventDefault()}
                  onKeyPress={(e) => e.preventDefault()}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <Input
                  label="End Date:"
                  type="date"
                  min="2020-01-01"
                  onMouseDown={(e) => e.preventDefault()}
                  onKeyPress={(e) => e.preventDefault()}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </DatepickerControl>
            </wb-grid-column>
          </wb-grid-row>
        </wb-grid>
        <ServicesReportList
          startDate={startDate}
          endDate={endDate}
          bookingsFromDatabase={bookingsFromDatabase}
        />
      </WbAccordion>
    </Accordion>
  )
}

TimelineReport.propTypes = {
  bookingsFromDatabase: PropTypes.array,
}
