import React from 'react'
import PropTypes from 'prop-types'
import { CCOTable } from '../CCOTable'
import { ExportButton } from '../ExportButton'
import { ItemHeader } from './ItemHeader'
import { WbAccordion as Accordion } from '../../../../../common'

export const ReportByProvider = ({ packages, provider, total }) => (
  <Accordion title={<ItemHeader provider={provider} total={total} />}>
    <div className="cross-charging__provider">
      <div className="cross-charging__provider-header">
        <div>Cross Charging Overview for {provider}</div>
        <div>
          <ExportButton title={`Export for ${provider}`} providers={provider} />
        </div>
      </div>
      <CCOTable packages={packages} />
    </div>
  </Accordion>
)

ReportByProvider.propTypes = {
  provider: PropTypes.string,
  total: PropTypes.number,
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      priceVariantName: PropTypes.string,
      price: PropTypes.number,
      services: PropTypes.arrayOf(
        PropTypes.shape({
          serviceName: PropTypes.string,
          provider: PropTypes.string,
        }),
      ),
    }),
  ),
}
