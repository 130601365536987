import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AsyncPaginate } from 'react-select-async-paginate'
import { components } from 'react-select'
import { findUsers } from '../../../http'

const MultiValueLabel = (props) => (
  <components.MultiValueLabel {...props}>
    {props.children.split(' (')[0]}
  </components.MultiValueLabel>
)

export const generateOptionLabel = ({
  firstName,
  lastName,
  daimlerId,
  email,
}) => `${firstName} ${lastName} (${daimlerId}${email ? ' - ' + email : ''})`

export const generateUsersListForSelect = (response, filterOutputFn) => {
  if (!response?.content) {
    throw new Error('Response content not valid!')
  }
  return filterUsersList(response.content, filterOutputFn).map(
    generateSelectOption,
  )
}

export const filterUsersList = (users, filterOutputFn) =>
  filterOutputFn ? users.filter(filterOutputFn) : users

export const generateSelectOption = (user) => {
  const { daimlerId } = user
  return {
    value: daimlerId,
    label: generateOptionLabel(user),
    color: '#00B8D9',
    isFixed: true,
  }
}

export const generateActivePageNumber = (numberOfRecords, pageSize) =>
  Math.ceil(numberOfRecords / pageSize)

export const UsersAsyncMultiSelect = ({
  isMulti = true,
  disabled = false,
  closeMenuOnSelect = false,
  loadOptionsOnMenuOpen = false,
  pageSize = 20,
  placeholder = 'Search users',
  selectedUsers,
  onUserSelect,
  filterOutputFn,
}) => {
  const [search, setSearch] = useState('')

  const loadOptions = async (searchTerm, loadedOptions) => {
    const response =
      searchTerm &&
      (await findUsers(
        searchTerm.toLowerCase(),
        generateActivePageNumber(loadedOptions.length, pageSize),
        pageSize,
        process.env.NODE_ENV !== 'production',
        true,
      ))
    const options = generateUsersListForSelect(response, filterOutputFn)
    return {
      options,
      hasMore: !response?.last,
    }
  }

  const onInputChange = isMulti
    ? (value, { action }) => {
        if (action === 'set-value') {
          setSearch('')
        }
        setSearch(value)
      }
    : (value) => setSearch(value)

  return (
    <AsyncPaginate
      isMulti={isMulti}
      isDisabled={disabled}
      inputValue={search}
      debounceTimeout={300}
      onInputChange={onInputChange}
      className="autocomplete"
      classNamePrefix="as"
      placeholder={placeholder}
      loadOptions={loadOptions}
      value={selectedUsers}
      onChange={onUserSelect}
      loadOptionsOnMenuOpen={loadOptionsOnMenuOpen}
      closeMenuOnSelect={closeMenuOnSelect}
      components={{
        MultiValueLabel,
      }}
    />
  )
}

MultiValueLabel.propTypes = {
  children: PropTypes.string,
}

UsersAsyncMultiSelect.propTypes = {
  isMulti: PropTypes.bool,
  pageSize: PropTypes.number,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  loadOptionsOnMenuOpen: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  selectedUsers: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  ]),
  onUserSelect: PropTypes.func,
  filterOutputFn: PropTypes.func,
}
