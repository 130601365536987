export const REQUEST_TENANTS = 'REQUEST_TENANTS'
export const CHANGE_API_ACCESS = 'CHANGE_API_ACCESS'
export const RECEIVE_WHITELIST = 'RECEIVE_WHITELIST'
export const RECEIVE_ROLES = 'RECEIVE_ROLES'
export const RECEIVE_SCHEMA = 'RECEIVE_SCHEMA'
export const SET_ARTS = 'SET_ARTS'
export const RECEIVE_TENANTS = 'RECEIVE_TENANTS'
export const RECEIVE_COSTSMANAGEMENT = 'RECEIVE_COSTSMANAGEMENT'
export const SET_MEMBER_EXPIRING_DATE_RANGE = 'SET_MEMBER_EXPIRING_DATE_RANGE'
export const FILTER_TENANTS = 'FILTER_TENANTS'
export const SELECT_TENANT = 'SELECT_TENANT'
export const DELETE_TENANT = 'DELETE_TENANT'
export const UPDATE_TENANT = 'UPDATE_TENANT'
export const SET_TENANT_BOOKINGS = 'SET_TENANT_BOOKINGS'
export const SET_CURRENT_BOOKING_MEMBERS = 'SET_CURRENT_BOOKING_MEMBERS'
export const SET_CURRENT_BOOKING_SERVICE_PERMISSIONS =
  'SET_CURRENT_BOOKING_SERVICE_PERMISSIONS'
export const SET_CURRENT_BOOKING_SERVICES = 'SET_CURRENT_BOOKING_SERVICES'
export const SET_CURRENT_BOOKING_PERMISSIONS = 'SET_CURRENT_BOOKING_PERMISSIONS'
export const DELETE_BOOKING_SERVICE_PERMISSION =
  'DELETE_BOOKING_SERVICE_PERMISSION'
export const SET_CURRENT_BOOKING_SERVICE_PROPERTIES =
  'SET_CURRENT_BOOKING_SERVICE_PROPERTIES'
export const SET_CURRENT_BOOKING_SERVICE_PROPERTY =
  'SET_CURRENT_BOOKING_SERVICE_PROPERTY'
export const REMOVE_BOOKING_SERVICE_PROPERTY = 'REMOVE_BOOKING_SERVICE_PROPERTY'
export const UPDATE_BOOKING_SERVICE_PROPERTY = 'UPDATE_BOOKING_SERVICE_PROPERTY'
export const CHANGE_AUTH = 'CHANGE_AUTH'
export const CHANGE_EDITING = 'CHANGE_EDITING'
export const CHANGE_CREATING = 'CHANGE_CREATING'
export const CHANGE_DELETING = 'CHANGE_DELETING'
export const CHANGE_SAVING = 'CHANGE_SAVING'
export const START_SAVING = 'START_SAVING'
export const CHANGE_SERVER_ERRORS = 'CHANGE_SERVER_ERRORS'
export const CHANGE_MODAL = 'CHANGE_MODAL'
export const CHANGE_FORM_DATA = 'CHANGE_FORM_DATA'
export const CHANGE_NAV_MENU = 'CHANGE_NAV_MENU'
export const REQUEST_ROLES = 'REQUEST_ROLES'
export const SET_ROLES = 'SET_ROLES'
export const SET_PACKAGES = 'SET_PACKAGES'
export const SET_SERVICE = 'SET_SERVICE'
export const ADD_PACKAGE = 'ADD_PACKAGE'
export const ADD_PACKAGE_PRICE = 'ADD_PACKAGE_PRICE'
export const UPDATE_PACKAGE_PRICE = 'UPDATE_PACKAGE_PRICE'
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE'
export const SET_SERVICES = 'SET_SERVICES'
export const ADD_PLATFORM_SERVICE = 'ADD_PLATFORM_SERVICE'
export const UPDATE_PLATFORM_SERVICE = 'UPDATE_PLATFORM_SERVICE'
export const ATTACH_SERVICE_TO_PACKAGE = 'ATTACH_SERVICE_TO_PACKAGE'
export const DETACH_SERVICE_FROM_PACKAGE = 'DETACH_SERVICE_FROM_PACKAGE'
export const SET_PACKAGE_SERVICES = 'SET_PACKAGE_SERVICES'
export const SET_PACKAGE_PRICES = 'SET_PACKAGE_PRICES'
export const SET_ACTIVE_PACKAGE_PRICE = 'SET_ACTIVE_PACKAGE_PRICE'
export const SET_PACKAGE = 'SET_PACKAGE'
export const SET_MODAL = 'SET_MODAL'
export const SET_SERVICE_PERMISSION = 'SET_SERVICE_PERMISSION'
export const SET_SERVICE_PERMISSIONS = 'SET_SERVICE_PERMISSIONS'
export const ADD_PERMISSION_TO_SERVICE = 'ADD_PERMISSION_TO_SERVICE'
export const UPDATE_SERVICE_PERMISSION = 'UPDATE_SERVICE_PERMISSION'
export const REMOVE_PERMISSION_FROM_SERVICE = 'REMOVE_PERMISSION_FROM_SERVICE'
export const SET_SERVICE_PROPERTIES = 'SET_SERVICE_PROPERTIES'
export const REMOVE_SERVICE_PROPERTY = 'REMOVE_SERVICE_PROPERTY'
export const SET_PROPERTY = 'SET_PROPERTY'
export const ADD_SERVICE_PROPERTY = 'ADD_SERVICE_PROPERTY'
export const UPDATE_SERVICE_PROPERTY = 'UPDATE_SERVICE_PROPERTY'
export const POST_NOTIFICATION = 'POST_NOTIFICATION'
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION'
export const SET_CROSS_CHARGING_OVERVIEW = 'SET_CROSS_CHARGING_OVERVIEW'
export const SET_CURRENT_TENANT_CROSS_CHARGING_OVERVIEW =
  'SET_CURRENT_TENANT_CROSS_CHARGING_OVERVIEW'
export const SET_PACKAGE_PERMISSIONS = 'SET_PACKAGE_PERMISSIONS'
export const SET_CURRENT_TENANT_MEMBERS = 'SET_CURRENT_TENANT_MEMBERS'
export const SET_PACKAGE_PRICE_VARIANTS = 'SET_PACKAGE_PRICE_VARIANTS'
export const ADD_PACKAGE_PRICE_VARIANT = 'ADD_PACKAGE_PRICE_VARIANT'
export const UPDATE_PACKAGE_PRICE_VARIANT = 'UPDATE_PACKAGE_PRICE_VARIANT'
export const REMOVE_PACKAGE_PRICE_VARIANT = 'REMOVE_PACKAGE_PRICE_VARIANT'
export const ADD_WAITING_PERMISSIONS = 'ADD_WAITING_PERMISSIONS'
export const SET_TENANT_COMPLIANCE_INFO = 'SET_TENANT_COMPLIANCE_INFO'
export const REMOVE_WAITING_PERMISSIONS = 'REMOVE_WAITING_PERMISSIONS'
export const SET_TENANT_ROLES = 'SET_TENANT_ROLES'
export const SET_ALL_USERS = 'SET_ALL_USERS'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_AKAMAI_CP_CODES = 'SET_AKAMAI_CP_CODES'
export const TOGGLE_CP_CODE = 'TOGGLE_CP_CODE'
export const TOGGLE_AKAMAI_LOADING_STATE = 'TOGGLE_AKAMAI_LOADING_STATE'
export const TOGGLE_AKAMAI_INVALIDATING_STATE =
  'TOGGLE_AKAMAI_INVALIDATING_STATE'
export const CLEAN_AKAMAI_SELECTED_CP_CODES = 'CLEAN_AKAMAI_SELECTED_CP_CODES'
export const SET_AKAMAI_SERVER_MESSAGES = 'SET_AKAMAI_SERVER_MESSAGES'
export const CLEAN_AKAMAI_STATE = 'CLEAN_AKAMAI_STATE'
export const CLEAN_AKAMAI_SERVER_MESSAGES = 'CLEAN_AKAMAI_SERVER_MESSAGES'
export const SET_SERVICE_PROVIDERS = 'SET_SERVICE_PROVIDERS'
export const SET_PROVIDERS = 'SET_PROVIDERS'
export const SET_PROVIDER = 'SET_PROVIDER'
export const SET_PROVIDER_USERS = 'SET_PROVIDER_USERS'
export const SET_PROVIDER_USER = 'SET_PROVIDER_USER'
export const SET_PROVIDER_ROLES = 'SET_PROVIDER_ROLES'
export const SET_TDMS_USERS_WITH_ROLES = 'SET_TDMS_USERS_WITH_ROLES'
export const SET_BACKOFFICE_BOOKINGS = 'SET_BACKOFFICE_BOOKINGS'
export const SET_BACKOFFICE_BOOKING = 'SET_BACKOFFICE_BOOKING'
export const SET_WEB_HOOKS = 'SET_WEB_HOOKS'
export const SET_WEB_HOOKS_PRODUCERS = 'SET_WEB_HOOKS_PRODUCERS'
export const SET_WEB_HOOKS_PROVIDERS = 'SET_WEB_HOOKS_PROVIDERS'
export const SET_WEB_HOOKS_EVENTS = 'SET_WEB_HOOKS_EVENTS'
export const SET_WEB_HOOK = 'SET_WEB_HOOK'
export const SET_APIKEYS = 'SET_APIKEYS'
