import React from 'react'
import PropTypes from 'prop-types'
import { CCOHeader } from '../components/CCOHeader'
import { YearlyReport } from '../components/YearlyReport'
import {
  Accordion,
  AccordionItem,
  AccordionToggle,
  AccordionSection,
  AccordionMobileHeader,
  AccordionContent,
} from '../../../../common'

export const CrossChargingOverviewComponent = ({
  providers,
  selectedYear,
  reportsByYear,
  setSelectedYear,
}) => (
  <div className="page-content admin-panel">
    <div className="page-body__section">
      <h3 className="wb-type-heading-m page-body__margin-bottom-s">
        Cross Charging Overview
      </h3>
      <Accordion theme="light">
        <AccordionItem>
          <AccordionToggle>
            <div>Show</div>
          </AccordionToggle>
          <AccordionSection>
            <AccordionMobileHeader>Close</AccordionMobileHeader>
            <AccordionContent>
              {reportsByYear ? (
                <div className="cross-charging">
                  <CCOHeader
                    selectedYear={selectedYear}
                    handleYearChange={(e) =>
                      setSelectedYear(parseInt(e.target.value))
                    }
                    years={Object.keys(reportsByYear).map((year) =>
                      parseInt(year),
                    )}
                  />
                  <YearlyReport
                    year={selectedYear}
                    providers={providers}
                    reportsByYear={reportsByYear}
                  />
                </div>
              ) : (
                'No report'
              )}
            </AccordionContent>
          </AccordionSection>
        </AccordionItem>
      </Accordion>
    </div>
  </div>
)
CrossChargingOverviewComponent.propTypes = {
  selectedYear: PropTypes.number,
  reportsByYear: PropTypes.object,
  providers: PropTypes.array,
  setSelectedYear: PropTypes.func,
}
