import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setModal } from '../../../../../../../../../data/actions'
import { ProviderDetailsComponent } from '../component'

const ProviderDetailsContainer = ({ provider, dispatchModal }) => {
  const onClickEdit = () =>
    dispatchModal({
      variant: 'providerForm',
      providerSlug: provider.slug,
      header: 'Edit Provider',
      mode: 'edit',
      onCancel: () => {
        dispatchModal({})
      },
    })

  return provider ? (
    <ProviderDetailsComponent provider={provider} onClickEdit={onClickEdit} />
  ) : null
}

export const ProviderDetails = connect(
  ({ backOffice: { provider } }) => ({
    provider,
  }),
  {
    dispatchModal: setModal,
  },
)(ProviderDetailsContainer)

ProviderDetailsContainer.propTypes = {
  provider: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.string,
  }),
  dispatchModal: PropTypes.func,
}
