import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  setModal,
  getPackageServices,
  getPackagePriceVariants,
  archivePackage,
} from '../../../../../../../data/actions'
import { CurrentPackageComponent } from '../component'

const CurrentPackageContainer = ({
  activePackage,
  dispatchModal,
  getServices,
  getPriceVariants,
  movePackageToArchive,
}) => {
  useEffect(() => {
    if (activePackage) {
      getServices(activePackage)
      getPriceVariants()
    }
  }, [activePackage])

  const onClickEdit = () =>
    dispatchModal({
      variant: 'package',
      mode: 'edit',
      onApprove: () => {},
      onCancel: () => dispatchModal({}),
    })

  const onClickArchive = () =>
    dispatchModal({
      variant: 'prompt',
      okText: 'Archive',
      header: `This operration cannot be undone! Are you sure you want to archive the current package (${activePackage.name}) ?`,
      onApprove: () => {
        movePackageToArchive(activePackage, () => dispatchModal({}))
      },
      onCancel: () => dispatchModal({}),
    })

  return (
    activePackage && (
      <CurrentPackageComponent
        activePackage={activePackage}
        onClickEdit={onClickEdit}
        onClickArchive={onClickArchive}
      />
    )
  )
}

CurrentPackageContainer.propTypes = {
  activePackage: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    packageKey: PropTypes.string,
    description: PropTypes.string,
  }),
  dispatchModal: PropTypes.func,
  getServices: PropTypes.func,
  getPriceVariants: PropTypes.func,
  movePackageToArchive: PropTypes.func,
}

export const CurrentPackage = connect(
  ({ backOffice }) => ({
    activePackage: backOffice.package,
  }),
  {
    dispatchModal: setModal,
    getServices: getPackageServices,
    getPriceVariants: getPackagePriceVariants,
    movePackageToArchive: archivePackage,
  },
)(CurrentPackageContainer)
