import React from 'react'
import PropTypes from 'prop-types'
import {
  TanstackTableWrapper,
  TablePagination,
} from '../../../../../../../../../common'
import { ProviderUsersTableHead } from '../ProviderUsersTableHead'

export const UsersListComponent = ({
  searchTerm,
  showBatchActions,
  isEditable,
  table,
  tableActions,
}) => (
  <div className="provider-users">
    <ProviderUsersTableHead
      searchTerm={searchTerm}
      isEditable={isEditable}
      showBatchActions={showBatchActions}
      actions={tableActions}
    />
    <TanstackTableWrapper table={table} isEditable={isEditable} />
    <TablePagination table={table} />
  </div>
)

UsersListComponent.propTypes = {
  searchTerm: PropTypes.string,
  showBatchActions: PropTypes.bool,
  isEditable: PropTypes.bool,
  table: PropTypes.shape({
    getHeaderGroups: PropTypes.func,
    getRowModel: PropTypes.func,
  }),
  tableActions: PropTypes.shape({
    deselectAllUsers: PropTypes.func,
    batchRemoveMembers: PropTypes.func,
    addNewMembers: PropTypes.func,
    onChangeSearch: PropTypes.func,
  }),
}
