import React from 'react'
import PropTypes from 'prop-types'
import { Hint } from '../../../../../../../../../common'

export const LabeledHint = ({ label, hint }) => (
  <div>
    <div>{label}</div>
    <Hint className="compliance-form__hint--italic" text={hint} />
  </div>
)

LabeledHint.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
}
