import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import 'github-markdown-css/github-markdown.css'
import { getMemberByRoles } from '../../../../../util'
import { TenantDashboardSection } from '../../../../common'
import { InfoItem } from './components/InfoItem'

export const TenantBasicInfo = ({
  info: {
    members,
    status,
    name,
    providerName,
    description,
    disabledPlanningIT,
    continuity,
    personalData,
    planningItId,
    icto,
    confidentiality,
    integrity,
    availability,
    partOfDigitalHouse,
    programName,
    awsAccountId,
    agileReleaseTrain,
  },
  needsComplianceValidation,
}) => {
  const [budgetApprover] = getMemberByRoles(members, ['BUDGET_APPROVER'])
  const [isa] = getMemberByRoles(members, ['ISA'])
  const [controller] = getMemberByRoles(members, ['CONTROLLER'])

  const memberToMailLink = (member) =>
    member ? (
      <a href={`mailto:${member.email}`}>
        {member.firstName} {member.lastName}
      </a>
    ) : (
      'unknown'
    )
  const detailsValues = [
    [
      ['Part of the Digital House', partOfDigitalHouse ? 'Yes' : 'No'],
      ['Agile Release Train (ART)', agileReleaseTrain || 'unknown'],
      ['Program Name', programName || 'unknown'],
    ],
    [
      ['Budget Approver', memberToMailLink(budgetApprover)],
      ['Controller', memberToMailLink(controller)],
      ['Information Security Architect', memberToMailLink(isa)],
    ],
  ]

  if (awsAccountId) {
    detailsValues[1].push(['AWS account ID', awsAccountId])
  }

  return (
    <>
      <InfoItem title="Status" value={status} />
      <InfoItem title="Name" value={name} />
      <InfoItem title="Provider" value={providerName} />

      <div className="tenant-body__block">
        <div className="tenant-body__block-label">Description</div>
        <div className="markdown-body">
          <ReactMarkdown remarkPlugins={[gfm]}>{description}</ReactMarkdown>
        </div>
      </div>

      <TenantDashboardSection values={detailsValues} title="Details" />

      {needsComplianceValidation && (
        <div className="tenant-body__table warning warning-alert">
          <div className="warning-alert__label">
            Compliance data needing your validation.
          </div>
          <p className="warning-alert__value">
            Please click the "Validate Compliance Data" button,
            <br />
            edit the data (if needed) and confirm by clicking "Validate and
            Save".
          </p>
        </div>
      )}

      <div className="tenant-body__block warning warning-bottomline">
        <div className="tenant-body__block-label">Compliance</div>
        <div className="tenant-body__block-inner-grid">
          <div>
            <InfoItem
              title="Not Obliged To Document In APP-ID (LeanIX)"
              value={disabledPlanningIT ? 'Yes' : 'No'}
              subItem
            />
            {!disabledPlanningIT && (
              <div>
                <InfoItem title="Icto" value={icto} subItem />
                <InfoItem
                  title="APP-ID (LeanIX)"
                  value={planningItId}
                  subItem
                />
              </div>
            )}
          </div>

          <div>
            <InfoItem title="Confidentiality" value={confidentiality} subItem />
            <InfoItem title="Integrity" value={integrity} subItem />
            <InfoItem title="Availability" value={availability} subItem />
            <InfoItem
              title="Continuity"
              value={continuity ? 'Yes' : 'No'}
              subItem
            />
            <InfoItem
              title="Personal Data"
              value={personalData ? 'Yes' : 'No'}
              subItem
            />
          </div>
        </div>
      </div>
    </>
  )
}

TenantBasicInfo.propTypes = {
  info: PropTypes.shape({
    agileReleaseTrain: PropTypes.string,
    providerName: PropTypes.string,
    availability: PropTypes.string,
    awsAccountId: PropTypes.string,
    confidentiality: PropTypes.string,
    continuity: PropTypes.bool,
    description: PropTypes.string,
    disabledPlanningIT: PropTypes.bool,
    icto: PropTypes.number,
    integrity: PropTypes.string,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        daimlerId: PropTypes.string,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    ),
    name: PropTypes.string,
    partOfDigitalHouse: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    personalData: PropTypes.bool,
    planningItId: PropTypes.number,
    programName: PropTypes.string,
    status: PropTypes.string,
  }),
  needsComplianceValidation: PropTypes.bool,
}
