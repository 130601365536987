import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import validate from '../../../../../../util/helpers/validate'
import {
  Button,
  Input,
  ModalContent,
  ModalFooter,
  Select,
  Card,
} from '../../../../../common'
import { copyToClipBoard } from '../../../../../admins/components/utils/helpers'

export const controls = {
  description: {
    value: '',
    valid: false,
    touched: false,
    validationRules: {
      minLength: 3,
    },
  },
  permission: {
    value: '',
    valid: false,
    touched: false,
    validationRules: {
      oneOf: ['RO', 'RW'],
    },
  },
  externalAuthSystemTokenIssuer: {
    value: '',
    valid: false,
    touched: false,
    validationRules: {
      isValidUrl: true,
    },
  },
}

export const checkSubmitDisabled = (controlsState) =>
  !controlsState.permission.valid || !controlsState.description.valid

export const ApiKeyForm = ({ title, onApprove, onClose }) => {
  const operationTypes = [
    { id: 'RO', name: 'Read Only (RO)' },
    { id: 'RW', name: 'Read Write (RW)' },
  ]

  const [state, setState] = useState(controls)
  const [token, setToken] = useState('')

  useEffect(() => {
    return () => setToken('')
  }, [])

  const updateInputState = (control, value) => {
    setState({
      ...state,
      [control]: {
        ...state[control],
        value,
        valid: validate(value, state[control].validationRules),
        touched: true,
      },
    })
  }

  const onSubmit = () => {
    onApprove(
      {
        description: state.description.value,
        permission: state.permission.value,
      },
      (apiToken) => setToken(apiToken),
    )
  }

  return (
    <ModalContent headline={title}>
      {!token ? (
        <>
          <p>Please add the following details to create new API Key</p>
          <div className="wb-margin-top-s wb-margin-bottom-xs">
            <Input
              label="Description"
              placeholder="Key purpose"
              value={state.description.value}
              onChange={(e) => updateInputState('description', e.target.value)}
            />
          </div>
          <div className="wb-margin-bottom-s">
            <Select
              label="Permission"
              placeholder="Choose One"
              options={operationTypes}
              value={state.permission.value}
              onChange={(e) => updateInputState('permission', e.target.value)}
            />
          </div>
          <ModalFooter>
            <Button variant="secondary" size="small" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              size="small"
              onClick={onSubmit}
              disabled={checkSubmitDisabled(state)}
            >
              Create API Key
            </Button>
          </ModalFooter>
        </>
      ) : (
        <div>
          <table className="wb-margin-bottom-s">
            <thead></thead>
            <tbody>
              <tr>
                <td className="wb-padding-end-s">Description</td>
                <td>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    {state.description.value}
                  </p>
                </td>
              </tr>
              <tr>
                <td className="wb-padding-end-s">Permission</td>
                <td>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    {state.permission.value}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <p
              className="wb-type-heading-xs wb-margin-bottom-xxs"
              style={{ color: 'black' }}
            >
              API Key
            </p>
          </div>
          <Card>
            <div
              className="wb-margin-xxs"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span>{token}</span>
              <Button
                variant="primary"
                size="small"
                withIcon="copy-clipboard/24"
                iconOnly
                onClick={() => copyToClipBoard(token)}
              />
            </div>
          </Card>
          <div className="wb-margin-s" style={{ display: 'flex' }}>
            <wb-icon
              name="bds/info-circle/16"
              style={{ width: '60px', color: '#0073e6' }}
            ></wb-icon>
            <p>
              <span className="wb-type-copy-strong">Important:</span> Once
              closed, the API key will not be visible again. Make sure you store
              the API Key before closing this window.
            </p>
          </div>
          <ModalFooter
            style={{ display: 'flex', flexDirection: 'row-reverse' }}
          >
            <Button variant="primary" size="small" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </div>
      )}
    </ModalContent>
  )
}

ApiKeyForm.propTypes = {
  title: PropTypes.string,
  onApprove: PropTypes.func,
  onClose: PropTypes.func,
}
