import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  setModal,
  removeTenantBooking,
  addTenantBooking,
  updateTenantBooking,
  getTenantBookings,
  setBookings,
} from '../../../../../data/actions'
import { BookingsComponent } from '../component'

const BookingsContainer = ({
  tenants,
  bookings,
  getBookings,
  addBooking,
  updateBooking,
  updateBookings,
  deleteBooking,
  dispatchModal,
}) => {
  const [selectedTenant, setSelectedTenant] = useState({ value: '', label: '' })
  useEffect(() => {
    if (selectedTenant) {
      getBookings(selectedTenant.value)
    }
    return () => updateBookings([])
  }, [selectedTenant])

  const onCancel = () => dispatchModal({})
  const onDeleteBooking = (booking) => {
    dispatchModal({
      variant: 'prompt',
      okText: 'Remove',
      onApprove: () => deleteBooking(selectedTenant.value, booking.id),
      header: 'Are you sure you want to delete selected Booking?',
      onCancel,
    })
  }

  const onEditBooking = (booking) =>
    dispatchModal({
      variant: 'booking',
      mode: 'edit',
      onApprove: (bookingData) =>
        updateBooking(selectedTenant.value, booking.id, bookingData),
      header: 'Edit booking',
      booking: booking,
      onCancel,
    })

  const onAddBooking = () =>
    dispatchModal({
      variant: 'booking',
      mode: 'add',
      onApprove: (priceVariantId, bookingData) =>
        addBooking(selectedTenant.value, priceVariantId, bookingData),
      header: 'Add booking',
      onCancel,
    })
  const addBookingDisabled = !selectedTenant?.value

  return (
    <BookingsComponent
      tenants={tenants}
      selectedTenant={selectedTenant}
      addBookingDisabled={addBookingDisabled}
      bookings={bookings}
      actions={{
        setSelectedTenant,
        onEditBooking,
        onDeleteBooking,
        onAddBooking,
      }}
    />
  )
}

export const tenantOptionPropType = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
})

const bookingPropType = PropTypes.shape({
  id: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  comment: PropTypes.string,
  tenantName: PropTypes.string,
  tenantAcronym: PropTypes.string,
  packageName: PropTypes.string,
  packageDisplayName: PropTypes.string,
  packageAllowBookingPermissions: PropTypes.bool,
  packageSlug: PropTypes.string,
  provider: PropTypes.string,
  variantName: PropTypes.string,
  variantSlug: PropTypes.string,
})

BookingsContainer.propTypes = {
  tenants: PropTypes.arrayOf(tenantOptionPropType),
  bookings: PropTypes.arrayOf(bookingPropType),
  getBookings: PropTypes.func,
  addBooking: PropTypes.func,
  updateBooking: PropTypes.func,
  deleteBooking: PropTypes.func,
  dispatchModal: PropTypes.func,
  updateBookings: PropTypes.func,
}

export const Bookings = connect(
  ({ sso: { roles }, tenants: { allTenants }, backOffice: { bookings } }) => ({
    tenants: Object.values(allTenants)
      .filter(({ status }) => status !== 'archived')
      .map(({ acronym, name, providerSlug }) => ({
        value: acronym,
        label: `${name} (${acronym})`,
        providerSlug,
      })),
    bookings,
  }),
  {
    dispatchModal: setModal,
    deleteBooking: removeTenantBooking,
    addBooking: addTenantBooking,
    updateBooking: updateTenantBooking,
    getBookings: getTenantBookings,
    updateBookings: setBookings,
  },
)(BookingsContainer)
