import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { TenantForm } from '../TenantForm'

export const Edit = ({
  arts,
  isSaving,
  serverErrors,
  tenantInfo,
  onCancelClick,
  onSaveClick,
}) => (
  <div className="tenant-content">
    <table
      className={classnames('tenants-edit tenants-info', {
        'tenants-edit--saving': isSaving,
        'tenants-edit--serverErrors': serverErrors.hasErrors,
      })}
    >
      <thead>
        <tr>
          <th></th>
        </tr>
      </thead>
      <tbody className="tenants-edit__content">
        <tr>
          <td colSpan="4">
            {serverErrors.hasErrors && (
              <div
                className="tenants-edit__errors tenants-edit__errors--server"
                style={{ background: 'red' }}
              >
                {serverErrors.message}
              </div>
            )}
            <TenantForm
              tenant={tenantInfo}
              arts={arts}
              onCancel={onCancelClick}
              onSave={onSaveClick}
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)

Edit.propTypes = {
  tenantInfo: PropTypes.object,
  isSaving: PropTypes.bool,
  arts: PropTypes.array,
  serverErrors: PropTypes.object,
  onSaveClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
}
