import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { useHistory } from 'react-router-dom'
import debounce from 'lodash/debounce'
import {
  filterTenants,
  changeEditing,
  changeCreating,
} from '../../../../data/actions'
import { scrollTop } from '../../../../util'
import { trackPageView } from '../../../../util/analytics'
import { AppsNavComponent } from '../component'
import {
  generateFilteredTenantsList,
  generateFilters,
  reduceTenants,
  tenantPropType,
} from './utils'

const AppsNavContainer = (props) => {
  const {
    selectedTenant,
    username,
    allTenants,
    isEditing,
    providersList,
    changeCreatingFn,
    changeEditingFn,
    filterTenantsFn,
    ...rest
  } = props

  const tenantFilters = generateFilters(allTenants, providersList, username)
  const history = useHistory()
  const [state, setState] = useState({
    filterCategories: [],
    inputState: '',
  })
  const [narrowViewport, setNarrowViewport] = useState(false)

  const handleResize = () => setNarrowViewport(window.innerWidth < 768)

  const onInputChange = (val) => {
    setState({
      ...state,
      inputState: val,
    })
  }

  const onItemClick = (acronym) => {
    history.replace(`/apps/${acronym}`)
    if (isEditing || selectedTenant === acronym) {
      return
    }
    scrollTop()
  }

  const onNewClick = () => {
    changeCreatingFn(true)
    changeEditingFn(true)
  }

  const setFilters = (newFilter) => {
    const newFilters = [...state.filterCategories]
    const filterIndex = newFilters.findIndex(
      (filterItem) => filterItem === newFilter,
    )
    if (filterIndex > -1) {
      newFilters.splice(filterIndex, 1)
    } else {
      newFilters.push(newFilter)
    }
    setState({
      ...state,
      filterCategories: newFilters,
    })
  }

  const resetFilters = () =>
    setState({
      inputState: '',
      filterCategories: [],
    })

  const handleSelectionChange = (selectedItemData) => {
    if (!selectedItemData) {
      return
    }
    const { selected } = selectedItemData
    trackPageView({
      name: selected.id,
      hier1: 'apps',
    })
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', debounce(handleResize), 200)
    return () =>
      window.removeEventListener('resize', debounce(handleResize), 200)
  }, [])

  return (
    <AppsNavComponent
      state={state}
      isEditing={isEditing}
      selectedTenant={selectedTenant}
      narrowViewport={narrowViewport}
      {...rest}
      filteredTenants={generateFilteredTenantsList(
        tenantFilters,
        state,
        allTenants,
      )}
      filters={tenantFilters}
      actions={{
        onInputChange,
        onItemClick,
        onNewClick,
        handleSelectionChange,
        reduceTenants,
        setFilters,
        resetFilters,
      }}
    />
  )
}

AppsNavContainer.propTypes = {
  isFetching: PropTypes.bool,
  apiAccess: PropTypes.bool,
  isEditing: PropTypes.bool,
  isSaving: PropTypes.bool,
  isWhitelisted: PropTypes.bool,
  filterTerm: PropTypes.string,
  selectedTenant: PropTypes.string,
  username: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
  providersList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  allTenants: PropTypes.objectOf(PropTypes.shape(tenantPropType)),
  filterTenantsFn: PropTypes.func,
  changeEditingFn: PropTypes.func,
  changeCreatingFn: PropTypes.func,
}

export const Nav = connect(
  ({
    tenants: {
      isFetching,
      apiAccess,
      filterTerm,
      filteredTenants,
      selectedTenant,
      isEditing,
      isSaving,
      isWhitelisted,
      roles,
      allTenants,
    },
    sso: { username },
    backOffice: { providers },
  }) => ({
    isFetching,
    apiAccess,
    filterTerm,
    filteredTenants,
    allTenants,
    selectedTenant,
    isEditing,
    isSaving,
    isWhitelisted,
    roles,
    username,
    providersList: providers.map(({ name, slug }) => ({
      label: name,
      value: slug,
    })),
  }),
  {
    filterTenantsFn: filterTenants,
    changeEditingFn: changeEditing,
    changeCreatingFn: changeCreating,
  },
)(AppsNavContainer)
