import React from 'react'
import PropTypes from 'prop-types'

export const Accordion = ({ theme = 'light', children }) => (
  <wb-accordion theme={theme} prevent-mobile-flyout>
    {children}
  </wb-accordion>
)

Accordion.propTypes = {
  theme: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
