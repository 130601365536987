import React from 'react'
import PropTypes from 'prop-types'

const displayTableCell = { display: 'table-cell' }
export const BookingServiceDetails = ({ service }) => (
  <div style={{ display: 'table' }}>
    <div className="row" style={{ display: 'table-row' }}>
      <div
        className="wb-type-copy"
        style={{ width: '7rem', display: 'table-cell' }}
      >
        Name:
      </div>
      <div className="wb-type-copy-strong" style={displayTableCell}>
        {service.name}
      </div>
    </div>
    <div className="row" style={{ display: 'table-row' }}>
      <div
        className="wb-type-copy"
        style={{ width: '7rem', ...displayTableCell }}
      >
        Provider:
      </div>
      <div className="wb-type-copy-strong" style={displayTableCell}>
        {service.providerSlug}
      </div>
    </div>
    <div className="row" style={{ display: 'table-row' }}>
      <div
        className="wb-type-copy"
        style={{ width: '7rem', ...displayTableCell }}
      >
        Description:
      </div>
      <div className="wb-type-copy-strong">{service.description}</div>
    </div>
    <div className="row" style={{ display: 'table-row' }}>
      <div
        className="wb-type-copy"
        style={{ width: '7rem', ...displayTableCell }}
      >
        Slug:
      </div>
      <div className="wb-type-copy-strong" style={displayTableCell}>
        {service.slug}
      </div>
    </div>
  </div>
)

BookingServiceDetails.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string,
    providerSlug: PropTypes.string,
    description: PropTypes.string,
    slug: PropTypes.string,
    serviceKey: PropTypes.string,
  }),
}
