import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '..'
import { TooltipHost } from './TooltipHost'

export const Tooltip = ({ message, className, position, icon }) => (
  <span className={className}>
    <TooltipHost position={position} message={message}>
      <Button
        size="small"
        iconOnly
        withIcon={icon}
        className="wb-round-button wb-round-button--solid-level-0 wb-round-button--small"
      />
    </TooltipHost>
  </span>
)

Tooltip.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  position: PropTypes.string,
  icon: PropTypes.string,
}
