import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { getPackages } from '../../../data/actions'
import { BackOfficeComponent } from '../component'

const BackOfficeContainer = ({ isAdmin, isProviderUser, listPackages }) => {
  const { tab } = useParams()
  const history = useHistory()

  const onTabChange = (currentTab) => {
    history.replace(`/backoffice/${currentTab}`)
  }
  useEffect(() => {
    listPackages()
  }, [])
  const selectedTab = tab || 'bookings'

  return (
    <BackOfficeComponent
      isAdmin={isAdmin}
      isProviderUser={isProviderUser}
      selectedTab={selectedTab}
      onTabChange={onTabChange}
    />
  )
}

BackOfficeContainer.propTypes = {
  isAdmin: PropTypes.bool,
  isProviderUser: PropTypes.bool,
  listPackages: PropTypes.func,
}

export const BackOffice = connect(
  ({ tenants: { isAlmighty }, sso: { providerRoles } }) => ({
    isAdmin: isAlmighty,
    isProviderUser: Boolean(Object.keys(providerRoles).length),
  }),
  {
    listPackages: getPackages,
  },
)(BackOfficeContainer)
