export const BASE_URL = process.env.LOCAL_BACKEND
  ? `${process.env.LOCAL_BACKEND}/tdms/v1`
  : `https://${process.env.TENANTS_API_HOST}/tdms/v1`

export const csrfRef = { current: null }
export const COMMON_HEADERS = {
  credentials: 'include',
  redirect: 'manual',
}
export const rolesUrl = `${BASE_URL}/permissions/roles`
