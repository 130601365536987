import React from 'react'
import PropTypes from 'prop-types'
import { ReactSelect, SearchInput } from '../../../../../common'

export const TableHead = ({
  operationTypes,
  selectedOpTypes,
  allTenants,
  selectedTenants,
  query,
  isTenantScoped,
  actions: { setSelectedOpTypes, setSelectedTenants, setQuery },
}) => (
  <div className="wb-margin-bottom-xs tenant-logs__head">
    <div className="wb-padding-end-xs tenant-logs__filter">
      <div className="tenant-logs__filter-col wb-padding-end-xs">
        <ReactSelect
          options={operationTypes.map(({ type: value, name: label }) => ({
            label,
            value,
          }))}
          value={selectedOpTypes}
          onChange={(types) => setSelectedOpTypes(types)}
          isMulti={true}
          placeholder="Select log types"
        />
      </div>
      {!isTenantScoped ? (
        <div
          className="wb-padding-start-xs tenant-logs__filter-col"
          id="tenantsSelector"
        >
          <ReactSelect
            isMulti={true}
            hasSelectAll={false}
            options={allTenants.map((tnt) => ({ label: tnt, value: tnt }))}
            value={selectedTenants}
            onChange={(tenants) => setSelectedTenants(tenants)}
            placeholder="Select tenants"
          />
        </div>
      ) : null}
    </div>
    <div className="wb-padding-start-xs tenant-logs__filter-col">
      <SearchInput
        label="Search Members"
        placeholder="Please enter search word"
        required
        value={query ?? ''}
        className="tenant-logs__filter-col__search-input"
        onChange={(e) => setQuery(e.target.value)}
      />
    </div>
  </div>
)

TableHead.propTypes = {
  operationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  selectedOpTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  allTenants: PropTypes.arrayOf(PropTypes.string),
  selectedTenants: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  query: PropTypes.string,
  isTenantScoped: PropTypes.bool,
  actions: PropTypes.shape({
    setSelectedOpTypes: PropTypes.func,
    setSelectedTenants: PropTypes.func,
    setQuery: PropTypes.func,
  }),
}
