import React from 'react'
import PropTypes from 'prop-types'
import { ReportByProvider } from '../ReportByProvider'
import { YearlyReportHeader } from '../YearlyReportHeader'
import { Accordion } from '../../../../../common'

const generateProvidersObj = (providers) => {
  const obj = {}
  providers.forEach((provider) => {
    obj[provider.slug] = provider
  })
  return obj
}

export const YearlyReport = ({ year, providers, reportsByYear }) => {
  let yearlyTotal = 0
  const providersObj = generateProvidersObj(providers)
  const reportsByProvider = []
  for (const provider in reportsByYear[year]) {
    yearlyTotal += reportsByYear[year][provider].sum
    reportsByProvider.push(
      <ReportByProvider
        key={provider}
        provider={providersObj[provider].name}
        total={reportsByYear[year][provider].sum}
        packages={reportsByYear[year][provider].packages}
      />,
    )
  }
  return (
    <div className="wb-padding-xs cross-charging__body">
      <YearlyReportHeader year={year} total={yearlyTotal} />
      <Accordion prevent-mobile-flyout>{reportsByProvider}</Accordion>
    </div>
  )
}

YearlyReport.propTypes = {
  providers: PropTypes.array,
  year: PropTypes.number,
  reportsByYear: PropTypes.object,
}
