import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  changeNavMenu,
  fetchTenantsIfNeeded,
  fetchSchemaIfNeeded,
  fetchWhitelistIfNeeded,
  getTenantRoles,
  getAllUsers,
  getArts,
  listProviders,
} from '../../../data/actions'
import { goToPage } from '../../../util'
import { trackLinkOutClick } from '../../../util/analytics'
import { HeaderComponent } from '../component'

export const HREF_GUIDE = 'https://backstage.i.mercedes-benz.com/esg/'
export const HREF_STATUS = 'https://app.datadoghq.eu/dashboard/4ts-k9f-uyc/'

function openInNewTabWithNoopener(href) {
  trackLinkOutClick(href)
  const aTag = document.createElement('a')
  aTag.rel = 'noopener'
  aTag.target = '_blank'
  aTag.href = href
  aTag.click()
}

const HeaderContainer = ({
  isEditing,
  navMenuSelected,
  roles,
  providerRoles,
  changeHashMenu,
  getTenants,
  getSchema,
  getWhiteListed,
  getRoles,
  getProviders,
  getUsers,
  fetchArts,
}) => {
  useEffect(() => {
    getTenants()
    getSchema()
    getWhiteListed()
    getRoles()
    getUsers()
    fetchArts()
    getProviders()
  }, [])
  const isAdmin = roles.includes('admin')
  const isProviderUser = Boolean(Object.keys(providerRoles).length)

  const menuItems = {
    top: [
      {
        path: 'apps',
        title: 'Apps',
        showItem: true,
        onClick: () => onNavMenuChange('apps'),
      },
      {
        path: 'sla',
        title: 'SLA',
        showItem: true,
        onClick: () => onNavMenuChange('sla'),
      },
      {
        title: 'Guide',
        onClick: () => openInNewTabWithNoopener(HREF_GUIDE),
        showItem: true,
        props: {
          target: '_blank',
          href: HREF_GUIDE,
        },
      },
      {
        title: 'Status',
        onClick: () => openInNewTabWithNoopener(HREF_STATUS),
        showItem: true,
        props: {
          target: '_blank',
          href: HREF_STATUS,
        },
      },
      {
        path: 'member/myprofile',
        title: 'My Profile',
        showItem: true,
        onClick: () => onNavMenuChange('member/myprofile'),
      },
    ],
    bottom: [
      {
        path: 'backoffice/bookings',
        title: 'Backoffice*',
        showItem: isAdmin || isProviderUser,
        onClick: () => onNavMenuChange('backoffice/bookings'),
      },
      {
        path: 'admins',
        title: 'Admins*',
        showItem: isAdmin || isProviderUser,
        onClick: () => onNavMenuChange('admins'),
      },
    ],
  }
  const onNavMenuChange = (menuSelected) => {
    changeHashMenu(menuSelected)
    goToPage(`/${menuSelected}`)
  }

  const onClickLogOut = () => window.location.replace(process.env.LOGOUT_URL)
  const goToHelp = () => (window.location.href = '#/sla/nebula-support')
  const goToHome = () => onNavMenuChange('apps')

  return (
    <HeaderComponent
      menuItems={menuItems}
      isEditing={isEditing}
      navMenuSelected={navMenuSelected}
      actions={{ goToHelp, onClickLogOut, goToHome }}
    />
  )
}

HeaderContainer.propTypes = {
  isEditing: PropTypes.bool,
  navMenuSelected: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
  providerRoles: PropTypes.shape({}),
  changeHashMenu: PropTypes.func,
  getTenants: PropTypes.func,
  getSchema: PropTypes.func,
  getWhiteListed: PropTypes.func,
  getRoles: PropTypes.func,
  getUsers: PropTypes.func,
  fetchArts: PropTypes.func,
  getProviders: PropTypes.func,
}

export const Header = connect(
  ({
    tenants: { isEditing },
    global: { navMenuSelected },
    sso: { roles, providerRoles },
  }) => ({
    isEditing,
    navMenuSelected,
    roles,
    providerRoles,
  }),
  {
    changeHashMenu: changeNavMenu,
    getTenants: fetchTenantsIfNeeded,
    getSchema: fetchSchemaIfNeeded,
    getWhiteListed: fetchWhitelistIfNeeded,
    getRoles: getTenantRoles,
    getUsers: getAllUsers,
    fetchArts: getArts,
    getProviders: listProviders,
  },
)(HeaderContainer)
